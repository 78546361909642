import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  MoneyField,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  MaxFeeValues,
  Money,
  NetworkErrorCode,
  SubscriptionPlanParam,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getMoneyObject,
  getNetworkErrorCode,
} from 'services/utils';

export interface Props extends DialogProps {
  onClose: () => void;
  paramKey: SubscriptionPlanParam | null;
}

const getMinValueByParamKey = (key: SubscriptionPlanParam | null) => {
  if (key === SubscriptionPlanParam.MAX_ACTIVE_MEMBERS_COUNT) {
    return 1;
  }
  return 0;
};

const getInitialValue = (
  paramKey: SubscriptionPlanParam,
  value: Money | number | null
) => {
  if (typeof value === 'number') {
    if (!value) return 0;
    return value;
  } else {
    if (!value) return getMinValueByParamKey(paramKey);
    return (value.value / 100).toString();
  }
};

const getValueForSubmit = (
  paramKey: SubscriptionPlanParam,
  value: number | string
) => {
  if (value === '') return null;

  if (
    [
      SubscriptionPlanParam.BASE_FEE,
      SubscriptionPlanParam.ADDITIONAL_USER_FEE,
    ].includes(paramKey) &&
    value === 0
  ) {
    return getMoneyObject(0);
  }

  if (typeof value === 'number') {
    return value;
  } else {
    const intValue = parseFloat(value.toString()) * 100;
    return getMoneyObject(intValue);
  }
};

const EditValueDialog = ({ paramKey: paramKeyNullable, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, subscriptionPlan },
    dispatch,
  } = useGlobalState();
  const paramKey = useMemo(() => paramKeyNullable!, []);
  const [unlimited, setUnlimited] = useState(
    subscriptionPlan[paramKey] === null
  );
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      value: getInitialValue(paramKey, subscriptionPlan[paramKey]),
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = await updateValue(
          paramKey,
          organization!.id,
          getValueForSubmit(paramKey, values.value)
        );
        if (!mounted.current) return;
        dispatch({
          type: 'SET_SUBSCRIPTION_PLAN_DATA',
          payload: {
            subscriptionPlan: {
              ...data,
            },
          },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        switch (getNetworkErrorCode(error)) {
          case NetworkErrorCode.maxUsersThresholdExceeded:
          case NetworkErrorCode.maxVirtualCardsThresholdExceeded:
          case NetworkErrorCode.maxSingleUseCardsThresholdExceeded:
          case NetworkErrorCode.valueMustBePositive:
            enqueueSnackbar(
              t(
                `int.subscriptionSettingsPage.subscriptionPlanParamsSection.editValueDialog.errors.${getNetworkErrorCode(
                  error
                )}`
              ),
              {
                variant: 'error',
              }
            );
            break;
          default:
            enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
            logError(error);
            break;
        }
      }
    },
  });

  const updateValue = async (
    key: SubscriptionPlanParam,
    orgId: string,
    value: Money | number | null
  ) => {
    switch (key) {
      case SubscriptionPlanParam.BASE_FEE:
        return api.updateMonthlyBaseFee(orgId, {
          baseFee: value as Money | null,
        });
      case SubscriptionPlanParam.ADDITIONAL_USER_FEE:
        return api.updateAdditionalUserFee(orgId, {
          additionalUserFee: value as Money | null,
        });
      case SubscriptionPlanParam.FREE_MEMBERS_COUNT:
        return api.updateFreeUsers(orgId, {
          freeMembersCount: value as number | null,
        });
      case SubscriptionPlanParam.MAX_ACTIVE_MEMBERS_COUNT:
        return api.updateMaxUsers(orgId, {
          maxActiveMembersCount: value as number | null,
        });
      case SubscriptionPlanParam.MAX_VIRTUAL_CARDS_COUNT:
        return api.updateMaxVirtualCards(orgId, {
          maxVirtualCardsCount: value as number | null,
        });
      case SubscriptionPlanParam.MAX_SINGLE_USE_CARDS_COUNT:
        return api.updateMaxSingleUseCards(orgId, {
          maxSingleUseCardsCount: value as number | null,
        });
    }
  };

  const isMoneyFieldAllowed = (
    paramKey: SubscriptionPlanParam,
    value?: number
  ) => {
    if (!value) return true;

    return (
      (paramKey === SubscriptionPlanParam.BASE_FEE &&
        value <= MaxFeeValues.baseFee) ||
      (paramKey === SubscriptionPlanParam.ADDITIONAL_USER_FEE &&
        value <= MaxFeeValues.additionalUserFee)
    );
  };

  const canSetUnlimitedValues = ![
    SubscriptionPlanParam.BASE_FEE,
    SubscriptionPlanParam.ADDITIONAL_USER_FEE,
  ].includes(paramKey);

  const renderInput = () => {
    const label = t(
      `int.subscriptionSettingsPage.subscriptionPlanParamsSection.options.${paramKey}`
    );

    switch (paramKey) {
      case SubscriptionPlanParam.BASE_FEE:
      case SubscriptionPlanParam.ADDITIONAL_USER_FEE:
        return (
          <MoneyField
            label={label}
            placeholder={label}
            {...omit(formik.getFieldProps('value'), 'onChange')}
            onValueChange={({ value }) => {
              formik.setFieldValue('value', value);
            }}
            isAllowed={(values) =>
              isMoneyFieldAllowed(paramKey, values.floatValue)
            }
            decimalScale={2}
          />
        );
      case SubscriptionPlanParam.FREE_MEMBERS_COUNT:
      case SubscriptionPlanParam.MAX_ACTIVE_MEMBERS_COUNT:
      case SubscriptionPlanParam.MAX_VIRTUAL_CARDS_COUNT:
      case SubscriptionPlanParam.MAX_SINGLE_USE_CARDS_COUNT:
        const min = getMinValueByParamKey(paramKey);
        return (
          <TextField
            type="number"
            label={label}
            placeholder={label}
            inputProps={{ min }}
            {...formik.getFieldProps('value')}
            {...(unlimited && {
              disabled: true,
              placeholder: '∞',
              value: '',
            })}
          />
        );
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t(
          'int.subscriptionSettingsPage.subscriptionPlanParamsSection.editValueDialog.title'
        )}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-subscription-plan-param-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              {renderInput()}
            </Grid>
            {canSetUnlimitedValues && (
              <Grid item xs={12}>
                <FormControlLabel
                  label={t(
                    'int.subscriptionSettingsPage.subscriptionPlanParamsSection.editValueDialog.unlimited'
                  )}
                  control={<Checkbox />}
                  disabled={formik.isSubmitting}
                  checked={unlimited}
                  onChange={() => {
                    const updatedUnlimited = !unlimited;
                    setUnlimited(updatedUnlimited);
                    if (updatedUnlimited) {
                      formik.setFieldValue('value', '');
                    } else {
                      formik.setFieldValue(
                        'value',
                        getInitialValue(paramKey, subscriptionPlan[paramKey])
                      );
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            formik.isSubmitting ||
            (!unlimited && formik.values.value === '') ||
            ([
              SubscriptionPlanParam.BASE_FEE,
              SubscriptionPlanParam.ADDITIONAL_USER_FEE,
            ].includes(paramKey) &&
              formik.values.value === '')
          }
          form="edit-subscription-plan-param-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditValueDialog);
