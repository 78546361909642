import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyTextToClipboard from 'components/CopyTextToClipboard';
import { StyledIconButton } from 'components/MainHeader/style';
import { useGlobalState } from 'context/GlobalState';
import { MAX_RECEIPT_FILE_SIZE_MB } from 'domains/transaction/constants';
import { Link, Popover, Tooltip, TrayIcon, Typography } from 'elements';
import useSnackbar from 'hooks/useSnackbar';

const ReceiptInboxMenu = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Tooltip title={t('header.receiptInbox')}>
        <StyledIconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={!!anchorEl ? 'selected' : ''}
          data-intercom-target="receipt-inbox-button"
        >
          <TrayIcon />
        </StyledIconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ sx: { width: 400, p: 2 } }}
      >
        <CopyTextToClipboard
          displayValue={
            <Link
              href={`mailto:${organization!.receiptInboxEmail}`}
              rel="noreferrer"
              target="_blank"
            >
              {organization!.receiptInboxEmail}
            </Link>
          }
          value={organization!.receiptInboxEmail}
          onCopy={() =>
            enqueueSnackbar(t('receiptInboxMenu.copiedToClipboard'), {
              autoHideDuration: 1500,
            })
          }
        />
        <Typography
          mt={2}
          component="div"
          variant="caption"
          color="text.secondary"
        >
          {t('receiptInboxMenu.text', { size: MAX_RECEIPT_FILE_SIZE_MB })}
        </Typography>
      </Popover>
    </>
  );
};

export default ReceiptInboxMenu;
