import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, ReceiptIcon, Typography } from 'elements';
import { AccountEntry, AccountEntryBillPaymentType } from 'services/constants';

const InfoBox = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <Paper
    sx={{
      px: 1.5,
      py: 2,
      mt: 1,
      minHeight: 56,
    }}
    variant="outlined"
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      <Typography component="div" variant="body2" noWrap>
        {label}
      </Typography>
      <Typography component="div" noWrap variant="body2" ml={2}>
        {value}
      </Typography>
    </Box>
  </Paper>
);

interface Props {
  accountEntry: AccountEntry;
}

const BillEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
    </>
  );
};

const PrefundingEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.bank')}
        value={billPaymentInfo.counterpartyBankName}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.sender')}
        value={billPaymentInfo.counterparty}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.senderIban')}
        value={
          <>
            <Typography variant="body2" noWrap>
              {billPaymentInfo.counterpartyIban}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
              textAlign="right"
            >
              {billPaymentInfo.counterpartyBic}
            </Typography>
          </>
        }
      />
    </>
  );
};

const BillPaymentReversalEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={
          <>
            {t('accountingEntryDetailsPage.originalPayment')}
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {t('accountingEntryDetailsPage.originalDate')}
            </Typography>
          </>
        }
        value={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden" minWidth={0} textAlign="right">
              <Typography variant="body2" noWrap>
                {billPaymentInfo.originalPaymentPublicId}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
                textAlign="right"
              >
                {billPaymentInfo.originalPaymentCutoffDate}
              </Typography>
            </Box>
            <Box flexShrink={0} ml={2} lineHeight={0}>
              <ReceiptIcon color="action" />
            </Box>
          </Box>
        }
      />
    </>
  );
};

const DirectDebitReversalFeeEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={
          <>
            {t('accountingEntryDetailsPage.originalPayment')}
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {t('accountingEntryDetailsPage.originalDate')}
            </Typography>
          </>
        }
        value={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden" minWidth={0} textAlign="right">
              <Typography variant="body2" noWrap>
                {billPaymentInfo.originalPaymentPublicId}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
                textAlign="right"
              >
                {billPaymentInfo.originalPaymentCutoffDate}
              </Typography>
            </Box>
            <Box flexShrink={0} ml={2} lineHeight={0}>
              <ReceiptIcon color="action" />
            </Box>
          </Box>
        }
      />
    </>
  );
};

const PayoutEntry = ({ accountEntry }: Props) => {
  const { t } = useTranslation();
  const billPaymentInfo = accountEntry.billPaymentInfo!;

  return (
    <>
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.txDetailsBlockTitle')}
      </Typography>

      <InfoBox
        label={t('accountingEntryDetailsPage.reference')}
        value={billPaymentInfo.publicId}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.bank')}
        value={billPaymentInfo.counterpartyBankName}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.receiver')}
        value={billPaymentInfo.counterparty}
      />
      <InfoBox
        label={t('accountingEntryDetailsPage.receiverIban')}
        value={
          <>
            <Typography variant="body2" noWrap>
              {billPaymentInfo.counterpartyIban}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
              textAlign="right"
            >
              {billPaymentInfo.counterpartyBic}
            </Typography>
          </>
        }
      />
    </>
  );
};

const AccountEntryDetailsPageContent = ({ accountEntry }: Props) => {
  const billPaymentType = accountEntry.billPaymentInfo?.billPaymentType;
  switch (billPaymentType) {
    case AccountEntryBillPaymentType.bill:
      return <BillEntry accountEntry={accountEntry} />;
    case AccountEntryBillPaymentType.prefunding:
      return <PrefundingEntry accountEntry={accountEntry} />;
    case AccountEntryBillPaymentType.billPaymentReversal:
      return <BillPaymentReversalEntry accountEntry={accountEntry} />;
    case AccountEntryBillPaymentType.directDebitReversalFee:
      return <DirectDebitReversalFeeEntry accountEntry={accountEntry} />;
    case AccountEntryBillPaymentType.payout:
      return <PayoutEntry accountEntry={accountEntry} />;
    default:
      return null;
  }
};

export default AccountEntryDetailsPageContent;
