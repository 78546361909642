import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { VatIdChangeDialog } from 'domains/organization/dialogs';
import { Grid, LabeledValue } from 'elements';
import { Organization } from 'services/constants';
import { useCanUser } from 'services/rbac';

const VatIdFormGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onUpdated = (organization: Organization) => {
    dispatch({
      type: 'SET_ORGANIZATION_DATA',
      payload: { organization },
    });
    setIsDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={t('settingsOrganizationPage.vat')}
          value={organization!.vatId || '-'}
          onEdit={
            canUser('org-vat-id:change')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <VatIdChangeDialog
        open={isDialogOpen}
        organizationId={organization!.id}
        organizationCountry={organization!.country}
        vatId={organization!.vatId}
        onSuccess={onUpdated}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default VatIdFormGroup;
