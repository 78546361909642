import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/transaction/pages/AccountEntriesPage';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';
import { Project, Team } from 'services/constants';
import { getLabelByType } from './AccountEntriesSubTypeFilter';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  teams: Team[];
  projects: Project[];
}

const FilterChips = ({
  params,
  selectedFiltersCount,
  setParam,
  teams,
  projects,
}: Props) => {
  const { t } = useTranslation();

  const typeChipTooltip = useMemo(() => {
    if (params.subType.length < 2) return '';
    return params.subType.map((item) => getLabelByType(item, t)).join(', ');
  }, [params.subType]);

  const categoryChipTooltip = useMemo(() => {
    if (params.category.length < 2) return '';
    return params.category
      .map((item) => t(`merchantCategories.${item}`))
      .join(', ');
  }, [params.category]);

  const teamChipTooltip = useMemo(() => {
    if (!teams.length || params.teamId.length < 2) return '';
    const teamNames = params.teamId
      .map((item) => teams.find((team) => team.id === item)?.name)
      .filter((item) => !!item);
    return teamNames.join(', ');
  }, [params.teamId, teams]);

  const projectChipTooltip = useMemo(() => {
    if (!projects.length || params.projectIds.length < 2) return '';
    const projectNames = params.projectIds
      .map((item) => projects.find((project) => project.id === item)?.name)
      .filter((item) => !!item);
    return projectNames.join(', ');
  }, [params.projectIds, projects]);

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  if (!selectedFiltersCount) return null;

  return (
    <>
      <PageFilterChips>
        {!!params.subType.length && (
          <Tooltip title={typeChipTooltip}>
            <Chip
              label={getFilterChipText(
                getLabelByType(params.subType[0], t),
                params.subType.length
              )}
              onDelete={() => setParam('subType', [])}
            />
          </Tooltip>
        )}

        {(params.fromDate || params.toDate) && (
          <Chip
            label={periodChipText}
            onDelete={() =>
              setParam([
                ['toDate', ''],
                ['fromDate', ''],
              ])
            }
          />
        )}

        {!!params.receipt && (
          <Chip
            label={getFilterChipText(
              t(`transactionReceiptStatuses.${params.receipt}`),
              1,
              t('filters.receipts')
            )}
            onDelete={() => setParam('receipt', '')}
          />
        )}

        {!!params.category.length && (
          <Tooltip title={categoryChipTooltip}>
            <Chip
              label={getFilterChipText(
                t(`merchantCategories.${params.category[0]}`),
                params.category.length
              )}
              onDelete={() => setParam('category', [])}
            />
          </Tooltip>
        )}

        {!!params.teamId.length && !!teams.length && (
          <Tooltip title={teamChipTooltip}>
            <Chip
              label={getFilterChipText(
                teams.find((team) => team.id === params.teamId[0])?.name || '',
                params.teamId.length,
                t('filters.team')
              )}
              onDelete={() => setParam('teamId', [])}
            />
          </Tooltip>
        )}

        {!!params.projectIds.length && !!projects.length && (
          <Tooltip title={projectChipTooltip}>
            <Chip
              label={getFilterChipText(
                projects.find((project) => project.id === params.projectIds[0])
                  ?.name || '',
                params.projectIds.length,
                t('filters.project')
              )}
              onDelete={() => setParam('projectIds', [])}
            />
          </Tooltip>
        )}

        {!!params.expenseType && (
          <Chip
            label={t(`expenseTypes.${params.expenseType}`)}
            onDelete={() => setParam('expenseType', '')}
          />
        )}
      </PageFilterChips>
    </>
  );
};

export default FilterChips;
