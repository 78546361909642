import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonGroup,
  CaretDownIcon,
  MenuContainer,
  MenuItem,
} from 'elements';

interface Props {
  title: string;
  onAdd: () => void;
  onUpload: () => void;
  format?: string;
}

// Used for custom cases when user is allowed to add and/or upload
// new items manually or using CSV
export const AddNewItemMenu = ({
  title,
  onAdd,
  onUpload,
  format = 'CSV',
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box ml="auto">
      <MenuContainer
        button={
          <ButtonGroup variant="contained" aria-label="split button">
            <Button>{title}</Button>
            <Button>
              <CaretDownIcon />
            </Button>
          </ButtonGroup>
        }
      >
        <MenuItem onClick={onAdd}>
          {t('addSettingItemMenu.addManualButtonTitle')}
        </MenuItem>
        <MenuItem onClick={onUpload}>
          {t('addSettingItemMenu.uploadButtonTitle', { format })}
        </MenuItem>
      </MenuContainer>
    </Box>
  );
};
