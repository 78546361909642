import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { LoaderWithOverlay } from 'elements';
import withPageErrorWrapper, {
  useShowPageError,
} from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import { ForwardingIntegrations } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import NotificationsGroup from './NotificationsGroup';
import ReceiptForwardingGroup from './ReceiptForwardingGroup';
import ReceiptInboxOptionsGroup from './ReceiptInboxOptionsGroup';
import ReceiptNotNeededGroup from './ReceiptNotNeededGroup';

interface State {
  forwardingIntegrations: ForwardingIntegrations | null;
  isLoading: boolean;
}

const ReceiptManagementSubPage = () => {
  const showPageError = useShowPageError();
  const {
    dispatch,
    state: { featureModules, organization, receiptsSettings },
  } = useGlobalState();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();

  const [state, setState] = useState<State>({
    forwardingIntegrations: null,
    isLoading: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [forwardingIntegrations, receiptsSettings] = await Promise.all([
        canUser('automatic-receipt-forwarding:view')
          ? api.getForwardingIntegration(organization!.id)
          : null,
        canUser('receipts-settings:view')
          ? api.getReceiptsSettings(organization!.id)
          : null,
      ]);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        forwardingIntegrations: forwardingIntegrations,
        isLoading: false,
      }));
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          receiptsSettings,
        },
      });
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (
      canUser('automatic-receipt-forwarding:view') ||
      canUser('receipts-settings:view')
    )
      getData();
  }, []);

  if (!featureModules.RECEIPT_MANAGEMENT)
    return (
      <Redirect
        to={generatePath(getPath('settingsOrganization'), {
          orgId: organization!.id,
        })}
      />
    );

  if (state.isLoading) return <LoaderWithOverlay loading />;

  return (
    <>
      <NotificationsGroup />

      {!!state.forwardingIntegrations && (
        <ReceiptForwardingGroup
          forwardingIntegrations={state.forwardingIntegrations}
          onUpdate={(forwardingIntegrations) =>
            setState((prevState) => ({
              ...prevState,
              forwardingIntegrations,
            }))
          }
        />
      )}

      {receiptsSettings && (
        <>
          <ReceiptNotNeededGroup />
          <ReceiptInboxOptionsGroup />
        </>
      )}
    </>
  );
};

export default withPageErrorWrapper(ReceiptManagementSubPage);
