import { logError } from 'services/monitoring';

declare global {
  interface Window {
    onXS2AReady: any;
    XS2A:
      | {
          startFlow(
            token: string,
            config: {
              onLoad?(): void;
              onReady?(): void;
              onAbort?(): void;
              onError(error: any): void;
              onFinished(): void;
              onClose(): void;
            }
          ): void;
          close(): void;
        }
      | undefined;
  }
}

const KLARNA_LAUNCHER_SCRIPT_URL =
  'https://x.klarnacdn.net/xs2a/app-launcher/v0/xs2a-app-launcher.js';

export const runKlarnaApiIntegration = (token: string) =>
  new Promise<boolean>((resolve, reject) => {
    if (typeof window.XS2A?.startFlow !== 'function') return reject();
    let isSuccessful = false;
    // see https://docs.openbanking.klarna.com/xs2a/xs2a-app.html for more details
    window.XS2A.startFlow(token, {
      onError: (error: any) => {
        logError(error);
      },
      onFinished: () => {
        isSuccessful = true;
      },
      onClose: () => {
        resolve(isSuccessful);
      },
    });
  });

export const lazyLoadKlarnaLauncherScript = () =>
  new Promise<boolean>((resolve, reject) => {
    if (typeof window.XS2A?.startFlow === 'function') return resolve(true);
    window.onXS2AReady = () => {
      resolve(true);
    };
    const scriptTag = document.createElement('script');
    scriptTag.onerror = () => {
      reject();
    };
    scriptTag.src = KLARNA_LAUNCHER_SCRIPT_URL;
    document.body.appendChild(scriptTag);
  });
