import { Role } from 'services/rbac';
import {
  AccountEntryBillPaymentType,
  AccountEntryType,
  AccountGroup,
  AccountingItemStatus,
  AccountingSystem,
  AccountingSystemIntegrationMethod,
  AmlRiskScoring,
  ApiExportTransactionStatus,
  ApiIntegrationStatus,
  AutomationType,
  BankAccountTransfersAllowedStatus,
  BankAccountType,
  BankingServicePartner,
  BankSubmissionStatus,
  BankSubmissionType,
  BillPaymentBatchBillPaymentType,
  BillPaymentBillPaymentType,
  BillPaymentType,
  BillStatus,
  BookingTextMode,
  CardAccountCurrency,
  CardAccountStatus,
  CardCategoryControlType,
  CardConfig,
  CardConfigGroup,
  CardControlRestriction,
  CardDateControlType,
  CardDesignLayout,
  CardHistoryEntryType,
  CardIssuanceUnavailableReason,
  CardLimitChangeRequestStatus,
  CardLimitRenewFrequency,
  CardLocationControlType,
  CardLockReason,
  CardManufacturer,
  CardMerchantControlType,
  CardNewType,
  CardPremiumProductType,
  CardReplacementReason,
  CardSimpleStatus,
  CardStatus,
  CardTimeControlType,
  CardType,
  CardVelocityGroupLimits,
  CashbackRedemptionBatchState,
  CashbackRedemptionStatus,
  CashbackRedemptionType,
  CirculaConnectionStatus,
  CirculaConnectionType,
  CodatDataItemStatus,
  CodatMappingTarget,
  CompanyRegistryProvider,
  CompensationBatchStatus,
  ComplianceDocumentUploadOption,
  ComplianceRatingValue,
  CustomFieldStatus,
  CustomFieldType,
  CustomFieldVisibility,
  DatevCostMode,
  DirectDebitType,
  DocumentGenericType,
  DocumentType,
  EnterpriseSize,
  ExpenseType,
  ExpenseTypeChangeableBy,
  ExportFormat,
  ExternalReportProvider,
  FeatureModuleKey,
  ForwardingIntegrationFrequency,
  ForwardingIntegrationStatus,
  ForwardUnmatchedReceiptsInterval,
  GlAccountUploadErrorCode,
  HistoryCashbackRedemptionStatus,
  HistoryCashbackRedemptionType,
  IdentificationType,
  IntegrationType,
  LegalRepresentativeType,
  LegitimationDocument,
  LegitimationProvider,
  LegitimationStatus,
  MandateStatus,
  MembershipStatus,
  MemberStatus,
  MerchantCategory,
  MerchantDescriptionSource,
  MerchantSplitType,
  MerchantStatus,
  MimeType,
  MonthlyCashbackProgressStatus,
  OnboardingDocumentAllowedAttribute,
  OnboardingDocumentCategory,
  OnboardingDocumentName,
  OnboardingDocumentStatus,
  OrganizationAccountType,
  OrganizationProductType,
  OrganizationRepresentativeExternalStatus,
  OrganizationRepresentativeInternalStatus,
  OrganizationSettlementStatus,
  OrganizationStatus,
  OriginatorPaymentType,
  PartialPaymentStatus,
  PartnerAuthStatus,
  PartnerIdType,
  PartnerName,
  PartnerScope,
  PartnerStatus,
  PartnerWhitelabelLevel,
  PaymentFrequencyType,
  PaymentStatus,
  PaymentType,
  ProcessingAccountTransferType,
  ProgramSettlementStatus,
  ProjectStatus,
  ProjectUploadErrorCode,
  ReceiptAutomatchStatus,
  ReceiptDateExportMode,
  ReceiptInboxLayout,
  ReceiptInboxMatchingFlow,
  ReceiptMimeType,
  ReceiptNumberExportMode,
  ReceiptRejectionReason,
  ReceiptsAutoMatchingStatus,
  ReceiptsAutoMatchingTagGroup,
  ReceiptStatus,
  RelatedIndividualType,
  ServiceLevel,
  SettlementPaymentType,
  SmsLogSource,
  SmsLogStatus,
  SubcategoryStatus,
  SubscriptionPlanType,
  SupplierUploadErrorCode,
  SupportedCountry,
  SupportedWebAppLanguage,
  TeamStatus,
  TransactionExportStatus,
  TransactionReviewStatus,
  TransactionsDownloadFileType,
  TransactionSimpleType,
  TransactionStatus,
  UboType,
  UnderwritingCase,
  VatRateStatus,
  VssType,
  XlsxUploadSuccessStatus,
  XlsxUploadType,
} from './index';

export type ValueWithAuditLog<T> = T extends null
  ? {
      value: null;
      updatedBy: null;
      updatedAt: null;
    }
  : {
      value: T;
      updatedBy: string;
      updatedAt: string;
    };

export interface Money {
  value: number;
  currency: string;
}

export interface Address {
  country: SupportedCountry;
  city: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
  streetAdditional: string | null;
}

export interface FeatureModule<FMK extends FeatureModuleKey, S = null> {
  name: FMK;
  enabled: boolean;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  internal: boolean;
  disabledByPartner: boolean; // hide the tile for the organization
  settings: S;
}

export type FeatureModuleByKeyMap = {
  [K in Exclude<
    FeatureModuleKey,
    | FeatureModuleKey.cardRequests
    | FeatureModuleKey.managerTxReviews
    | FeatureModuleKey.receiptManagement
  >]: FeatureModule<K>;
} & {
  // feature modules with settings

  [FeatureModuleKey.cardRequests]: FeatureModule<
    FeatureModuleKey.cardRequests,
    {
      CARD_REQUESTS_AUTO_APPROVAL_ENABLED: boolean;
      CARD_REQUESTS_AUTO_APPROVAL_LIMIT: Money;
    }
  >;

  [FeatureModuleKey.managerTxReviews]: FeatureModule<
    FeatureModuleKey.managerTxReviews,
    {
      LIMIT_TX_REVIEW_TO_TEAM: boolean;
      REVIEW_FOR_EXPORT_REQUIRED: boolean;
    }
  >;

  [FeatureModuleKey.receiptManagement]: FeatureModule<
    FeatureModuleKey.receiptManagement,
    { RECEIPT_FOR_EXPORT_REQUIRED: boolean }
  >;
};

export type FeatureModuleValueByKeyMap = {
  [K in FeatureModuleKey]: boolean;
};

export interface MappedFeatureModules {
  featureModules: FeatureModuleValueByKeyMap;
  featureModulesWithData: FeatureModuleByKeyMap;
}

export interface ToggleFeatureModulePayload {
  feature: FeatureModuleKey;
  enabled: boolean;
}

export interface UpdateFeatureModuleSettingsPayload<
  FMK extends FeatureModuleKey
> {
  featureName: FMK;
  settings: FeatureModuleByKeyMap[FMK]['settings'];
}

export interface Membership {
  id: string;
  country: SupportedCountry;
  memberId: string;
  organizationId: string;
  organizationName: string;
  roles: string[];
  status: MembershipStatus;
}

export interface MembershipList {
  memberships: Membership[];
}

export interface PartialMember {
  id: string;
  firstName: string;
  lastName: string;
}

export interface PartialMemberPageableList {
  members: PartialMember[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface GetPartialMembersParams {
  organizationId: string;
  teamIds?: string; // joined array
  excludedTeamId?: string;
  page?: number;
  limit?: number;
  sort?: string;
  q?: string;
  role?: string; // joined array
  status?: string; // joined array
}

export interface MemberTeamDetails {
  teamId: string;
  name: string;
  hasActiveCards: boolean;
  teamManager: boolean;
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationId: string;
  roles: string[];
  status: MemberStatus;
  teams: MemberTeamDetails[];
  registeredAt: string;
  lastActiveAt: string;
  cards: {
    cardId: string;
    status: CardStatus;
    type: CardType;
    cardConfig: CardConfig;
    cardDesignId: string;
    cardType: CardNewType;
    premiumProductType: CardPremiumProductType | null;
    maxUsage: 1 | null;
    issuingDate: string | null;
  }[];
  activeRequestsCount: number;
  embeddedPartnerMembership: boolean;
  phoneDevicePaired: boolean;
  securityKeyPaired: boolean;
}

export interface SettlementGroupBankAccount {
  iban: string;
  accountName: string;
  bic: string;
}

export interface SettlementGroup {
  id: string;
  accountGroup: string;
  currency: string;
  settlementBankAccounts: {
    programSettlementAccount: SettlementGroupBankAccount;
    issuerFeeAccount: SettlementGroupBankAccount;
    issuerIssPrefundAccount: SettlementGroupBankAccount;
    issuerEansPrefundAccount: SettlementGroupBankAccount;
  };
  settlementPaymentType: string;
}

export interface SettlementGroupList {
  settlementGroups: SettlementGroup[];
}

export interface Settlement {
  settlementId: string;
  programSettlementId: string;
  createdAt: string;
  vssType: VssType;
  reportDate: string;
  settlementAmount: Money;
  transactionsCount: number;
  feesAmount: Money;
  status: OrganizationSettlementStatus;
  fileName: string | null;
  paymentId: string | null;
  paymentStatus:
    | PaymentStatus.delayed
    | PaymentStatus.failed
    | PaymentStatus.paid
    | PaymentStatus.pending
    | PaymentStatus.readyForPmt
    | null;
  errorMessage: string | null;
  organizationId: string | null;
  organizationName: string | null;
  cardAccountId: string;
  cardAccountName: string;
  defaultAccount: boolean;
  accountGroup: AccountGroup;
  accountNumber: string;
  drawdownAmount: Money;
}

export interface SettlementPageableList {
  hasNextPage: boolean;
  organizationId: string | null;
  settlements: Settlement[];
  totalSettlementsAmount: Money;
  cardAccountId: string;
}

export interface ProgramSettlement {
  settlementId: string;
  createdAt: string;
  uploadedAt: string;
  reportDate: string;
  vssType: VssType;
  transactionsCount: number;
  settlementAmount: Money;
  feesAmount: Money;
  chargesAmount: Money;
  netSettlementAmount: Money;
  status: ProgramSettlementStatus;
  matchedTotalTransactionAmount: Money | null;
  matchedTotalFeeAmount: Money | null;
  matchedTransactionCount: number | null;
  paymentId: string | null;
  programPaymentFileId: string | null;
  feePaymentId: string | null;
  feePaymentFileId: string | null;
  organizationPaymentFileId: string | null;
  programSettlementFileName: string | null;
  feePaymentFileName: string | null;
  organizationSettlementsFileName: string | null;
  vssFileName: string | null;
  machineReadableReportFileName: string | null;
  settlementGroupId: string;
  settlementPaymentType: SettlementPaymentType;
  accountGroup: AccountGroup;
  currency: string;
  sreId: string;
  manualProgramSettlementPayment: boolean;
  syntheticCreditEnabled: boolean;
  allOrganizationsSettled: boolean;
  cardAccountCount: number | null;
  cfpPaymentFileId: string | null;
  cfpPaymentFileName: string | null;
  cfpPaymentId: string | null;
  organizationCount: number | null;
  rdpPaymentFileId: string | null;
  rdpPaymentFileName: string | null;
  rdpPaymentId: string | null;
  rppPaymentFileId: string | null;
  rppPaymentFileName: string | null;
  rppPaymentId: string | null;
  totalDrawdownAmount: Money | null;
}

export interface ProgramSettlementPageableList {
  hasNextPage: boolean;
  settlements: ProgramSettlement[];
  totalCount: number;
}

export interface MemberPageableList {
  totalCount: number;
  hasNextPage: boolean;
  members: Member[];
}

export interface MemberDetails {
  id: string;
  insured: boolean;
  title: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneSynced: boolean;
  securityKeyName: string | null;
  securityKeyPaired: boolean;
  organizationId: string;
  status: MemberStatus;
  roles: string[];
  teams: MemberTeamDetails[];
  iamId: string;
  termsAndConditionsConsentDate: string;
  legalRepresentative: null | LegalRepresentativeType;
  multiOrgUser: boolean;
  powerOfAttorney: boolean;
  hashedId: string;
  embeddedPartnerMembership: boolean;
}

// nullable values are only possible for org admins
// who had been invited before the feature was implemented
export interface MemberPermissions {
  id: string;
  canCreateCardForSelf: boolean | null;
  maxSpendLimitPerCard: Money | null;
}

export interface MemberCount {
  activeMembersCount: number;
  invitedMembersCount: number;
  activeAccountOwnersCount: number;
  invitedAccountOwnersCount: number;
  activeOrgAdminsCount: number;
  invitedOrgAdminsCount: number;
  activeAccountantsCount: number;
  invitedAccountantsCount: number;
}

export interface InviteMemberPayload {
  title: string | null;
  email: string;
  firstName: string;
  lastName: string;
  languageCode: SupportedWebAppLanguage;
  roles: Role[];
  organizationId: string;
  teamIds?: string[];
  // required for org admins
  canCreateCardForSelf?: boolean;
  maxSpendLimitPerCard?: Money | null;
}

export interface GetMembersParams {
  organizationId?: string;
  teamIds?: string;
  page?: number;
  limit?: number;
  sort?: string;
  q?: string;
  role?: string; // joined array
  status?: string; // joined array
  teamEntitiesOnly?: boolean;
}

export interface UpdateMemberTeamsPayload {
  organizationId: string;
  teamsToAssign: string[];
  teamsToUnassign: string[];
}

export interface TeamMember {
  memberId: string;
  firstName: string;
  lastName: string;
}

export interface Team {
  id: string;
  name: string;
  costCenter: string | null;
  teamStatus: TeamStatus;
  createdAt: string;
  members: TeamMember[];
  managers: TeamMember[];
  maxLimitPerCard: Money;
  teamManagerCanIssueCardForSelf: boolean;
  budget: Money | null;
  balance: Money | null;
  availableBudget: Money | null;
  managerTxReviewRequired: boolean;
}

export interface MinimalTeam {
  id: string;
  name: string;
  costCenter: string | null;
  teamStatus: TeamStatus;
  isManager: boolean;
}

export interface GetTeamsParams {
  organizationId: string;
  memberId?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  status?: TeamStatus | undefined;
  q?: string;
}

export interface TeamPageableList {
  hasNextPage: boolean;
  teams: Team[];
}

export interface UpdateTeamPayload {
  name: string;
  costCenter?: string;
  managerTxReviewRequired?: boolean;
}

export interface CreateTeamPayload extends UpdateTeamPayload {
  organizationId: string;
}

export interface UpdateTeamMembersPayload {
  membersToAdd: { memberId: string; teamManager?: boolean }[];
  membersToDelete?: { memberId: string }[];
  membersToUpdate?: { memberId: string; teamManager: boolean }[];
}

export interface UpdateTeamPermissionsPayload {
  maxLimitPerCard: Money;
  teamManagerCanIssueCardForSelf: boolean;
}

export interface TeamTransactionAssignment {
  teamId: string;
  hasAssignedTransactions: boolean;
}

export interface TeamTransactionAssignmentList {
  assignments: TeamTransactionAssignment[];
}

export interface OrganizationSearchItem {
  companyId: number;
  companyIdType: CompanyRegistryProvider;
  companyName: string;
  street: string;
  postalCode: string;
  city: string;
}

export interface OrganizationSearchResult {
  data: OrganizationSearchItem[];
}

export interface CreateOrganizationPayload {
  accountGroup: AccountGroup;
  companyId: number;
  companyIdType: CompanyRegistryProvider;
  partnerId: PartnerIdType;
  partnerRevenueShareId: PartnerIdType;
  hubspotId: string;
  partnersOrganizationId: string | null;
}

export interface HubspotCompany {
  alreadyLinkedOrganizationId: string | null;
  alreadyLinkedOrganizationName: string | null;
  hubspotId: string;
  name: string;
}

export interface LegalRepresentative {
  memberId: string;
  type: LegalRepresentativeType;
}

export interface Organization {
  id: string;
  productType: OrganizationProductType;
  name: string;
  tradeName: string | null;
  status: OrganizationStatus;
  legalForm: string;
  foundationDate: string;
  nationalRegisterCourt: string;
  nationalRegisterId: string;
  nationalRegisterType: string;
  tradeRegisterNumber: string;
  vatId: string;
  kycNowId: number;
  hubspotId: string | null;
  billingEmail: string | null;
  billingAddress: Address;
  deliveryAddress: Address | null;
  bankConnectionRequired: boolean;
  missingReceiptNotificationEnabled: boolean;
  paymentDefermentInDays: number;
  integrations: IntegrationType[];
  omrFeatureEnabled: boolean;
  automaticActivationEnabled: boolean;
  receiptInboxEmail: string;
  country: SupportedCountry;
  activatedAt: string | null;
  partnerId: PartnerIdType;
  partnerName: PartnerName | null;
  partnerRevenueShareId: string | null;
  partnerRevenueShareName: PartnerName | null;
  partnersOrganizationId: string;
  registerExcerptDate: string | null;
  crefoId: number | null;
  serviceLevel: ServiceLevel;
  defaultAccountGroup: AccountGroup;
  canAccountGroupBeChanged: boolean;
  tradeNameRequired: boolean;
  legalRepresentatives: LegalRepresentative[];
}

export interface CardAccount {
  accountGroup: ValueWithAuditLog<AccountGroup>;
  accountType: ValueWithAuditLog<OrganizationAccountType>;
  activatedAt: ValueWithAuditLog<string | null>;
  billPaymentTransferType: ValueWithAuditLog<BillPaymentType>;
  createdAt: string;
  creditLimit: ValueWithAuditLog<Money>;
  availableLimit: ValueWithAuditLog<Money>;
  balance: ValueWithAuditLog<Money>;
  currency: ValueWithAuditLog<CardAccountCurrency>;
  defaultAccount: ValueWithAuditLog<boolean>;
  iban: ValueWithAuditLog<string | null>;
  ibanActivated: ValueWithAuditLog<boolean | null>;
  id: string;
  minimumPaymentFrequency: ValueWithAuditLog<PaymentFrequencyType>;
  name: ValueWithAuditLog<string>;
  paymentDefermentInDays: ValueWithAuditLog<number>;
  paymentFrequency: ValueWithAuditLog<PaymentFrequencyType>;
  paymentFrequencyCutoffDay: ValueWithAuditLog<number | null>;
  purpose: ValueWithAuditLog<string | null>;
  status: ValueWithAuditLog<CardAccountStatus>;
}

export interface CardAccountList {
  availableLimitInMainCurrency: { [C in CardAccountCurrency]?: Money };
  availableLimitPerCurrency: { [C in CardAccountCurrency]?: Money };
  cardAccounts: CardAccount[];
  cardAccountsPerCurrency: { [C in CardAccountCurrency]?: number };
  totalAvailableLimit: Money;
}

export interface CardAccountBalance {
  cardAccountId: string;
  organizationId: string;
  cardAccountBalance: Money;
  pendingTransactionsTotalAmount: Money;
  pendingPayoutsTotalAmount: Money;
  pendingTopupsTotalAmount: Money;
  creditLimit: Money;
  spendableAmount: Money;
}

export interface PartialOrganization {
  organizationId: string;
  name: string;
  city: string;
  status: OrganizationStatus;
  membersCount: number;
  activeCardsCount: number | null;
  balance: Money | null;
  limit: Money;
  availableLimit: Money | null;
  country: SupportedCountry;
  accountType: OrganizationAccountType;
  partnerName: PartnerName | null;
  partnerId: PartnerIdType;
  accountGroups: AccountGroup[];
  mainCardAccountAccountGroup: AccountGroup;
  cardAccountCurrencies: string[];
  mainCardAccountCurrency: string;
}

export interface PartialOrganizationPageableList {
  hasNextPage: boolean;
  organizations: PartialOrganization[];
}

export interface OrganizationIntegrations {
  forwardingConfiguration: ForwardingIntegrations;
  syncSettings: SyncSettings | null;
}

export interface OrganizationBalance {
  organizationId: string;
  availableLimit: Money;
  balance: Money;
  limit: Money;
}

export interface RiskAssessmentCompleted {
  organizationId: string;
  completedAt: string;
  completedBy: string;
}

export interface ComplianceCheckCompleted {
  organizationId: string;
  completedAt: string;
  completedBy: string;
}

export interface ProbabilityOfDefault {
  userName: string;
  probabilityOfDefault: number;
  varengoldRating: number;
  assignedAt: string;
}

export interface RiskAndCompliance {
  accountType: OrganizationAccountType;
  accountTypeChangeAllowed: boolean;
  riskAssessment: RiskAssessmentCompleted | null;
  complianceCheck: ComplianceCheckCompleted | null;
  probabilityOfDefault: ProbabilityOfDefault | null;
  underwritingCase: UnderwritingCase | null;
}

export interface PaymentFrequency {
  organizationId: string;
  min: {
    value: PaymentFrequencyType;
    userName: string;
    assignedAt: string;
  };
  current: {
    value: PaymentFrequencyType;
    cutoffDay: number | null;
    userName: string;
    assignedAt: string;
  };
}

export interface CrefoReport {
  crefoReportRawResponseId: string;
  downloadedAt: string;
  fileName: string;
  path: string;
  reportCreatedAt: string;
}

export interface ExternalReport {
  downloadedAt: string;
  fileName: string;
  path: string;
  provider: ExternalReportProvider;
  reportCreatedAt: string;
  reportId: string;
}

export interface CrefoReportList {
  organizationId: string;
  reportsPaths: CrefoReport[];
}

export interface PaymentDeferment {
  organizationId: string;
  changedByEmail: string;
  changedAt: string;
  paymentDefermentInDays: number;
}

export interface Confirmation {
  memberId: string;
  membershipId: string;
  confirmedAt: string;
  email: string;
  name: string;
  legalRepresentative: string;
  powerOfAttorney: boolean;
}

export interface Onboarding {
  organizationId: string;
  legalName: string;
  nationalRegisterCourt: string;
  tradeRegisterNumber: string;
  foundationDate: string;
  vatId: string | null;
  hubspotId: string | null;
  status: OrganizationStatus;
  billingAddress: Address;
  deliveryAddress: Address | null;
  directDebitIban: string | null;
  riskAssessmentCompleted: boolean;
  complianceCheckCompleted: boolean;
  directDebitCompleted: boolean;
  bankTermsConfirmations: null | Confirmation[];
  directDebitActivationConfirmations: null | Confirmation[];
  issuerTermsConfirmations: null | Confirmation[];
  termsAndConditionsCompleted: boolean;
  bankConnectionRequired: boolean;
  platformTermsConfirmations: null | Confirmation[];
}

export interface AccountingSystemList {
  accountingSystems: Array<{
    accountingSystemId: AccountingSystem;
    accountingSystemName: string;
    availableExportFormats: ExportFormat[];
  }>;
}

export interface AccountingItems {
  costCenterCount: number;
  costUnitCount: number;
  subCategoryCount: number;
  supplierCount: number;
  vatRateCount: number;
}

export interface AccountingSettings {
  organizationId: string;
  vatRateEnabled: boolean;
  vatRateExportMandatory: boolean;
  subcategoryEnabled: boolean;
  subcategoryExportMandatory: boolean;
  projectEnabled: boolean;
  costCenterEnabled: boolean;
  costCenterExportMandatory: boolean; //  costCenter same as team
  costUnitExportMandatory: boolean; // costUnit same as project
  supplierEnabled: boolean;
  supplierExportMandatory: boolean;
  useAccountingExport: boolean;
  accountingSystem: AccountingSystem | null;
  accountingSystemName: string | null;
  accountingSystemIntegrationMethod: AccountingSystemIntegrationMethod | null;
  consultantNumber: number | null;
  clientNumber: number | null;
  beginOfFiscalYear: number | null;
  generalLedgerAccountLength: number | null;
  pliantCreditCardAccount: string | null;
  useSupplierAccount: boolean;
  defaultSupplierAccount: string | null;
  cashInTransitAccount: string | null;
  exportFormats: ExportFormat[];
  apiErrorDetails: string[] | null;
  apiIntegrationStatus: ApiIntegrationStatus;
  datevCostMode: DatevCostMode;
  accountStatementSwitchDate: string;
  accountingItems: AccountingItems;
  receiptNumberExportMode: ReceiptNumberExportMode;
  receiptDateExportMode: ReceiptDateExportMode;
  // should be renamed in BE (not only for datev)
  datevBookingTextMode: BookingTextMode;
  datevUsePaymentBookingTextPrefix: boolean;
  datevUseInformationField: boolean;
  paymentSyncEnabled: boolean;
  commentEnabled: boolean;
  commentExportMandatory: boolean;
  amountLogicEnabled: boolean;
  bmdForeignCurrencyHandling: boolean;
}

export interface DatevConnection {
  apiIntegrationStatus: ApiIntegrationStatus;
  validationErrors: string[] | null;
  connectedAt: string | null;
  disconnectedAt: string | null;
  datevClientId: string | null;
  connectedByFirstName: string | null;
  connectedByLastName: string | null;
  tokenValidUntil: string | null;
}

export interface DatevExportTransactions {
  status: ApiExportTransactionStatus;
  totalTransactions: number;
  uploadedTransactions: number;
  createdAt: string;
  updatedAt: string;
}

export interface UpdateAccountingSettingsPayload {
  organizationId: string;
  vatRateEnabled: boolean;
  subcategoryEnabled: boolean;
  useAccountingExport: boolean;
  projectEnabled?: boolean;
  supplierEnabled?: boolean;
  costCenterEnabled?: boolean;
  paymentSyncEnabled?: boolean;
  commentEnabled?: boolean;
  exportFormats?: ExportFormat[];
  amountLogicEnabled?: boolean;
  bookingTextMode?: BookingTextMode;
  bmdForeignCurrencyHandling?: boolean;
}

export interface UpdateMandatoryExportSettingsPayload {
  organizationId: string;
  costUnitExportMandatory: boolean;
  supplierExportMandatory: boolean;
  vatRateExportMandatory: boolean;
  subcategoryExportMandatory: boolean;
  costCenterExportMandatory: boolean;
  commentExportMandatory: boolean;
}

export interface UpdateAccountingGeneralSettingsPayload {
  organizationId: string;
  consultantNumber?: number;
  clientNumber?: number;
  beginOfFiscalYear?: number;
  generalLedgerAccountLength?: number;
}

export interface UpdateAccountingGeneralAccountsPayload {
  organizationId: string;
  pliantCreditCardAccount: string | null;
  useSupplierAccount: boolean;
  defaultSupplierAccount: string | null;
  cashInTransitAccount: string | null;
}

export interface UpdateDatevAccountingSettingsPayload {
  organizationId: string;
  datevCostMode: DatevCostMode;
  datevBookingTextMode?: BookingTextMode;
  datevUsePaymentBookingTextPrefix?: boolean;
  datevUseInformationField?: boolean;
  receiptDateExportMode?: ReceiptDateExportMode;
  receiptNumberExportMode?: ReceiptNumberExportMode;
}

export interface DatevClient {
  id: string;
  name: string;
  client_number: string;
  consultant_number: string;
}

export interface DatevClientList {
  clients: DatevClient[];
}

export interface CardProcessingAccount {
  organizationId: string;
  cardProcessingAccountIban: string | null;
  cardProcessingAccountBalance: Money | null;
}

export interface CardDesign {
  id: string;
  name: string;
  smallImage: string;
  largeImage: string;
  layout: CardDesignLayout;
  textColor: string;
  active: boolean;
  defaultDesign: boolean;
  cardConfig: CardConfig;
}

export interface CardDesignList {
  cardDesigns: CardDesign[];
}

export interface CardConfigSetting {
  canBeIssued: boolean;
  cardAccounts: string[];
  cardConfig: CardConfig;
  cardConfigGroup: CardConfigGroup;
  cardType: CardNewType;
  cardDesignIds: string[];
  premiumProductType: CardPremiumProductType | null;
  maxUsage: 1 | null;
  mccGroup: string;
  cardControls: {
    categories: { [K in CardCategoryControlType]: { enabled: boolean } };
    merchants: { [K in CardMerchantControlType]: { enabled: boolean } };
    dates: { [K in CardDateControlType]: { enabled: boolean } };
    times: { [K in CardTimeControlType]: { enabled: boolean } };
  };
  showDetailsModal: boolean;
  validityPeriodEnabled: boolean;
}

export interface CardConfigSettingList {
  cardConfigs: CardConfigSetting[];
}

export interface MccCategory {
  mcc: string;
  mccName: string;
}

export interface CardCategoryControl {
  restriction: CardControlRestriction;
  type: CardCategoryControlType;
  values: MerchantCategory[] | string[];
  displayValues:
    | {
        code: string; // MccCategory.mcc
        name: string; // MccCategory.mccName
      }[]
    | null;
}

export interface CardMerchantControl {
  restriction: CardControlRestriction;
  type: CardMerchantControlType;
  values: string[];
}

export interface CardDateControl {
  restriction: CardControlRestriction;
  type: CardDateControlType;
  values: string[];
  timezone: string;
}

export interface CardTimeControl {
  restriction: CardControlRestriction;
  type: CardTimeControlType;
  values: string[];
  timezone: string;
  offset: string;
}

export interface CardLocationControl {
  restriction: CardControlRestriction;
  type: CardLocationControlType;
  values: string[];
}

export interface CardCustomFieldItem {
  defaultValueForCard: string;
  label: string;
  transactionCustomFieldId: string;
  cardTransactionCustomFieldId: string;
}

export interface Card {
  accountGroup: AccountGroup;
  activationDate: string | null;
  cardId: string;
  cardName: string | null;
  cardRefNum: string | null;
  cardAccountId: string;
  cardDesignId: string;
  cardConfig: CardConfig;
  cardConfigGroup: CardConfigGroup;
  cardControls: {
    categories: CardCategoryControl | null;
    merchants: CardMerchantControl | null;
    dates: CardDateControl | null;
    times: CardTimeControl | null;
    locations: CardLocationControl | null;
  };
  cardType: CardNewType;
  premiumProductType: CardPremiumProductType | null;
  maxUsage: 1 | null;
  chFirstName: string;
  chLastName: string;
  issuingDate: string | null;
  monthlyPeriodEnd: string;
  memberId: string;
  balance: Money;
  availableLimit: Money;
  limit: Money;
  limitRenewFrequency: CardLimitRenewFrequency;
  limitRenewDate: string | null;
  manufacturer: CardManufacturer;
  organizationId: string;
  simpleStatus: CardSimpleStatus;
  status: CardStatus;
  lockReason?: CardLockReason;
  teamId: string | null;
  teamName: string | null;
  terminated: boolean;
  token: string;
  transactionLimit: Money;
  type: CardType;
  expiryDate: string | null;
  limitChangeRequestId: string | null;
  integrationType: IntegrationType | null;
  requestedLimitRenewFrequency: CardLimitRenewFrequency | null;
  requestedLimit: Money | null;
  requestedTransactionLimit: Money | null;
  velocityLimitGroup: CardVelocityGroupLimits | null;
  platformFee: boolean;
  purpose: string | null;
  projectId: string | null;
  projectName: string | null;
  replacedByCardId: string | null;
  replacementReason: CardReplacementReason | null;
  customFirstName: string | null;
  customLastName: string | null;
  cardValidity: {
    validFrom: string;
    validTo: string;
    validTimezone: string;
  } | null;
  cardTxnCustomFields: CardCustomFieldItem[];
}

export interface CardPageableList {
  cards: Card[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface IssueCardPayload {
  organizationId: string;
  memberId: string;
  teamId?: string;
  cardAccountId: string;
  cardConfig: CardConfig;
  cardDesignId: string;
  transactionLimit: Money;
  limit: Money;
  limitRenewFrequency: CardLimitRenewFrequency;
  projectId?: string;
  cardControls?: {
    categories?: CardCategoryControl;
    merchants?: CardMerchantControl;
    dates?: CardDateControl;
    times?: CardTimeControl;
    locations?: CardLocationControl;
  };
  expiryPeriodMonths?: number; // ignored for physical cards
  // Must be sent when cardConfigSetting.validityPeriodEnabled === true
  validFrom?: string;
  validTo?: string;
  validTimezone?: string;
  // allowed only for virtual cards
  cardName?: string;
  purpose?: string;
  customFirstName?: string;
  customLastName?: string;
}

export interface ChangeCardLimitsPayload {
  limit: Money;
  transactionLimit: Money;
  limitRenewFrequency: CardLimitRenewFrequency;
}

export interface UpdateCardPayload {
  cardDesignId: string;
  cardName?: string | null;
  purpose?: string;
}

export interface GetCardsParams {
  organizationId: string;
  teamId?: string; // joined array
  memberId?: string;
  page?: number;
  limit?: number;
  sort?: string;
  q?: string;
  type?: string; // joined array
  cardConfigGroups?: string; // joined array
  status?: string; // joined array
}

type CardHistoryEntryBase<T, P> = {
  type: T;
  id: string;
  cardId: string;
  organizationId: string;
  initiatorId: string | null;
  initiatorName: string;
  initiatedAt: string;
} & P;

export type CardHistoryCardControlEntry = CardHistoryEntryBase<
  | CardHistoryEntryType.cardControlCategories
  | CardHistoryEntryType.cardControlDates
  | CardHistoryEntryType.cardControlMerchants
  | CardHistoryEntryType.cardControlTimes
  | CardHistoryEntryType.cardControlLocations,
  {
    prevRestrictionType: CardControlRestriction | null;
    restrictionType: CardControlRestriction | null;
  }
>;

export type CardHistoryValidityChangedEntry = CardHistoryEntryBase<
  | CardHistoryEntryType.validityFromChanged
  | CardHistoryEntryType.validityToChanged
  | CardHistoryEntryType.validityTimezoneChanged,
  {
    prevValidityFrom: string | null;
    validityFrom: string | null;
    prevValidityTo: string | null;
    validityTo: string | null;
    prevValidityTimezone: string | null;
    validityTimezone: string | null;
  }
>;

// This type will be gradually split into separate specific types
export type CardHistoryCommonEntry = CardHistoryEntryBase<
  Exclude<
    CardHistoryEntryType,
    | CardHistoryEntryType.cardControlCategories
    | CardHistoryEntryType.cardControlDates
    | CardHistoryEntryType.cardControlMerchants
    | CardHistoryEntryType.cardControlTimes
    | CardHistoryEntryType.cardControlLocations
    | CardHistoryEntryType.validityFromChanged
    | CardHistoryEntryType.validityToChanged
    | CardHistoryEntryType.validityTimezoneChanged
  >,
  {
    prevTeamId: string | null;
    prevTeamName: string | null;
    teamId: string | null;
    teamName: string | null;
    prevTransactionLimit: Money | null;
    transactionLimit: Money;
    requestedTransactionLimit: Money | null;
    prevLimit: Money | null;
    limit: Money;
    requestedLimit: Money | null;
    prevLimitRenewFrequency: CardLimitRenewFrequency | null;
    limitRenewFrequency: CardLimitRenewFrequency;
    requestedLimitRenewFrequency: CardLimitRenewFrequency | null;
    replacementCardId: string | null;
    replacementCardReason: CardReplacementReason | null;
    replacementCardRefNum: string | null;
    cardType: CardNewType | null;
  }
>;

export type CardHistoryEntry =
  | CardHistoryCardControlEntry
  | CardHistoryValidityChangedEntry
  | CardHistoryCommonEntry;

export interface CardHistoryEntryPageableList {
  entries: CardHistoryEntry[];
  hasNextPage: boolean;
}

export interface GetCardHistoryParams {
  page: number;
  limit: number;
}

export interface GetCardCountsAndLimitsParams {
  organizationId: string;
  memberId?: string;
  // true => the number of all issued single-use cards
  // false => the number of single-use cards issued in a current month
  totalIssued?: boolean;
}

export interface CardCountAndLimit {
  unavailableReason: CardIssuanceUnavailableReason | null;
  issued: number;
  max: number | null;
}

export type CardCountAndLimitByConfigMap = {
  [K in CardConfig]?: CardCountAndLimit;
};

export interface CardCount {
  activeCardsByType: { [key in CardType]?: number };
  pendingCardsByType: { [key in CardType]?: number };
  requestedCardsByType: { [key in CardType]?: number };
}

export interface TopCardSpender {
  cardHolderFirstName: string | null;
  cardHolderLastName: string | null;
  cardId: string;
  cardName: string | null;
  cardRefNum: string | null;
  cardType: CardType | null;
  cardConfig: CardConfig;
  cardDesignId: string;
  newCardType: CardNewType;
  premiumProductType: CardPremiumProductType | null;
  maxUsage: 1 | null;
  totalAmount: Money;
}

export interface TopCardSpenderList {
  cards: TopCardSpender[];
}

export interface Pin {
  pin: string;
}

export interface TransactionMerchantInfo {
  id: string;
  displayName: string;
  logoPath: string | null;
  status: MerchantStatus;
}

export interface ExportError {
  error: string;
  requestId: string;
  errorDescription: string;
  errorUri: string;
}

export interface TransactionExportError {
  paymentXmlError: ExportError | null;
  invoiceXmlError: ExportError | null;
  receiptError: ExportError | null;
  error: ExportError | null;
  createdAt: string;
  updatedAt: string;
}

export interface SupplierTransactionInfo {
  id: string;
  name: string;
  accountNumber: string | null;
  autoMatched: boolean;
}

export type ProjectTransactionInfo =
  | {
      id: string;
      name: string;
      costUnit: string;
    }
  | {
      id: null;
      name: null;
      costUnit: null;
    };

export interface Transaction {
  transactionId: string;
  organizationId: string;
  organizationName?: string;
  memberId: string;
  cardId: string;
  cardRefNum: string;
  cardType: CardType;
  cardConfig: CardConfig;
  cardDesignId: string;
  newCardType: CardNewType;
  premiumProductType: CardPremiumProductType | null;
  maxUsage: 1 | null;
  cardName: string;
  platformFeeCard: boolean;
  memberFirstName: string;
  memberLastName: string;
  exportDate: string | null;
  exportStatus: TransactionExportStatus;
  exportFormat: ExportFormat | null;
  disputed: boolean;
  integrationType: IntegrationType | null;
  status: TransactionStatus;
  type: string;
  simpleType: TransactionSimpleType;
  category: MerchantCategory;
  merchant: TransactionMerchantInfo;
  merchantName: string;
  merchantNameConfirmation: string | null;
  transactionAmount: Money;
  fxTransactionAmount: Money;
  createdAt: string;
  confirmedAt: string;
  bookingDate: string | null;
  teamId: string | null;
  teamName: string | null;
  comment: string;
  receiptDate: string | null;
  receiptsCount: number;
  receiptNeeded: boolean;
  receiptUploadEnabled: boolean;
  reversedAt: string | null;
  emission?: number | null;
  declineReason: string;
  declineReasonDescription: string;
  hasMultipleAccountingTransactions: boolean;
  exportError: TransactionExportError | null;
  receiptNumber: string;
  project: ProjectTransactionInfo;
  supplier: SupplierTransactionInfo | null;
  country?: string;
  hasRejectedReceipts: boolean;
  reviewStatus: TransactionReviewStatus | null;
  reviewComment: string | null;
  reviewedAt: string | null;
  reviewerFullName: string | null;
  reviewerEmail: string | null;
  exportExternalReference: string | null;
  cardAccountId: string;
  cardAccountName: string | null;
  defaultAccount: boolean;
  expenseType: ExpenseType;
  expenseTypeChangeableBy: ExpenseTypeChangeableBy;
}

export interface TransactionPageableList {
  totalCount: number;
  hasNextPage: boolean;
  transactions: Transaction[];
}

export interface GetTransactionsParams {
  organizationId: string;
  teamId?: string; // joined array
  memberId?: string;
  cardId?: string;
  page?: number;
  limit?: number;
  q?: string;
  fromDate?: string;
  toDate?: string;
  receiptNeeded?: boolean;
  receiptMissing?: boolean;
  reviewStatus?: string;
  category?: string; // joined array
  projectIds?: string; // joined array
  type?: string; // joined array
  status?: string; // joined array
  merchantId?: string; // joined array
  cardAccountId?: string;
  expenseType?: ExpenseType;
}

export interface Receipt {
  autoMatched: boolean;
  receiptId: string;
  transactionId: string;
  organizationId: string;
  memberId: string;
  memberFullName: string;
  fileName: string;
  createdAt: string;
  mimeType: ReceiptMimeType;
  receiptNumber: string;
  receiptNumberStatus: ReceiptAutomatchStatus;
  receiptDate: string | null;
  receiptDateStatus: ReceiptAutomatchStatus;
  status: ReceiptStatus;
  rejectionReason?: ReceiptRejectionReason;
  comment?: string;
}

export interface ReceiptList {
  receiptNumber: string;
  receiptNumberStatus: ReceiptAutomatchStatus;
  receiptDate: string | null;
  receiptDateStatus: ReceiptAutomatchStatus;
  receipts: Receipt[];
}

export interface UpdateReceiptPayload {
  transactionId: string;
  receiptNumber?: string;
  receiptDate?: string;
}

export interface Subcategory {
  id: string;
  name: string;
  number: string | null;
  status: AccountingItemStatus;
  category: MerchantCategory;
  organizationId: string;
}

export interface SubcategoryPageableList {
  hasNextPage: boolean;
  subcategories: Subcategory[];
}

export interface VatRate {
  id: string;
  name: string;
  rate: number;
  code: string | null;
  status: AccountingItemStatus;
  organizationId: string;
}

export interface VatRatePageableList {
  hasNextPage: boolean;
  vatRates: VatRate[];
}

export interface Project {
  id: string;
  name: string;
  costUnit: string;
  status: AccountingItemStatus;
  organizationId: string;
  isPrivate: boolean;
  teams: { id: string; name: string }[];
  externalReference: string;
}

export interface GetProjectsParams {
  organizationId: string;
  status?: AccountingItemStatus | undefined;
  source?: string;
  name?: string;
  q?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
}

export interface GetCardholderProjectsParams {
  organizationId: string;
  transactionId?: string;
  name?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  q?: string;
}

export interface ProjectPageableList {
  hasNextPage: boolean;
  projects: Project[];
}

export interface AddProjectPayload {
  organizationId: string;
  name: string;
  costUnit: string;
  teamIds: string[];
}

export interface UpdateProjectPayload {
  status: AccountingItemStatus;
  organizationId: string;
  name: string;
  costUnit: string;
  teamIds: string[];
}

export interface Supplier {
  id: string;
  name: string;
  accountNumber: string | null;
  status: AccountingItemStatus;
  organizationId: string;
}

export interface BulkUploadError {
  index: number;
  errorCode:
    | SupplierUploadErrorCode
    | GlAccountUploadErrorCode
    | ProjectUploadErrorCode;
  warning: boolean;
  error: boolean;
}

export interface SupplierList {
  suppliers: Supplier[];
  errors: BulkUploadError[];
}

export interface SubcategoryUploadList {
  subcategories: Subcategory[];
  errors: BulkUploadError[];
}

export interface ProjectUploadList {
  projects: Project[];
  errors: BulkUploadError[];
}

export interface GetSuppliersParams {
  organizationId: string;
  status?: AccountingItemStatus | undefined;
  page?: number;
  limit?: number;
  q?: string;
  sortBy?: string;
}

export interface SupplierPageableList {
  hasNextPage: boolean;
  suppliers: Supplier[];
}

export interface AddSupplierPayload {
  organizationId: string;
  name: string;
  accountNumber?: string;
}

export interface UpdateSupplierPayload {
  status: AccountingItemStatus;
  organizationId: string;
  name: string;
  accountNumber?: string;
}

export interface UploadSupplierParams {
  delimiter: string;
  nameHeader: string;
  accountNumberHeader: string;
}

export interface UploadGlAccountParams {
  delimiter: string;
  nameHeader: string;
  accountNumberHeader: string;
  categoryHeader: string;
}

export interface LatestXlsxUploadResponseStats {
  total: number;
  created: number;
  skipped: number;
  errors: number;
}

export interface LatestXslxUploadResponse {
  id: string;
  type: XlsxUploadType;
  status: XlsxUploadSuccessStatus;
  stats: LatestXlsxUploadResponseStats;
  createdBy: string;
  completedAt: string;
  processedRecords: number;
  totalRecords: number;
}

export interface XlsxValidationErrors {
  [key: string]: string[];
}

export interface XlsxUploadResponse {
  id: string;
  validationErrors: XlsxValidationErrors;
}

export interface UploadProjectParams {
  delimiter: string;
  nameHeader: string;
  costUnitHeader: string;
}

export interface AssignSupplierPayload {
  organizationId: string;
  supplierId: string;
  transactionId: string;
}

export interface UnAssignSupplierPayload {
  organizationId: string;
  transactionId: string;
}

export interface SupplierDetails {
  organizationId: string;
  transactionId: string;
}

export interface TxCustomField {
  label: string;
  value: string;
  automatched: boolean;
}

export interface AccountingTransaction {
  index: number;
  id: string;
  category: MerchantCategory;
  subcategoryId: string | null;
  subcategoryAutomatched: boolean;
  teamId: string | null;
  vatRateId: string | null;
  vatRateAutomatched: boolean;
  projectId: string | null;
  projectName: string | null;
  costUnit: string | null;
  amount: Money;
  fxAmount: Money;
  txnCustomFieldIdToField:
    | {
        [id: string]: TxCustomField;
      }
    | {};
}

export type UpdateAccountingTransactionsPayload = Array<{
  index: number;
  id?: string;
  category: MerchantCategory;
  subcategoryId: string | null;
  vatRateId: string | null;
  projectId: string | null;
  projectName: string | null;
  costUnit: string | null;
  teamId?: string | null;
  fxAmount: Money;
  txnCustomFieldIdToField:
    | {
        [id: string]: TxCustomField;
      }
    | {};
}>;

export interface AccountingTransactionList {
  transactionId: string;
  organizationId: string;
  accountingTransactions: AccountingTransaction[];
}

export interface TransactionSupplierInfo {
  id: string;
  name: string;
  accountNumber: string;
}

export interface ExportFlowTransaction {
  transactionId: string;
  organizationId: string;
  merchantName: string;
  merchant: TransactionMerchantInfo;
  createdAt: string;
  confirmedAt: string;
  receiptDate: string | null;
  receiptNeeded: boolean;
  receiptsCount: number;
  receiptUploadEnabled: boolean;
  reviewStatus: TransactionReviewStatus | null;
  transactionAmount: Money;
  fxTransactionAmount: Money;
  subcategoryStatus: SubcategoryStatus;
  subcategoryId: string | null;
  vatRateStatus: VatRateStatus;
  vatRateId: string | null;
  projectStatus: ProjectStatus;
  projectId: string | null;
  exportDateAndCounter: string;
  category: MerchantCategory;
  simpleType: TransactionSimpleType;
  status: TransactionStatus;
  hasMultipleAccountingTransactions: boolean;
  comment: string;
  teamId: string | null;
  teamName: string | null;
  costCenter: string | null;
  transactionSupplierInfo: TransactionSupplierInfo | null;
  bookingDate: string;
  exportError: TransactionExportError | null;
  cardAccountId: string;
  cardAccountName: string | null;
  cardAccountNumber: string | null;
  defaultAccount: boolean;
  memberFirstName: string;
  memberLastName: string;
  expenseType: ExpenseType;
}

export interface ExportFlowTransactionList {
  transactions: ExportFlowTransaction[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface ExportSseData {
  status: ApiExportTransactionStatus;
  totalTransactions: number;
  uploadedTransactions: number;
  createdAt: string;
  updatedAt: string;
}

export interface ExportFlowSummary {
  selected: number;
  missingReceipts: number;
  missingVatRate: number;
  missingSubcategory: number;
  missingReview: number;
  earliestTransaction: string;
  latestTransaction: string;
  mandatoryFieldsMissed: boolean;
}

export interface GetExportFlowFileParams {
  organizationId: string;
  withReceipts: boolean;
  common: boolean;
  format: 'CSV' | 'XML';
  transactionIds?: string;
  accountingExportId?: string;
}

export interface DownloadExportFlowFilePayload {
  organizationId: string;
  withReceipts: boolean;
  common: boolean;
  format:
    | ExportFormat.csv
    | ExportFormat.xml
    | ExportFormat.csvMerged
    | ExportFormat.csvSmartExcel;
  transactionIds?: string[];
  accountingExportId?: string;
  accountingSystem?:
    | AccountingSystem.datev
    | AccountingSystem.bmd
    | AccountingSystem.lexware
    | 'DEFAULT_CSV';
}

export interface GetBillPaymentsCsvParams {
  organizationId: string;
  billId?: string;
  organizationAccountStatementId?: string;
  accountingSystem: AccountingSystem | 'DEFAULT_CSV';
}

export interface GetBillPaymentsXmlParams {
  organizationId: string;
  billId?: string;
  organizationAccountStatementId?: string;
}

export interface UpdateTransactionsExportPayload {
  organizationId: string;
  transactionIds: string[];
  newExportStatus: TransactionExportStatus;
  exportFormat?: ExportFormat;
  withReceipts?: boolean;
}

export interface UpdateTransactionsReviewPayload {
  transactionIds: string[];
  reviewStatus: TransactionReviewStatus;
  reviewComment?: string;
}

export interface GetAccountEntriesParams {
  organizationId: string;
  cardAccountId?: string;
  fromBookingDate?: string;
  toBookingDate?: string;
  q?: string;
  subType?: string;
  category?: string;
  teamIds?: string;
  projectIds?: string;
  receiptStatus?: string;
  page?: number;
  limit?: number;
  expenseType?: ExpenseType;
}

export interface GetAccountEntriesCsvParams {
  organizationId: string;
  cardAccountId?: string;
  format: TransactionsDownloadFileType;
  fromBookingDate?: string;
  toBookingDate?: string;
}

export interface GetBillingStmtParams {
  organizationId: string;
  cardAccountId?: string;
  fromBookingDate: string;
  toBookingDate: string;
}

export interface AccountEntryTransactionInfo {
  transactionId: string;
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  cardId: string;
  cardRefNum: string;
  cardType: CardType;
  cardConfig: CardConfig;
  cardDesignId: string;
  newCardType: CardNewType;
  premiumProductType: CardPremiumProductType | null;
  maxUsage: 1 | null;
  cardName: string;
  status: TransactionStatus;
  type: string;
  simpleType: TransactionSimpleType;
  category: MerchantCategory;
  merchantName: string;
  merchant: TransactionMerchantInfo;
  transactionAmount: Money;
  fxTransactionAmount: Money;
  createdAt: string;
  confirmedAt: string;
  comment: string;
  receiptNeeded: boolean;
  receiptsCount: number;
  hasRejectedReceipts: boolean;
  receiptUploadEnabled: boolean;
  hasMultipleAccountingTransactions: boolean;
  integrationType: IntegrationType | null;
  exportStatus: TransactionExportStatus;
  disputed: boolean;
  expenseType: ExpenseType;
}

export interface AccountEntry {
  id: string;
  organizationId: string;
  index: number;
  type: AccountEntryType;
  bookingDate: string;
  amount: Money;
  balanceAfter: Money;
  billPaymentInfo: {
    billPaymentId: string;
    billPaymentType: AccountEntryBillPaymentType;
    paymentType: BillPaymentType;
    publicId: string;
    requestDate: string | null;
    cutoffDate: string | null;
    paymentDefermentInDays: number | null;
    counterparty: string;
    counterpartyIban: string;
    counterpartyBic: string;
    counterpartyBankName: string;
    purpose: string;
    originalPaymentPublicId: string | null;
    originalPaymentCutoffDate: string | null;
  } | null;
  transactionInfo: AccountEntryTransactionInfo | null;
}

export interface AccountEntryPageableList {
  accountEntries: AccountEntry[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface FxRate {
  source: string;
  target: string;
  ecbRate: number;
  ecbRateDate: string;
  rate: number;
  schemeRateDate: string;
  visaMarkupPercentage: number;
}

export interface FxRateList {
  fxRates: FxRate[];
}

export interface GetFxRatesParams {
  source: string; // currency code
  target: string; // currency code(s)
}

export interface FxRateListDEPRECATED {
  fxRates: {
    foreignCurrency: string;
    ecbRate: number;
    ecbRateDate: string;
    visaRate: number;
    visaRateDate: string;
    visaMarkupPercentage: number;
  }[];
}

export interface Bill {
  billId: string;
  organizationId: string;
  status: BillStatus;
  billingNumber: string;
  amount: Money;
  paidAmount: Money;
  pendingAmount: Money;
  deferredAmount: Money;
  unpaidAmount: Money;
  startAt: string;
  closeAt: string;
  createdAt: string;
  updatedAt: string;
  openingBalance: Money;
  closingBalance: Money;
  billingStatementDocumentId: string | null;
  numberOfTransactionsIncluded: number;
}

export interface BillList {
  bills: Bill[];
}

export interface Payment {
  id: string;
  publicId: string | null;
  billId: string;
  organizationId: string;
  billPaymentType: BillPaymentBillPaymentType;
  status: PartialPaymentStatus;
  type: BillPaymentType;
  directDebitType: DirectDebitType | null;
  amount: Money;
  paymentDefermentInDays: number | null;
  cutoffDate: string | null;
  submissionDate: string;
  collectionDate: string | null;
  paymentDate: string | null;
  counterpartyIban: string | null;
  counterpartyBankName: string | null;
  topupApproved: boolean;
  returnResponseCode: string | null;
  originalPaymentPublicId: string | null;
  originalPaymentPaymentDate: string | null;
  retryAttempt: number;
  payoutAccountIban: string | null;
  payoutAccountBankName: string | null;
}

export interface OrganizationAccountStatementPayment {
  id: string;
  type: BillPaymentBillPaymentType;
  status: PartialPaymentStatus;
  paymentBatchId: string | null;
  purpose: string | null;
  reference: string | null;
  requestDate: string | null;
  cutOffDate: string | null;
  paymentDate: string | null;
  collectionDate: string | null;
  bookingDate: string | null;
  defermentDays: number | null;
  amount: Money;
  transferType: BillPaymentType;
  directDebitType: DirectDebitType | null;
  counterPartyBankName: string | null;
  counterPartyIban: string | null;
  originalPaymentReference: string | null;
  originalPaymentPaymentDate: string | null;
  returnReasonCode: string | null;
  initialPaymentPublicId: string | null;
  initialPaymentPaymentDate: string | null;
  retryAttempt: number | null;
  cardAccountId: string;
  cardAccountName: string | null;
  defaultAccount: boolean;
}

export interface OrganizationAccountStatement {
  cardAccountId: string;
  organizationAccountStatementId: string;
  organizationId: string;
  startDate: string;
  endDate: string;
  firstAccountEntryIndex: number | null;
  lastAccountEntryIndex: number | null;
  openingBalance: Money;
  totalTransactionCount: number;
  totalTransactionAmount: Money;
  totalPaymentCount: number;
  totalPaymentAmount: Money;
  closingBalance: Money;
  closed: boolean;
  documentId: string | null;
  cardStatementZipDocumentId: string | null;
}

export interface OrganizationAccountStatementsResponse {
  upcomingPayments: OrganizationAccountStatementPayment[];
  pendingPayments: OrganizationAccountStatementPayment[];
  statements: OrganizationAccountStatement[];
}

export interface OrganizationBillPaymentsPageableResponse {
  upcomingPayments: OrganizationAccountStatementPayment[];
  pendingPayments: OrganizationAccountStatementPayment[];
  organizationPastBillPaymentsPageableResponse: {
    pastPayments: OrganizationAccountStatementPayment[];
    hasNextPage: boolean;
  };
}

export interface Bank {
  name: string;
  postalCode: string;
  city: string;
  bic: string;
}

export interface BankAccount {
  createdAt: string;
  updatedAt: string;
  id: string;
  organizationId: string;
  bankId: string;
  bankName: string;
  bic: string;
  iban: string | null;
  directDebit: boolean;
  bankConnectionId: number | null;
  active: boolean;
  name: string | null;
  accountNumber: string | null;
  detectedAsTransferSender: boolean;
  transfersAllowedStatus: BankAccountTransfersAllowedStatus;
  bankAccountType: BankAccountType;
  cashbackPayout: boolean;
}

export interface BankAccountList {
  bankAccounts: BankAccount[];
}

export interface UpdateBankAccountStatusData {
  bankAccountId: string;
  status: BankAccountTransfersAllowedStatus;
}

export interface BankConnection {
  id: number;
  sessionId: string | null;
  bankId: number;
  status: string;
  updateStatus: string;
  organizationId: string;
  accountIds: number[];
  expiresAt: string | null;
  userActionRequired: boolean;
  dataUpdateError: string | null;
  dataUpdateResult: string | null;
  bankAccountType: BankAccountType;
  dataUpdateTime: string | null;
}

export interface BankConnectionList {
  bankConnections: BankConnection[];
}

export interface BankConnectionImportStatus {
  status:
    | 'PENDING' // client initiated import process and was redirected to the finAPI page
    | 'IMPORTED' // we received callback and imported all bank accounts
    | 'AUTHORIZED' // customer confirmed bank accounts to import
    | 'FAILED' // we received an error from finAPI
    | 'DISCARDED'; // no callback received or client decided not to proceed with the import
  startedAt: string;
  startedBy: string;
  completedAt: string;
  bankConnectionId: number;
  errorMessage: string | null;
  errorCode: string | null;
  requestId: string | null;
}

export interface BankConnectionUrl {
  webFormUri: string | null;
  status: string;
}

export interface Xs2aSessionResponse {
  xs2aSessionId: string;
  sessionId: string;
  sessionIdShort: string;
  consent: string;
  token: string;
}

export interface DirectDebitInfo {
  activationConfirmations: Confirmation[];
  bankAccountId: string;
  bankBic: string;
  bankingServicePartner: BankingServicePartner;
  bankName: string;
  confirmedAt: string | null;
  deactivatedAt: string | null;
  deactivationConfirmations: Confirmation[];
  directDebitType: DirectDebitType;
  documentId: string | null;
  documentName: string | null;
  iban: string;
  id: string;
  mandateReference: string;
  mandateStatus: MandateStatus;
  organizationId: string;
}

export interface CashbackRateAudit {
  updatedAt: string;
  email: string;
}

// Total cashback amounts across all organization cashback buckets
// (one bucket per card account) in the default organization currency
export interface Cashback {
  generalRate: number;
  customGeneralRateEnabled: boolean;
  generalRateAudit: CashbackRateAudit | null;
  customGeneralRateExpirationDate: string | null;
  fxRate: number;
  fxRateEnabled: boolean;
  fxRateAudit: CashbackRateAudit | null;
  customFxRateExpirationDate: string | null;
  mccLowRate: number;
  mccLowRateEnabled: boolean;
  mccLowRateAudit: CashbackRateAudit | null;
  customMccLowRateExpirationDate: string | null;
  mccLowTwoRate: number;
  mccLowTwoRateEnabled: boolean;
  mccLowTwoRateAudit: CashbackRateAudit | null;
  customMccLowTwoRateExpirationDate: string | null;
  mccZeroRate: number;
  mccZeroRateEnabled: boolean;
  mccZeroRateAudit: CashbackRateAudit | null;
  customMccZeroRateExpirationDate: string | null;
  totalEarned: Money;
  totalPending: Money;
  totalRedeemable: Money; // available after a waiting period of 75 days
  totalRedeemed: Money;
  // totalRedeemed = totalPayout + totalOffset
  totalPayout: Money; // paid to customer's bank account
  totalOffset: Money; // redeemed for CO2 compensation
  cashbackMonthlyThreshold: Money;
  cashbackMonthlyThresholdAudit: CashbackRateAudit | null;
  upcomingCashbackMonthlyThreshold: Money | null;
  cashbackDefaults: {
    maxMonthlyThreshold: Money;
    maxCustomCashback: Money;
    minRedeemableCashback: Money;
  };
}

// Cashback amounts aggregated by currency
export interface CurrencyCashback {
  totalEarned: Money;
  totalPending: Money;
  totalRedeemable: Money;
  totalRedeemed: Money;
  totalPayout: Money;
  totalOffset: Money;
  cashbackDefaults: {
    maxCustomCashback: Money;
    minRedeemableCashback: Money;
  };
}

export interface CurrencyCashbackByCurrencyMap {
  buckets: { [C in CardAccountCurrency]?: CurrencyCashback };
}

export interface MonthlyCashbackProgress {
  year: number;
  month: number;
  cashbackAmount: Money;
  spendAmount: Money; // negative
  status: MonthlyCashbackProgressStatus;
}

export interface MonthlyCashbackProgressList {
  availableYears: number[];
  cashback: MonthlyCashbackProgress[];
}

export interface GetHistoryCashbackRedemptionsParams {
  page: number;
  limit: number;
  currency?: string;
}

export interface HistoryCashbackRedemption {
  id: string;
  payoutDate: string | null;
  amount: Money;
  type: HistoryCashbackRedemptionType;
  state: HistoryCashbackRedemptionStatus;
  receiptId: string | null;
}

export interface HistoryCashbackRedemptionPageableList {
  hasNextPage: boolean;
  results: HistoryCashbackRedemption[];
}

export interface BankForConnection {
  id: string;
  name: string;
  bic: string;
  blz: string;
  location: string;
  city: string;
  popularity: number;
  interfaces: any[];
  testBank: boolean;
}

export interface BankForConnectionPageableList {
  banks: BankForConnection[];
  hasNextPage: boolean;
}

export interface TacMetadata {
  acceptedAt: string;
  confirmations: Confirmation[];
  documentFileName: string;
  documentId: string;
  organizationId: string;
  type: string;
}

export interface TacMetadataList {
  termsAndConditions: TacMetadata[];
}

export interface SettlementPayment {
  id: string;
  type: OriginatorPaymentType;
  status: PaymentStatus;
  organizationId: string | null;
  accountTransactionId: string | null;
  originatorId: string | null;
  paymentFileId: string | null;
  paymentFileName: string | null;
  createdAt: string;
}

export interface SettlementPaymentList {
  payments: SettlementPayment[];
}

export interface ProgramSettlementPaymentDetails {
  creditorAccount: {
    accountHolderName: string;
    bankName: string;
    bic: string;
    iban: string;
  };
  debtorAccount: {
    accountHolderName: string;
    bankName: string;
    bic: string;
    iban: string;
  };
  purpose: string;
  amount: Money;
}

export interface ProgramSettlementPaymentsFile {
  programSettlementId: string;
  paymentFileId: string;
  paymentType:
    | PaymentType.receivablePurchasePmt
    | PaymentType.receivableDiscountPmt
    | PaymentType.creditFundingPmt
    | PaymentType.orgSetlPmt
    | PaymentType.programSetlFeePmt
    | PaymentType.programSetlPmt;
  paymentsStatuses: {
    [PaymentStatus.failed]?: number;
    [PaymentStatus.paid]?: number;
    [PaymentStatus.pending]?: number;
    [PaymentStatus.readyForPmt]?: number;
  };
  amount: Money;
  createdAt: string;
  paymentFileName?: string;
}

export interface BillPaymentsBankAccount {
  id: string;
  organizationId: string;
  organizationName: string;
  accountHolderName: string | null;
  levenshteinDistance: number;
  firstPaymentDate: string | null;
  bankName: string;
  bic: string;
  iban: string;
  directDebit: boolean;
  detectedAsTransferSender: boolean;
  transfersAllowedStatus: BankAccountTransfersAllowedStatus;
  cashbackPayout: boolean;
  deleted: boolean;
  active: boolean;
}

export interface GetBillPaymentsBankAccountsParams {
  page?: number;
  limit?: number;
  detectedAsTransferSender?: boolean;
  q?: string;
  transfersAllowedStatuses?: BankAccountTransfersAllowedStatus;
}

export interface BillPaymentsBankAccountPageableList {
  bankAccounts: BillPaymentsBankAccount[];
  hasNextPage: boolean;
}

export interface ReceivableRepayment {
  accountGroup: AccountGroup;
  actualPaymentDate: string | null;
  amount: Money;
  cardAccountCount: number;
  createdAt: string;
  currency: string;
  id: string;
  paymentFileId: string;
  paymentFileName: string;
  plannedPaymentDate: string;
  status:
    | PaymentStatus.readyForPmt
    | PaymentStatus.delayed
    | PaymentStatus.pending
    | PaymentStatus.failed
    | PaymentStatus.paid;
  submissionDate: string;
  submittedAt: string;
}

export interface ReceivableRepaymentsPageableList {
  repayments: ReceivableRepayment[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface GetReceivableRepaymentsParams {
  page?: number;
  limit?: number;
}

export interface ReceivableRepaymentAmount {
  amount: Money;
  cardAccountNumber: string;
  organizationName: string;
}

export interface ReceivableRepaymentAmountPageableList {
  repayments: ReceivableRepaymentAmount[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface BillPaymentBatch {
  id: string;
  createdAt: string;
  submissionDate: string;
  submittedAt: string | null;
  plannedPaymentDate: string;
  actualPaymentDate: string | null;
  billPaymentType: BillPaymentBatchBillPaymentType;
  currency: string;
  paymentType: BillPaymentType;
  directDebitType: DirectDebitType | null;
  count: number;
  amount: Money;
  paymentFileId: string;
  paymentFileName: string;
  states: {
    [PaymentStatus.readyForPmt]?: number;
    [PaymentStatus.delayed]?: number;
    [PaymentStatus.pending]?: number;
    [PaymentStatus.failed]?: number;
    [PaymentStatus.paid]?: number;
  };
  accountGroup: AccountGroup;
}

export interface GetBillPaymentBatchesParams {
  page?: number;
  limit?: number;
  sort?: string;
  paymentStatuses?: string;
  paymentDateFrom?: string;
  paymentDateTo?: string;
  billPaymentTypes?: string;
  directDebitTypes?: string;
}

export interface BillPaymentBatchPageableList {
  billPayments: BillPaymentBatch[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface CreatePayoutPaymentPayload {
  organizationId: string;
  payoutAmount: Money;
  bankAccountId: string;
  cardAccountId?: string;
}

export interface BatchPayment {
  publicId: string;
  organizationName: string;
  amount: Money;
  status: PartialPaymentStatus;
  paymentExportId: string;
}

export interface BatchPaymentList {
  payments: BatchPayment[];
}

export interface ProcessingAccount {
  accountNumber: string;
  bankName: string;
  beneficiary: string;
  bic: string;
  defaultPaymentPurpose: string;
  iban: string;
  sortCode: string;
  transferType: ProcessingAccountTransferType;
}

export interface CashbackRedemptionBatch {
  id: string;
  createdAt: string;
  paymentDate: string;
  amount: Money;
  count: number;
  accountGroup: AccountGroup;
  batchState: CashbackRedemptionBatchState;
  submittedAt: string | null;
  paymentFileId: string | null;
  paymentFileName: string | null;
  paymentFileCreatedAt: string | null;
  currency: string;
  states: {
    [status in CashbackRedemptionStatus]: number;
  };
}

export interface CashbackRedemptionBatchPageableList {
  batches: CashbackRedemptionBatch[];
  hasNextPage: boolean;
}

export interface CashbackRedemption {
  id: string;
  createdAt: string;
  organizationName: string;
  type: CashbackRedemptionType;
  publicId: string | null;
  paymentExportId: string | null;
  amount: Money;
  state: CashbackRedemptionStatus;
}

export interface CashbackRedemptionList {
  redemptions: CashbackRedemption[];
}

export interface GetCompensationBatchesParams {
  page?: number;
  limit?: number;
  sort?: string;
}

export interface CompensationBatch {
  id: string;
  createdAt: string;
  paymentDate: string | null;
  count: number;
  state: CompensationBatchStatus;
  amount: Money;
}

export interface CompensationBatchPageableList {
  batches: CompensationBatch[];
  hasNextPage: boolean;
}

export interface CompensationBatchDetails extends CompensationBatch {
  redemptionBatches: {
    id: string;
    organizationName: string;
    amount: Money;
    redemptionsCount: number;
    state: CompensationBatchStatus;
  }[];
}

export interface CompensationCertificationProject {
  name: string;
  url: string;
  applyOrder: number;
  provider: string;
  register: string;
  purchasedOn: string;
  serialNumberFrom: string;
  serialNumberTo: string;
}

export interface CompensationCertificationProjectList {
  projects: CompensationCertificationProject[];
}

export interface CompensationStatement {
  organizationName: string;
  compensatedAmount: Money;
  compensatedEmissionAmount: number;
  certificates: {
    certificateNumber: string;
    coveredAmount: number;
  }[];
}

export interface CompensationStatementList {
  result: CompensationStatement[];
}

export interface Document {
  documentId: string;
  documentName: string;
  organizationId: string | null;
  type: DocumentType;
  version: string;
  createdAt: string;
  contentType: string;
  counterparty: string;
  tag: string | null;
  externalEntityId: string | null;
  additionalExternalEntityId: string | null;
}

export type DocumentsByGenericTypeMap = {
  [K in DocumentGenericType]: Document[];
};

export interface Device {
  deviceId: string;
  deviceName: string;
  pairedAt: string;
}

export interface TransactionEmission {
  emissionAmount: number;
  memberId: string;
  organizationId: string;
  transactionId: string;
}

export interface TransactionEmissionList {
  transactions: TransactionEmission[];
}

export interface OrganizationEmission {
  lifetimeCarbonEmission: number | null;
  lifetimeCarbonOffset: number | null;
  totalCarbonEmission: number | null;
  totalCarbonOffset: number | null;
}

export interface CarbonPrice {
  carbonPriceId: string;
  pricePerTonne: Money;
}

export interface MonthlyEmissionData {
  year: number;
  month: number;
  emissionAmount: number;
  offsetAmount: number;
}

export interface MonthlyOrgEmissionsByYear {
  availableYears: number[];
  emissions: MonthlyEmissionData[];
}

export interface PartnerDeal {
  description: {
    shortDescription: {
      mobile: string;
      web: string;
    };
    fullDescription: {
      claimSteps: string[];
      details: {
        type: 'DOCUMENT' | 'URL';
        payload: string;
      } | null;
      info: string;
      termsBullets: string[];
      url: string;
    };
  };
  expireAt: string;
  merchantLogo: string;
  merchantName: string;
  partnerDealCategory: string;
  partnerDealId: string;
  promoImage: string;
  redemptionCode: string | null;
}

export interface LimitChangeRequest {
  cardId: string;
  createdAt: string;
  creatorComment: string;
  id: string;
  requestedLimitRenewFrequency: CardLimitRenewFrequency | null;
  requestedLimit: Money | null;
  requestedTransactionLimit: Money | null;
  status: CardLimitChangeRequestStatus;
}

export interface CreateLimitChangeRequestPayload {
  cardId: string;
  limitRenewFrequency: CardLimitRenewFrequency | null;
  limit: Money | null;
  transactionLimit: Money | null;
  comment: string;
}

export interface ApproveLimitChangeRequestPayload {
  approvedLimitRenewFrequency: CardLimitRenewFrequency;
  approvedLimit: Money;
  approvedTransactionLimit: Money;
}

export interface GeneralInfo {
  supportEmail: string;
  supportPhone: string;
  supportPhoneFormatted: string;
}

export interface CreateOrgManuallyPayload {
  accountGroup: AccountGroup;
  billingAddress: Address;
  foundationDate: string;
  legalForm: string;
  name: string;
  nationalRegisterCourt: string;
  tradeRegisterNumber: string;
  partnerId: PartnerIdType;
  partnerRevenueShareId: PartnerIdType;
  hubspotId: string;
  partnersOrganizationId: string | null;
}

export interface Merchant {
  id: string;
  displayName: string;
  status: MerchantStatus;
  country: string;
  logoPath: string | null;
  legalName: string;
  url: string | null;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  vatNumber: string;
  companyRegistry: string | null;
  transactionsCount: number;
  transactionsWithReceiptsCount: number;
  transactionsVolume: Money;
  splitType: MerchantSplitType;
}

export interface GetMerchantsParams {
  page?: number;
  limit?: number;
  q?: string;
  statuses?: string;
  countries?: string;
}

export interface MerchantPageableList {
  merchants: Merchant[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface OrganizationMerchantTeamDetails {
  teamId: string;
  name: string;
}

export interface OrganizationMerchant {
  id: string;
  displayName: string;
  status: MerchantStatus;
  logoPath: string | null;
  legalName: string | null;
  url: string | null;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  vatNumber: string;
  companyRegistry: string | null;
  country: string;
  transactionsCount: number;
  transactionsVolume: Money;
  currentTransactionsCount: number;
  currentTransactionsVolume: Money;
  previousMonthTransactionsCount: number;
  previousMonthTransactionsVolume: Money;
  supplierResponse: {
    id: string;
    name: string;
    accountNumber: string | null;
  } | null;
  subcategoryResponse: {
    id: string;
    name: string;
    number: string | null;
  } | null;
  vatRateResponse: {
    id: string;
    code: string | null;
    name: string;
    rate: string;
  } | null;
  transactionCategories: MerchantCategory[];
  teamResponses: OrganizationMerchantTeamDetails[];
  relatedMerchants: Merchant[];
}

export interface OrganizationMerchantPageableList {
  merchants: OrganizationMerchant[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface GetOrganizationMerchantsParams {
  page?: number;
  limit?: number;
  sort?: string;
  q?: string;
  countries?: string;
}

export interface MerchantVolumeItem {
  date: string;
  transactionsVolume: Money;
}

export interface MerchantVolumeItemList {
  items: MerchantVolumeItem[];
}

export interface MerchantDe42 {
  autoGroupId: string;
  merchantIdDe42: string;
  merchantName: string;
}

export interface RelatedMerchant {
  id: string;
  displayName: string;
  logoPath: string | null;
  splitType: MerchantSplitType;
}

export interface MerchantDetails {
  id: string;
  displayName: string;
  country: string;
  logoPath: string | null;
  status: MerchantStatus;
  merchantGroupId: string;
  legalName: string;
  url: string | null;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  vatNumber: string;
  companyRegistry: string | null;
  merchantsDe42: MerchantDe42[];
  splitType: MerchantSplitType;
  splitRule: { id: string; matchingStrings: string } | null;
  note: string | null;
  descriptionSource: MerchantDescriptionSource;
  relatedMerchants: RelatedMerchant[];
  subscription: boolean;
  transactionsCount: number;
  transactionsVolume: Money;
  transactionsWithReceiptsCount: number;
}

export interface UpdateMerchantPayload {
  displayName: string;
  legalName: string;
  url: string;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  vatNumber: string;
  country: string;
  companyRegistry: string;
  note: string | null;
}

export interface SplitMerchantDetails {
  mainMerchant: {
    transactionsCount: number;
    transactionsVolume: Money;
  };
  splitMerchant: {
    transactionsCount: number;
    transactionsVolume: Money;
  };
}

export interface ForwardingIntegrations {
  organizationId: string;
  forwardingEmail: string;
  status: ForwardingIntegrationStatus;
  createdAt: string;
  updatedAt: string;
  forwardingFrequency: ForwardingIntegrationFrequency;
  includeCreditCardStatement: boolean;
  includeTransactionDetails: boolean;
  detailedEmailSubject: boolean;
}

export interface ReceiptsSettings {
  organizationId: string;
  receiptNotNeeded: boolean;
  forwardUnmatchedReceipts: boolean;
  forwardUnmatchedReceiptsToEmail: string | null;
  forwardUnmatchedReceiptsInterval: ForwardUnmatchedReceiptsInterval | null;
}

export interface UpdateReceiptSettingsPayload {
  receiptNotNeeded: boolean;
  forwardUnmatchedReceipts: boolean;
  forwardUnmatchedReceiptsToEmail: string | null;
  forwardUnmatchedReceiptsInterval: ForwardUnmatchedReceiptsInterval | null;
}

// 14/06/22 - right now only for circula
export interface SyncSettings {
  organizationId: string;
  orgConnectionStatus: CirculaConnectionStatus;
  orgConnectionType: CirculaConnectionType;
  createdAt: string;
  updatedAt: string;
}

export interface UpdateCirculaSettingsPayload {
  type: CirculaConnectionType;
  status: CirculaConnectionStatus;
}

export interface CreateCardRequestPayload {
  cardAccountId: string;
  cardConfig: CardConfig;
  cardDesignId: string;
  organizationId: string;
  transactionLimit: Money;
  limit: Money;
  limitRenewFrequency: CardLimitRenewFrequency;
  comment?: string;
  projectId?: string | null;
  cardControls?: {
    categories?: CardCategoryControl;
    merchants?: CardMerchantControl;
    dates?: CardDateControl;
    times?: CardTimeControl;
    locations?: CardLocationControl;
  };
  expiryPeriodMonths?: number; // ignored for physical cards
  // Must be sent when cardConfigSetting.validityPeriodEnabled === true
  validFrom?: string;
  validTo?: string;
  validTimezone?: string;
  // only for virtual
  cardName?: string;
  teamId?: string;
  purpose?: string; // only for single-use virtual cards
  customFirstName?: string;
  customLastName?: string;
}

export interface UpdateCardControlsRequestPayload {
  categories?: CardCategoryControl;
  merchants?: CardMerchantControl;
  dates?: CardDateControl;
  times?: CardTimeControl;
  locations?: CardLocationControl;
}

export interface CardRequest {
  cardId: string;
  cardRequestId: string;
  organizationId: string;
  memberId: string;
  transactionLimit: Money;
  limit: Money;
  type: CardType;
  expiryDate: string | null;
  cardName: string;
  comment: string;
  createdAt: string;
  purpose: string | null;
}

export interface Partner {
  name: PartnerName;
  partnerId: PartnerIdType;
  clientId: string;
  endpoint: string;
  tokenUri: string;
  oauthScope: string;
  userAgent: string;
  storeTransactionSync: boolean;
  integrationTypeName: string;
  status: PartnerStatus;
  useOAuth2: boolean;
  callbackEnabled: boolean;
  partnerScope: PartnerScope;
  pciDssCompliant: boolean;
  applicationLink: string | null;
  applicationWalletLink: string | null;
  activateIntegrationInOneStep: boolean;
}

export interface PartnerPageableList {
  partners: Partner[];
  hasNextPage: boolean;
}

export interface PartnerOrgAuthDetails {
  partnerId: PartnerIdType;
  organizationId: string;
  status: PartnerAuthStatus;
  changedBy: string | null;
  syncFromDate: string | null;
  applicationLink: string | null;
  applicationWalletLink: string | null;
  shareOnboardingStatus: boolean | null;
  latestSharingStatusAudit: {
    userName: string;
    assignedAt: string;
    previousStatus: boolean;
    currentStatus: boolean;
  } | null;
}

export interface PartnerConfig {
  partnerId: string;
  name: string;
  clientId: string;
  tokenUri: string;
  oauthScope: string;
  userAgent: string;
  storeTransactionSync: boolean;
  integrationTypeName: string;
  status: PartnerStatus;
  useOAuth2: boolean;
  callbackEnabled: boolean;
  partnerScope: PartnerScope;
  pciDssCompliant: boolean;
  applicationLink: string;
  applicationWalletLink: string;
  auth0Tenant: string;
  uiHostUrl: string;
  issuerUri: string;
  customIssuerUri: string;
  logoUrl: string;
  logoSize: string;
  whitelabelLevel: PartnerWhitelabelLevel;
  allowedOrigins: string[];
  autogenerateCustomFields: number;
  orgAuthRedirectUrl: string;
  activateIntegrationInOneStep: boolean;
}

export interface PartnerAudit {
  userName: string | null;
  partnerId: string;
  partnerName: PartnerName | null;
  partnerRevenueShareId: string;
  partnerRevenueShareName: PartnerName | null;
  assignedAt: string | null;
  partnersOrganizationsAudit: {
    userName: string;
    partnersOrganizationId: string;
    assignedAt: string;
  } | null;
}

export interface OrganizationPartner {
  partnerName: string;
  partnerId: PartnerIdType;
  authStatus: PartnerAuthStatus;
  scope: PartnerScope;
  changedBy: string;
  createdAt: string;
}

export interface NotificationSetting {
  type: string;
  enabled: boolean;
}

export interface NotificationSettingsCategorized {
  groups: {
    group: string;
    categories: NotificationSetting[];
  }[];
}

export interface BankSubmission {
  approvalRequestedAt: string | null;
  approvalRequestedBy: string | null;
  canBeSubmitted: boolean;
  createdAt: string;
  id: string;
  isReadyForReview: boolean;
  organizationActivationDate: string | null;
  organizationId: string;
  rawDataExists: boolean;
  requiredFilesUploaded: unknown;
  secondReviewAt: string | null;
  secondReviewBy: string | null;
  status: BankSubmissionStatus;
  type: BankSubmissionType;
  updatedAt: string;
}

export interface Legitimation {
  date: string;
  document: LegitimationDocument;
  identificationDocument: {
    documentId: string;
    issuanceDate: string;
    issuingOffice: string;
    validityDate: string;
  };
  provider: LegitimationProvider;
}

export interface BankSubmissionRawDataLegitimation {
  date: string;
  identificationDocument: {
    issuanceDate: string;
    issuer: string;
    number: string;
    validityDate: string;
  };
  identificationType: LegitimationDocument;
  provider: LegitimationProvider;
}

export interface Kyc {
  amlRiskScoring: {
    checkDate: string;
    result: AmlRiskScoring;
  };
  checkResult: true; // hardcoded, required in VG submission
  sanctionListCheck: {
    checkDate: string;
    result: 'OK' | null;
  };
}

export interface BankSubmissionRawData {
  accountLimit: number;
  beneficialOwners: {
    citizenship: string[];
    city: string;
    companyShare: number;
    country: string;
    dateOfBirth: string;
    isFATCA: boolean;
    givenName: string;
    kyc: Kyc;
    legitimation: BankSubmissionRawDataLegitimation;
    name: string;
    isPep: boolean;
    postalCode: string;
    street: string;
    streetNumber: string;
    tin: string;
  }[];
  company: {
    city: string;
    country: string;
    dateFounding: string;
    economicSector: string;
    legitimationOn: string;
    enterpriseSize: {
      annualTurnover: number;
      balanceTotal: number;
      dateEnterpriseSize: string;
      numberEmployees: number;
      size: number;
    };
    kyc: Kyc;
    legalForm: string;
    name: string;
    nationalRegisterCourt: string;
    nationalRegisterDate: string;
    nationalRegisterId: string;
    postalCode: string;
    street: string;
    streetNumber: string;
    vat: string;
  };
  creditRating: number;
  iban: string | null;
  representatives: {
    citizenship: string[];
    city: string;
    country: string;
    countryOfBirth: string;
    dateOfBirth: string;
    email: string;
    isFATCA: boolean;
    givenName: string;
    kyc: Kyc;
    legitimation: BankSubmissionRawDataLegitimation;
    mobileNumber: string;
    name: string;
    isPep: boolean;
    placeOfBirth: string;
    postalCode: string;
    salutation: 1 | 2;
    street: string;
    streetNumber: string;
    tin: string;
  }[];
}

export interface RelatedIndividual {
  id: string;
  firstName: string;
  lastName: string;
  idType: RelatedIndividualType;
}

export type CreateNewRepresentative =
  | RelatedIndividual
  | {
      id: 'manual';
      firstName: string;
      lastName: string;
      idType: null;
    };

/**
 * @deprecated use TaxResidencyWithAuditLogs
 */
export interface TaxResidency {
  taxId: string;
  taxResidence: string;
  primary: boolean;
}

export interface TaxResidencyWithAuditLogs {
  taxId: ValueWithAuditLog<string>;
  taxResidence: ValueWithAuditLog<string>;
  primary: ValueWithAuditLog<boolean>;
}

/**
 * @deprecated use RelatedPerson
 */
export interface OrganizationRepresentative {
  citizenships: string[];
  city: string | null;
  companyShare: number;
  countryCode: string;
  countryOfBirth: string;
  dateOfBirth: string;
  email: string;
  externalStatus: OrganizationRepresentativeExternalStatus;
  givenName: string;
  id: string;
  identificationArchiveDownloaded: boolean;
  identificationType: IdentificationType | null;
  internalStatus: OrganizationRepresentativeInternalStatus;
  isFatca: boolean;
  isPep: boolean;
  isRepresentativeDataFull: boolean;
  uboType: UboType;
  kyc: Kyc;
  legalRepType: LegalRepresentativeType;
  legitimation: Legitimation;
  mobileNumber: string;
  placeOfBirth: string;
  postalCode: string | null;
  reasonForAdditionalInfoRequest: string | null;
  salutation: 1 | 2;
  street: string | null;
  streetNumber: string | null;
  surName: string;
  taxId: string; // deprecated
  legitimationStatus: LegitimationStatus;
  taxResidences: TaxResidency[];
  canStartIdentification: boolean;
}

export interface RelatedPerson {
  availableIdentificationTypes: {
    name: IdentificationType;
    default: boolean;
  }[];
  canStartIdentification: boolean;
  citizenships: ValueWithAuditLog<string[]>;
  city: ValueWithAuditLog<string | null>;
  companyShare: ValueWithAuditLog<number>;
  countryCode: ValueWithAuditLog<string>;
  countryOfBirth: ValueWithAuditLog<string>;
  dateOfBirth: ValueWithAuditLog<string>;
  email: ValueWithAuditLog<string>;
  externalStatus: ValueWithAuditLog<OrganizationRepresentativeExternalStatus>;
  givenName: ValueWithAuditLog<string>;
  id: ValueWithAuditLog<string>;
  identificationArchiveDownloaded: ValueWithAuditLog<boolean>;
  identificationType: ValueWithAuditLog<IdentificationType>;
  internalStatus: ValueWithAuditLog<OrganizationRepresentativeInternalStatus>;
  isFatca: ValueWithAuditLog<boolean>;
  isPep: ValueWithAuditLog<boolean>;
  isRepresentativeDataFull: boolean;
  kyc: ValueWithAuditLog<Kyc>;
  legalRepType: ValueWithAuditLog<LegalRepresentativeType>;
  legitimation: ValueWithAuditLog<Legitimation>;
  legitimationStatus: ValueWithAuditLog<LegitimationStatus>;
  mobileNumber: ValueWithAuditLog<string>;
  placeOfBirth: ValueWithAuditLog<string>;
  postalCode: ValueWithAuditLog<string | null>;
  reasonForAdditionalInfoRequest: ValueWithAuditLog<string | null>;
  salutation: ValueWithAuditLog<1 | 2>;
  street: ValueWithAuditLog<string | null>;
  streetNumber: ValueWithAuditLog<string | null>;
  surName: ValueWithAuditLog<string>;
  taxId: ValueWithAuditLog<string>; // deprecated
  taxResidences: TaxResidencyWithAuditLogs[];
  uboType: ValueWithAuditLog<UboType>;
}

type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

export type OrganizationRepresentativePayload = Partial<
  DeepNullable<OrganizationRepresentative>
> &
  Pick<
    OrganizationRepresentative,
    'salutation' | 'surName' | 'givenName' | 'legalRepType' | 'uboType'
  >;

export interface OrganizationRepresentativeAdditionalInfo {
  email: string | null;
  externalStatus: OrganizationRepresentativeExternalStatus;
  givenName: string;
  isEmailRequestedFromUser: boolean;
  isFatca: boolean | null;
  isPep: boolean | null;
  legalRepType: LegalRepresentativeType;
  legitimationStatus: LegitimationStatus;
  reasonForAdditionalInfoRequest: string;
  representativeId: string;
  salutation: 1 | 2;
  surName: string;
  taxResidences: TaxResidency[];
  uboType: UboType;
}

export type OrganizationRepresentativeAdditionalInfoUpdatePayload = Pick<
  OrganizationRepresentativeAdditionalInfo,
  'email' | 'isFatca' | 'isPep' | 'taxResidences'
>;

export interface RelatedIndividualDetails {
  authority: string | null;
  birthYear: string | null;
  city: string | null;
  country: string | null;
  firstName: string | null;
  isPerson: boolean;
  lastName: string | null;
  position: string | null;
  postalCode: string | null;
  shareholderAmountUnit: string | null;
  shareholderAmountValue: string | null;
  startedAt: string | null;
  street: string | null;
  streetNumber: string | null;
  type: string | null;
}

export interface GetLocoiaEmbedSettingsParams {
  sort?: string;
  page?: number;
  limit?: number;
  externalSystem?: AccountingSystem;
}

export interface LocoiaEmbedSetting {
  locoiaEmbedId: string;
  name: string;
  locoiaWebhookUrl: string;
  locoiaWebhookSecret: string;
  externalSystemType: string;
  externalSystem: string;
  createdAt: string;
  updatedAt: string;
}

export interface LocoiaEmbedSettingsPageableList {
  locoiaEmbedSettings: LocoiaEmbedSetting[];
  hasNextPage: boolean;
}

export interface LocoiaGeneratedJwt {
  locoiaEmbedId: string;
  jwt: string;
}

export interface CodatDataConnectionErrors {
  statusCode: string;
  statusText: string;
  errorMessage: string;
  erroredOnUtc: string;
  sourceType: string;
}

export interface CodatDataConnections {
  id: string;
  integrationId: string;
  integrationKey: string;
  sourceId: string;
  platformName: string;
  linkUrl: string;
  status: string;
  lastSync: string;
  created: string;
  sourceType: string;
  dataConnectionErrors: CodatDataConnectionErrors;
  connectionInfo: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
}

export interface CodatData {
  id: string;
  name: string;
  platform: string;
  redirectUrl: string;
  lastSync: string;
  dataConnections: CodatDataConnections[];
  created: string;
  createdByUserName: string;
}

export interface CodatDataRefreshed {
  id: string;
  name: string;
  platform: string;
  redirectUrl: string;
  lastSync: string;
  dataConnections: CodatDataConnections[];
  created: string;
  createdByUserName: string;
}

export interface MappingOptionsSummary {
  newItems: number;
  selectedItems: number;
  unselectedItems: number;
}

export interface CodatMappingOptionsSummary {
  lastSynced: string | null;
  accounts: MappingOptionsSummary;
  taxRates: MappingOptionsSummary;
  suppliers: MappingOptionsSummary;
  trackingCategories: MappingOptionsSummary;
}

export interface CodatAccountItem {
  id: string;
  name: string;
  accountType: string;
  validTransactionTypes: string[];
  status: CodatDataItemStatus;
}

export interface UpdateCodatAccountsPayload {
  organizationId: string;
  selectedAccounts: {
    codatAccountId: string;
    status: CodatDataItemStatus;
  }[];
}

export interface MappedCodatSubcategoriesPayload {
  organizationId: string;
  codatSubcategories: {
    id: string;
    name: string;
    pliantCategory: MerchantCategory;
  }[];
}

export interface CodatTaxRateItem {
  id: string;
  name: string;
  code: string;
  effectiveTaxRate: number;
  totalTaxRate: number;
  validTransactionTypes: string[];
  status: CodatDataItemStatus;
}

export interface UpdateCodatTaxRatesPayload {
  organizationId: string;
  selectedTaxRates: {
    codatTaxRateId: string;
    status: CodatDataItemStatus;
  }[];
}

export interface CodatSupplierItem {
  id: string;
  name: string;
  currency: string | null;
  defaultCurrency: string;
  status: CodatDataItemStatus;
  modifiedDate: string;
}

export interface UpdateCodatSuppliersPayload {
  organizationId: string;
  selectedSuppliers: {
    codatSupplierId: string;
    status: CodatDataItemStatus;
  }[];
}

export interface MappedCodatTaxRatesPayload {
  organizationId: string;
  codatTaxRates: {
    id: string;
    name: string;
    code: string;
    effectiveTaxRate: number;
    totalTaxRate: number;
  }[];
}

export interface MappedCodatSuppliersPayload {
  organizationId: string;
  codatSuppliers: {
    id: string;
    supplierName: string;
  }[];
}

export interface CodatTrackingCategoryItem {
  id: string;
  name: string;
  modifiedDate: string;
  hasChildren: boolean;
  parentId: string | null;
  status: CodatDataItemStatus;
  mappingTarget: CodatMappingTarget | null;
  filteredProjectCategoryId: string | null;
  filteredTeamCategoryId: string | null;
  mappedProjectId: string | null;
  mappedTeamId: string | null;
}

export interface UpdateCodatTrackingCategoriesPayload {
  organizationId: string;
  mappingTarget: CodatMappingTarget;
  filteredProjectCategoryId?: string | null;
  filteredTeamCategoryId?: string | null;
  selectedTrackingCategories: {
    codatTrackingCategoryId: string;
    status: CodatDataItemStatus;
  }[];
}

export interface MappedCodatTeamsPayload {
  organizationId: string;
  codatTrackingCategories: {
    id: string;
    name: string;
    teamId: string | null;
    costCenter: string;
  }[];
}

export interface MappedCodatProjectsPayload {
  organizationId: string;
  codatTrackingCategories: {
    id: string;
    name: string;
    projectId: string | null;
    costUnit: string;
  }[];
}

export interface CodatCompanyBankAccount {
  id: string;
  name: string;
}

export interface GetCodatCompanyBankAccountsParams {
  name?: string;
  page?: number;
  limit?: number;
}

export interface CodatCompanyBankAccountsPageableList {
  bankAccounts: CodatCompanyBankAccount[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface CodatCompanySupplier {
  id: string;
  name: string;
}

export interface GetCodatCompanySuppliersParams {
  name?: string;
  page?: number;
  limit?: number;
}

export interface CodatCompanySuppliersPageableList {
  suppliers: CodatCompanySupplier[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface CodatCompanyCustomer {
  id: string;
  name: string;
}

export interface GetCodatCompanyCustomersParams {
  name?: string;
  page?: number;
  limit?: number;
}

export interface CodatCompanyCustomersPageableList {
  customers: CodatCompanyCustomer[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface CodatCompanyConfig {
  bankAccount: { id: string | null; name: string | null };
  supplier: { id: string | null; name: string | null };
  customer: { id: string | null; name: string | null };
}

export interface UpdateCodatCompanyConfigData {
  bankAccount: { id: string; name: string };
  supplier: { id: string; name: string };
  customer: { id: string; name: string };
}

export interface CodatExpenseSettings {
  id: string;
  fxConversion: boolean;
  draftState: boolean;
}

export interface UpdateCodatExpenseSettingsPayload {
  id: string;
  accountingSystem: AccountingSystem;
  fxConversion: boolean;
  draftState: boolean;
}

export interface SubscriptionPlan {
  id: string;
  type: SubscriptionPlanType;
  baseFee: Money;
  additionalUserFee: Money;
  freeMembersCount: number;
  fxFeePercentage: number;
  activeAndInvitedMembersCount: number;
  activeMembersCount: number;
  invitedMembersCount: number;
  lastEditAt: string | null;
  lastEditUserName: string | null;
  maxActiveMembersCount: number | null;
  maxVirtualCardsCount: number;
  maxSingleUseCardsCount: number;
  insuranceEnabled: boolean;
  premiumFeaturesEnabled: boolean;
  showPlanPage: boolean;
}

export enum MaxFeeValues {
  baseFee = 10000,
  additionalUserFee = 100,
}

export interface PremiumCardFeeTier {
  id: string;
  fee: Money;
  totalCardsCount: number | null; // null only for default tier
  takenCardsCount: number;
  defaultTier: boolean;
}

export interface PremiumCardFeeTierList {
  tiers: PremiumCardFeeTier[];
  lastEditUserName: string | null;
  lastEditAt: string | null;
}

export interface PremiumCardInvoice {
  cardId: string;
  fee: Money | null;
  startDate: string | null;
  endDate: string | null;
  nextChargingDate: string | null;
}

export interface CreateOnboardingDocument {
  type: OnboardingDocumentName;
  relatedEntityId?: string;
  customLabel?: string;
  attributes: { [key: string]: string } | null;
  shouldAlsoRequest?: boolean;
}

export interface OnboardingDocumentFile {
  id: string;
  name: string;
  sizeInBytes: number;
  mediaType: string;
}

export interface OnboardingDocumentType {
  allowedAttributes: OnboardingDocumentAllowedAttribute[];
  allowedMediaTypes: string[];
  category: OnboardingDocumentCategory;
  filenameTemplate: string;
  maxFileSizeInBytes: number;
  maxNumberOfFiles: number;
  minNumberOfFiles: number;
  name: OnboardingDocumentName;
  question: boolean;
  subcategory: string | null;
}

export interface OnboardingDocument {
  answer: string | null;
  answerLocal: string | null;
  attributes: { [key: string]: string };
  createdAt: string;
  customLabel: string | null;
  customLabelLocal: string | null;
  description: string | null;
  descriptionLocal: string | null;
  files: OnboardingDocumentFile[];
  id: string;
  organizationId: string;
  reasonForRequest: string | null;
  relatedEntityId: string;
  status: OnboardingDocumentStatus;
  type: OnboardingDocumentType;
  updatedAt: string;
  updatedBy: string;
}

export interface RequestOnboardingDocument {
  onboardingDocumentId: string;
  reasonForRequest?: string;
}

export interface UpdateComplianceDocumentsPayload {
  type: DocumentType;
  option: ComplianceDocumentUploadOption;
  file: File;
  version: string;
  country: string | null;
  partnerId: string | null;
}

export interface ExistingActiveOptInRequest {
  existsActiveOptIn: boolean;
}

export interface PlatformLegalForm {
  abbreviation: string;
  anacreditClassifier: string;
  country: string;
  fullName: string;
  id: string;
}

export interface OpenActiveOptIn {
  termsAndConditionsType: string;
  documentUrl: string;
  documentVersion: string;
  customerSupportPhone: string;
  acceptedBy: string | null;
}

export interface ActiveOptInConfirmation {
  memberId: string;
  legalRepresentative: LegalRepresentativeType;
}

export interface ActiveOptInRequest {
  openActiveOptIns: OpenActiveOptIn[];
  legalReps: string[];
}

export interface GetMissingReceiptCountParams {
  organizationId: string;
  memberId?: string;
  teamId?: string;
}

export interface GetReviewStatusCountParams {
  organizationId: string;
  memberId?: string;
  teamId?: string;
}

export interface ReviewStatusCount {
  counts:
    | {
        [key in TransactionReviewStatus]: number;
      }
    | null;
}

export interface CrmInconsistency {
  objectId: string;
  objectType: 'COMPANY';
  hubspotId: 'string';
  name: 'string';
  diffs: {
    property: 'string';
    hubspotValue: any;
    dbValue: any;
  }[];
}

export interface SmsLog {
  id: string;
  ip: string | null;
  message: string;
  phoneNumber: string;
  source: SmsLogSource;
  status: SmsLogStatus;
  timestamp: number;
  location: string | null;
}

export interface Timezone {
  offset: string;
  timezone: string;
  cities: string;
}

export interface FirmographicsFundingPayload {
  capitalRaised: boolean | null;
  capitalRaisedAmount: Money | null;
  capitalRaisedSource: string | null;
}

export interface FirmographicsSizePayload {
  annualRevenue: Money | null;
  annualRevenueSource: string | null;
  balanceSheet: Money | null;
  balanceSheetSource: string | null;
  firmographicsDate: string | null;
  numberOfEmployees: number | null;
  numberOfEmployeesSource: string | null;
}

export interface FirmographicsSegmentationPayload {
  customEnterpriseSize: string | null;
  selfDeclaredAnnualRevenue: string | null;
  selfDeclaredBalanceSheet: string | null;
  selfDeclaredNumberOfEmployees: string | null;
}

export interface Firmographics {
  annualRevenue: ValueWithAuditLog<Money>;
  annualRevenueSource: ValueWithAuditLog<string>;
  balanceSheet: ValueWithAuditLog<Money>;
  balanceSheetSource: ValueWithAuditLog<string>;
  capitalRaised: ValueWithAuditLog<boolean>;
  capitalRaisedAmount: ValueWithAuditLog<Money>;
  capitalRaisedSource: ValueWithAuditLog<string>;
  enterpriseSize: ValueWithAuditLog<EnterpriseSize>;
  firmographicsDate: ValueWithAuditLog<string>;
  isCustomEnterpriseSize: ValueWithAuditLog<boolean>;
  numberOfEmployees: ValueWithAuditLog<number>;
  numberOfEmployeesSource: ValueWithAuditLog<string>;
  selfDeclaredAnnualRevenue: ValueWithAuditLog<string>;
  selfDeclaredBalanceSheet: ValueWithAuditLog<string>;
  selfDeclaredNumberOfEmployees: ValueWithAuditLog<string>;
  selfDeclaredSegment: ValueWithAuditLog<string>;
}

export interface OrganizationsStaticData {
  agencyProfileSources: { name: string; label: string }[];
  capitalRaisedSources: { name: string; label: string }[];
  companyStatuses: { name: string; label: string }[];
  discrepancyReasons: { name: string; label: string }[];
  enterpriseSizes: { name: string; label: string }[];
  firmographicsSources: { name: string; label: string }[];
  industryCodeSources: { name: string; label: string }[];
  industryCodeTypes: { name: string; label: string }[];
  paymentFrequencies: { name: string; label: string }[];
  paymentTerms: { name: string; label: string }[];
  probabilityOfDefaultSources: { name: string; label: string }[];
  selfDeclaredAnnualRevenue: { name: string; label: string }[];
  selfDeclaredBalanceSheet: { name: string; label: string }[];
  selfDeclaredNumberOfEmployees: { name: string; label: string }[];
  underwritingCaseTypes: { name: string; label: string }[];
}

export interface ComplianceStaticData {
  amlScreeningValues: { name: string; label: string }[];
  complianceRatingValues: { name: string; label: string }[];
  onboardingTypes: { name: string; label: string }[];
  tpmlRatings: { name: string; label: string }[];
  vgRatings: { name: string; label: string }[];
}

export interface CreditStaticData {
  agencyProfileSources: { name: string; label: string }[];
  capitalRaisedSources: { name: string; label: string }[];
  companyStatuses: { name: string; label: string }[];
  discrepancyReasons: { name: string; label: string }[];
  enterpriseSizes: { name: string; label: string }[];
  firmographicsSources: { name: string; label: string }[];
  industryCodeSources: { name: string; label: string }[];
  industryCodeTypes: { name: string; label: string }[];
  paymentFrequencies: { name: string; label: string }[];
  paymentTerms: { name: string; label: string }[];
  probabilityOfDefaultSources: { name: string; label: string }[];
  selfDeclaredAnnualRevenue: { name: string; label: string }[];
  selfDeclaredBalanceSheet: { name: string; label: string }[];
  selfDeclaredNumberOfEmployees: { name: string; label: string }[];
  underwritingCaseTypes: { name: string; label: string }[];
}

export interface InternalIndustryType {
  internalIndustrySubtypes: { code: string; name: string; position: number }[];
  name: string;
  position: number;
}

export interface AgencyProfile {
  agencyLimit: ValueWithAuditLog<Money>;
  agencyLimitSource: ValueWithAuditLog<string>;
  companyStatus: ValueWithAuditLog<string>;
  companyStatusSource: ValueWithAuditLog<string>;
  creditRating: ValueWithAuditLog<number>;
  crefoId: ValueWithAuditLog<number>;
  duns: ValueWithAuditLog<string>;
  insolvency: ValueWithAuditLog<boolean>;
  insolvencySource: ValueWithAuditLog<string>;
  probabilityOfDefault: ValueWithAuditLog<number>;
  probabilityOfDefaultSource: ValueWithAuditLog<string>;
  publishedAccounts: ValueWithAuditLog<boolean>;
  publishedAccountsDate: ValueWithAuditLog<string>;
  publishedAccountsSource: ValueWithAuditLog<string>;
}

export interface AgencyProfilePayload {
  agencyLimit: Money | null;
  agencyLimitSource: string | null;
  companyStatus: string | null;
  companyStatusSource: string | null;
  crefoId: number | null;
  duns: string | null;
  insolvency: boolean | null;
  insolvencySource: string | null;
  probabilityOfDefault: number | null;
  probabilityOfDefaultSource: string | null;
  publishedAccounts: boolean | null;
  publishedAccountsDate: string | null;
  publishedAccountsSource: string | null;
}

export interface CustomerSummary {
  country: ValueWithAuditLog<SupportedCountry>;
  foundationDate: ValueWithAuditLog<string>;
  industryCode: ValueWithAuditLog<string>;
  industryCodeDescription: string | null;
  industryCodeSource: ValueWithAuditLog<string>;
  industryCodeType: ValueWithAuditLog<string>;
  internalIndustryCode: string | null;
  internalIndustryType: ValueWithAuditLog<string | null>;
  internalIndustrySubtype: ValueWithAuditLog<string | null>;
  legalForm: ValueWithAuditLog<string>;
  legalName: ValueWithAuditLog<string>;
  organizationAge: ValueWithAuditLog<number>;
  riskUnit: ValueWithAuditLog<string | null>;
  riskUnitGroup: ValueWithAuditLog<string | null>;
  riskUnitHolding: ValueWithAuditLog<boolean | null>;
  vatId: ValueWithAuditLog<string>;
  website: ValueWithAuditLog<string>;
}

export type CustomerSummaryPayload = {
  foundationDate: string | null;
  industryCode: string | null;
  industryCodeSource: string | null;
  industryCodeType: string | null;
  internalIndustryType: string | null;
  internalIndustrySubtype: string | null;
  legalForm: string | null;
  legalName: string | null;
  riskUnit: string | null;
  riskUnitGroup: string | null;
  riskUnitHolding: boolean | null;
  vatId: string | null;
  website: string | null;
};

export interface CreditProfile {
  agencyProfile: AgencyProfile;
  customerSummary: CustomerSummary;
  firmographics: Firmographics;
}

export interface CreditAssessmentSummary {
  nextMonitoringDate: ValueWithAuditLog<string | null>;
  organizationLimit: ValueWithAuditLog<Money>;
  probabilityOfDefault: ValueWithAuditLog<number>;
  probabilityOfDefaultSource: ValueWithAuditLog<string>;
}

export interface CreditAssessmentSummaryPayload {
  nextMonitoringDate: string | null;
  probabilityOfDefault: number | null;
  probabilityOfDefaultSource: string | null;
}

export interface CreditAssessmentLimits {
  gracePeriodInMonths: number | null;
  limit: ValueWithAuditLog<Money>;
  limitExtendedCollateral: ValueWithAuditLog<Money>;
  limitFullCollateral: ValueWithAuditLog<Money>;
  limitIncreaseAllowed: ValueWithAuditLog<boolean>;
  limitStandardCollateral: ValueWithAuditLog<Money>;
  maxLimit: ValueWithAuditLog<Money>;
  maxLimitExtendedCollateral: ValueWithAuditLog<Money>;
  maxLimitFullCollateral: ValueWithAuditLog<Money>;
  maxLimitStandardCollateral: ValueWithAuditLog<Money>;
  peakLimit: ValueWithAuditLog<Money>;
  peakLimitExtendedCollateral: ValueWithAuditLog<Money>;
  peakLimitFullCollateral: ValueWithAuditLog<Money>;
  peakLimitStandardCollateral: ValueWithAuditLog<Money>;
  standardCollateralLimit: Money;
}

export interface CreditAssessmentLimitsPayload {
  limit: Money;
  limitExtendedCollateral?: Money;
  limitFullCollateral?: Money;
  limitStandardCollateral?: Money;
  maxLimit: Money;
  maxLimitExtendedCollateral?: Money;
  maxLimitFullCollateral?: Money;
  maxLimitStandardCollateral?: Money;
  peakLimit: Money;
  peakLimitExtendedCollateral?: Money;
  peakLimitFullCollateral?: Money;
  peakLimitStandardCollateral?: Money;
  limitIncreaseAllowed: boolean;
}

export interface CreditAssessmentUnderwritingCase {
  availableUnderwritingCases: {
    name: string;
    available: boolean;
    unavailableReasons: string[];
  }[];
  discrepancyReason: ValueWithAuditLog<string>;
  discrepancyReasonDescription: ValueWithAuditLog<string>;
  expectedMonthlySpend: ValueWithAuditLog<Money>;
  requestedLimit: ValueWithAuditLog<Money>;
  requestedPaymentFrequency: ValueWithAuditLog<string>;
  requestedPaymentTerms: ValueWithAuditLog<string>;
  selectedUnderwritingCase: ValueWithAuditLog<string>;
}

export interface CreditAssessmentUnderwritingCasePayload {
  discrepancyReason: string | null;
  discrepancyReasonDescription: string | null;
  expectedMonthlySpend: Money | null;
  requestedLimit: Money | null;
  requestedPaymentFrequency: string | null;
  requestedPaymentTerms: string | null;
  selectedUnderwritingCase: string | null;
}

export interface CreditAssessment {
  limits: CreditAssessmentLimits;
  summary: CreditAssessmentSummary;
  underwritingCase: CreditAssessmentUnderwritingCase;
}

export interface CardAccountPaymentSettingsPayload {
  minimumPaymentFrequency: string;
  paymentDefermentInDays: number;
  paymentFrequency: string;
  paymentFrequencyCutoffDay: number | null;
}

export interface MasterData {
  billingAddress: {
    city: ValueWithAuditLog<string | null>;
    country: ValueWithAuditLog<string | null>;
    postalCode: ValueWithAuditLog<string | null>;
    streetAdditional: ValueWithAuditLog<string | null>;
    streetFull: ValueWithAuditLog<string | null>;
    streetName: ValueWithAuditLog<string | null>;
    streetNumber: ValueWithAuditLog<string | null>;
  };
  country: ValueWithAuditLog<string | null>;
  foundationDate: ValueWithAuditLog<string | null>;
  industryCode: ValueWithAuditLog<string | null>;
  industryCodeDescription: string | null;
  industryCodeSource: ValueWithAuditLog<string | null>;
  industryCodeType: ValueWithAuditLog<string | null>;
  internalIndustryCode: string | null;
  internalIndustryType: ValueWithAuditLog<string | null>;
  internalIndustrySubtype: ValueWithAuditLog<string | null>;
  legalForm: ValueWithAuditLog<string | null>;
  legalName: ValueWithAuditLog<string | null>;
  nationalRegisterCourt: ValueWithAuditLog<string | null>;
  organizationAge: ValueWithAuditLog<number | null>;
  registerExcerptAge: ValueWithAuditLog<number | null>;
  registerExcerptDate: ValueWithAuditLog<string | null>;
  riskUnitGroup: ValueWithAuditLog<string | null>;
  spanishProvinceCode: ValueWithAuditLog<number | null>;
  tradeName: ValueWithAuditLog<string | null>;
  tradeRegisterNumber: ValueWithAuditLog<string | null>;
  vatId: ValueWithAuditLog<string | null>;
  website: ValueWithAuditLog<string | null>;
}

export interface MasterDataPayload {
  billingAddress: {
    city: string;
    country: string;
    postalCode: string;
    streetAdditional?: string;
    streetName: string;
    streetNumber: string;
  };
  foundationDate: string;
  industryCode: string;
  industryCodeSource: string;
  industryCodeType: string;
  internalIndustryType: string | null;
  internalIndustrySubtype: string | null;
  legalForm: string;
  legalName: string;
  nationalRegisterCourt: string;
  registerExcerptDate: string;
  riskUnitGroup: string | null;
  spanishProvinceCode?: number;
  tradeName?: string;
  tradeRegisterNumber: string;
  vatId?: string;
  website?: string;
}

export interface ComplianceRating {
  adverseMedia: ValueWithAuditLog<boolean | null>;
  amlScreening: ValueWithAuditLog<AmlRiskScoring | null>;
  amlScreeningDate: ValueWithAuditLog<string | null>;
  complianceRatingValue: ValueWithAuditLog<string | null>;
  highRiskCountry: ValueWithAuditLog<boolean | null>;
  highRiskIndustry: ValueWithAuditLog<boolean | null>;
  nextMonitoringDate: ValueWithAuditLog<string | null>;
  onboardingType: ValueWithAuditLog<string | null>;
  other: ValueWithAuditLog<string | null>;
  pep: ValueWithAuditLog<boolean | null>;
  sanctionScreening: ValueWithAuditLog<boolean | null>;
  sanctionScreeningDate: ValueWithAuditLog<string | null>;
  sanctioned: ValueWithAuditLog<boolean | null>;
  tpmlRating: ValueWithAuditLog<string | null>;
  unusuallyComplexStructure: ValueWithAuditLog<boolean | null>;
  vgRating: ValueWithAuditLog<string | null>;
}

export interface ComplianceRatingPayload {
  adverseMedia: boolean | null;
  amlScreening: AmlRiskScoring | null;
  amlScreeningDate: string | null;
  complianceRatingValue: ComplianceRatingValue | null;
  highRiskCountry: boolean | null;
  highRiskIndustry: boolean | null;
  nextMonitoringDate: string | null;
  onboardingType: string | null;
  other: string | null;
  pep: boolean | null;
  sanctionScreening: boolean | null;
  sanctionScreeningDate: string | null;
  sanctioned: boolean | null;
  tpmlRating: string | null;
  unusuallyComplexStructure: boolean | null;
  vgRating: string | null;
}

export interface PreCheck {
  preCheckCompletedDate: ValueWithAuditLog<string | null>;
}

export interface ComplianceCompany {
  complianceRating: ComplianceRating;
  masterData: MasterData;
  preCheck: PreCheck;
}

export interface UpdateAccTxnCustomFieldPayload {
  transactionCustomFieldId: string;
  value: string;
}

export interface UpdateAccTxnCustomFieldResponse {
  accountingTransactionId: string;
  transactionCustomFieldId: string;
  value: string;
  autoMatched: boolean;
}

export interface GetCustomFieldsPayload {
  organizationId: string;
  status?: CustomFieldStatus;
  page?: number;
  limit?: number;
}

export interface CustomField {
  id: string;
  organizationId: string;
  label: string;
  defaultValue: string | null;
  description: string | null;
  type: CustomFieldType | null;
  status: CustomFieldStatus;
  automationType: AutomationType | null;
  mandatoryOnAutomation: boolean;
  mandatoryForExport: boolean;
  visibility: CustomFieldVisibility;
}

export interface CustomFieldsPageableList {
  transactionCustomFields: CustomField[];
  hasNextPage: boolean;
}

export interface ManageCustomFieldPayload {
  organizationId: string;
  label: string;
  defaultValue?: string | null;
  description?: string | null;
  type?: CustomFieldType | null;
  status?: CustomFieldStatus;
  automationType?: AutomationType | null;
  mandatoryOnAutomation?: boolean;
  mandatoryForExport?: boolean;
  visibility: CustomFieldVisibility;
}

export interface UpdateCardsTxCustomFieldPayload {
  cardId: string;
  // Used for updating detection.
  // If not passed to BE, then BE will create a new custom field.
  cardTransactionCustomFieldId?: string;
  defaultValueForCard: string;
  organizationId: string;
  transactionCustomFieldId: string;
}

export interface UpdateCardsCardTxCustomFieldsPayload {
  cardTransactionCustomFieldRequests: UpdateCardsTxCustomFieldPayload[];
}

export interface CardsCardTxCustomField {
  cardId: string;
  cardTransactionCustomFieldId: string;
  defaultValueForCard: string;
  mandatoryOnAutomation: boolean;
  organizationId: string;
  transactionCustomFieldId: string;
  transactionCustomFieldLabel: string;
  transactionCustomFieldType: CustomFieldType;
}

export interface CardsCardTxCustomFieldsResponseList {
  cardTransactionCustomFieldResponses: CardsCardTxCustomField[];
}

export interface SpanishProvince {
  code: number;
  name: string;
}

export interface GetReceiptsAutoMatchingParams {
  q?: string;
  tabGroup?: ReceiptsAutoMatchingTagGroup;
  status?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
  limit?: number;
  memberId?: string;
  organizationId?: string;
}

export interface ReceiptAutoMatching {
  attachmentFileName: string;
  createdAt: string;
  emailFrom: string;
  emailSubject: string | null;
  id: string;
  ocr: {
    amount: Money | null;
    invoiceNumber: string | null;
    merchantName: string | null;
    receiptDate: string | null;
    receiptNumber: string | null;
    transactionReference: string | null;
  };
  status: ReceiptsAutoMatchingStatus;
  tabGroup: ReceiptsAutoMatchingTagGroup;
  matchedTransactions: string[];
}

export interface ReceiptsAutoMatchingPageableList {
  data: ReceiptAutoMatching[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface ReceiptAutoMatchingDetails {
  attachmentFileName: string;
  createdAt: string;
  emailFrom: string;
  emailSubject: string | null;
  id: string;
  ocr: {
    amount: Money | null;
    invoiceNumber: string | null;
    merchantName: string | null;
    receiptDate: string | null;
    receiptNumber: string | null;
    transactionReference: string | null;
  };
  status: ReceiptsAutoMatchingStatus;
  tabGroup: ReceiptsAutoMatchingTagGroup;
  matchedTransactions: string[];
  otherFilesCount: number;
}

export interface ReceiptsAutoMatchingThumbnail {
  taskId: string;
  thumbnail: string | null;
}

export interface ReceiptsAutoMatchingThumbnailsList {
  thumbnails: ReceiptsAutoMatchingThumbnail[];
}

export interface ReceiptsAutoMatchingAttachment {
  attachment: string;
  taskId: string;
  fileName: string;
  mimeType: MimeType;
}

export interface ReceiptInboxState {
  layout: ReceiptInboxLayout;
  isDialogOpen: boolean;
  flow: ReceiptInboxMatchingFlow | null;
  selectedTransationHasReceipt: boolean;
  receipt: ReceiptAutoMatching | null;
  thumbnail: string;
}

export interface ReceiptsAutoMatchingSameEmail {
  attachmentFileName: string;
  taskId: string;
  taskStatus: ReceiptsAutoMatchingStatus;
}

export interface NaceIndustryCode {
  code: string;
  description: string;
}
