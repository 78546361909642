export const internalRootPaths = {
  root: '/',
  globalTransactions: '/transactions',
  globalTransactionDetails: '/transactions/:transactionId',
  users: '/users',
  userDetailsPartial: '/users/:orgId',
  userDetails: '/users/:orgId/:memberId',
  organizations: '/organizations',
  nonCustomerOrganizations: '/non-customer-organizations',
  merchants: '/merchants',
  merchantsDetails: '/merchants/:merchantId',
  programSettlements: '/program-settlements',
  programSettlementDetails: '/program-settlements/:settlementId',
  settlementAccount: '/settlement-account',
  receivableRepayments: '/receivable-repayments',
  receivableRepaymentDetails: '/receivable-repayments/:repaymentId',
  billPaymentsBatches: '/bill-payments-batches',
  billPaymentsBatchDetails: '/bill-payments-batches/:batchId',
  cashbackPaymentBatches: '/cashback-payment-batches',
  cashbackPaymentBatchDetails: '/cashback-payment-batches/:batchId',
  payoutBatches: '/payout-batches',
  payoutBatchDetails: '/payout-batches/:batchId',
  compensationBatches: '/compensation-batches',
  compensationBatchDetails: '/compensation-batches/:batchId',
  billingAccountsApproval: '/billing-accounts-approvals',
  billingAccountsApprovalsAll: '/billing-accounts-approvals/all',
  billingAccountsApprovalsAllDetails:
    '/billing-accounts-approvals/all/:bankAccountId',
  billingAccountsApprovalsReview: '/billing-accounts-approvals/review',
  billingAccountsApprovalsReviewDetails:
    '/billing-accounts-approvals/review/:bankAccountId',
  crmIntegration: '/crm-integration',
};

const orgPath = `${internalRootPaths.organizations}/:orgId`;

export const internalOrgPaths = {
  root: orgPath,
  onboarding: `${orgPath}/onboarding`,
  dashboard: `${orgPath}/dashboard`,
  cardAccounts: `${orgPath}/card-accounts`,
  cards: `${orgPath}/cards`,
  cardDetails: `${orgPath}/cards/:cardId`,
  transactions: `${orgPath}/transactions`,
  transactionDetails: `${orgPath}/transactions/:transactionId`,
  transactionsReview: `${orgPath}/transactions-review`,
  transactionsReviewDetails: `${orgPath}/transactions-review/:transactionId`,
  accounting: `${orgPath}/account`,
  accountingTransaction: `${orgPath}/account/transactions`,
  accountingTransactionDetails: `${orgPath}/account/transactions/:transactionId`,
  accountingEntry: `${orgPath}/account/entries`,
  accountingEntryDetails: `${orgPath}/account/entries/:entryId`,
  statements: `${orgPath}/statements`,
  billing: `${orgPath}/billing`,
  billingPayments: `${orgPath}/billing-payments`,
  billingStatements: `${orgPath}/billing-statements`,
  members: `${orgPath}/members`,
  teams: `${orgPath}/teams`,
  memberDetails: `${orgPath}/members/:memberId`,
  export: `${orgPath}/export`,
  exportQueue: `${orgPath}/export-queue`,
  exported: `${orgPath}/exported`,
  exportTransactionDetails: `${orgPath}/export/:transactionId`,
  exportQueueTransactionDetails: `${orgPath}/export-queue/:transactionId`,
  exportedTransactionDetails: `${orgPath}/exported/:transactionId`,
  rewards: `${orgPath}/rewards`,
  rewardsCashback: `${orgPath}/rewards/cashback`,
  rewardsPartnerDeals: `${orgPath}/rewards/partner-deals`,
  merchants: `${orgPath}/merchants`,
  merchantDetails: `${orgPath}/merchants/:merchantId`,
  settlements: `${orgPath}/settlements`,
  settlementsDetails: `${orgPath}/settlements/:settlementId`,
  processingAccount: `${orgPath}/processing-account`,
  terms: `${orgPath}/terms`,
  termsGeneral: `${orgPath}/terms/general`,
  termsSubscriptionSettings: `${orgPath}/terms/subscription-settings`,
  termsCashbackSettings: `${orgPath}/terms/cashback-settings`,
  creditAndCompliance: `${orgPath}/credit-and-compliance`,
  credit: `${orgPath}/credit-and-compliance/credit`,
  creditProfile: `${orgPath}/credit-and-compliance/credit/profile`,
  creditAssessment: `${orgPath}/credit-and-compliance/credit/assessment`,
  compliance: `${orgPath}/credit-and-compliance/compliance`,
  complianceCompany: `${orgPath}/credit-and-compliance/compliance/company`,
  complianceIndividuals: `${orgPath}/credit-and-compliance/compliance/individuals`,
  complianceBankSubmission: `${orgPath}/credit-and-compliance/compliance/bank-submission`,
  settings: `${orgPath}/settings`,
  settingsIntegrations: `${orgPath}/settings/integrations`,
  settingsOrganization: `${orgPath}/settings/organization`,
  settingsBankAccounts: `${orgPath}/settings/organization/bank-accounts`,
  settingsReceiptManagement: `${orgPath}/settings/organization/receipt-management`,
  settingsSubscriptionPlan: `${orgPath}/settings/organization/subscription-plan`,
  settingsOrgIntegrations: `${orgPath}/settings/organization/integrations`,
  settingsFeatureModules: `${orgPath}/settings/feature-modules`,
  settingsAccounting: `${orgPath}/settings/accounting`,
  settingsAccounts: `${orgPath}/settings/accounting/accounts`,
  settingsCategories: `${orgPath}/settings/accounting/categories`,
  settingsVatRates: `${orgPath}/settings/accounting/vat-rates`,
  settingsProjects: `${orgPath}/settings/accounting/projects`,
  settingsTeams: `${orgPath}/settings/accounting/teams`,
  settingsSuppliers: `${orgPath}/settings/accounting/suppliers`,
  settingsDatev: `${orgPath}/settings/accounting/datev`,
  settingsBmd: `${orgPath}/settings/accounting/bmd`,
  settingsLocoia: `${orgPath}/settings/accounting/locoia`,
  settingsAccountingSystem: `${orgPath}/settings/accounting/accounting-system`,
  pliantEarth: `${orgPath}/pliant-earth`,
  partner: `${orgPath}/partner`,
};

export const internalRootPathsArray = Object.values(internalRootPaths);
export const internalOrgPathsArray = Object.values(internalOrgPaths);
export const internalPathsArray = internalRootPathsArray.concat(
  internalOrgPathsArray
);

export type InternalOrgPathsKeys = keyof typeof internalOrgPaths;
