import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  PencilSimpleIcon,
  PlusIcon,
  Select,
} from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardAccountStatus, CardProcessingAccount } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import AssignAccountDialog from './AssignAccountDialog';

interface Props {
  cardAccountId: string;
  onCardAccountIdChange: (cardAccountId: string) => void;
}

const ProcessingAccountInfo = ({
  cardAccountId,
  onCardAccountIdChange,
}: Props) => {
  const canUser = useCanUser();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();
  const [
    isAssignAccountNumberDialogOpen,
    setIsAssignAccountNumberDialogOpen,
  ] = useState(false);
  const [data, setData] = useState<CardProcessingAccount | null>(null);
  const selectedCardAccount = cardAccounts.find(
    (ca) => ca.id === cardAccountId
  );

  const getData = async () => {
    try {
      const data = await api.getCardProcessingAccountBalance(organization!.id);
      if (!mounted.current) return;
      setData(data);
    } catch (error) {
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const isOrgInOnboarding = useIsOrgInOnboarding();

  const showAssignAccountNumberDialog = () =>
    setIsAssignAccountNumberDialogOpen(true);

  const closeAccountNumberDialog = () =>
    setIsAssignAccountNumberDialogOpen(false);

  const onAccountNumberUpdated = (cardProcessingAccountIban: string) => {
    setData({ ...data!, cardProcessingAccountIban });
  };

  if (!data) return null;

  if (!isOrgInOnboarding && cardAccounts.length > 1) {
    return (
      <FormControl sx={{ position: 'relative', width: 250, mt: 2, ml: 'auto' }}>
        <Select<string>
          value={cardAccountId}
          onChange={(e) => onCardAccountIdChange(e.target.value)}
        >
          {cardAccounts
            .filter((item) => item.status.value === CardAccountStatus.active)
            .map((account) => (
              <MenuItem value={account.id} key={account.id}>
                {getCardAccountName(account)}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText sx={{ position: 'absolute', bottom: -24 }}>
          {selectedCardAccount?.iban.value}
        </FormHelperText>
      </FormControl>
    );
  }

  return (
    <Box ml="auto">
      {canUser('processing-account:change') &&
        !data.cardProcessingAccountIban &&
        isOrgInOnboarding && (
          <Button
            variant="text"
            startIcon={<PlusIcon />}
            onClick={showAssignAccountNumberDialog}
            data-test-id="assign-processing-account-btn"
          >
            {t('int.processingAccountPage.assignAccountBtn')}
          </Button>
        )}
      {data?.cardProcessingAccountIban && (
        <>
          <Typography variant="subtitle2" component="span">
            {t('int.processingAccountPage.assignedAccountDetails', {
              iban: data.cardProcessingAccountIban,
            })}
          </Typography>
          {isOrgInOnboarding && canUser('processing-account:change') && (
            <IconButton
              size="small"
              onClick={showAssignAccountNumberDialog}
              sx={{ ml: 1 }}
            >
              <PencilSimpleIcon />
            </IconButton>
          )}
        </>
      )}

      <AssignAccountDialog
        open={isAssignAccountNumberDialogOpen}
        onClose={closeAccountNumberDialog}
        onSuccess={onAccountNumberUpdated}
        organizationId={organization!.id}
        cardProcessingAccountIban={data?.cardProcessingAccountIban}
      />
    </Box>
  );
};

export default ProcessingAccountInfo;
