import { useGlobalState } from 'context/GlobalState';
import { MerchantAvatar, MerchantLogo } from 'domains/merchant/components';
import { AvatarProps } from 'elements';
import {
  AccountEntryTransactionInfo,
  ExpenseType,
  ExportFlowTransaction,
  Transaction,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { PrivateExpenseAvatar } from './PrivateExpenseAvatar';
import { SplitTransactionsAvatar } from './SplitTransactionsAvatar';

type TransactionAvatarSize = 'medium' | 'large';

interface Props extends AvatarProps {
  size?: TransactionAvatarSize;
  transaction:
    | Transaction
    | AccountEntryTransactionInfo
    | ExportFlowTransaction;
}

export const TransactionAvatar = ({
  size = 'medium',
  transaction,
  ...props
}: Props) => {
  const { merchantDisplayNameEnabled } = useFlags();
  const {
    state: { featureModules },
  } = useGlobalState();

  if (
    featureModules.PRIVATE_EXPENSE &&
    transaction.expenseType === ExpenseType.private
  )
    return (
      <PrivateExpenseAvatar
        size={size}
        firstName={transaction.memberFirstName || ''}
        lastName={transaction.memberLastName || ''}
        {...props}
      />
    );

  if (transaction.hasMultipleAccountingTransactions)
    return <SplitTransactionsAvatar size={size} {...props} />;

  return merchantDisplayNameEnabled && transaction.merchant?.logoPath ? (
    <MerchantLogo size={size} url={transaction.merchant.logoPath} />
  ) : (
    <MerchantAvatar category={transaction.category} size={size} {...props} />
  );
};
