import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Co2EmissionBadge } from 'components/Badge';
import { CardIconWithDetails } from 'domains/card/components';
import {
  IntegrationCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionMissingReceiptCell,
  TransactionReviewStatusIcon,
} from 'domains/transaction/components';
import { drawerPlaceholderColumn, GridColDef } from 'elements';
import { Transaction } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<Transaction>[] = [
      {
        headerName: t('chTeamTransactionsPage.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row }) => (
          <MerchantCell transaction={row} showReceiptIcon showCommentIcon />
        ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.2,
        renderCell: ({ row }) => (
          <TransactionMissingReceiptCell transaction={row} />
        ),
      },
      {
        headerName: t('chTeamTransactionsPage.date'),
        field: 'createdAt',
        sortable: false,
        flex: 0.7,
        valueGetter: ({ row }) => moment(row.createdAt).format('D MMM YYYY'),
      },
      {
        headerName: t('chTeamTransactionsPage.card'),
        field: 'cardType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardRefNum={row.cardRefNum}
            cardName={row.cardName}
            showCardNameTooltip
          />
        ),
      },
      {
        headerName: t('chTeamTransactionsPage.member'),
        field: 'memberFirstName',
        flex: 0.8,
        sortable: false,
        valueGetter: ({ row }) =>
          getFullName(row.memberFirstName, row.memberLastName) || '-',
      },
      {
        headerName: t('chTeamTransactionsPage.team'),
        field: 'teamName',
        flex: 0.8,
        sortable: false,
      },
      {
        headerName: t('chTeamTransactionsPage.account'),
        field: 'cardAccountName',
        flex: 0.8,
        sortable: false,
      },
      {
        headerName: t('chTeamTransactionsPage.emission'),
        field: 'emission',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.6,
        renderCell: ({ value }) =>
          value ? <Co2EmissionBadge unit="kg" value={value} /> : null,
      },
      {
        headerName: t('chTeamTransactionsPage.amount'),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 0.8,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      {
        headerName: t('chTeamTransactionsPage.review'),
        field: 'reviewStatus',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.4,
        renderCell: ({ row, value }) => (
          <TransactionReviewStatusIcon
            status={value}
            expenseType={row.expenseType}
          />
        ),
      },
      {
        headerName: t('chTeamTransactionsPage.export'),
        field: '_integration_',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 0.4,
        renderCell: ({ row }) => (
          <IntegrationCell
            status={row.status}
            exportStatus={row.exportStatus}
            integrationType={row.integrationType}
          />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language]);
};

export default useColumns;
