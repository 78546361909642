import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { DocumentLink } from 'domains/document/components';
import { getDocumentName, getDocumentUrl } from 'domains/document/utils';
import { Box, Grid, Typography } from 'elements';
import { DocumentGenericType } from 'services/constants';

const tacMetadataTypeByDocumentGenericType: Partial<Record<
  DocumentGenericType,
  string
>> = {
  [DocumentGenericType.issuerTermsAndConditions]: 'ISSUER',
  [DocumentGenericType.bankTermsAndConditions]: 'PARTNER_BANK',
  [DocumentGenericType.platformTermsAndConditions]: 'PLATFORM',
  [DocumentGenericType.insuranceTerms]: 'INSURANCE',
};

const LegalSection = () => {
  const { t } = useTranslation();
  const {
    state: { termsAndConditions },
  } = useCreditAndComplianceContext();
  const {
    state: { documents, organization },
  } = useGlobalState();

  const tacDocumentsWithConfirmations = useMemo(
    () =>
      [
        DocumentGenericType.platformTermsAndConditions,
        DocumentGenericType.bankTermsAndConditions,
        DocumentGenericType.issuerTermsAndConditions,
      ].map((genericType) => {
        const doc = documents?.[genericType]?.[0];
        if (!doc) return null;

        return {
          id: doc.documentId,
          name: getDocumentName(doc, genericType, organization!.partnerId),
          url: getDocumentUrl(doc.documentName),
          metadata:
            termsAndConditions?.find(
              (metadata) =>
                metadata.type ===
                tacMetadataTypeByDocumentGenericType[genericType]
            ) ?? null,
        };
      }),
    [documents, termsAndConditions]
  );

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="space-between"
        py={1}
      >
        <Typography variant="h6">
          {t('int.complianceLegalSection.title')}
        </Typography>
      </Box>

      <Grid container direction="column" py={3} spacing={1}>
        {tacDocumentsWithConfirmations.map(
          (tacDoc) =>
            tacDoc && (
              <Grid key={tacDoc.id} item xs={12}>
                <DocumentLink {...tacDoc} />
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default LegalSection;
