import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  DataGridCell,
  FlagPennantIcon,
  ThumbsUpIcon,
  Tooltip,
  WarningDiamondIcon,
} from 'elements';
import { ExpenseType, TransactionReviewStatus } from 'services/constants';

interface Props {
  status: TransactionReviewStatus | null;
  expenseType: ExpenseType;
}

const TransactionReviewStatusIcon = ({ status, expenseType }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();

  if (featureModules.PRIVATE_EXPENSE && expenseType === ExpenseType.private)
    return <DataGridCell>—</DataGridCell>;

  if (!status) return null;

  const renderIcon = () => {
    if (status === TransactionReviewStatus.approved)
      return <ThumbsUpIcon color="action" />;
    if (status === TransactionReviewStatus.flagged)
      return <FlagPennantIcon color="error" />;
    return <WarningDiamondIcon color="error" />;
  };

  return (
    <Tooltip title={t(`transactionReviewStatus.${status}`)}>
      {renderIcon()}
    </Tooltip>
  );
};

export default TransactionReviewStatusIcon;
