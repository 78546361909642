import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import { ExpenseType, VatRateStatus } from 'services/constants';

const formatPercentOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

interface Props {
  vatRateStatus: VatRateStatus;
  vatRateId: string | null;
  expenseType: ExpenseType;
}

const VatRateCell = ({ vatRateStatus, vatRateId, expenseType }: Props) => {
  const { t } = useTranslation();
  const {
    state: { vatRates, featureModules },
  } = useGlobalState();

  if (featureModules.PRIVATE_EXPENSE && expenseType === ExpenseType.private)
    return <DataGridCell>—</DataGridCell>;

  if (vatRateStatus === VatRateStatus.multiple)
    return (
      <DataGridCell sx={{ textTransform: 'uppercase' }}>
        {t('exportPage.multiple')}
      </DataGridCell>
    );
  if (vatRateStatus === VatRateStatus.missing)
    return <DataGridCell>—</DataGridCell>;

  const selectedVatRate = vatRates?.find((item) => item.id === vatRateId);
  if (!selectedVatRate) return null;

  return (
    <DataGridCell>
      {selectedVatRate.name}
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        noWrap
      >
        <FormatPercent
          value={selectedVatRate.rate}
          options={formatPercentOptions}
        />
        {selectedVatRate.code && <> / {selectedVatRate.code}</>}
      </Typography>
    </DataGridCell>
  );
};

export default VatRateCell;
