import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  CategoryFilter,
  ExpenseTypeFilter,
  ProjectFilter,
  ReceiptFilter,
  TeamFilter,
} from 'domains/transaction/components';
import { QueryParams } from 'domains/transaction/pages/AccountEntriesPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  ExpenseType,
  MerchantCategory,
  Project,
  Team,
  TransactionReceiptStatus,
} from 'services/constants';
import AccountEntriesSubTypeFilter, {
  AccountEntriesSubTypeFilterOption,
} from './AccountEntriesSubTypeFilter';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teams: Team[];
  projects: Project[];
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

type KeyToValueType = {
  subType: AccountEntriesSubTypeFilterOption;
};

type KeyValue<T extends keyof KeyToValueType> = {
  key: T;
  value: KeyToValueType[T];
};

type SetFilterArgs<T extends keyof KeyToValueType> = KeyValue<T> & {
  type: 'add' | 'remove';
};

interface State {
  subType: AccountEntriesSubTypeFilterOption[];
  category: MerchantCategory[];
  teamId: string[];
  projectIds: string[];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  expenseType: ExpenseType | '';
}

const initialState: State = {
  subType: [],
  category: [],
  teamId: [],
  projectIds: [],
  receipt: '',
  fromDate: null,
  toDate: null,
  expenseType: '',
};

const FiltersDrawer = ({
  teams,
  projects,
  params,
  setParam,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.subType.length ||
    state.category.length ||
    state.teamId.length ||
    state.projectIds.length ||
    state.receipt ||
    state.fromDate ||
    state.toDate ||
    state.expenseType
  );

  useEffect(() => {
    if (props.open)
      setState({
        subType: params.subType,
        category: params.category,
        teamId: params.teamId,
        projectIds: params.projectIds,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        expenseType: params.expenseType,
      });
  }, [params, props.open]);

  const setFilters = <T extends keyof KeyToValueType>({
    key,
    value,
    type,
  }: SetFilterArgs<T>) => {
    if (type === 'add')
      setState((prevState) => ({
        ...prevState,
        [key]: [...prevState[key], value],
      }));
    else
      setState((prevState) => ({
        ...prevState,
        [key]: (prevState[key] as KeyToValueType[T][]).filter(
          (item) => item !== value
        ),
      }));
  };

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.format('YYYY-MM-DD'),
      toDate: state.toDate?.format('YYYY-MM-DD'),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(
      featureModules.MULTI_CARD_ACCOUNT || featureModules.MULTI_CURRENCY_BILLING
        ? [
            ...Object.entries(initialState),
            ['cardAccountId', params.cardAccountId],
          ]
        : Object.entries(initialState)
    );
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <AccountEntriesSubTypeFilter
        value={state.subType}
        onChange={(status, checked) =>
          setFilters({
            key: 'subType',
            value: status,
            type: checked ? 'add' : 'remove',
          })
        }
      />

      <DateFilter
        label={t('filters.bookingDate')}
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate,
            toDate,
          }))
        }
      />

      <ReceiptFilter
        value={state.receipt}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, receipt: value }))
        }
      />

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {!!teams.length && (
        <TeamFilter
          teams={teams}
          value={state.teamId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, teamId: value }))
          }
        />
      )}

      {!!projects.length && (
        <ProjectFilter
          projects={projects}
          value={state.projectIds}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, projectIds: value }))
          }
        />
      )}

      <ExpenseTypeFilter
        value={state.expenseType}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, expenseType: value }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
