import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { MerchantStatus } from 'services/constants';

const TYPES_BY_STATUSES: {
  [k in MerchantStatus]: StatusBadgeProps['color'];
} = {
  [MerchantStatus.published]: 'success',
  [MerchantStatus.draft]: 'secondary',
  [MerchantStatus.new]: 'warning',
};

interface Props {
  status: MerchantStatus;
}

export const MerchantStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      variant="tinted"
      color={TYPES_BY_STATUSES[status]}
      label={t(`merchantStatuses.${status}`)}
    />
  );
};
