import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import {
  SettlementPaymentsList,
  SettlementStatusBadge,
  VssTypeBadge,
} from 'domains/billing/components';
import {
  Box,
  Button,
  CalendarCheckIcon,
  LoaderWithOverlay,
  Paper,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import { Settlement, SettlementPayment } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

interface Props extends DetailsDrawerProps {}

interface State {
  isLoading: boolean;
  settlement: Settlement | null;
  error: unknown;
  payments: SettlementPayment[] | null;
}

const OrganizationSettlementDetailsPage = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const { settlementId } = useParams<{ settlementId: string }>();
  const mounted = useMounted();
  const idRef = useRef(settlementId);
  const [state, setState] = useState<State>({
    isLoading: true,
    settlement: null,
    error: null,
    payments: null,
  });
  const { settlement } = state;

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [settlement, payments] = await Promise.all([
        api.getOrgSettlement(settlementId),
        api.getSettlementPayments(settlementId).then(
          (data) => data.payments,
          () => null
        ),
      ]);
      if (!mounted.current || settlementId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        settlement,
        payments,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || settlementId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error: error,
        settlement: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!settlementId) return;
    idRef.current = settlementId;
    getData();
  }, [settlementId]);

  return (
    <DetailsDrawer {...props}>
      {settlement && (
        <>
          <DetailsDrawerHeader>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="h5" mr={1}>
                {settlement.reportDate}
              </Typography>
              <VssTypeBadge value={settlement.vssType} sx={{ mr: 1 }} />
              <SettlementStatusBadge status={settlement.status} />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              mt={2}
              color="text.secondary"
            >
              <CalendarCheckIcon color="inherit" />
              <Typography
                variant="body2"
                color="inherit"
                component="div"
                ml={1}
              >
                {t('orgSettlementDetailsPage.createdAt')}{' '}
                {moment.utc(settlement.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </Box>
          </DetailsDrawerHeader>

          <DetailsDrawerContent>
            <Box p={3}>
              <Typography variant="overline" component="div">
                {t('orgSettlementDetailsPage.info')}
              </Typography>
              <Paper variant="outlined" sx={{ mb: 1, px: 2, py: 1 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">
                    {t('orgSettlementDetailsPage.count')}
                  </Typography>
                  <Typography variant="body2">
                    {t('orgSettlementDetailsPage.orgSettlementAmount')}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="caption">
                    {settlement.transactionsCount}
                  </Typography>

                  <Typography variant="caption" color="text.secondary">
                    <FormatMoney
                      value={settlement.settlementAmount}
                      fractionalPart
                    />
                  </Typography>
                </Box>
              </Paper>

              <Paper
                sx={{ display: 'flex', alignItems: 'center', p: 2 }}
                variant="outlined"
              >
                <Typography variant="body2">
                  {t('orgSettlementDetailsPage.fees')}
                </Typography>
                <Typography variant="body2" color="text.secondary" ml="auto">
                  <FormatMoney value={settlement.feesAmount} fractionalPart />
                </Typography>
              </Paper>

              {canUser('program-settlements-page:visit') && (
                <Box pt={2} textAlign="right">
                  <Button
                    variant="text"
                    component={Link}
                    to={generatePath(
                      internalRootPaths.programSettlementDetails,
                      { settlementId: settlement.programSettlementId }
                    )}
                  >
                    {t('orgSettlementDetailsPage.goToProgramSettlement')}
                  </Button>
                </Box>
              )}
            </Box>

            <SettlementPaymentsList
              payments={state.payments}
              onRetry={getData}
              label={t('orgSettlementDetailsPage.orgSettlementPayments')}
              noItemsLabel={t('orgSettlementDetailsPage.noData')}
            />
          </DetailsDrawerContent>
        </>
      )}

      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(OrganizationSettlementDetailsPage);
