import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { adminPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import {
  CardCategoryControlIndicator,
  CardCustomFields,
  CardCustomValidityPeriod,
  CardDateControlIndicator,
  CardDetailsMenu,
  CardHistory,
  CardInReplacementBox,
  CardLimit,
  CardLocationControlIndicator,
  CardMerchantControlIndicator,
  CardRequestDetails,
  CardStatusBadge,
  CardTimeControlIndicator,
  CardTransactionLimit,
  LimitChangeRequestDetails,
} from 'domains/card/components';
import {
  ActivateCardDialog,
  PinDialog,
  UnlockPinDialog,
} from 'domains/card/dialogs';
import {
  useAvailableVirtualTravelCardSubtypes,
  useCardAccountNameGetter,
} from 'domains/card/hooks';
import { getCardNameWithRefNum } from 'domains/card/utils';
import { IntegrationTypeIcon } from 'domains/partner/components';
import {
  Alert,
  BankIcon,
  Box,
  Button,
  Chip,
  EyeIcon,
  Link,
  LockIcon,
  LockOpenIcon,
  Paper,
  ProjectorScreenIcon,
  Tooltip,
  Typography,
  UsersIcon,
} from 'elements';
import useCardLock from 'hooks/useCardLock';
import {
  AccountGroup,
  Card,
  CardLockReason,
  CardSimpleStatus,
  CardStatus,
  CardType,
  CirculaConnectionType,
  IntegrationType,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  card: Card | undefined;
  onCardUpdated: (card: Card) => void;
}

const CardDetailsPaper = ({ card, onCardUpdated }: Props) => {
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organizationIntegrations, featureModules, cardAccounts },
  } = useGlobalState();
  const availableTravelCardSubtypes = useAvailableVirtualTravelCardSubtypes();
  const getCardAccountName = useCardAccountNameGetter();
  const {
    lockCard,
    unlockCard,
    isCardLocked,
    isLockingOrUnlocking,
  } = useCardLock(onCardUpdated);
  const [isUnlockPinDialogOpen, setIsUnlockPinDialogOpen] = useState(false);
  const [isActivateCardDialogOpen, setIsActivateCardDialogOpen] = useState(
    false
  );
  const [isPinDialogOpen, setIsPinDialogOpen] = useState(false);

  const lockOrUnlockCard = () => {
    if (isLockingOrUnlocking) return;
    isCardLocked(card!) ? unlockCard(card!.cardId) : lockCard(card!.cardId);
  };

  const renderCardAccountBadge = (cardAccountId: string) => {
    const account = cardAccounts.find((item) => item.id === cardAccountId);
    if (!account) return null;
    return (
      <Box display="flex" alignItems="center" my={0.5} mr={2}>
        <BankIcon sx={{ color: 'text.secondary', mr: 1 }} />
        <Chip label={getCardAccountName(account)} size="small" />
      </Box>
    );
  };

  const renderActions = (card: Card) => {
    if (card.simpleStatus === CardSimpleStatus.pending) {
      if (card.status === CardStatus.pendingValidity)
        return (
          <Alert severity="warning">
            {t('cardDetails.pendingValidityHint')}
          </Alert>
        );

      return card.type === CardType.virtual ||
        card.type === CardType.singleUse ? (
        <Alert severity="warning">
          {t('cardDetails.cardIsBeingIssuedHint')}
        </Alert>
      ) : (
        <>
          <Alert severity="warning" sx={{ mb: 2 }}>
            {card.accountGroup === AccountGroup.vgTpml
              ? t('cardDetails.activateCardHint')
              : t('cardDetails.activateCardLastDigitsHint')}
          </Alert>
          <Button
            onClick={() => setIsActivateCardDialogOpen(true)}
            fullWidth
            size="large"
            data-test-id="activate-card-btn"
          >
            {t('cardDetails.activateCard')}
          </Button>
        </>
      );
    }

    if (
      card.status === CardStatus.lockedByDateTimeCardControls &&
      !!card.lockReason
    ) {
      return (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {card.lockReason ===
            CardLockReason.lockedByDatesAndTimesCardControl &&
            t('cardDetails.cardLockedByDatesAndTimesControlHint')}
          {card.lockReason === CardLockReason.lockedByTimesCardControl &&
            t('cardDetails.cardLockedByTimesControlHint')}
          {card.lockReason === CardLockReason.lockedByDatesCardControl &&
            t('cardDetails.cardLockedByDatesControlHint')}
        </Alert>
      );
    }

    if (card.status === CardStatus.lockedPin) {
      return (
        <>
          <Alert severity="warning" sx={{ mb: 2 }}>
            {t('cardDetails.cardLockedPinHint')}
          </Alert>
          <Button
            disabled={isUnlockPinDialogOpen}
            onClick={() => setIsUnlockPinDialogOpen(true)}
            startIcon={<LockOpenIcon />}
            size="large"
            variant="outlined"
            fullWidth
          >
            {t('cardDetails.unlockViewPin')}
          </Button>
        </>
      );
    }

    if (cardIsInRequestedStatus) return null;

    return (
      <>
        <Tooltip title={t('cardDetails.lockCardHint')}>
          <Box mb={2}>
            <Button
              onClick={lockOrUnlockCard}
              fullWidth
              size="large"
              variant="outlined"
              startIcon={isCardLocked(card) ? <LockOpenIcon /> : <LockIcon />}
              data-test-id={
                isCardLocked(card) ? 'unlock-card-btn' : 'lock-card-btn'
              }
            >
              {isCardLocked(card)
                ? t('cardDetails.unlockCard')
                : t('cardDetails.lockCard')}
            </Button>
          </Box>
        </Tooltip>
        {![CardType.virtual, CardType.singleUse].includes(card.type) && (
          <Button
            onClick={() => setIsPinDialogOpen(true)}
            fullWidth
            size="large"
            variant="outlined"
            startIcon={<EyeIcon />}
            data-test-id="view-card-pin-btn"
          >
            {t('cardDetails.viewChangePin')}
          </Button>
        )}
      </>
    );
  };

  if (!card)
    return (
      <Box width={640} mt={4} mx="auto" p={3}>
        <Typography variant="body2" textAlign="center">
          {t('cardDetails.noCardsAvailable')}
        </Typography>
      </Box>
    );

  const cardIsInRequestedStatus =
    card.simpleStatus === CardSimpleStatus.requested;
  const isTeamVisible = featureModules.TEAMS && card.teamName;

  return (
    <Box width={640} mt={4} mx="auto">
      <Box display="flex" alignItems="center" minHeight="40px">
        <Typography variant="h5" mr={2} noWrap>
          {getCardNameWithRefNum(card)}
        </Typography>
        <Box flex="1 0 auto" display="flex" alignItems="center">
          <CardStatusBadge
            status={card.simpleStatus}
            sx={{ flexShrink: 0, mr: 2 }}
          />
          {card.integrationType === IntegrationType.circula &&
            organizationIntegrations?.syncSettings?.orgConnectionType ===
              CirculaConnectionType.partial && (
              <Tooltip title={t('tooltips.circulaSynced')}>
                <Box flexShrink={0} mr={2}>
                  <IntegrationTypeIcon
                    integrationType={IntegrationType.circula}
                    sx={{ verticalAlign: 'top' }}
                  />
                </Box>
              </Tooltip>
            )}
        </Box>
        {canUser('card:view-in-another-app', card) && (
          <Link
            variant="body2"
            component={RouterLink}
            to={generatePath(adminPaths.cardDetails, { cardId: card.cardId })}
            sx={{ flexShrink: 0 }}
          >
            {t('cardDetails.viewInAdminApp')}
          </Link>
        )}
        {!cardIsInRequestedStatus && (
          <CardDetailsMenu
            card={card}
            onUpdate={onCardUpdated}
            isCardholderApp
          />
        )}
      </Box>

      {availableTravelCardSubtypes.length > 1 &&
        i18n.exists(`cardNamesForSubtypes.${card.cardConfig}`) && (
          <Typography
            variant="subtitle2"
            color="text.secondary"
            mt={-0.5}
            mb={1.5}
          >
            {t(`cardNamesForSubtypes.${card.cardConfig}`)}
          </Typography>
        )}

      {(cardAccounts.length > 1 || isTeamVisible || card.projectName) && (
        <Box display="flex" alignItems="center" flexWrap="wrap" mt={1} mb={2.5}>
          {cardAccounts.length > 1 &&
            renderCardAccountBadge(card.cardAccountId)}
          {isTeamVisible && (
            <Box display="flex" alignItems="center" my={0.5} mr={2}>
              <UsersIcon sx={{ color: 'text.secondary', mr: 1 }} />
              <Chip label={card.teamName} size="small" />
            </Box>
          )}
          {card.projectName && (
            <Box display="flex" alignItems="center" my={0.5} mr={2}>
              <ProjectorScreenIcon sx={{ color: 'text.secondary', mr: 1 }} />
              <Chip label={card.projectName} size="small" />
            </Box>
          )}
        </Box>
      )}

      {card.limitChangeRequestId && (
        <Box mt={2}>
          <LimitChangeRequestDetails
            isCardholderApp
            card={card}
            onUpdate={onCardUpdated}
          />
        </Box>
      )}
      {cardIsInRequestedStatus && (
        <Box mt={2}>
          <CardRequestDetails
            isCardholderApp
            card={card}
            onUpdate={onCardUpdated}
          />
        </Box>
      )}

      <Box display="flex" my={2}>
        <Box flex="1 1 50%" pr={1}>
          {card.type !== CardType.singleUse && (
            <Box mb={2}>
              <CardLimit card={card} />
            </Box>
          )}
          <CardTransactionLimit card={card} />
          {card.type === CardType.singleUse && !cardIsInRequestedStatus && (
            <Paper
              variant="outlined"
              sx={(theme) => ({
                padding: theme.spacing(2, 1.5, 2, 2),
                mt: 2,
              })}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                mt={-0.5}
              >
                {t('cardDetails.purpose')}
              </Typography>
              <Typography variant="body2">{card.purpose || '-'}</Typography>
            </Paper>
          )}
        </Box>
        <Box flex="1 1 50%" pl={1}>
          {renderActions(card)}
        </Box>
      </Box>

      <CardCustomValidityPeriod cardValidity={card.cardValidity} />

      {(card.cardControls.categories ||
        card.cardControls.merchants ||
        card.cardControls.dates ||
        card.cardControls.times ||
        card.cardControls.locations) && (
        <Box mt={2}>
          <CardCategoryControlIndicator
            control={card.cardControls.categories}
          />
          <CardMerchantControlIndicator control={card.cardControls.merchants} />
          <CardDateControlIndicator control={card.cardControls.dates} />
          <CardTimeControlIndicator control={card.cardControls.times} />
          <CardLocationControlIndicator control={card.cardControls.locations} />
        </Box>
      )}

      <CardInReplacementBox card={card} isCardholderApp />

      <CardCustomFields card={card} />

      <Box mt={2}>
        <CardHistory
          key={card.cardId}
          card={card}
          contentHeight={305}
          isCardholderApp
        />
      </Box>

      <ActivateCardDialog
        open={isActivateCardDialogOpen}
        onClose={() => setIsActivateCardDialogOpen(false)}
        card={card}
        onSuccess={onCardUpdated}
      />
      <PinDialog
        open={isPinDialogOpen}
        onClose={() => setIsPinDialogOpen(false)}
        card={card}
      />
      <UnlockPinDialog
        open={isUnlockPinDialogOpen}
        onClose={() => setIsUnlockPinDialogOpen(false)}
        card={card}
        onUpdate={onCardUpdated}
      />
    </Box>
  );
};

export default CardDetailsPaper;
