import {
  IntegrationType,
  Transaction,
  TransactionExportStatus,
} from 'services/constants';

export const isTransactionReadonly = (
  transaction: Transaction,
  isAdminApp: boolean
) => {
  const isReadonlyForApp = isAdminApp
    ? transaction.exportStatus === TransactionExportStatus.exported
    : [
        TransactionExportStatus.inExportQueue,
        TransactionExportStatus.exported,
      ].includes(transaction.exportStatus);

  return (
    (transaction.integrationType === IntegrationType.circula ||
      transaction.integrationType === IntegrationType.none) &&
    isReadonlyForApp
  );
};
