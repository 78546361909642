import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getSubscriptionPlanName } from 'domains/organization/utils';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  LoadingButton,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode, SubscriptionPlanType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

// if possible should be moved to BE
const sortSubscriptionPlanTypes = (plans: SubscriptionPlanType[]) =>
  sortBy(plans, [
    (item) =>
      ({
        [SubscriptionPlanType.light]: 0,
        [SubscriptionPlanType.standard]: 1,
        [SubscriptionPlanType.custom]: 2,
        [SubscriptionPlanType.starter]: 3, // deprecated
        [SubscriptionPlanType.premium]: 4, // deprecated
        [SubscriptionPlanType.enterprise]: 5, // deprecated
      }[item as string] ?? 6),
    // sort partner plans alphabetically
    (item) => item,
  ]);

interface State {
  isLoading: boolean;
  availablePlanTypes: SubscriptionPlanType[];
}

const SubscriptionPlanTypeSection = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, subscriptionPlan },
    dispatch,
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    availablePlanTypes: [],
  });
  const error = !state.isLoading && !state.availablePlanTypes.length;

  const formik = useFormik<{ planType: SubscriptionPlanType }>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      planType: subscriptionPlan.type,
    },
    onSubmit: async ({ planType }, { setSubmitting }) => {
      try {
        const data = await api.updatePlan(organization!.id, { type: planType });
        if (!mounted.current) return;
        dispatch({
          type: 'SET_SUBSCRIPTION_PLAN_DATA',
          payload: { subscriptionPlan: data },
        });
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        formik.setFieldValue('planType', subscriptionPlan.type);
        switch (getNetworkErrorCode(error)) {
          case NetworkErrorCode.maxUsersThresholdExceeded:
          case NetworkErrorCode.maxVirtualCardsThresholdExceeded:
          case NetworkErrorCode.maxSingleUseCardsThresholdExceeded:
          case NetworkErrorCode.valueMustBePositive:
            enqueueSnackbar(
              t(
                `int.subscriptionSettingsPage.subscriptionPlanParamsSection.editValueDialog.errors.${getNetworkErrorCode(
                  error
                )}`
              ),
              {
                variant: 'error',
              }
            );
            break;
          default:
            enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
            logError(error);
        }
      }
    },
  });

  const getAvailablePlanTypes = async () => {
    try {
      const { validTargetPlans } = await api.getAvailablePlans(
        organization!.id
      );
      if (!mounted.current) return;
      const availablePlanTypes = sortSubscriptionPlanTypes([
        ...validTargetPlans,
        subscriptionPlan.type,
      ]);
      setState((prevState) => ({
        ...prevState,
        availablePlanTypes,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        availablePlanTypes: [],
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getAvailablePlanTypes();
    // available plans depend on the active subscription plan
  }, [subscriptionPlan.type]);

  const getFormHelperText = () => {
    if (error) return <FormHelperText>{t('errors.loadData')}</FormHelperText>;

    if (subscriptionPlan.lastEditUserName && subscriptionPlan.lastEditAt) {
      return (
        <FormHelperText sx={{ whiteSpace: 'nowrap' }}>
          {t('int.common.lastEditedBy', {
            name: subscriptionPlan.lastEditUserName,
            time: moment(subscriptionPlan.lastEditAt).format(
              'DD MMM YYYY HH:mm'
            ),
          })}
        </FormHelperText>
      );
    }

    return null;
  };

  return (
    <Box p={3} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
      <Typography variant="h5" mb={3}>
        {t('int.subscriptionSettingsPage.subscriptionPlanTypeSection.title')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            disabled={state.isLoading || error}
            error={error}
          >
            <Select<SubscriptionPlanType>
              {...formik.getFieldProps('planType')}
              renderValue={(selected) => getSubscriptionPlanName(selected)}
              data-test-id="plan-type-select"
            >
              {/* Prevent "MUI: You have provided an out-of-range value" warning */}
              {state.availablePlanTypes.length === 0 ? (
                <MenuItem value={subscriptionPlan.type}>
                  {getSubscriptionPlanName(subscriptionPlan.type)}
                </MenuItem>
              ) : (
                state.availablePlanTypes.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    disabled={item === subscriptionPlan.type}
                  >
                    {getSubscriptionPlanName(item)}
                  </MenuItem>
                ))
              )}
            </Select>
            {getFormHelperText()}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              onClick={() => formik.handleSubmit()}
              disabled={formik.values.planType === subscriptionPlan.type}
              loading={formik.isSubmitting}
            >
              {t('common.button.save')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubscriptionPlanTypeSection;
