import { useState } from 'react';
import { styled } from '@mui/material';
import { isNil, omitBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { OnboardingDocumentStatusBadge } from 'components/Badge';
import { useGlobalState } from 'context/GlobalState';
import {
  ArchiveIcon,
  Box,
  Button,
  ChatCircleTextIcon,
  DotsThreeOutlineVerticalIcon,
  FileTextIcon,
  FileXIcon,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  PencilSimpleIcon,
  QuestionIcon,
  Tooltip,
  Typography,
  UploadSimpleIcon,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import {
  OnboardingDocument,
  OnboardingDocumentStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import AnswerSection from './AnswerSection';
import FileRow from './FileRow';
import OnboardingDocumentDescription from './OnboardingDocumentDescription';
import OnboardingDocumentTitle from './OnboardingDocumentTitle';
import RejectDocumentDialog from './RejectDocumentDialog';
import UploadFilesDialog from './UploadFilesDialog';

const Bold = styled('b')`
  font-weight: 700;
`;

interface Props {
  isDisabled?: boolean;
  document: OnboardingDocument;
  onUpdate?: () => Promise<void>;
  isViewOnly?: boolean;
  internalOnly?: {
    archiveDocument: (
      documentId: string,
      setIsDocumentCardLoading: (isLoading: boolean) => void
    ) => Promise<void>;
  };
}

const OnboardingDocumentRow = ({
  document,
  internalOnly,
  isDisabled,
  isViewOnly,
  onUpdate,
}: Props) => {
  const canUser = useCanUser();
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const partnerName = usePartnerName();
  const [isUploadFilesDialogOpen, setIsUploadFilesDialogOpen] = useState(false);
  const [isRejectDocumentDialogOpen, setIsRejectDocumentDialogOpen] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isQuestion = document.type.question;

  const hasButtonInTitleRow =
    !isViewOnly &&
    canUser('org-onboarding-document-files:manage') &&
    document.status === OnboardingDocumentStatus.REQUESTED &&
    document.type.maxNumberOfFiles > 0;

  const documentStatus =
    document.status === OnboardingDocumentStatus.REQUESTED &&
    document.reasonForRequest
      ? 'REJECTED' // FE only state
      : document.status;

  const hasPredefinedDescription = !!t(
    `onboardingDocumentType.description.${document.type.name}`,
    {
      defaultValue: false,
    }
  );
  const hasTooltip = !!t(
    `onboardingDocumentType.fileTypeTooltip.${document.type.name}`,
    { defaultValue: false }
  );

  const generateInternalFilename = (fileNumber: number) => {
    const hasNumeration = document.files.length > 1;

    if (!document.type.filenameTemplate) {
      return (
        document.type.name +
        (hasNumeration ? `_${String(fileNumber).padStart(2, '0')}` : '')
      );
    }

    let filename = document.type.filenameTemplate;

    if (filename.startsWith('YYYYMMDD_')) {
      filename = filename.replace('YYYYMMDD', moment().format('YYYYMMDD'));
    }

    if (filename.includes('_XXXX_')) {
      const organizationNameWithoutSpaces = organization!.name.replace(
        /[\s|.]/g,
        ''
      );
      filename = filename.replace('XXXX', organizationNameWithoutSpaces);
    }

    if (filename.includes('_MM"M"YY_')) {
      const [year, month] = document.attributes.toMonthYear.split('-');
      filename = filename.replace('MM"M"YY', `${month}M${year.slice(2)}`);
    }

    return (
      filename +
      (hasNumeration ? `_${String(fileNumber).padStart(2, '0')}` : '')
    );
  };

  return (
    <>
      <Box
        sx={{
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 1,
          padding: 2,
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box display="flex" marginTop={0.5}>
              <Box component="span" marginRight={1}>
                {documentStatus !== 'REJECTED' ? (
                  isQuestion ? (
                    <ChatCircleTextIcon />
                  ) : (
                    <FileTextIcon />
                  )
                ) : (
                  <FileXIcon />
                )}
              </Box>

              <Box>
                <OnboardingDocumentTitle
                  attributes={document.attributes}
                  customLabel={document.customLabel}
                  customLabelLocal={document.customLabelLocal}
                  name={document.type.name}
                />
              </Box>

              {hasTooltip && (
                <Tooltip
                  title={
                    <Trans
                      i18nKey={`onboardingDocumentType.fileTypeTooltip.${document.type.name}`}
                      components={{ b: <Bold />, br: <br /> }}
                      values={{
                        orgName: document.attributes.orgName ?? '',
                        partnerName,
                      }}
                    />
                  }
                >
                  <Box component="span" marginLeft={1}>
                    <QuestionIcon fontSize="medium" />
                  </Box>
                </Tooltip>
              )}
            </Box>

            <Grid
              columnSpacing={2}
              container
              minHeight="24px"
              minWidth="fit-content"
              width="fit-content"
              alignItems="center"
            >
              <Grid
                item
                alignItems="flex-end"
                display="flex"
                flexDirection="column"
              >
                <OnboardingDocumentStatusBadge status={documentStatus} />
                {internalOnly &&
                  document.status === OnboardingDocumentStatus.SUBMITTED && (
                    <Typography variant="caption" color="textSecondary">
                      {t('onboardingDocumentRow.submittedByAndAt', {
                        updatedAt: moment(document.updatedAt).format(
                          'DD.MM.YYYY HH:mm'
                        ),
                        updatedBy: document.updatedBy,
                      })}
                    </Typography>
                  )}
              </Grid>

              {hasButtonInTitleRow && (
                <Grid item>
                  <Button
                    disabled={isDisabled || isLoading}
                    onClick={() => setIsUploadFilesDialogOpen(true)}
                    variant="outlined"
                    startIcon={
                      document.files.length === 0 ? (
                        <UploadSimpleIcon />
                      ) : (
                        <PencilSimpleIcon />
                      )
                    }
                  >
                    {document.files.length === 0
                      ? t('common.button.upload')
                      : t('common.button.edit')}
                  </Button>
                </Grid>
              )}

              {internalOnly && (
                <>
                  {document.status === OnboardingDocumentStatus.SUBMITTED && (
                    <Grid item>
                      <Button
                        onClick={() => setIsRejectDocumentDialogOpen(true)}
                        variant="outlined"
                      >
                        <FileXIcon />
                        <Box component="span" marginLeft={1}>
                          {t('common.button.reject')}
                        </Box>
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                      sx={{ minWidth: 'unset', padding: '7px' }}
                      variant="outlined"
                    >
                      <DotsThreeOutlineVerticalIcon fontSize="small" />
                    </Button>
                  </Grid>

                  <Menu
                    anchorEl={menuAnchorEl}
                    onClose={() => setMenuAnchorEl(null)}
                    open={!!menuAnchorEl}
                  >
                    <MenuItem
                      onClick={() =>
                        internalOnly.archiveDocument(
                          document.id,
                          (isLoading: boolean) => setIsLoading(isLoading)
                        )
                      }
                    >
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      Archive
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Box>

          {hasPredefinedDescription ? (
            <OnboardingDocumentDescription
              description={
                <Trans
                  i18nKey={`onboardingDocumentType.description.${document.type.name}`}
                  components={{
                    b: <Bold />,
                    br: <br />,
                    li: <li />,
                    ul: <ul />,
                  }}
                  values={{
                    ...omitBy(document.attributes, isNil),
                    ...(document.attributes?.date && {
                      date: moment(
                        document.attributes.date,
                        'YYYY-MM-DD'
                      ).format('DD.MM.YYYY'),
                    }),
                    orgName: organization!.name,
                  }}
                />
              }
            />
          ) : (
            document.description && (
              <OnboardingDocumentDescription
                description={document.description}
                descriptionLocal={document.descriptionLocal}
              />
            )
          )}

          {isQuestion && (
            <Box mt="16px">
              <AnswerSection document={document} onUpdate={onUpdate!} />
            </Box>
          )}

          {!!document.files.length && (
            <Box mt="16px">
              <Typography
                marginBottom={2}
                sx={{ color: 'grey.800' }}
                variant="caption"
              >
                {t('onboardingDocumentRow.uploadedFiles')}
              </Typography>
              <Box display="flex" flexDirection="column" marginBottom={2}>
                {document.files.map((file, index) => (
                  <FileRow
                    key={file.id}
                    documentId={document.id}
                    file={file}
                    {...(internalOnly && {
                      internalFilename: generateInternalFilename(index + 1),
                    })}
                  />
                ))}
              </Box>
            </Box>
          )}

          {document.status === OnboardingDocumentStatus.REQUESTED &&
            document.reasonForRequest && (
              <>
                <Typography
                  maxWidth="392px"
                  paddingX={1}
                  paddingY={2}
                  sx={{ backgroundColor: '#f8ffcc', borderRadius: 1 }}
                  variant="body2"
                >
                  {document.reasonForRequest}
                </Typography>
              </>
            )}
        </Box>
      </Box>

      <UploadFilesDialog
        document={document}
        onClose={() => setIsUploadFilesDialogOpen(false)}
        onUpdate={onUpdate!}
        open={isUploadFilesDialogOpen}
      />

      <RejectDocumentDialog
        document={document}
        onClose={() => setIsRejectDocumentDialogOpen(false)}
        onUpdate={onUpdate!}
        open={isRejectDocumentDialogOpen}
      />
    </>
  );
};

export default OnboardingDocumentRow;
