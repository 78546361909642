import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';

interface Props extends DialogProps {
  onClose: () => void;
  onSave: (comment: string) => void;
  ids?: string[];
  loading?: boolean;
}

const TransactionReviewCommentDialog = ({
  onSave,
  ids,
  loading = false,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {ids
          ? t('txReviewCommentDialog.titleMultiple', { count: ids.length })
          : t('txReviewCommentDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <Typography variant="body2">
            {t('txReviewCommentDialog.description')}
          </Typography>
        </Box>
        <TextField
          label={t('txReviewCommentDialog.commentLabel', { count: 200 })}
          inputProps={{ maxLength: 200 }}
          onChange={(event) => setComment(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!comment.trim().length}
          onClick={() => onSave(comment.trim())}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={loading} />
    </Dialog>
  );
};

export default withDialogWrapper(TransactionReviewCommentDialog);
