import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import { ExpenseType } from 'services/constants';

interface Props {
  teamId: string | null;
  expenseType: ExpenseType;
}

const TeamCell = ({ teamId, expenseType }: Props) => {
  const {
    state: { teams, accountingSettings, featureModules },
  } = useGlobalState();

  if (featureModules.PRIVATE_EXPENSE && expenseType === ExpenseType.private)
    return <DataGridCell>—</DataGridCell>;

  const team = !!teamId && teams.find((team) => team.id === teamId);
  if (!team) return <DataGridCell>—</DataGridCell>;

  return (
    <DataGridCell>
      {team.name}
      {!!accountingSettings?.costCenterEnabled && (
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          noWrap
        >
          {team.costCenter}
        </Typography>
      )}
    </DataGridCell>
  );
};

export default TeamCell;
