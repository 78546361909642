import { FormEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import PartnerSelect from 'components/PartnerSelect';
import { useGlobalState } from 'context/GlobalState';
import {
  AccountGroupSelect,
  OrganizationToHubspotLinkField,
} from 'domains/organization/components';
import { getPartnerNameById } from 'domains/partner/utils';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  LoaderWithOverlay,
  MenuItem,
  Select,
  SuitcaseSimpleIcon,
  Switch,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountGroup,
  CardAccountCurrency,
  CompanyRegistryProvider,
  DEFAULT_AUTOCOMPLETE_DELAY,
  NetworkErrorCode,
  Organization,
  OrganizationSearchItem,
  PartnerIdType,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

const MIN_ALLOWED_CHARS = 3;

interface State {
  inputValueOrg: string;
  searchResults: OrganizationSearchItem[];
  selectedOrg: OrganizationSearchItem | null;
  isOpen: boolean;
  isLoading: boolean;
  isCreateOrgLoading: boolean;
  accountGroup: AccountGroup | null;
  hubspotId: string;
  partnersOrganizationId: string;
  country: SupportedCountry;
  isConfirmationModalOpen: boolean;
  isKycEnabled: boolean;
  currency: CardAccountCurrency;
}

export interface Props extends DialogProps {
  isNonCustomerOrganizationsPage: boolean;
  isSourcePartnerSelected: boolean;
  selectedPartnerId: PartnerIdType;
  selectedPartnerRevenueShareId: PartnerIdType;
  onClose: () => void;
  onSuccess: (organization: Organization) => void;
  onPartnerChange: (
    partnerId: PartnerIdType,
    partnerRevenueShareId: PartnerIdType
  ) => void;
  openManualOrgCreationDialog: () => void;
}

const CreateOrgDialog = ({
  isNonCustomerOrganizationsPage,
  isSourcePartnerSelected,
  selectedPartnerId,
  selectedPartnerRevenueShareId,
  onSuccess,
  onPartnerChange,
  openManualOrgCreationDialog,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { cardAccountCurrencies, partners },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    inputValueOrg: '',
    selectedOrg: null,
    searchResults: [],
    isOpen: false,
    isLoading: false,
    isCreateOrgLoading: false,
    accountGroup: null,
    hubspotId: '',
    partnersOrganizationId: '',
    country: SupportedCountry.de,
    isConfirmationModalOpen: false,
    isKycEnabled: false,
    currency: CardAccountCurrency.EUR,
  });

  const getCompanyRegistryProvider = (
    country: SupportedCountry,
    isKycEnabled: boolean
  ) => {
    if (country === SupportedCountry.de) {
      if (isKycEnabled) return CompanyRegistryProvider.kyc;

      return CompanyRegistryProvider.crefo;
    }

    return CompanyRegistryProvider.dnb;
  };

  const searchOrganization = async (name: string) => {
    try {
      const { data: searchResults } = await api.getOrganizationSearch(
        name,
        state.country,
        getCompanyRegistryProvider(state.country, state.isKycEnabled)
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        searchResults,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const createOrganization = async () => {
    try {
      setState((prevState) => ({ ...prevState, isCreateOrgLoading: true }));
      const partnerOrgIdTrimmed = state.partnersOrganizationId.trim();
      const payload = {
        accountGroup: state.accountGroup!,
        partnerId: selectedPartnerId,
        partnerRevenueShareId: selectedPartnerRevenueShareId,
        companyId: state.selectedOrg!.companyId,
        companyIdType: state.selectedOrg!.companyIdType,
        hubspotId: state.hubspotId,
        partnersOrganizationId:
          isSourcePartnerSelected && partnerOrgIdTrimmed
            ? partnerOrgIdTrimmed
            : null,
        currency: state.currency,
      };

      const organization = isNonCustomerOrganizationsPage
        ? await api.createNonCustomerOrganization(payload)
        : await api.createOrganization(payload);
      if (!mounted.current) return;
      onSuccess(organization);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCreateOrgLoading: false,
        isConfirmationModalOpen: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.alreadyExists) {
        enqueueSnackbar(t('int.createOrgDialog.orgExistsError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  useEffect(() => {
    let active = true;

    if (state.inputValueOrg.length < MIN_ALLOWED_CHARS) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) searchOrganization(state.inputValueOrg);
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValueOrg]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSourcePartnerSelected)
      setState((prevState) => ({
        ...prevState,
        isConfirmationModalOpen: true,
      }));
    else createOrganization();
  };

  if (state.isConfirmationModalOpen)
    return (
      <ConfirmDialog
        {...props}
        loading={state.isCreateOrgLoading}
        title={t('int.createOrgConfirmationDialog.title')}
        description={
          <Trans
            i18nKey="int.createOrgConfirmationDialog.description"
            components={{
              item: <Box mt={2} />,
              partner: <Box style={{ fontWeight: 'bold' }} />,
            }}
            values={{
              revenueSharePartner: getPartnerNameById(
                selectedPartnerRevenueShareId,
                partners
              ),
              configurationPartner: getPartnerNameById(
                selectedPartnerId,
                partners
              ),
            }}
          />
        }
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmationModalOpen: false,
          }))
        }
        onSuccess={createOrganization}
      />
    );

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.createOrgDialog.addNewOrg')}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} noValidate id="create-org-form">
          <Grid container spacing={2}>
            {!isNonCustomerOrganizationsPage && (
              <>
                <Grid item xs={12}>
                  <PartnerSelect
                    name="partnerRevenueShareId"
                    label={t('int.partnerSelect.revenueLabel')}
                    tooltipLabel={t('int.partnerSelect.revenueTooltip')}
                    value={selectedPartnerRevenueShareId}
                    onChange={(value) => onPartnerChange(value, value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PartnerSelect
                    name="partnerId"
                    label={t('int.partnerSelect.configLabel')}
                    tooltipLabel={t('int.partnerSelect.configTooltip')}
                    value={selectedPartnerId}
                    isPartnerConfig
                    onChange={(value) =>
                      onPartnerChange(value, selectedPartnerRevenueShareId)
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Autocomplete<SupportedCountry, false, true, false>
                value={state.country}
                onChange={(_, option) =>
                  setState((prevState) => ({
                    ...prevState,
                    country: option,
                    isKycEnabled: false,
                  }))
                }
                options={supportedCountries}
                disableClearable
                disabled={state.isCreateOrgLoading}
                label={t('int.createOrgDialog.country')}
                placeholder={t('int.createOrgDialog.country')}
                getOptionLabel={(option: SupportedCountry) =>
                  t(`countries.${option}`)
                }
                noOptionsText={t('common.nothingFound')}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth disabled={state.isCreateOrgLoading}>
                <InputLabel id="main-card-account-currency-select-label">
                  {t('int.createOrgDialog.currency')}
                </InputLabel>
                <Select
                  value={state.currency}
                  onChange={(event) =>
                    setState((prevState) => ({
                      ...prevState,
                      currency: event.target.value as CardAccountCurrency,
                    }))
                  }
                  labelId="main-card-account-currency-select-label"
                >
                  {cardAccountCurrencies.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <AccountGroupSelect
                country={state.country}
                currency={state.currency}
                partnerId={selectedPartnerId}
                value={state.accountGroup}
                onChange={(accountGroup) =>
                  setState((prevState) => ({ ...prevState, accountGroup }))
                }
              />
            </Grid>

            {!isNonCustomerOrganizationsPage &&
              state.country === SupportedCountry.de && (
                <Grid item xs={12}>
                  <Box mt={2}>
                    <FormControlLabel
                      checked={state.isKycEnabled}
                      onChange={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isKycEnabled: !prevState.isKycEnabled,
                        }))
                      }
                      control={<Switch />}
                      label={t('int.createOrgDialog.useKycLabel')}
                    />
                  </Box>
                </Grid>
              )}

            <Grid item xs={12}>
              <Autocomplete<OrganizationSearchItem, false, false, false>
                inputValue={state.inputValueOrg}
                onInputChange={(e, value) =>
                  setState((prevState) => ({
                    ...prevState,
                    inputValueOrg: value,
                    isOpen: value.length >= MIN_ALLOWED_CHARS,
                  }))
                }
                value={state.selectedOrg}
                onChange={(e, selectedOrg) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedOrg,
                    hubspotId: '',
                  }));
                }}
                options={state.searchResults}
                open={state.isOpen}
                onOpen={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isOpen: prevState.inputValueOrg.length >= MIN_ALLOWED_CHARS,
                  }))
                }
                onClose={() =>
                  setState((prevState) => ({
                    ...prevState,
                    searchResults: prevState.selectedOrg
                      ? prevState.searchResults
                      : [],
                    isOpen: false,
                  }))
                }
                loading={state.isLoading}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) =>
                  option.companyId === value.companyId
                }
                getOptionLabel={(option) => option.companyName}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.companyId}>
                      <SuitcaseSimpleIcon />
                      <Box ml={2}>
                        <div>{option.companyName}</div>
                        <Typography variant="caption" color="textSecondary">
                          {`${option.street}, ${option.postalCode} ${option.city}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
                helperText={
                  <>
                    {t('int.createOrgDialog.orgNotListed')}{' '}
                    <Link
                      component="button"
                      type="button"
                      onClick={openManualOrgCreationDialog}
                    >
                      {t('int.createOrgDialog.addOrgManually')}
                    </Link>
                  </>
                }
                forcePopupIcon={false}
                label={t('int.createOrgDialog.orgName')}
                placeholder={t('int.createOrgDialog.orgName')}
                noOptionsText={t('common.nothingFound')}
              />
            </Grid>

            {!isNonCustomerOrganizationsPage && (
              <Grid item xs={12}>
                <OrganizationToHubspotLinkField
                  disabled={!state.selectedOrg}
                  validHsOrgNames={
                    state.selectedOrg?.companyName
                      ? [state.selectedOrg?.companyName]
                      : []
                  }
                  onSuccess={(hsId) =>
                    setState((prevState) => ({
                      ...prevState,
                      hubspotId: hsId,
                    }))
                  }
                  resetOnChange={() => {
                    if (state.hubspotId) {
                      setState((prevState) => ({
                        ...prevState,
                        hubspotId: '',
                      }));
                    }
                  }}
                />
              </Grid>
            )}

            {isSourcePartnerSelected && (
              <Grid item xs={12}>
                <TextField
                  value={state.partnersOrganizationId}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      partnersOrganizationId: e.target.value,
                    }))
                  }
                  inputProps={{ maxLength: 100 }}
                  label={t('int.createOrgDialog.partnersOrganizationId')}
                  placeholder={t('int.createOrgDialog.partnersOrganizationId')}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            !state.accountGroup ||
            !state.selectedOrg ||
            (!isNonCustomerOrganizationsPage && !state.hubspotId) ||
            state.isCreateOrgLoading
          }
          form="create-org-form"
          type="submit"
        >
          {t('int.createOrgDialog.addOrg')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isCreateOrgLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(CreateOrgDialog);
