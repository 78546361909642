import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import {
  OrganizationSettlementStatus,
  ProgramSettlementStatus,
  SettlementStatus,
} from 'services/constants';

const getColor = (status: SettlementStatus): StatusBadgeProps['color'] => {
  switch (status) {
    case OrganizationSettlementStatus.settled:
    case ProgramSettlementStatus.settled:
      return 'success';
    case OrganizationSettlementStatus.resolve:
    case ProgramSettlementStatus.resolve:
      return 'warning';
    case ProgramSettlementStatus.reconcile:
    case OrganizationSettlementStatus.confirm:
    case ProgramSettlementStatus.confirm:
      return 'default';
    case OrganizationSettlementStatus.pending:
    case ProgramSettlementStatus.pending:
      return 'secondary';
  }
};

interface Props {
  status: SettlementStatus;
}

const SettlementStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      variant="tinted"
      color={getColor(status)}
      label={t(`settlementStatuses.${status}`)}
    />
  );
};

export default SettlementStatusBadge;
