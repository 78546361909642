import React, { useEffect, useState } from 'react';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { LoaderWithOverlay } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import { CardCount } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import AddonsSection from './AddonsSection';
import PaymentDetailsAndLastFeeTransactionsSection from './PaymentDetailsAndLastFeeTransactionsSection';
import PlanSection from './PlanSection';

const SubscriptionPlanSubPage = () => {
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const [cardCount, setCardCount] = useState<CardCount | null>(null);

  const getData = async () => {
    try {
      setIsLoading(true);
      const [subscriptionPlanData, cardCountData] = await Promise.all([
        api.getSubscriptionPlanParams(organization!.id),
        api.getCardCount(organization!.id),
      ]);

      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: { subscriptionPlan: subscriptionPlanData },
      });

      if (!mounted.current) return;

      setCardCount(cardCountData);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          {cardCount ? (
            <>
              <PlanSection cardCount={cardCount} />
              <AddonsSection cardCount={cardCount} />
            </>
          ) : (
            <WidgetError onReload={getData} />
          )}
          {canUser('platform-fee-card:view') && (
            <PaymentDetailsAndLastFeeTransactionsSection />
          )}
        </>
      )}

      <LoaderWithOverlay loading={isLoading} />
    </>
  );
};

export default withPageConfig(SubscriptionPlanSubPage, {
  permission: 'subscription-plan-settings:view',
});
