import FormatDecimal from 'components/FormatDecimal';
import {
  GlobeHemisphereWestIcon,
  SvgIconProps,
  Typography,
  TypographyProps,
} from 'elements';

interface Props {
  unit?: 'kg' | 't';
  value: number;
  IconProps?: SvgIconProps;
  TypographyProps?: TypographyProps;
}

export const Co2EmissionBadge = ({
  unit = 't',
  value,
  IconProps = {},
  TypographyProps = {},
}: Props) => {
  return (
    <>
      <GlobeHemisphereWestIcon color="action" {...IconProps} />
      <Typography variant="body2" ml={0.5} noWrap {...TypographyProps}>
        {unit === 'kg' ? (
          <FormatDecimal value={Math.round(value / 1000)} unit="kg" />
        ) : (
          <FormatDecimal value={value / 1000000} unit="t" />
        )}
      </Typography>
    </>
  );
};
