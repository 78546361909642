import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import { FxRateListDEPRECATED } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const DEFAULT_SELECTED_CURRENCY = 'USD';

interface Props extends DialogProps {
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  currency: string;
  data: FxRateListDEPRECATED | null;
}

const FxRatesCalculatorDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const partnerName = usePartnerName();
  const {
    state: { subscriptionPlan },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    currency: DEFAULT_SELECTED_CURRENCY,
    data: null,
  });
  const selectedFxRate = useMemo(
    () =>
      state.data &&
      state.data.fxRates.find(
        (item) => item.foreignCurrency === state.currency
      ),
    [state.data, state.currency]
  );

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.getFxRatesDEPRECATED();
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        data,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getFullCurrencyLabel = (currencyCode: string) => {
    const fullName = t(`currencies.${currencyCode}`);
    return `${currencyCode} - ${fullName}`;
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('fxRatesDialog.header', { partnerName })}</DialogTitle>
      <DialogContent>
        <Typography mb={3}>
          <Trans
            i18nKey="fxRatesDialog.detailsTextA"
            values={{ partnerName }}
            components={{
              fee: <FormatPercent value={subscriptionPlan.fxFeePercentage} />,
            }}
          />
        </Typography>
        <Typography mb={3}>
          {t('fxRatesDialog.detailsTextB', { partnerName })}
        </Typography>
        <Typography>{t('fxRatesDialog.detailsTextC')}</Typography>

        {selectedFxRate && (
          <>
            <Box maxWidth="50%" my={3}>
              <FormControl fullWidth disabled={state.isLoading}>
                <InputLabel id="currency-label">
                  {t('fxRatesDialog.selectLabel')}
                </InputLabel>
                <Select<string>
                  value={state.currency}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      currency: e.target.value,
                    }))
                  }
                  renderValue={() =>
                    getFullCurrencyLabel(selectedFxRate.foreignCurrency)
                  }
                  labelId="currency-label"
                >
                  {state.data?.fxRates.map(({ foreignCurrency }) => (
                    <MenuItem key={foreignCurrency} value={foreignCurrency}>
                      {getFullCurrencyLabel(foreignCurrency)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box display="flex">
              <Box width="50%">
                <Typography variant="caption" component="div" mb={1}>
                  {t('fxRatesDialog.ECBRate')}
                </Typography>
                <Typography variant="h6">
                  1 {selectedFxRate.foreignCurrency} = {selectedFxRate.ecbRate}{' '}
                  EUR
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {t('fxRatesDialog.dateUpdated')}:{' '}
                  {moment(selectedFxRate.ecbRateDate, 'YYYY-MM-DD').format(
                    'D MMM YYYY'
                  )}
                </Typography>

                <Typography variant="caption" component="div" mt={3}>
                  {t('fxRatesDialog.pliantMarkupVisaRate')}
                </Typography>
                <Typography variant="h6">
                  <FormatPercent value={subscriptionPlan.fxFeePercentage} />
                </Typography>
              </Box>

              <Box width="50%">
                <Typography variant="caption" component="div" mb={1}>
                  {t('fxRatesDialog.visaRate')}
                </Typography>
                <Typography variant="h6">
                  1 {selectedFxRate.foreignCurrency} = {selectedFxRate.visaRate}{' '}
                  EUR
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {t('fxRatesDialog.dateUpdated')}:{' '}
                  {moment(selectedFxRate.visaRateDate, 'YYYY-MM-DD').format(
                    'D MMM YYYY'
                  )}
                </Typography>

                <Typography variant="caption" component="div" mt={3}>
                  {t('fxRatesDialog.totalMarkupECBRate')}
                </Typography>
                <Typography variant="h6">
                  <FormatPercent value={selectedFxRate.visaMarkupPercentage} />
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(FxRatesCalculatorDialog);
