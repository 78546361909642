import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import { AccountEntryAvatar } from 'domains/transaction/components';
import { Box, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import { AccountEntry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import AccountEntryDetailsPageContent from './AccountEntryDetailsPageContent';
import AccountEntryPaymentTypeBadge from './AccountEntryPaymentTypeBadge';
import BillPaymentInfo from './BillPaymentInfo';

interface State {
  isLoading: boolean;
  accountEntry: AccountEntry | null;
  error: unknown;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (accountEntry: AccountEntry) => void;
}

const AccountEntryDetailsPage = ({ onUpdate, ...props }: Props) => {
  const api = useImperativeApi();
  const { entryId } = useParams<{ entryId: string }>();
  const mounted = useMounted();
  const idRef = useRef(entryId);
  const [state, setState] = useState<State>({
    isLoading: false,
    accountEntry: null,
    error: null,
  });

  useEffect(() => {
    if (state.accountEntry) onUpdate(state.accountEntry);
  }, [state.accountEntry]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const accountEntry = await api.getAccountEntry(entryId);
      if (!mounted.current || entryId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntry,
        error: null,
      }));
    } catch (error) {
      if (!mounted.current || entryId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntry: null,
        error,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!entryId) return;
    idRef.current = entryId;
    getData();
  }, [entryId]);

  return (
    <DetailsDrawer {...props}>
      {state.error && <WidgetError onReload={getData} />}
      {state.accountEntry && (
        <>
          <DetailsDrawerHeader pb={2.5}>
            <Box display="flex" alignItems="center">
              <AccountEntryAvatar
                size="large"
                type={state.accountEntry.type}
                billPaymentType={
                  state.accountEntry.billPaymentInfo?.billPaymentType
                }
              />

              <Box pl={2} ml="auto" textAlign="right" overflow="hidden">
                <Typography
                  variant="h4"
                  color={
                    state.accountEntry.amount.value > 0
                      ? 'success.main'
                      : 'error.main'
                  }
                >
                  <FormatMoney
                    value={state.accountEntry.amount}
                    fractionalPart
                    positiveSign
                  />
                </Typography>

                {state.accountEntry.billPaymentInfo?.billPaymentType && (
                  <AccountEntryPaymentTypeBadge
                    billPaymentType={
                      state.accountEntry.billPaymentInfo.billPaymentType
                    }
                    paymentType={state.accountEntry.billPaymentInfo.paymentType}
                  />
                )}
              </Box>
            </Box>

            <BillPaymentInfo accountEntry={state.accountEntry} />
          </DetailsDrawerHeader>

          <DetailsDrawerContent pb={1}>
            <Box p={3}>
              <AccountEntryDetailsPageContent
                accountEntry={state.accountEntry}
              />
            </Box>
          </DetailsDrawerContent>
        </>
      )}

      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(AccountEntryDetailsPage);
