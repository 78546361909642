import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  StatusBadge,
  Tooltip,
  Typography,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { PageContent, PageHeader, PageTitle } from 'layout';
import { FeatureModuleKey } from 'services/constants';
import ManageFeatureModuleDialog from './ManageFeatureModuleDialog';
import useModuleTiles, { ModuleTile } from './useModuleTiles';

const FeatureModulesPage = () => {
  const { t } = useTranslation();
  const {
    state: { featureModules, subscriptionPlan },
  } = useGlobalState();
  const moduleTiles = useModuleTiles();
  const [
    moduleTileToManage,
    setModuleTileToManage,
  ] = useState<ModuleTile | null>(null);

  if (featureModules.TEAMS === undefined) {
    // If one of the feature modules is undefined,
    // it means that request to load feature modules failed.
    // In this case, we still allow users to access the app but show
    // the error message saying that some functionalities might be missing.
    return <WidgetError onReload={() => window.location.reload()} />;
  }

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('featureModulesSubpage.title')}
          suptitle={t('navigation.settings.title')}
        />
      </PageHeader>

      <Divider sx={{ mx: 5 }} />

      <PageContent pb={5}>
        <Grid container spacing={3}>
          {moduleTiles.map((item) => {
            return item.isHidden ? null : (
              <Grid item key={item.key}>
                <Card
                  sx={{
                    width: 348,
                    cursor: 'pointer',
                    position: 'relative',
                    height: '100%',
                    ...(item.key === FeatureModuleKey.pliantEarth
                      ? {
                          backgroundColor: (theme) =>
                            theme.variables.pliantearth.sky,
                        }
                      : {}),
                  }}
                  onClick={() => setModuleTileToManage(item)}
                  variant="outlined"
                >
                  {item.beta && (
                    <StatusBadge
                      sx={(theme) => ({
                        position: 'absolute',
                        top: theme.spacing(3),
                        left: theme.spacing(3),
                      })}
                      color="info"
                      variant="filled"
                      label={t('common.betaBadgeLabel')}
                    />
                  )}
                  {item.isActive && (
                    <StatusBadge
                      sx={(theme) => ({
                        position: 'absolute',
                        top: theme.spacing(3),
                        right: theme.spacing(3),
                      })}
                      color="success"
                      variant="filled"
                      label={t('featureModulesSubpage.active')}
                    />
                  )}

                  {item.isPremium && !subscriptionPlan.premiumFeaturesEnabled && (
                    <Tooltip
                      title={t(
                        'manageFeatureModuleDialog.disabledReasons.featureNotAvailableOnCurrentPlanTooltip'
                      )}
                      placement="top"
                    >
                      <Box
                        position="absolute"
                        sx={(theme) => ({
                          lineHeight: 0,
                          top: theme.spacing(3),
                          right: theme.spacing(3),
                        })}
                      >
                        <StatusBadge
                          label={t('common.badge.upgrade')}
                          variant="filled"
                          color="secondary"
                        />
                      </Box>
                    </Tooltip>
                  )}

                  <CardMedia
                    sx={(theme) => ({
                      height: 130,
                      backgroundSize: 'contain',
                      mt: theme.spacing(3),
                      mx: theme.spacing(3),
                    })}
                    image={item.tileImage}
                  />

                  <CardContent sx={{ p: 3 }}>
                    <Typography variant="h6" mt={1}>
                      {t(`featureModuleNames.${item.key}`)}
                    </Typography>

                    <Typography color="textSecondary" mt={2}>
                      {t(
                        `featureModulesSubpage.moduleShortDescriptions.${item.key}`
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </PageContent>

      <ManageFeatureModuleDialog
        moduleTile={moduleTileToManage}
        open={!!moduleTileToManage}
        onClose={() => setModuleTileToManage(null)}
      />
    </>
  );
};

export default withPageConfig(FeatureModulesPage, {
  permission: 'feature-modules-subtab:visit',
});
