import React, { FC, ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, EditButton } from 'components/Button';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { VatIdChangeDialog } from 'domains/organization/dialogs';
import { StatusBadge } from 'elements';
import { Onboarding, Organization } from 'services/constants';
import { Group, GroupLabel, ItemParagraph } from './style';

interface VatIdFormGroupProps {
  onboarding: Onboarding;
  isChosen: boolean;
  setIsVatIdChosen: () => void;
  label: ReactNode;
}

const VatIdFormGroup: FC<VatIdFormGroupProps> = ({
  onboarding,
  isChosen,
  setIsVatIdChosen,
  label,
}) => {
  const { t } = useTranslation();
  const {
    actions: { updateOnboarding },
  } = useOnboardingContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onUpdated = (organization: Organization) => {
    updateOnboarding({ vatId: organization.vatId });
    setIsVatIdChosen();
    setIsDialogOpen(false);
  };

  const onCancelEdit = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Group $alignItems="center">
        <GroupLabel $lineHeight={undefined}>{label}</GroupLabel>

        <Box display="flex" alignItems="center">
          {isChosen ? (
            <>
              <ItemParagraph>{onboarding.vatId || '-'}</ItemParagraph>
              <EditButton
                $m="0 0 0 10px"
                onClick={() => setIsDialogOpen(true)}
              />
            </>
          ) : (
            <>
              <Button variant="outlined" onClick={() => setIsDialogOpen(true)}>
                {t('onboardingPage.addInfoBtn')}
              </Button>
              <StatusBadge
                sx={{ ml: 2 }}
                label={t('common.badge.informationRequired')}
                color="warning"
              />
            </>
          )}
        </Box>
      </Group>

      <VatIdChangeDialog
        open={isDialogOpen}
        noPreselect={!isChosen}
        organizationId={onboarding.organizationId}
        organizationCountry={onboarding.billingAddress.country}
        vatId={onboarding.vatId}
        onSuccess={onUpdated}
        onClose={onCancelEdit}
      />
    </>
  );
};

export default VatIdFormGroup;
