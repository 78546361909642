import React from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { FirmographicsFundingPayload } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getMoneyObject } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues
  extends Omit<
    FirmographicsFundingPayload,
    'capitalRaised' | 'capitalRaisedAmount'
  > {
  capitalRaised: string | null;
  capitalRaisedAmount: number | null;
}

const EditFirmographicsFundingDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const firmographics = creditProfile!.firmographics;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      capitalRaised:
        typeof firmographics.capitalRaised.value === 'boolean'
          ? firmographics.capitalRaised.value
            ? 'yes'
            : 'no'
          : null,
      capitalRaisedAmount:
        typeof firmographics.capitalRaisedAmount.value?.value === 'number'
          ? firmographics.capitalRaisedAmount.value.value / 100
          : null,
      capitalRaisedSource: firmographics.capitalRaisedSource.value ?? '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      if (values.capitalRaised === 'yes' && !values.capitalRaisedAmount) {
        errors.capitalRaisedAmount = t('common.errors.fieldRequired');
      }
      if (values.capitalRaised === 'yes' && !values.capitalRaisedSource) {
        errors.capitalRaisedSource = t('common.errors.fieldRequired');
      }

      return errors;
    },
    onSubmit: async (values) => {
      const payload = {
        capitalRaised: !!values.capitalRaised
          ? values.capitalRaised === 'yes'
          : null,
        capitalRaisedAmount:
          typeof values.capitalRaisedAmount === 'number'
            ? getMoneyObject(values.capitalRaisedAmount * 100)
            : null,
        capitalRaisedSource: !!values.capitalRaisedSource
          ? values.capitalRaisedSource
          : null,
      };

      try {
        await api.updateFirmographicsFunding(organization!.id, payload);
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editFirmographicsFundingDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.capitalRaised}>
              <InputLabel>
                {t('int.editFirmographicsFundingDialog.capitalRaised')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('capitalRaised'), 'helperText')}
                onChange={(event) => {
                  if (event.target.value === 'no') {
                    formik.setFieldValue('capitalRaisedAmount', null);
                    formik.setFieldValue('capitalRaisedSource', '');
                  }
                  onChange('capitalRaised', event.target.value);
                }}
              >
                {[
                  { name: 'yes', label: t('common.yes') },
                  { name: 'no', label: t('common.no') },
                ].map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.capitalRaised}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <MoneyField
              key={formik.values.capitalRaised} // required to reset value on capitalRaised change
              {...getCustomFieldProps('capitalRaisedAmount')}
              onValueChange={({ floatValue = null }) => {
                onChange('capitalRaisedAmount', floatValue);
              }}
              label={t('int.editFirmographicsFundingDialog.amount')}
              disabled={formik.values.capitalRaised === 'no'}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.capitalRaisedSource}>
              <InputLabel>
                {t('int.editFirmographicsFundingDialog.source')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('capitalRaisedSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('capitalRaisedSource', event.target.value)
                }
                disabled={formik.values.capitalRaised === 'no'}
              >
                {organizationsStaticData!.capitalRaisedSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.capitalRaisedSource}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditFirmographicsFundingDialog);
