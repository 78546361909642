import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, matchPath, useHistory } from 'react-router';
import { Redirect, Route, useLocation, useParams } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import { IssueCardDialog } from 'domains/card/dialogs';
import { CardDetailsPage } from 'domains/card/pages';
import { AddTeamMembersDialog } from 'domains/member/dialogs';
import { MemberDetailsPage } from 'domains/member/pages';
import {
  Box,
  Button,
  DataGrid,
  gridUtils,
  LoaderWithOverlay,
  MenuItem,
  Select,
  useGridApiRef,
  UserIcon,
  VerticalCardsIcon,
} from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  PageFilters,
  PageHeader,
  PageTableContent,
  PageTitle,
  PageTitleActions,
} from 'layout';
import {
  Card,
  DEFAULT_PAGE_LIMIT,
  Member,
  MemberDetails,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { isCardVoid, isSortValid } from 'services/utils';
import TeamBudget from './TeamBudget';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { sort } = Object.fromEntries(new URLSearchParams(qs).entries());

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+status',
  };
};

interface State {
  isLoading: boolean;
  members: Member[];
  hasNextPage: boolean;
  isAddMembersDialogOpen: boolean;
  isIssueCardDialogOpen: boolean;
}

const TeamDetailsSubpage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const api = useImperativeApi();
  const showPageError = useShowPageError();
  const mounted = useMounted();
  const canUser = useCanUser();
  const setQueryParam = useSetQueryParam();
  const { allowedSortKeys, columns } = useColumns();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const history = useHistory();
  const { teamId } = useParams<{ teamId: string }>();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const [state, setState] = useState<State>({
    isLoading: true,
    members: [],
    hasNextPage: false,
    isAddMembersDialogOpen: false,
    isIssueCardDialogOpen: false,
  });
  const teamDetailsPath = useMemo(
    () =>
      generatePath(cardholderPaths.teamDetails, { teamId: teamId }) +
      location.search,
    [teamId, location.search]
  );
  const team = useMemo(() => managedTeams.find((item) => item.id === teamId)!, [
    managedTeams,
    teamId,
  ]);
  // Row should be selected if either the member or the card details page is open
  const rowSelectionModel = useMemo(() => {
    if (!state.members.length) return [];

    const memberDetailsMatch = matchPath<{ memberId: string }>(
      location.pathname,
      { path: cardholderPaths.teamMemberDetails }
    );
    if (!!memberDetailsMatch) return [memberDetailsMatch.params.memberId];

    const cardDetailsMatch = matchPath<{ cardId: string }>(location.pathname, {
      path: cardholderPaths.teamCardDetails,
    });
    if (!!cardDetailsMatch) {
      const member = state.members.find((item) =>
        item.cards.some(
          (card) => card.cardId === cardDetailsMatch.params.cardId
        )
      );

      if (!!member) return [member.id];
    }

    return [];
  }, [location.pathname, state.members]);

  const getData = async (page: number, isLoadMore = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { sort } = paramsRef.current;
      const { members, hasNextPage } = await api.getMembers({
        page,
        limit: DEFAULT_PAGE_LIMIT,
        sort,
        organizationId: organization!.id,
        teamIds: teamId,
        teamEntitiesOnly: true,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        members: isLoadMore ? [...prevState.members, ...members] : members,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const loadInitialData = () => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  };

  useEffect(() => {
    loadInitialData();
  }, [location.search, teamId]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, true);
  };

  const onMemberUpdate = (member: MemberDetails, cards: Card[]) => {
    if (!member.teams.find((item) => item.teamId === teamId)) {
      // Close the member details page and re-fetch data when member is unassigned from a current team
      history.replace(teamDetailsPath);
      loadInitialData();
      return;
    }
    setState((prevState) => ({
      ...prevState,
      members: prevState.members.map((item) =>
        item.id === member.id ? { ...item, ...member, cards } : item
      ),
    }));
  };

  const onCardUpdate = (card: Card) => {
    setState((prevState) => ({
      ...prevState,
      members: prevState.members.map((memberItem) =>
        memberItem.id === card.memberId
          ? {
              ...memberItem,
              cards: isCardVoid(card)
                ? memberItem.cards.filter(
                    (cardItem) => cardItem.cardId !== card.cardId
                  )
                : memberItem.cards.map((cardItem) =>
                    cardItem.cardId === card.cardId ? card : cardItem
                  ),
            }
          : memberItem
      ),
    }));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('managerTeamsPage.title')}>
          <PageTitleActions>
            <Button
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isAddMembersDialogOpen: true,
                }))
              }
              startIcon={<UserIcon />}
              variant="outlined"
            >
              {t('managerTeamsPage.addMember')}
            </Button>

            {canUser('card:create') && (
              <Button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isIssueCardDialogOpen: true,
                  }))
                }
                startIcon={<VerticalCardsIcon />}
              >
                {t('managerTeamsPage.issueCard')}
              </Button>
            )}
          </PageTitleActions>
        </PageTitle>

        <PageFilters>
          <Box width={220}>
            <Select<string>
              value={teamId}
              onChange={(e) =>
                history.push(
                  generatePath(cardholderPaths.teamDetails, {
                    teamId: e.target.value,
                  }) + location.search
                )
              }
              renderValue={(selected) => {
                const team = managedTeams.find((item) => item.id === selected);
                return team?.name;
              }}
            >
              {managedTeams.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </PageFilters>

        {featureModules.TEAM_BUDGETS && !!team.budget?.value && (
          <TeamBudget
            balance={team.balance!}
            budget={team.budget}
            availableBudget={team.availableBudget!}
          />
        )}
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Member>
          apiRef={dataGridRef}
          rowHeight={72}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowSelectionModel={rowSelectionModel}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.members}
          columns={columns}
          columnVisibilityModel={{
            drawerPlaceholder: !!rowSelectionModel.length,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(teamDetailsPath);
            else
              history.push(
                generatePath(cardholderPaths.teamMemberDetails, {
                  teamId,
                  memberId: row.id,
                }) + location.search
              );
          }}
          onSortModelChange={(sort) => {
            if (state.isLoading || !state.members.length) return;
            setQueryParam('sort', gridUtils.getNewSortParam(sort));
          }}
          slots={{ loadingOverlay: () => null }}
        />
      </PageTableContent>

      <Route
        exact
        path={cardholderPaths.teamMemberDetailsPartial}
        render={() => <Redirect to={teamDetailsPath} />}
      />
      <Route
        path={cardholderPaths.teamMemberDetails}
        children={({ match }) => (
          <MemberDetailsPage
            open={!!match}
            teamId={teamId}
            onUpdate={onMemberUpdate}
          />
        )}
      />

      <Route
        exact
        path={cardholderPaths.teamCardDetailsPartial}
        render={() => <Redirect to={teamDetailsPath} />}
      />
      <Route
        path={cardholderPaths.teamCardDetails}
        children={({ match }) => (
          <CardDetailsPage
            open={!!match}
            teamId={teamId}
            onUpdate={onCardUpdate}
          />
        )}
      />

      <AddTeamMembersDialog
        teamId={teamId}
        open={state.isAddMembersDialogOpen}
        onSuccess={() => {
          setState((prevState) => ({
            ...prevState,
            isAddMembersDialogOpen: false,
          }));
          loadInitialData();
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddMembersDialogOpen: false,
          }))
        }
      />

      <IssueCardDialog
        open={state.isIssueCardDialogOpen}
        onSuccess={() => {
          setState((prevState) => ({
            ...prevState,
            isIssueCardDialogOpen: false,
          }));
          loadInitialData();
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isIssueCardDialogOpen: false,
          }))
        }
        managerTeam={team}
      />
    </>
  );
};

export default TeamDetailsSubpage;
