import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AutomationType,
  CUSTOM_FIELD,
  customFieldsVisibilityOptions,
  CustomFieldType,
  CustomFieldVisibility,
  NetworkErrorCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface FormikValues {
  label: string;
  defaultValue: string;
  description: string;
  mandatoryOnAutomation: boolean;
  mandatoryForExport: boolean;
  visibility: CustomFieldVisibility;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const AddCustomFieldDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();

  const formik = useFormik<FormikValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      label: '',
      defaultValue: '',
      description: '',
      mandatoryOnAutomation: true,
      mandatoryForExport: true,
      visibility: CustomFieldVisibility.adminAndCardholder,
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const { visibility, ...otherValues } = values;
        await api.addCustomField({
          organizationId: organization!.id,
          ...trimObjValues(otherValues),
          type: CustomFieldType.text,
          automationType: AutomationType.card,
          visibility: visibility,
        });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.transactionCustomFieldLabelIsTaken
        ) {
          setFieldError(
            'label',
            t('accountingGeneralSubpage.addCustomFieldDialog.labelIsTakenError')
          );
          return;
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.transactionCustomFieldLimitReached
        ) {
          enqueueSnackbar(
            t(
              'accountingGeneralSubpage.addCustomFieldDialog.labelLimitReachedError'
            ),
            { variant: 'error' }
          );
          props.onClose();
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('accountingGeneralSubpage.addCustomFieldDialog.title')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="add-custom-field-form"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t(
                  'accountingGeneralSubpage.addCustomFieldDialog.labelLabel'
                )}
                {...formik.getFieldProps('label')}
                disabled={formik.isSubmitting}
                inputProps={{
                  maxLength: CUSTOM_FIELD.labelMaxLength,
                }}
                error={!!formik.errors.label}
                helperText={
                  formik.errors.label ||
                  (formik.values.label.length >
                    CUSTOM_FIELD.labelMaxLength - 10 &&
                    t('errors.maxCharacters', {
                      count: formik.values.label.length,
                      max: CUSTOM_FIELD.labelMaxLength,
                    }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={
                  <>
                    {t(
                      'accountingGeneralSubpage.addCustomFieldDialog.defaultValueLabel'
                    )}
                    <InputLabelTooltipIcon
                      title={t(
                        'accountingGeneralSubpage.addCustomFieldDialog.defaultValueLabelTooltip'
                      )}
                    />
                  </>
                }
                {...formik.getFieldProps('defaultValue')}
                disabled={formik.isSubmitting}
                inputProps={{
                  maxLength: CUSTOM_FIELD.valueMaxLength,
                }}
                multiline
                maxRows={2}
                helperText={
                  formik.values.defaultValue.length >
                    CUSTOM_FIELD.valueMaxLength - 10 &&
                  t('errors.maxCharacters', {
                    count: formik.values.defaultValue.length,
                    max: CUSTOM_FIELD.valueMaxLength,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t(
                  'accountingGeneralSubpage.addCustomFieldDialog.descriptionLabel'
                )}
                {...formik.getFieldProps('description')}
                disabled={formik.isSubmitting}
                inputProps={{
                  maxLength: CUSTOM_FIELD.descriptionMaxLength,
                }}
                multiline
                maxRows={2}
                helperText={
                  formik.values.description.length >
                    CUSTOM_FIELD.descriptionMaxLength - 10 &&
                  t('errors.maxCharacters', {
                    count: formik.values.description.length,
                    max: CUSTOM_FIELD.descriptionMaxLength,
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>
                  {t(
                    'accountingGeneralSubpage.addCustomFieldDialog.visibilityLabel'
                  )}
                  <InputLabelTooltipIcon
                    title={t(
                      'accountingGeneralSubpage.addCustomFieldDialog.visibilityLabelTooltip'
                    )}
                  />
                </InputLabel>
                <Select
                  {...formik.getFieldProps('visibility')}
                  renderValue={(value) => t(`customFieldsVisibility.${value}`)}
                >
                  {customFieldsVisibilityOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`customFieldsVisibility.${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                name="mandatoryOnAutomation"
                checked={formik.values.mandatoryOnAutomation}
                disabled={formik.isSubmitting}
                onChange={(_, checked) =>
                  formik.setFieldValue('mandatoryOnAutomation', checked)
                }
                control={<Checkbox />}
                label={
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    component="span"
                  >
                    {t(
                      'accountingGeneralSubpage.addCustomFieldDialog.mandatoryOnAutomationLabel'
                    )}
                    <FormControlLabelTooltipIcon
                      title={t(
                        'accountingGeneralSubpage.addCustomFieldDialog.mandatoryOnAutomationLabelTooltip'
                      )}
                    />
                  </Box>
                }
              />

              <FormControlLabel
                name="mandatoryForExport"
                checked={formik.values.mandatoryForExport}
                disabled={formik.isSubmitting}
                onChange={(_, checked) =>
                  formik.setFieldValue('mandatoryForExport', checked)
                }
                control={<Checkbox />}
                label={
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    component="span"
                  >
                    {t(
                      'accountingGeneralSubpage.addCustomFieldDialog.mandatoryForExportLabel'
                    )}
                    <FormControlLabelTooltipIcon
                      title={t(
                        'accountingGeneralSubpage.addCustomFieldDialog.mandatoryForExportLabelTooltip'
                      )}
                    />
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={formik.isSubmitting || !formik.values.label.trim().length}
          type="submit"
          form="add-custom-field-form"
        >
          {t('common.button.create')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AddCustomFieldDialog);
