import { MouseEvent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  ArrowCircleDownFilledIcon,
  ArrowsCounterClockwiseIcon,
  Chip,
  FlagPennantIcon,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  ThumbsUpIcon,
  Tooltip,
} from 'elements';
import { TransactionReviewStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  reviewStatus: TransactionReviewStatus | null;
  reviewedAt: string | null;
  reviewerFullName: string | null;
  updateReview: (reviewStatus: TransactionReviewStatus) => Promise<void>;
}

const TransactionReviewDropdown = ({
  reviewStatus,
  reviewedAt,
  reviewerFullName,
  updateReview,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  const isDisabled =
    !canUser('transaction-review:change') &&
    !canUser('team-transaction-review:change');
  const isApproved = reviewStatus === TransactionReviewStatus.approved;

  if (!reviewStatus || reviewStatus === TransactionReviewStatus.needsReview)
    return null;

  const renderStatusButton = (
    openMenu?: (e: MouseEvent<HTMLElement>) => void
  ) => {
    return (
      <Tooltip
        title={
          isApproved && reviewedAt
            ? t('transactionDetailsHeader.approvedTooltip', {
                date: moment(reviewedAt).format('DD.MM.YYYY'),
                name: reviewerFullName,
              })
            : ''
        }
        placement="bottom-end"
      >
        <Chip
          label={t(`transactionReviewStatus.${reviewStatus}`)}
          size="small"
          color={isApproved ? 'success' : 'error'}
          deleteIcon={<ArrowCircleDownFilledIcon />}
          onDelete={openMenu ? (e) => openMenu(e) : undefined}
          clickable={!isDisabled}
        />
      </Tooltip>
    );
  };

  return isDisabled ? (
    renderStatusButton()
  ) : (
    <MenuContainer button={renderStatusButton}>
      <MenuItem
        onClick={() => updateReview(TransactionReviewStatus.needsReview)}
      >
        <ListItemIcon>
          <ArrowsCounterClockwiseIcon />
        </ListItemIcon>
        {t('transactionDetailsHeader.resetReview')}
      </MenuItem>
      {reviewStatus === TransactionReviewStatus.approved ? (
        <MenuItem onClick={() => updateReview(TransactionReviewStatus.flagged)}>
          <ListItemIcon>
            <FlagPennantIcon />
          </ListItemIcon>
          {t('transactionDetailsHeader.flagTransaction')}
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => updateReview(TransactionReviewStatus.approved)}
        >
          <ListItemIcon>
            <ThumbsUpIcon />
          </ListItemIcon>
          {t('transactionDetailsHeader.approveTransaction')}
        </MenuItem>
      )}
    </MenuContainer>
  );
};

export default TransactionReviewDropdown;
