import React from 'react';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'components/Form';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  XIcon,
} from 'elements';
import { countries, TaxResidency } from 'services/constants';

export type TaxIdNumberInputGroupProps = {
  canBeEdited: boolean;
  errors: string | string[] | FormikErrors<TaxResidency>[];
  isLoading: boolean;
  onChange: (taxResidences: TaxResidency[]) => void;
  onErrorChange: (errors: FormikErrors<TaxResidency>[]) => void;
  taxResidences: TaxResidency[];
};

const TaxIdNumberInputGroup = ({
  canBeEdited,
  isLoading,
  taxResidences,
  ...props
}: TaxIdNumberInputGroupProps) => {
  const { t } = useTranslation();
  const errors = props.errors as FormikErrors<TaxResidency>[];

  const onChange = (
    itemIndex: number,
    name: 'taxResidence' | 'taxId',
    value: string
  ) => {
    props.onChange(
      taxResidences.map((item, index) =>
        index === itemIndex ? { ...item, [name]: value } : item
      )
    );

    if (errors[itemIndex]) {
      props.onErrorChange(
        errors.map((item, index) =>
          index === itemIndex ? { ...item, [name]: '' } : item
        )
      );
    }
  };

  return (
    <Grid container spacing={2}>
      {taxResidences.map((_, index) => (
        <Grid key={index} item container spacing={2}>
          <Grid item xs={5}>
            <Autocomplete<
              { countryName: string; countryCode: string },
              false,
              true,
              false
            >
              disabled={isLoading || !canBeEdited}
              onChange={(_, option) => {
                onChange(index, 'taxResidence', option.countryCode ?? '');
                if (!option.countryCode && !!errors[index]?.taxId) {
                  const newErrors = errors.map((item, errorIndex) =>
                    errorIndex === index ? null : item
                  ) as FormikErrors<TaxResidency>[];
                  props.onErrorChange(newErrors);
                }
              }}
              value={countries.find(
                (c) => c.countryCode === taxResidences[index].taxResidence
              )}
              options={countries}
              getOptionLabel={(option) =>
                `${option.countryName} (${option.countryCode})`
              }
              id={`countryCode-${index}`}
              label={t('representativeAdditionalInfoSection.taxResidence')}
              disableClearable
              placeholder={t('representativeAdditionalInfoSection.country')}
              noOptionsText={t('common.nothingFound')}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              disabled={isLoading || !canBeEdited}
              label={t('representativeAdditionalInfoSection.tin')}
              name={`taxResidences[${index}].taxId`}
              value={taxResidences[index].taxId}
              onChange={(e) => onChange(index, 'taxId', e.target.value)}
            />
            {Array.isArray(errors) &&
              errors[index] &&
              typeof errors[index] !== 'string' && (
                <ErrorMessage>
                  {(errors[index] as FormikErrors<TaxResidency>).taxId}
                </ErrorMessage>
              )}
          </Grid>
          <Grid item xs={2} display="flex" alignItems="flex-end">
            {!!index && canBeEdited && (
              <IconButton
                disabled={isLoading || !canBeEdited}
                onClick={() =>
                  props.onChange([
                    ...taxResidences.slice(0, index),
                    ...taxResidences.slice(index + 1),
                  ])
                }
                aria-label="Delete"
              >
                <XIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      {canBeEdited && (
        <Grid item xs={12}>
          <Button
            disabled={isLoading || !canBeEdited}
            variant="text"
            onClick={() =>
              props.onChange([
                ...taxResidences,
                { taxId: '', taxResidence: '', primary: false },
              ])
            }
          >
            {t(`representativeAdditionalInfoSection.addAdditionalTinButton`)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TaxIdNumberInputGroup;
