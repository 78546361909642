import { TFunction } from 'i18next';

enum YesNo {
  YES = 'yes',
  NO = 'no',
}

export const getYesNoFromBoolean = (
  value: boolean | null,
  t: TFunction,
  options: {
    defaultValue?: string;
    returnSlug?: boolean;
  } = {
    defaultValue: '',
    returnSlug: false,
  }
) => {
  const defaultValue = options.defaultValue || '';
  const returnSlug = options.returnSlug || false;

  if (typeof value === 'boolean') {
    const slug = value ? YesNo.YES : YesNo.NO;
    return returnSlug ? slug : t(`common.${slug}`);
  }

  return defaultValue;
};

export const getBooleanFromYesNo = (value: string | null) => {
  if (value === YesNo.YES) {
    return true;
  }

  if (value === YesNo.NO) {
    return false;
  }

  return null;
};
