import React from 'react';
import CompleteComplianceCheckSection from './CompleteComplianceCheckSection';
import ComplianceInformationRequestSection from './ComplianceInformationRequestSection';
import ComplianceRatingSection from './ComplianceRatingSection';
import CustomerDeclarationsSection from './CustomerDeclarationsSection';
import LegalSection from './LegalSection';
import MasterDataSection from './MasterDataSection';
import PreCheckSection from './PreCheckSection';

const CompanySubPage = () => {
  return (
    <>
      <MasterDataSection />
      <ComplianceInformationRequestSection />
      <CustomerDeclarationsSection />
      <PreCheckSection />
      <ComplianceRatingSection />
      <LegalSection />
      <CompleteComplianceCheckSection />
    </>
  );
};

export default CompanySubPage;
