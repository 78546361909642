import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Autocomplete, Box, Button, Link, PlusIcon, XIcon } from 'elements';
import useInviteDialogThresholdExceededMessage from 'hooks/useInviteDialogThresholdExceededMessage';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  MemberStatus,
  PartialMember,
  TeamMember,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  inputValue: string;
  members: PartialMember[];
  isLoading: boolean;
}

interface Props {
  disabled: boolean;
  label: string;
  teamId: string;
  filteredOutMembers: TeamMember[];
  value: PartialMember[];
  onChange: (members: PartialMember[]) => void;
  onSave: () => void;
  onMemberInvite?: (firstName: string, lastName: string) => void;
}

const TeamMemberAutocomplete = ({
  disabled,
  label,
  teamId,
  filteredOutMembers,
  value,
  onChange,
  onSave,
  onMemberInvite,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization, subscriptionPlan },
  } = useGlobalState();
  const thresholdExceededMessage = useInviteDialogThresholdExceededMessage();
  const [state, setState] = useState<State>({
    inputValue: '',
    members: [],
    isLoading: true,
  });

  const getMembers = async () => {
    try {
      const { members } = await api.getPartialMembers({
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        sort: '+firstName',
        q: state.inputValue.trim(),
        status: [MemberStatus.invited, MemberStatus.active].join(),
        organizationId: organization!.id,
        excludedTeamId: teamId,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        members,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) getMembers();
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValue.trim()]);

  let noOptionsText;
  if (onMemberInvite && !value.length && !filteredOutMembers.length) {
    if (
      subscriptionPlan.maxActiveMembersCount !== null &&
      subscriptionPlan.activeAndInvitedMembersCount >=
        subscriptionPlan.maxActiveMembersCount
    ) {
      noOptionsText = thresholdExceededMessage;
    } else {
      noOptionsText = (
        <Trans
          i18nKey={'teamMemberAutocomplete.inviteMember'}
          components={{
            button: (
              <Link
                component="button"
                onMouseDown={() => {
                  const splitFullName = state.inputValue.split(' ');
                  onMemberInvite(splitFullName[0], splitFullName[1] || '');
                }}
              />
            ),
          }}
        />
      );
    }
  } else {
    noOptionsText = t('common.nothingFound');
  }

  // Currently MUI shows this warning in the console:
  // "Material-UI: The value provided to Autocomplete is invalid. None of the options match with ...".
  // This happens because options are fetched from the back-end, see this thread for more details:
  // https://github.com/mui-org/material-ui/issues/18514. The solution provided in the thread above
  // (add selected options to the options array and set filterSelectedOptions to true)
  // doesn't work for us because we want to show selected options. This warning doesn't break anything
  // and won't be shown in production - hence can be safely ignored.
  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} mr={1}>
        <Autocomplete<PartialMember, true, false, false>
          multiple
          disabled={disabled}
          label={label}
          placeholder={t('teamMemberAutocomplete.addMember')}
          noOptionsText={noOptionsText}
          loadingText={t('common.loading')}
          searchIcon={false}
          disableCloseOnSelect
          loading={state.isLoading}
          options={state.members}
          value={value}
          onChange={(e, value) => onChange(value)}
          inputValue={state.inputValue}
          onInputChange={(e, inputValue) =>
            setState((prevState) => ({ ...prevState, inputValue }))
          }
          renderTags={() => null}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              {selected ? (
                <XIcon fontSize="small" />
              ) : (
                <PlusIcon fontSize="small" />
              )}
              <Box ml={2}>{`${option.firstName} ${option.lastName}`}</Box>
            </li>
          )}
          filterOptions={(options) =>
            options.filter(
              (option) =>
                !filteredOutMembers.find(
                  (member) => member.memberId === option.id
                )
            )
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        />
      </Box>
      <Box flexShrink={0} mt={3}>
        <Button
          onClick={onSave}
          disabled={!value.length || disabled}
          variant="text"
        >
          <Trans
            i18nKey="teamMemberAutocomplete.addToTeam"
            values={{ count: value.length || '' }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default TeamMemberAutocomplete;
