import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { CardPicker, DialogStepper } from 'domains/card/components';
import {
  FormValues,
  getInitialValues,
  Step,
} from 'domains/card/dialogs/CardRequestDialog';
import useStepManager from 'domains/card/dialogs/CardRequestDialog/useStepManager';
import {
  useAvailableCardConfigSettings,
  useAvailableVirtualTravelCardSubtypes,
} from 'domains/card/hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';

interface Props {
  onClose: () => void;
}

const TypeStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const availableCardConfigSettings = useAvailableCardConfigSettings(true);
  const availableTravelCardSubtypes = useAvailableVirtualTravelCardSubtypes();
  const {
    state: { member },
  } = useGlobalState();
  const formik = useFormikContext<FormValues>();
  const stepManager = useStepManager();

  useEffect(() => {
    if (!availableCardConfigSettings.length)
      enqueueSnackbar(t('errors.general'), { variant: 'error' });
  }, [availableCardConfigSettings.length]);

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>{t('cardRequestDialog_v2.typeStep.title')}</DialogTitle>
      <DialogContent>
        <Typography component="div" variant="caption" mb={3}>
          {t('cardRequestDialog_v2.typeStep.chooseCardType')}
        </Typography>
        <CardPicker
          items={availableCardConfigSettings}
          travelCardSubtypes={availableTravelCardSubtypes}
          value={formik.values.cardConfigSetting}
          onChange={(cardConfigSetting) => {
            const customFields = formik.values.customFieldsResponse.map(
              (item) => item.defaultValue || ''
            );
            formik.resetForm({
              values: getInitialValues(
                customFields,
                formik.values.customFieldsResponse,
                cardConfigSetting
              ),
            });
          }}
          memberId={member.id}
          isCardRequestFlow={true}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!formik.values.cardConfigSetting}
          onClick={stepManager.goNext}
        >
          {t('common.button.continue')}
        </Button>
      </DialogActions>
    </>
  );
};

export default TypeStep;
