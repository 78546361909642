import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import {
  TransactionReviewStatus,
  transactionReviewStatuses,
} from 'services/constants';

const NONE_VALUE = 'NONE';

interface Props {
  pageKey: string;
  value: TransactionReviewStatus | typeof NONE_VALUE | '';
  onChange: (value: TransactionReviewStatus | typeof NONE_VALUE | '') => void;
}

const ReviewFilter = ({ pageKey, value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-review"
        id="panel-review-header"
      >
        <Typography>{t('filters.review')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<TransactionReviewStatus | typeof NONE_VALUE>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) =>
              onChange(e.target.value as TransactionReviewStatus | '')
            }
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.review')}
                  </Typography>
                );
              return selected === NONE_VALUE
                ? t(`${pageKey}.transactionReviewStatusNoneOption`)
                : t(`transactionReviewStatus.${selected}`);
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.review')}
              </Typography>
            </MenuItem>
            {transactionReviewStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {t(`transactionReviewStatus.${status}`)}
              </MenuItem>
            ))}
            <MenuItem value={NONE_VALUE}>
              {t(`${pageKey}.transactionReviewStatusNoneOption`)}
            </MenuItem>
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReviewFilter;
