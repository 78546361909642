export interface Flags {
  changePinEnabled: boolean;
  multiCurrencyBillingEnabled: boolean;
  cardDetailsPciWidgetEnabled: boolean;
  bookDemoSupportMenuItemEnabled: boolean;
  datevExportEnabled: boolean;
  partnerDealsEnabled: boolean;
  datevBillPaymentsCsvOnStatementsEnabled: boolean;
  datevBillPaymentsXmlOnStatementsEnabled: boolean;
  exportFlowEnabled: boolean;
  merchantDisplayNameEnabled: boolean;
  monthlyCashbackThresholdProgressEnabled: boolean;
  organizationMerchantsPageEnabled: boolean;
  onboardingPlatformTacsEnabled: boolean;
  suppliersEnabled: boolean;
  emailNotificationSettingsEnabled: boolean;
  allLanguagesEnabled: boolean;
  cashbackPayoutEnabled: boolean;
  onboardingComplianceStepWithInputEnabled: boolean;
  uploadOnboardingDocumentsInExternalAppEnabled: boolean;
  klarnaIntegrationEnabled: boolean;
  confirmationCheckboxOnPartnerAuthPageEnabled: boolean;
  receiptsZipOnBillingPageEnabled: boolean;
  managerTransactionReviewEnabled: boolean;
  smsDeliveryLogsEnabled: boolean;
  setBillingAccountAsPrefundEnabled: boolean;
  privateExpense: boolean;
  mobilexpenseMxpEnabled: boolean;
  bmdForeignCurrencyEnabled: boolean;
  voxelEnabled: boolean;
  confermaEnabled: boolean;
  basEnabled: boolean;
  receiptInboxEnabled: boolean;
  msDynamicsOptionsEnabled: boolean;
  securityKeyAuthenticationEnabled: boolean;
  scopevisioEnabled: boolean;
}

export const defaultFlags: Flags = {
  changePinEnabled: false,
  multiCurrencyBillingEnabled: false,
  cardDetailsPciWidgetEnabled: false,
  bookDemoSupportMenuItemEnabled: false,
  datevExportEnabled: false,
  partnerDealsEnabled: false,
  datevBillPaymentsCsvOnStatementsEnabled: false,
  datevBillPaymentsXmlOnStatementsEnabled: false,
  exportFlowEnabled: false,
  merchantDisplayNameEnabled: false,
  monthlyCashbackThresholdProgressEnabled: false,
  organizationMerchantsPageEnabled: false,
  onboardingPlatformTacsEnabled: false,
  suppliersEnabled: false,
  emailNotificationSettingsEnabled: false,
  allLanguagesEnabled: false,
  cashbackPayoutEnabled: false,
  onboardingComplianceStepWithInputEnabled: false,
  uploadOnboardingDocumentsInExternalAppEnabled: false,
  klarnaIntegrationEnabled: false,
  confirmationCheckboxOnPartnerAuthPageEnabled: false,
  receiptsZipOnBillingPageEnabled: false,
  managerTransactionReviewEnabled: false,
  smsDeliveryLogsEnabled: false,
  setBillingAccountAsPrefundEnabled: false,
  // the name is set by BE, we just use the same one.
  // This is a reason "enabled" is missing in the name
  privateExpense: false,
  mobilexpenseMxpEnabled: false,
  bmdForeignCurrencyEnabled: false,
  voxelEnabled: false,
  confermaEnabled: false,
  basEnabled: false,
  receiptInboxEnabled: false,
  msDynamicsOptionsEnabled: false,
  securityKeyAuthenticationEnabled: false,
  scopevisioEnabled: false,
};
