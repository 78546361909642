import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  EditButton,
  Link,
  QuestionIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from 'elements';
import useUrls from 'hooks/useUrls';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import ExportSettingsDialog from './ExportSettingsDialog';

const ExportSettingsGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { bmdForeignCurrencyEnabled } = useFlags();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const { HELP_CENTER_BMD_EXPORT_URL } = useUrls();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Table sx={{ mt: (theme) => theme.spacing(4) }}>
        <TableHead>
          <TableRow>
            <TableCell width="60%">
              {t('bmdSubPage.exportSettings.title', {
                name: accountingSettings!.accountingSystemName,
              })}
            </TableCell>
            <TableCell width="40%" align="right">
              {canUser('accounting-settings:update') && (
                <EditButton onClick={() => setIsDialogOpen(true)} />
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell width="60%">
              {t('bmdSubPage.exportSettings.bookingText')}
            </TableCell>
            <TableCell width="40%">
              {t(`bookingTextMode.${accountingSettings!.datevBookingTextMode}`)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              <Box display="flex" alignItems="center">
                {t('bmdSubPage.exportSettings.amountLogic')}
                <Tooltip
                  title={
                    <Trans
                      i18nKey="bmdSubPage.exportSettings.amountLogicTooltip"
                      components={{
                        linkTo: (
                          <Link
                            href={HELP_CENTER_BMD_EXPORT_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="inherit"
                          />
                        ),
                      }}
                    />
                  }
                >
                  <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell width="40%">
              {accountingSettings!.amountLogicEnabled
                ? t('common.mode.enabled')
                : t('common.mode.disabled')}
            </TableCell>
          </TableRow>

          {bmdForeignCurrencyEnabled && (
            <TableRow>
              <TableCell width="60%">
                <Box display="flex" alignItems="center">
                  {t('bmdSubPage.exportSettings.foreignCurrency')}
                  <Tooltip
                    title={t(
                      'bmdSubPage.exportSettings.foreignCurrencyTooltip'
                    )}
                  >
                    <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell width="40%">
                {accountingSettings!.bmdForeignCurrencyHandling
                  ? t('common.mode.enabled')
                  : t('common.mode.disabled')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <ExportSettingsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ExportSettingsGroup;
