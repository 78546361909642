import React, { memo, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { env } from 'env';

interface MessageEventData {
  event:
    | 'CARD_DATA_LOADING_STARTED'
    | 'STYLE_URL_ADDED'
    | 'LOADER_OVERLAY_REMOVED'
    | 'CARD_DATA_LOADING_SUCCESS'
    | 'CARD_DATA_CLEARED'
    | 'LOADER_OVERLAY_ADDED'
    | 'CARD_DATA_CARDHOLDER_COPIED'
    | 'CARD_DATA_CVV_COPIED'
    | 'CARD_DATA_EXPIRY_COPIED'
    | 'CARD_DATA_PAN_COPIED'
    | 'MISSING_PARAMETERS'
    | 'CARD_DATA_LOADING_FAILED'
    | 'CARD_DATA_COPYING_FAILED';
  code: number;
}

interface Props {
  type: 'pan' | 'cvv';
  cardToken: string;
  totp: string;
  textAlign?: 'center' | 'right';
  textColor?: string; // hex
  onLoadSuccess: () => void;
  onLoadError: () => void;
  onClear: () => void;
  onCopy?: () => void;
}

const CardSensitiveDataValue = ({
  type,
  cardToken,
  totp,
  textAlign,
  textColor,
  onLoadSuccess,
  onLoadError,
  onClear,
  onCopy,
}: Props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { randomValue, encodedData } = useMemo(() => {
    const data = JSON.stringify({
      cardId: cardToken,
      uniqueToken: totp,
      apiKeyId: env.REACT_APP_MEA_WALLET_API_KEY_ID,
      apiKey: env.REACT_APP_MEA_WALLET_API_KEY,
      styleUrl: window.location.origin + '/css/mcd-easy-launch-v2.css',
      loader: 'none',
      class: clsx(
        !!onCopy && 'clickable',
        !!textAlign && `text-align-${textAlign}`,
        !!textColor && `color-${textColor.slice(1)}`
      ),
      showCopy: !!onCopy,
      showCvv: type === 'cvv',
      showEmboss: false,
      showExpiry: false,
      showPan: type === 'pan',
    });

    return {
      randomValue: Math.floor(Math.random() * 1000000),
      encodedData: btoa(data),
    };
  }, []);

  useEffect(() => {
    const onMessage = (e: MessageEvent<MessageEventData>) => {
      if (e.source === iframeRef.current?.contentWindow) {
        switch (e.data.event) {
          case 'CARD_DATA_LOADING_SUCCESS':
            onLoadSuccess();
            break;
          case 'CARD_DATA_LOADING_FAILED':
            onLoadError();
            break;
          case 'CARD_DATA_CLEARED':
            onClear();
            break;
          case 'CARD_DATA_PAN_COPIED':
            if (type === 'pan' && onCopy) onCopy();
            break;
          case 'CARD_DATA_CVV_COPIED':
            if (type === 'cvv' && onCopy) onCopy();
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={`${env.REACT_APP_MEA_WALLET_IFRAME_URL}?var=${randomValue}&data=${encodedData}`}
      allow={`clipboard-write ${env.REACT_APP_MEA_WALLET_IFRAME_URL}`}
      title={type}
      width={type === 'pan' ? 165 : 40}
      height="20px"
      style={{ display: 'block', border: 'none' }}
    />
  );
};

export default memo(CardSensitiveDataValue);
