import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import { ExpenseType, SubcategoryStatus } from 'services/constants';

interface Props {
  subcategoryStatus: SubcategoryStatus;
  subcategoryId: string | null;
  expenseType: ExpenseType;
}

const SubcategoryCell = ({
  subcategoryStatus,
  subcategoryId,
  expenseType,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { subcategories, featureModules },
  } = useGlobalState();

  if (featureModules.PRIVATE_EXPENSE && expenseType === ExpenseType.private)
    return <DataGridCell>—</DataGridCell>;

  if (subcategoryStatus === SubcategoryStatus.multiple)
    return (
      <DataGridCell sx={{ textTransform: 'uppercase' }}>
        {t('exportPage.multiple')}
      </DataGridCell>
    );

  const selectedSubcategory = subcategories?.find(
    (item) => item.id === subcategoryId
  );

  return (
    <DataGridCell>
      {subcategoryStatus === SubcategoryStatus.missing ? (
        '—'
      ) : (
        <>
          {selectedSubcategory?.name}
          {selectedSubcategory?.number && (
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {selectedSubcategory.number}
            </Typography>
          )}
        </>
      )}
    </DataGridCell>
  );
};

export default SubcategoryCell;
