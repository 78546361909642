import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MemberCards } from 'domains/member/components';
import { hasEnoughLegalReps } from 'domains/member/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { Card, MemberDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  member: MemberDetails;
  cards: Card[] | null;
  onClose: () => void;
  onSuccess: () => void;
}

const DeactivateMemberDialog = ({
  member,
  cards,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { SUPPORT_REQUEST_URL } = useUrls();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingLegalReps, setIsFetchingLegalReps] = useState(true);
  const [enoughLegalReps, setEnoughLegalReps] = useState(false);
  const fullName = `${member.firstName} ${member.lastName}`;

  useEffect(() => {
    (async () => {
      try {
        const org = await api.getOrganization(member.organizationId);
        const enoughLegalReps = hasEnoughLegalReps(
          org?.legalRepresentatives || [],
          member
        );
        if (!mounted.current) return;
        setEnoughLegalReps(enoughLegalReps);
        setIsFetchingLegalReps(false);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setIsFetchingLegalReps(false);
      }
    })();
  }, []);

  const deactivateMember = async () => {
    try {
      setIsLoading(true);
      await api.deactivateMember(member.organizationId, member.id);
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('deactivateMemberDialog.heading', { fullName })}
      </DialogTitle>
      {!isFetchingLegalReps && (
        <>
          <DialogContent>
            {enoughLegalReps ? (
              <>
                <Typography variant="body2">
                  {t('deactivateMemberDialog.subheading')}
                </Typography>
                <Box my={3}>
                  <Typography color="textSecondary" mb={1}>
                    {fullName}
                  </Typography>
                  {!!cards?.length && (
                    <MemberCards cards={cards} orgId={member.organizationId} />
                  )}
                </Box>
                <Typography variant="body2">
                  {t('deactivateMemberDialog.explanation')}
                </Typography>
              </>
            ) : (
              <Typography component="div">
                <Trans
                  i18nKey="deactivateMemberDialog.notAllowed.description"
                  values={{ fullName }}
                  components={{
                    br: <br />,
                  }}
                />
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            {enoughLegalReps ? (
              <Button onClick={deactivateMember} disabled={isLoading}>
                {t('common.button.confirm')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  window.open(SUPPORT_REQUEST_URL, '_blank');
                  props.onClose();
                }}
              >
                {t('deactivateMemberDialog.notAllowed.submitRequest')}
              </Button>
            )}
          </DialogActions>
        </>
      )}
      <LoaderWithOverlay loading={isFetchingLegalReps || isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(DeactivateMemberDialog);
