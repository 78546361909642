import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { CashbackRedemptionStatus } from 'services/constants';

const colorsByStatuses: {
  [key in CashbackRedemptionStatus]: StatusBadgeProps['color'];
} = {
  [CashbackRedemptionStatus.submit]: 'default',
  [CashbackRedemptionStatus.waiting]: 'default',
  [CashbackRedemptionStatus.pending]: 'secondary',
  [CashbackRedemptionStatus.failed]: 'error',
  [CashbackRedemptionStatus.redeemed]: 'success',
};

interface Props {
  status: CashbackRedemptionStatus;
  count?: number;
}

const CashbackRedemptionStatusBadge = ({ status, count }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      variant="tinted"
      color={colorsByStatuses[status]}
      label={
        t(`cashbackRedemptionStatuses.${status}`).toUpperCase() +
        (isNumber(count) ? ` (${count})` : '')
      }
    />
  );
};

export default CashbackRedemptionStatusBadge;
