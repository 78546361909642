import { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  SuitcaseSimpleIcon,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  CardAccount,
  CardAccountStatus,
  DEFAULT_PAGE_LIMIT,
  SettlementTransaction,
  SettlementTransactionPaymentType,
  SettlementTransactionType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getValidQueryParamValues, isSortValid } from 'services/utils';
import Filters from './Filters';
import FiltersChips from './Filters/FiltersChips';
import ProcessingAccountInfo from './ProcessingAccountInfo';
import useColumns from './useColumns';

export const paymentTypes = [
  SettlementTransactionPaymentType.payout,
  SettlementTransactionPaymentType.billPmt,
  SettlementTransactionPaymentType.billPmtReversal,
  SettlementTransactionPaymentType.orgSetlPmt,
  SettlementTransactionPaymentType.prefundingPmt,
  SettlementTransactionPaymentType.other,
];

export const transactionTypes = [
  SettlementTransactionType.interest,
  SettlementTransactionType.remitCredit,
  SettlementTransactionType.remitDebit,
  SettlementTransactionType.sctCredit,
  SettlementTransactionType.sctDebit,
  SettlementTransactionType.sddAuth,
  SettlementTransactionType.sddBulk,
  SettlementTransactionType.sddReturn,
  SettlementTransactionType.sddSingle,
  SettlementTransactionType.unknown,
];

const getQueryParams = (
  qs: string,
  allowedSortKeys: string[],
  paymentTypes: SettlementTransactionPaymentType[],
  transactionTypes: SettlementTransactionType[],
  cardAccounts: CardAccount[],
  defaultCardAccount: CardAccount
) => {
  const {
    sort,
    q,
    paymentType,
    transactionType,
    fromDate,
    toDate,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  const validCardAccountIds = cardAccounts
    .filter((item) => item.status.value === CardAccountStatus.active)
    .map((account) => account.id);

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '-postingDate',
    q: q ? q.trim() : '',
    paymentType: getValidQueryParamValues(paymentType, paymentTypes),
    transactionType: getValidQueryParamValues(
      transactionType,
      transactionTypes
    ),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    cardAccountId: validCardAccountIds.includes(cardAccountId)
      ? cardAccountId
      : defaultCardAccount.id,
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  paymentType,
  transactionType,
  fromDate,
}: QueryParams) =>
  0 +
  (paymentType.length ? 1 : 0) +
  (transactionType.length ? 1 : 0) +
  (fromDate ? 1 : 0);

interface State {
  isLoading: boolean;
  transactions: SettlementTransaction[];
  hasNextPage: boolean;
  totalCount: number;
}

const ProcessingAccountPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const {
    state: { organization, cardAccounts, defaultCardAccount },
  } = useGlobalState();
  const { columns, allowedSortKeys } = useColumns();
  const paramsRef = useRef(
    getQueryParams(
      location.search,
      allowedSortKeys,
      paymentTypes,
      transactionTypes,
      cardAccounts,
      defaultCardAccount!
    )
  );
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: 0,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.transactions.length && !areFiltersApplied;
  const isOrgInOnboarding = useIsOrgInOnboarding();

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const {
        sort,
        q,
        paymentType,
        transactionType,
        fromDate,
        toDate,
        cardAccountId,
      } = paramsRef.current;
      const {
        transactions,
        hasNextPage,
        totalCount,
      } = await api.getSettlementTransactions({
        page,
        limit,
        sort,
        q: q.length ? q : undefined,
        paymentTypes: paymentType.length ? paymentType.join() : undefined,
        transactionType: transactionType.length
          ? transactionType.join()
          : undefined,
        dateType: fromDate ? 'POSTING' : undefined,
        fromDate: fromDate?.format('YYYY-MM-DD'),
        toDate: toDate?.format('YYYY-MM-DD'),
        organizationId: organization!.id,
        cardAccountId,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        transactions: isLoadMore
          ? [...prevState.transactions, ...transactions]
          : transactions,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(
      location.search,
      allowedSortKeys,
      paymentTypes,
      transactionTypes,
      cardAccounts,
      defaultCardAccount!
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.transactions.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  return (
    <>
      <PageHeader
        sx={{ mb: !isOrgInOnboarding && cardAccounts.length > 1 ? 2 : 0 }}
      >
        <PageTitle title={t('int.processingAccountPage.title')} />
        <Box mt={2} display="flex" alignItems="center">
          <Filters
            params={paramsRef.current}
            selectedFiltersCount={selectedFiltersCount}
            setParam={setQueryParam}
            disabled={isEmptyState}
            totalCount={state.totalCount}
          />
          <ProcessingAccountInfo
            cardAccountId={paramsRef.current.cardAccountId}
            onCardAccountIdChange={(cardAccountId) =>
              setQueryParam('cardAccountId', cardAccountId)
            }
          />
        </Box>
        <FiltersChips
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<SettlementTransaction>
          apiRef={dataGridRef}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          getRowId={(row) => row.transactionId}
          loading={state.isLoading}
          rows={state.transactions}
          rowCount={state.totalCount}
          columns={columns}
          onSortModelChange={handleSortModelChange}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={SuitcaseSimpleIcon}
                  label={t('int.processingAccountPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(ProcessingAccountPage, {
  permission: 'processing-account-page:visit',
});
