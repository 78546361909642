import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import CopyTextToClipboard from 'components/CopyTextToClipboard';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Link,
  Paper,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PartnerDeal } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadPdfFile, getGenericErrorMsg } from 'services/utils';
import { Logo, PromoImage } from './style';

interface Props extends DialogProps {
  deal: PartnerDeal;
  onClose: () => void;
}

const DealDescriptionDialog = ({ deal, ...props }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const openPdfDoc = async (id: string, name: string) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const data = await api.getPublicDocumentContent(id);
      downloadPdfFile(name + '.pdf', data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const openDealDetails = (deal: PartnerDeal) => {
    const details = deal.description.fullDescription.details!;
    if (details.type === 'DOCUMENT') {
      openPdfDoc(details.payload, deal.merchantName);
    } else {
      window.open(details.payload);
    }
  };

  return (
    <Dialog
      {...props}
      maxWidth="md"
      PaperProps={{ sx: { paddingRight: '350px', height: 600 } }}
    >
      <DialogContent>
        <Paper
          variant="outlined"
          sx={{
            width: 64,
            height: 64,
            mx: 'auto',
            mb: 2,
          }}
        >
          <Logo $src={deal.merchantLogo} />
        </Paper>
        <Typography align="center" mb={3}>
          {deal.merchantName}
        </Typography>
        <Typography variant="h6" mb={4}>
          {deal.description.shortDescription.web}
        </Typography>

        {deal.description.fullDescription.info && (
          <>
            <Typography variant="subtitle1" mb={1}>
              {t('partnerDealsSubpage.dialog.description')}
            </Typography>
            <Typography mb={3}>
              {deal.description.fullDescription.info}
            </Typography>
          </>
        )}

        {!!deal.description.fullDescription.claimSteps.length && (
          <>
            <Typography variant="subtitle1" mb={1}>
              {t('partnerDealsSubpage.dialog.claim')}
            </Typography>
            <Typography component="div" mb={3}>
              <ol>
                {deal.description.fullDescription.claimSteps.map(
                  (item: string, idx: number) => (
                    <li key={idx}>{item}</li>
                  )
                )}
              </ol>
            </Typography>
          </>
        )}

        {deal.redemptionCode && (
          <Box mb={3}>
            <CopyTextToClipboard
              displayValue={
                <>
                  {t('partnerDealsSubpage.dialog.offerCode')}{' '}
                  <b>{deal.redemptionCode}</b>
                </>
              }
              value={deal.redemptionCode}
              onCopy={() =>
                enqueueSnackbar(
                  t('partnerDealsSubpage.dialog.offerCodeCopied'),
                  { autoHideDuration: 1500 }
                )
              }
            />
          </Box>
        )}

        {deal.description.fullDescription.details && (
          <Typography mb={3}>
            <Trans
              i18nKey="partnerDealsSubpage.dialog.moreDetails"
              components={{
                a: (
                  <Link
                    component="button"
                    onClick={() => openDealDetails(deal)}
                  />
                ),
              }}
            />
          </Typography>
        )}

        {!!deal.description.fullDescription.termsBullets.length && (
          <>
            <Typography variant="subtitle1" mb={1}>
              {t('partnerDealsSubpage.dialog.terms')}
            </Typography>
            <Typography component="div" mb={3}>
              <ul>
                {deal.description.fullDescription.termsBullets.map(
                  (item: string, idx: number) => (
                    <li key={idx}>{item}</li>
                  )
                )}
              </ul>
            </Typography>
          </>
        )}
      </DialogContent>
      {deal.description.fullDescription.url && (
        <DialogActions
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Button onClick={props.onClose} variant="text" sx={{ mr: 1 }}>
            {t('common.button.close')}
          </Button>
          <Button
            component="a"
            href={deal.description.fullDescription.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('partnerDealsSubpage.dialog.website')}
          </Button>
        </DialogActions>
      )}
      <PromoImage $src={deal.promoImage} />
    </Dialog>
  );
};

export default withDialogWrapper(DealDescriptionDialog);
