import { useState } from 'react';
import { useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as PliantLogo } from 'assets/svg/pliantLogo.svg';
import { adminPaths, partnerPaths } from 'components/App';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import PartnerLogo from 'domains/partner/components/PartnerLogo';
import {
  PARTNER_AUTH_SESSION_KEY,
  PARTNER_AUTH_SESSION_VALUE,
} from 'domains/partner/constants';
import { LogoWrapper } from 'domains/partner/pages/style';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CaretDownIcon,
  Checkbox,
  FormControlLabel,
  LoaderWithOverlay,
  PlugsConnectedIcon,
  Typography,
} from 'elements';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import {
  DocumentGenericType,
  PartnerAuthStatus,
  PartnerConfig,
  PartnerIdType,
  PartnerOrgAuthDetails,
  PartnerScope,
  SKIP_PARTNER_AUTH,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import AuthConfirmationComponent from './AuthConfirmationComponent';
import withPartnerCheck from './withPartnerCheck';

const getExternalRedirectionUrl = (
  partnerId: PartnerIdType,
  baseUrl: string,
  id: string
) => {
  const redirectUrl = `${window.location.origin}${partnerPaths.partnerIntegrationStatus}?partnerId=${partnerId}`;
  return `${baseUrl}?organizationId=${id}&redirectUrl=${redirectUrl}&partnerId=${partnerId}`;
};

interface SharedData {
  title: string;
  subItems: { title: string; description: string }[];
}

interface Props {
  partnerDetails: PartnerConfig;
  partnerOrgDetails: PartnerOrgAuthDetails;
  appRedirectionUrl: string;
}

interface State {
  isLoading: boolean;
  isConfirmDialogOpen: boolean;
  shareOnboardingStatus: boolean;
  isConfirmationCheckboxChecked: boolean;
}

const PartnerAuthPage = ({
  partnerDetails,
  partnerOrgDetails,
  appRedirectionUrl,
}: Props) => {
  const theme = useTheme();
  const { confirmationCheckboxOnPartnerAuthPageEnabled } = useFlags();
  const history = useHistory();
  const { partnerId } = useParams<{ partnerId: string }>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const isOrgInOnboarding = useIsOrgInOnboarding();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { documents, organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    isConfirmDialogOpen: false,
    shareOnboardingStatus: false,
    isConfirmationCheckboxChecked: false,
  });

  const sharedDataInfo: SharedData[] = t(
    'partnersPage.generalPartner.sharedData',
    {
      returnObjects: true,
    }
  );

  const externalFlowLink = partnerDetails.orgAuthRedirectUrl;

  const isConfirmationCheckboxMandatory =
    confirmationCheckboxOnPartnerAuthPageEnabled &&
    isOrgInOnboarding &&
    [PartnerScope.embeddedWallet, PartnerScope.fullyEmbedded].includes(
      partnerDetails.partnerScope
    );

  const authorizePartner = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      let updatedPartner = await api.authorizePartner(
        partnerOrgDetails.partnerId,
        organization!.id,
        isConfirmationCheckboxMandatory
          ? state.shareOnboardingStatus
          : undefined
      );

      if (
        (!externalFlowLink &&
          isOrgInOnboarding &&
          updatedPartner.status !== PartnerAuthStatus.active) ||
        partnerDetails.activateIntegrationInOneStep
      ) {
        updatedPartner = await api.changePartnerStatus(
          partnerOrgDetails.partnerId,
          organization!.id,
          { status: PartnerAuthStatus.active }
        );
      }
      // update organization partner (source) - only if source exists
      if (organization!.partnerId === partnerId) {
        dispatch({
          type: 'SET_PARTNER_DATA',
          payload: {
            partnerOrgAuthDetails: updatedPartner,
          },
        });
      }
      if (!mounted.current) return;

      if (externalFlowLink) {
        // Flow with external redirection.
        // Set detection flag when redirect user back
        sessionStorage.setItem(
          PARTNER_AUTH_SESSION_KEY,
          PARTNER_AUTH_SESSION_VALUE
        );

        window.location.href = getExternalRedirectionUrl(
          partnerOrgDetails.partnerId,
          partnerDetails.orgAuthRedirectUrl,
          organization!.id
        );
      }
      // Flow for auth flow only inside our app
      else
        history.push(
          isOrgInOnboarding ? adminPaths.onboarding : appRedirectionUrl
        );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const renderCancelButton = () => {
    if (isOrgInOnboarding) {
      if (confirmationCheckboxOnPartnerAuthPageEnabled) return null;
      return (
        // onbocarding case is valid for only Candis yet
        <Button
          sx={{ mr: 2 }}
          size="large"
          variant="outlined"
          onClick={() => {
            sessionStorage.setItem(
              SKIP_PARTNER_AUTH.key,
              SKIP_PARTNER_AUTH.value
            );
            history.push(adminPaths.onboarding);
          }}
        >
          {t('partnersPage.generalPartner.skipButton')}
        </Button>
      );
    }
    return (
      <Button
        sx={{ mr: 2 }}
        size="large"
        variant="outlined"
        onClick={() => history.push(appRedirectionUrl)}
      >
        {t('partnersPage.generalPartner.declineButton')}
      </Button>
    );
  };

  if (!documents) return <WidgetError />;

  return (
    <ContentContainer mx="auto">
      <Box display="flex" alignItems="center" justifyContent="center">
        <LogoWrapper elevation={0} variant="tinted">
          {/* here should be always Pliant logo */}
          <PliantLogo
            style={{
              width: 64,
              color: theme.variables.navigation.light.text.primary,
            }}
          />
        </LogoWrapper>

        <PlugsConnectedIcon fontSize="large" sx={{ mx: 6 }} />

        <LogoWrapper elevation={0} variant="tinted">
          <PartnerLogo partnerId={partnerOrgDetails.partnerId} />
        </LogoWrapper>
      </Box>

      <Typography variant="subtitle1" textAlign="center" mt={2} mb={6}>
        <Trans
          i18nKey="partnersPage.generalPartner.title"
          values={{ name: partnerDetails.name }}
          components={{ span: <span /> }}
        />
      </Typography>

      <Typography variant="body2">
        {t('partnersPage.generalPartner.subTitle')}
      </Typography>

      <Box mt={2} mb={6}>
        {sharedDataInfo.map((item, index) => (
          <Accordion key={`partner-shared-${index}`}>
            <AccordionSummary expandIcon={<CaretDownIcon />}>
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'block' }}>
              {item.subItems.map((infoItem, infoItemIndex) => (
                <Box
                  key={`partner-infoItem-${index}-${infoItemIndex}`}
                  sx={{ '&+&': { mt: 1 } }}
                >
                  <Typography variant="body2">{infoItem.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {infoItem.description}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {isConfirmationCheckboxMandatory && (
        <>
          <FormControlLabel
            id="shareOnboardingStatus"
            name="shareOnboardingStatus"
            checked={state.shareOnboardingStatus}
            onChange={(e, checked) => {
              setState((prevState) => ({
                ...prevState,
                shareOnboardingStatus: checked,
              }));
            }}
            control={<Checkbox />}
            label={
              <Typography variant="caption">
                <Trans
                  i18nKey="partnersPage.generalPartner.shareOnboardingStatusLabel"
                  components={{
                    privacyLink: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={getDocumentUrl(
                          documents[
                            DocumentGenericType.platformDataPrivacyPolicyWeb
                          ][0].documentName
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </Typography>
            }
          />

          <FormControlLabel
            sx={{ mt: 1 }}
            id="confirmationCheckbox"
            name="confirmationCheckbox"
            checked={state.isConfirmationCheckboxChecked}
            onChange={(e, checked) => {
              setState((prevState) => ({
                ...prevState,
                isConfirmationCheckboxChecked: checked,
              }));
            }}
            control={<Checkbox />}
            label={
              <Typography variant="caption">
                <Trans
                  i18nKey="partnersPage.generalPartner.confirmationCheckboxLabel"
                  components={{
                    gtcLink: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={getDocumentUrl(
                          documents[
                            DocumentGenericType.platformTermsAndConditions
                          ][0].documentName
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    privacyLink: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={getDocumentUrl(
                          documents[
                            DocumentGenericType.platformDataPrivacyPolicyWeb
                          ][0].documentName
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </Typography>
            }
          />

          <Box textAlign="center" mt={4}>
            {externalFlowLink ? (
              <AuthConfirmationComponent
                isLoading={state.isLoading}
                onSuccess={authorizePartner}
                disabled={!state.isConfirmationCheckboxChecked}
              />
            ) : (
              <Button
                size="large"
                onClick={authorizePartner}
                disabled={!state.isConfirmationCheckboxChecked}
              >
                {t('partnersPage.generalPartner.authorizeButton')}
              </Button>
            )}
          </Box>
        </>
      )}

      {!isConfirmationCheckboxMandatory && (
        <Box display="flex">
          <Typography variant="caption" mr={8}>
            <Trans
              i18nKey="partnersPage.generalPartner.agreementText"
              components={{
                tokLink: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={getDocumentUrl(
                      documents[
                        DocumentGenericType.platformTermsAndConditions
                      ][0].documentName
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
                privacyLink: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={getDocumentUrl(
                      documents[
                        DocumentGenericType.platformDataPrivacyPolicyWeb
                      ][0].documentName
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </Typography>

          <Box ml="auto" flexShrink={0}>
            {renderCancelButton()}
            {externalFlowLink ? (
              <AuthConfirmationComponent
                isLoading={state.isLoading}
                onSuccess={authorizePartner}
              />
            ) : (
              <Button onClick={authorizePartner} size="large">
                {t('partnersPage.generalPartner.authorizeButton')}
              </Button>
            )}
          </Box>
        </Box>
      )}

      <LoaderWithOverlay loading={state.isLoading} />
    </ContentContainer>
  );
};

export default withPageErrorWrapper(withPartnerCheck(PartnerAuthPage));
