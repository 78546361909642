import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { Box, InfoIcon, Paper, Tooltip, Typography } from 'elements';
import { Card } from 'services/constants';

interface Props {
  card: Card;
}

const CardTransactionLimit = ({ card }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        minHeight: 54,
        padding: theme.spacing(0.5, 1.5, 0.5, 2),
      })}
    >
      <Typography variant="caption" color="text.secondary" noWrap>
        {t('cardLimit.transactionLimit')}
      </Typography>
      <Tooltip title={t('tooltips.transactionLimit')}>
        <InfoIcon
          sx={{ width: 16, height: 16, mx: 0.5, color: 'text.secondary' }}
        />
      </Tooltip>
      <Box flexShrink={0} ml="auto" textAlign="right">
        {card.requestedTransactionLimit && (
          <Typography component="div" variant="caption2" color="info.main">
            <FormatMoney
              value={card.requestedTransactionLimit}
              fractionalPart
            />
          </Typography>
        )}
        <Typography variant="body2" data-test-id="card-limit">
          <FormatMoney value={card.transactionLimit} fractionalPart />
        </Typography>
      </Box>
    </Paper>
  );
};

export default CardTransactionLimit;
