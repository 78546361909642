import React from 'react';
import { useTranslation } from 'react-i18next';
import AppLogo from 'components/AppLogo';
import { Button, CloudXIcon, Typography } from 'elements';
import { Content, Overlay } from './style';

const UnexpectedError = () => {
  const { t } = useTranslation();
  const reloadPage = () => window.location.reload();

  return (
    <Overlay>
      <AppLogo />
      <Content>
        <CloudXIcon
          fontSize="large"
          sx={{ verticalAlign: 'top', mb: 1, color: 'text.secondary' }}
        />
        <Typography variant="h6" mb={1}>
          {t('errors.unexpectedError.title')}
        </Typography>
        <Typography color="text.secondary" mb={2}>
          {t('errors.unexpectedError.description')}
        </Typography>
        <Button onClick={reloadPage} size="large">
          {t('errors.unexpectedError.reload')}
        </Button>
      </Content>
    </Overlay>
  );
};

export default UnexpectedError;
