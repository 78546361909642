import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { GlobalTransactionDetailsPage } from 'domains/transaction/pages';
import {
  DataGrid,
  FileXIcon,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  Transaction,
  TransactionReceiptStatus,
  transactionReceiptStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getReceiptFilterApiParams } from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const getQueryParams = (qs: string) => {
  const { q, merchantIds, receipt } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    q: q?.trim() || '',
    merchantIds: merchantIds || '',
    receipt: (transactionReceiptStatuses.includes(
      receipt as TransactionReceiptStatus
    )
      ? receipt
      : '') as TransactionReceiptStatus | '',
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ receipt }: QueryParams) => +!!receipt.length;

type RequestParams = {
  q?: string;
  merchantIds?: string;
  receiptNeeded?: boolean;
  receiptMissing?: boolean;
};

interface State {
  isLoading: boolean;
  transactions: Transaction[];
  hasNextPage: boolean;
  totalCount: number;
  isSearchOpen: boolean;
}

const GlobalTransactionsPage = () => {
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const columns = useColumns();
  const history = useHistory();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search));
  const pageRef = useRef(0);
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:transactionId',
    true
  );
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: 0,
    isSearchOpen: false,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.transactions.length && !areFiltersApplied;

  const getRequestParams = (): RequestParams => {
    const { q, merchantIds, receipt } = paramsRef.current;
    return {
      q: q.length ? q : undefined,
      merchantIds: merchantIds ? merchantIds : undefined,
      ...getReceiptFilterApiParams(receipt),
    };
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));

      const params = getRequestParams();
      const {
        transactions,
        hasNextPage,
        totalCount,
      } = await api.getGlobalTransactions({ ...params, page, limit });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        transactions: isLoadMore
          ? [...prevState.transactions, ...transactions]
          : transactions,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        isLoading: false,
      }));
    }
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const onTransactionDetailsUpdated = (transaction: Transaction) =>
    setState((prevState) => ({
      ...prevState,
      transactions: prevState.transactions.map((item) =>
        item.transactionId === transaction.transactionId ? transaction : item
      ),
    }));

  return (
    <>
      <PageHeader>
        <PageTitle title={t('globalTransactionsPage.header')} />

        <Filters
          selectedFiltersCount={selectedFiltersCount}
          params={paramsRef.current}
          setParam={setQueryParam}
          disabled={isEmptyState}
          transactionsCount={state.totalCount}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Transaction>
          apiRef={dataGridRef}
          rowHeight={72}
          getRowId={(row) => row.transactionId}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.transactions}
          columns={columns}
          columnVisibilityModel={{
            country: !isDetailsPageOpen,
            organizationName: !isDetailsPageOpen,
            cardType: !isDetailsPageOpen,
            memberFirstName: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          rowSelectionModel={
            detailsParams?.transactionId ? [detailsParams.transactionId] : []
          }
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${row.transactionId}${location.search}`);
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied)
                return <NothingFound />;

              return (
                <NoData
                  isNewDesign
                  Icon={FileXIcon}
                  label={t('globalTransactionsPage.noTransactions')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:transactionId`}
          children={({ match }) => (
            <GlobalTransactionDetailsPage
              open={!!match}
              onUpdate={onTransactionDetailsUpdated}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(GlobalTransactionsPage, {
  permission: 'global-transactions-page:visit',
});
