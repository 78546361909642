import React, { useLayoutEffect } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { useSupportedLanguages } from 'domains/organization/hooks';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MoneyField,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { Role, useCanUser } from 'services/rbac';
import {
  convertDineroToMoney,
  dineroFromFloat,
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';
import { AllFormikValues, FormikHandlers } from './index';

interface Props {
  onClose: () => void;
  onInviteSuccess: (member: MemberDetails, shouldIssueCard: boolean) => void;
  previousStep: () => void;
  setFormikHandlers: (value: React.SetStateAction<FormikHandlers>) => void;
}

const MemberPermissionsStep = ({
  previousStep,
  setFormikHandlers,
  onClose,
  onInviteSuccess,
}: Props) => {
  const formik = useFormikContext<AllFormikValues>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const currency = useCardAccountCurrency();
  const supportedLanguages = useSupportedLanguages();

  const onSubmit = async (
    {
      role,
      shouldIssueCard,
      editGeneralSettings,
      editModules,
      editIntegrations,
      maxSpendLimitPerCard,
      canCreateCardForSelf,
      accountingSettings,
      accountingExport,
      ...rest
    }: AllFormikValues,
    { setSubmitting, setErrors }: FormikHelpers<AllFormikValues>
  ) => {
    try {
      const memberData = trimObjValues(rest);
      const roles = [Role.cardholder]; // default role for all members
      if (role !== Role.cardholder) roles.push(role);
      if (editGeneralSettings && roles.includes(Role.orgAdmin))
        roles.push(Role.orgSettingsManager);
      if (editModules && roles.includes(Role.orgAdmin))
        roles.push(Role.orgModulesManager);
      if (editIntegrations && roles.includes(Role.orgAdmin))
        roles.push(Role.orgIntegrationManager);
      if (accountingExport && roles.includes(Role.orgAdmin))
        roles.push(Role.accountingExportManager);
      if (accountingSettings && !roles.includes(Role.accountOwner))
        roles.push(Role.accountingSettingsManager);

      const member = await api.inviteMember({
        ...memberData,
        ...(role === Role.orgAdmin && {
          maxSpendLimitPerCard: convertDineroToMoney(
            dineroFromFloat(
              !!maxSpendLimitPerCard ? maxSpendLimitPerCard : 0,
              currency
            )
          ),
          canCreateCardForSelf: canCreateCardForSelf || false,
        }),
        roles,
        title: memberData.title || null,
        languageCode: supportedLanguages[0].code,
        organizationId: organization!.id,
      });
      if (!mounted.current) return;
      enqueueSnackbar(t('common.invitationSent'));
      onInviteSuccess(member, shouldIssueCard);
    } catch (error) {
      if (!mounted.current) return;
      setSubmitting(false);
      if (getNetworkErrorCode(error) === NetworkErrorCode.memberLimitExceeded) {
        enqueueSnackbar(t('inviteMemberDialog.memberLimitExceeded'), {
          variant: 'error',
        });
      } else if (
        getNetworkErrorCode(error) === NetworkErrorCode.userAlreadyExists
      ) {
        setErrors({ email: t('inviteMemberDialog.userExistsError') });
        previousStep();
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  useLayoutEffect(() => {
    setFormikHandlers({ onSubmit, validate: () => {} });
  }, []);

  const isSubmitDisabled =
    (formik.values.role === Role.orgAdmin &&
      canUser('member-permissions:change') &&
      !formik.values.maxSpendLimitPerCard) ||
    formik.isSubmitting;

  const isOrgSettingsSectionVisible =
    (formik.values.role === Role.orgAdmin &&
      (canUser('member-org-settings-manager-role:update') ||
        canUser('member-org-modules-manager-role:update') ||
        canUser('member-org-integration-manager-role:update'))) ||
    ((formik.values.role === Role.accountant ||
      formik.values.role === Role.orgAdmin) &&
      canUser('member-accounting-settings-manager-role:update'));

  const isCardIssuanceSectionVisible =
    formik.values.role === Role.orgAdmin &&
    canUser('member-permissions:change');

  const isAccountingExportSectionVisible =
    formik.values.role === Role.orgAdmin &&
    canUser('member-accounting-export-manager-role:update');

  return (
    <>
      <DialogTitle>
        {t('inviteMemberDialog.memberPermissions.title', {
          role: t(`roles.${formik.values.role}`),
        })}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="permissions-form" noValidate>
          {isOrgSettingsSectionVisible && (
            <Box>
              <Typography variant="subtitle2">
                {t('inviteMemberDialog.memberPermissions.orgSettings.subtitle')}
              </Typography>
              {canUser('member-org-settings-manager-role:update') &&
                formik.values.role === Role.orgAdmin && (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.editGeneralSettings}
                          onChange={(e, checked) => {
                            formik.setFieldValue(
                              'editGeneralSettings',
                              checked
                            );
                          }}
                        />
                      }
                      label={
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          {t(
                            'inviteMemberDialog.memberPermissions.orgSettings.editGeneralSettings'
                          )}
                          <FormControlLabelTooltipIcon
                            title={t(
                              'inviteMemberDialog.memberPermissions.orgSettings.editGeneralSettingsTooltip'
                            )}
                          />
                        </Box>
                      }
                    />
                  </Box>
                )}
              {canUser('member-accounting-settings-manager-role:update') &&
                (formik.values.role === Role.accountant ||
                  formik.values.role === Role.orgAdmin) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.accountingSettings}
                        onChange={(e, checked) => {
                          formik.setFieldValue('accountingSettings', checked);
                        }}
                      />
                    }
                    label={
                      <Box component="span" display="flex" alignItems="center">
                        {t(
                          'inviteMemberDialog.memberPermissions.orgSettings.accountingSettings'
                        )}
                        <FormControlLabelTooltipIcon
                          title={t(
                            'inviteMemberDialog.memberPermissions.orgSettings.accountingSettingsTooltip'
                          )}
                        />
                      </Box>
                    }
                  />
                )}
              {canUser('member-org-modules-manager-role:update') &&
                formik.values.role === Role.orgAdmin && (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.editModules}
                          onChange={(e, checked) => {
                            formik.setFieldValue('editModules', checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          {t(
                            'inviteMemberDialog.memberPermissions.orgSettings.editModules'
                          )}
                          <FormControlLabelTooltipIcon
                            title={t(
                              'inviteMemberDialog.memberPermissions.orgSettings.editModulesTooltip'
                            )}
                          />
                        </Box>
                      }
                    />
                  </Box>
                )}
              {canUser('member-org-integration-manager-role:update') &&
                formik.values.role === Role.orgAdmin && (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.editIntegrations}
                          onChange={(e, checked) => {
                            formik.setFieldValue('editIntegrations', checked);
                          }}
                        />
                      }
                      label={
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                        >
                          {t(
                            'inviteMemberDialog.memberPermissions.orgSettings.editIntegrations'
                          )}
                          <FormControlLabelTooltipIcon
                            title={t(
                              'inviteMemberDialog.memberPermissions.orgSettings.editIntegrationsTooltip'
                            )}
                          />
                        </Box>
                      }
                    />
                  </Box>
                )}
            </Box>
          )}

          {isCardIssuanceSectionVisible && (
            <Box mt={4}>
              <Typography variant="subtitle2">
                {t(
                  'inviteMemberDialog.memberPermissions.cardIssuance.subtitle'
                )}
              </Typography>
              <Box mb={1}>
                <MoneyField
                  {...omit(
                    formik.getFieldProps('maxSpendLimitPerCard'),
                    'onChange'
                  )}
                  onValueChange={({ value }) =>
                    formik.setFieldValue('maxSpendLimitPerCard', value)
                  }
                  isNumericString
                  currency={currency.code}
                  disabled={formik.isSubmitting}
                  label={
                    <>
                      {t(
                        'inviteMemberDialog.memberPermissions.cardIssuance.maxLimitPerCard'
                      )}
                      <InputLabelTooltipIcon
                        title={t(
                          'inviteMemberDialog.memberPermissions.cardIssuance.maxLimitPerCardTooltip'
                        )}
                      />
                    </>
                  }
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.canCreateCardForSelf}
                    onChange={(e, checked) => {
                      formik.setFieldValue('canCreateCardForSelf', checked);
                    }}
                  />
                }
                label={t(
                  'inviteMemberDialog.memberPermissions.cardIssuance.canCreateCardForSelf'
                )}
              />
            </Box>
          )}

          {isAccountingExportSectionVisible && (
            <Box mt={4}>
              <Typography variant="subtitle2">
                {t('inviteMemberDialog.memberPermissions.accounting.subtitle')}
              </Typography>
              {canUser('member-accounting-export-manager-role:update') && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.accountingExport}
                      onChange={(e, checked) => {
                        formik.setFieldValue('accountingExport', checked);
                      }}
                    />
                  }
                  label={
                    <Box component="span" display="flex" alignItems="center">
                      {t(
                        'inviteMemberDialog.memberPermissions.accounting.accountingExport'
                      )}
                      <FormControlLabelTooltipIcon
                        title={t(
                          'inviteMemberDialog.memberPermissions.accounting.accountingExportTooltip'
                        )}
                      />
                    </Box>
                  }
                />
              )}
            </Box>
          )}
        </form>
      </DialogContent>

      <DialogActions>
        <Box flexGrow={1}>
          <Button variant="text" onClick={previousStep}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="permissions-form"
          type="submit"
        >
          {t('inviteMemberDialog.sendInvite')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default MemberPermissionsStep;
