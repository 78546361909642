import { AxiosRequestConfig } from 'axios';
import useMounted from 'hooks/useMounted';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  downloadPdfFile,
  downloadTxtFile,
  getFileNameFromHeader,
  getGenericErrorMsg,
} from 'services/utils';
import useSnackbar from './useSnackbar';

const useDownloadFile = (
  url: string,
  params?: any,
  axiosConfig?: AxiosRequestConfig
) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    try {
      const response = await api.downloadFile(url, params, axiosConfig);
      if (!mounted) return;
      const fileName = getFileNameFromHeader(response?.headers);
      if (response?.headers['content-type'] === 'application/pdf') {
        downloadPdfFile(fileName, response.data as string);
      } else {
        downloadTxtFile(fileName, response.data as string);
      }
    } catch (error) {
      if (!mounted) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return () => {
    getData();
  };
};

export default useDownloadFile;
