import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { adminPaths, partnerPaths } from 'components/App';
import { LoaderWithOverlay } from 'components/Loader';
import { PageContent, PageHeader } from 'components/Page';
import { useGlobalState } from 'context/GlobalState';
import ResumeApplicationButton from 'domains/creditAndCompliance/components/ResumeApplicationButton';
import { InviteMemberButtonWithDialog } from 'domains/member/components';
import withPageConfig from 'hoc/withPageConfig';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import usePartnerName from 'hooks/usePartnerName';
import {
  BillPaymentType,
  OrganizationAccountType,
  OrganizationStatus,
  PartnerAuthStatus,
  PartnerIds,
  PartnerScope,
  PartnerWhitelabelLevel,
  SKIP_PARTNER_AUTH,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import CancelApplicationButton from './CancelApplicationButton';
import ComplianceCheckBlock from './ComplianceCheckBlock';
import OnboardingDocumentsBlock from './OnboardingDocumentsBlock';
import OrganizationDetailsBlock from './OrganizationDetailsBlock';
import RepresentativeBlock from './RepresentativeBlock';
import RiskAssessmentBlock from './RiskAssessmentBlock';
import SepaDirectDebitBlock from './SepaDirectDebitBlock';
import Stepper from './Stepper';
import TermsAndConditions from './TermsAndConditionsBlock';
import { Disclaimer, Heading, MailDescription } from './style';
import {
  useOnboardingContext,
  withOnboardingContext,
} from './useOnboardingContext';

const OnboardingPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: {
      member,
      organization,
      partnerOrgAuthDetails,
      defaultCardAccount,
      partnerConfig,
    },
  } = useGlobalState();
  const isOrgInOnboarding = useIsOrgInOnboarding();
  const { getHeaderStyle, onContentScroll } = useHeaderBorderOnScroll();
  const { state: onboardingState } = useOnboardingContext();
  const {
    onboardingComplianceStepWithInputEnabled,
    uploadOnboardingDocumentsInExternalAppEnabled,
  } = useFlags();

  if (
    !isOrgInOnboarding &&
    organization!.status !== OrganizationStatus.cancelled
  )
    return <Redirect to="/" />;

  if (canUser('onboarding-role-page:visit') && !member.legalRepresentative)
    return <Redirect to={adminPaths.onboardingRole} />;

  if (
    canUser('partners:change') &&
    partnerConfig &&
    [PartnerScope.embeddedWallet, PartnerScope.fullyEmbedded].includes(
      partnerConfig.partnerScope
    ) &&
    partnerOrgAuthDetails?.status === PartnerAuthStatus.inactive &&
    sessionStorage.getItem(SKIP_PARTNER_AUTH.key) !== SKIP_PARTNER_AUTH.value
  )
    return <Redirect to={partnerPaths.partners} />;

  return (
    <>
      <PageHeader style={getHeaderStyle()}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Heading>{t('onboardingPage.title')}</Heading>
          <Stepper />
          <Box flexShrink={0}>
            <InviteMemberButtonWithDialog />
          </Box>
        </Box>
      </PageHeader>
      <PageContent onScroll={onContentScroll}>
        {onboardingState.isLoading ? (
          <LoaderWithOverlay size={32} thickness={3} />
        ) : (
          <>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box marginBottom={8}>
                <MailDescription $maxWidth="650px">
                  {t('onboardingPage.titleDescription', { partnerName })}
                </MailDescription>

                {partnerConfig?.partnerId !== PartnerIds.pliant &&
                  partnerConfig?.whitelabelLevel ===
                    PartnerWhitelabelLevel.FULLY_WHITELABEL && (
                    <Disclaimer $mt={12}>
                      {t('onboardingPage.subtitle')}
                    </Disclaimer>
                  )}
              </Box>

              {/* requried for Sales Role to cancel applications */}
              <CancelApplicationButton />
              <ResumeApplicationButton />
            </Box>
            {defaultCardAccount!.accountType.value ===
            OrganizationAccountType.credit ? (
              <>
                <OrganizationDetailsBlock />
                {uploadOnboardingDocumentsInExternalAppEnabled && (
                  <OnboardingDocumentsBlock />
                )}
                {onboardingComplianceStepWithInputEnabled &&
                  onboardingState.orgRepsAdditionalInfo.length > 0 && (
                    <RepresentativeBlock />
                  )}
                <RiskAssessmentBlock />
                {defaultCardAccount!.billPaymentTransferType.value ===
                  BillPaymentType.directDebit && <SepaDirectDebitBlock />}
                <TermsAndConditions />
                <ComplianceCheckBlock />
              </>
            ) : (
              <>
                <OrganizationDetailsBlock />
                {uploadOnboardingDocumentsInExternalAppEnabled && (
                  <OnboardingDocumentsBlock />
                )}
                {onboardingComplianceStepWithInputEnabled &&
                  onboardingState.orgRepsAdditionalInfo.length > 0 && (
                    <RepresentativeBlock />
                  )}
                <TermsAndConditions />
                <ComplianceCheckBlock />
              </>
            )}
          </>
        )}
      </PageContent>
    </>
  );
};

export default withPageConfig(withOnboardingContext(OnboardingPage), {
  permission: 'onboarding-page:visit',
});
