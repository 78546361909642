import React from 'react';
import { isNil, omit, omitBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Grid, Typography } from 'elements';
import { OnboardingDocumentStatus } from 'services/constants';

const QandASection = () => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    state: { onboardingDocuments },
  } = useCreditAndComplianceContext();

  return (
    <>
      <Typography variant="h6" mb={1}>
        {t('int.customerDeclarations.qaSection')}
      </Typography>

      <Grid container component="ul" rowSpacing={2}>
        {onboardingDocuments
          .filter((doc) => doc.status === OnboardingDocumentStatus.SUBMITTED)
          .map((doc) => {
            const hasPredefinedDescription = !!t(
              `onboardingDocumentType.description.${doc.type.name}`,
              {
                defaultValue: false,
              }
            );

            return (
              <Grid key={doc.id} item xs={12} component="li">
                <Grid container rowSpacing={0.5}>
                  <Grid item display="flex" flexDirection="column">
                    {doc.customLabel ? (
                      <>
                        <Typography>{doc.customLabel}</Typography>
                        {doc.customLabelLocal && (
                          <Typography variant="caption">
                            {doc.customLabelLocal}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Trans
                        i18nKey={`onboardingDocumentType.${doc.type.name}`}
                        values={{
                          ...omit(doc.attributes, [
                            'fromMonthYear',
                            'toMonthYear',
                          ]),
                          ...(doc.attributes['fromMonthYear'] && {
                            fromMonthYear: moment(
                              doc.attributes.fromMonthYear
                            ).format('MMM yyyy'),
                          }),
                          ...(doc.attributes['toMonthYear'] && {
                            toMonthYear: moment(
                              doc.attributes.toMonthYear
                            ).format('MMM yyyy'),
                          }),
                        }}
                        components={{
                          b: <b />,
                          li: <li />,
                          ul: <ul />,
                        }}
                      />
                    )}
                  </Grid>

                  {hasPredefinedDescription && (
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <Trans
                          i18nKey={`onboardingDocumentType.description.${doc.type.name}`}
                          components={{
                            b: <b />,
                            br: <br />,
                            li: <li />,
                            ul: <ul />,
                          }}
                          values={{
                            ...omitBy(doc.attributes, isNil),
                            ...(doc.attributes?.date && {
                              date: moment(
                                doc.attributes.date,
                                'YYYY-MM-DD'
                              ).format('DD.MM.YYYY'),
                            }),
                            orgName: organization!.name,
                          }}
                        />
                      </Typography>
                    </Grid>
                  )}

                  {doc.description && (
                    <Grid item xs={12} display="flex" flexDirection="column">
                      <Typography variant="body2">{doc.description}</Typography>
                      {doc.descriptionLocal && (
                        <Typography variant="caption">
                          {doc.descriptionLocal}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  {!doc.description && doc.descriptionLocal && (
                    <Grid item xs={12}>
                      <Typography>{doc.descriptionLocal}</Typography>
                    </Grid>
                  )}

                  {doc.files.map((file) => (
                    <Grid key={file.id} item xs={12}>
                      <Typography
                        key={file.id}
                        sx={{
                          bgcolor: 'grey.200',
                          p: (theme) => theme.spacing(0.5),
                        }}
                      >
                        {file.name}
                      </Typography>
                    </Grid>
                  ))}

                  {doc.answer && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          bgcolor: 'grey.200',
                          p: (theme) => theme.spacing(0.5),
                        }}
                      >
                        {doc.answer}
                      </Typography>
                      {doc.answerLocal && (
                        <Typography
                          component="div"
                          sx={{
                            bgcolor: 'grey.200',
                            marginTop: 0.5,
                            p: (theme) => theme.spacing(0.5),
                          }}
                          variant="caption"
                        >
                          {doc.answerLocal}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  {!doc.answer && doc.answerLocal && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          bgcolor: 'grey.200',
                          p: (theme) => theme.spacing(0.5),
                        }}
                        variant="body1"
                      >
                        {doc.answerLocal}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      <Trans
                        i18nKey="int.customerDeclarations.providedByAndAt"
                        components={{ b: <b /> }}
                        values={{
                          providedBy: doc.updatedBy,
                          providedAt: moment(doc.updatedAt).format(
                            'DD MMM YYYY HH:mm'
                          ),
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default QandASection;
