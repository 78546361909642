import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { QueryParams } from 'domains/organization/pages/OrganizationsPage';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  getFilterChipText,
  PageFilterChips,
  PageFiltersComponent,
} from 'layout';
import { Partner, PartnerScope } from 'services/constants';
import FiltersDrawer from './FiltersDrawer';

const getPartners = (partners: Partner[] | null) => {
  const visiblePartners = partners?.length
    ? partners.filter((item) =>
        [
          PartnerScope.embeddedWallet,
          PartnerScope.fullyEmbedded,
          PartnerScope.pliantInternal,
          PartnerScope.whitelabel,
          PartnerScope.logoCard,
        ].includes(item.partnerScope)
      )
    : [];

  return sortBy(visiblePartners!, (v) => v.name.toLowerCase());
};

interface Props {
  isNCOPage: boolean;
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
}

const Filters = ({
  isNCOPage,
  params,
  selectedFiltersCount,
  setParam,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { partners },
  } = useGlobalState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const visiblePartners = getPartners(partners);

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => t(`orgStatuses.${item}`).toUpperCase())
      .join(', ');
  }, [params.status]);

  const partnerChipText = useMemo(
    () =>
      visiblePartners.find((item) => item.partnerId === params.partnerConfigId)
        ?.name || '',
    [params.status]
  );

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
      />

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {!!params.status.length && (
            <Tooltip title={statusChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`orgStatuses.${params.status[0]}`).toUpperCase(),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}
          {!!params.partnerConfigId && (
            <Chip
              label={partnerChipText}
              onDelete={() => setParam('partnerConfigId', '')}
            />
          )}
          {!!params.country && (
            <Chip
              label={t(`countries.${params.country}`)}
              onDelete={() => setParam('country', '')}
            />
          )}
          {!!params.currency && (
            <Chip
              label={params.currency}
              onDelete={() => setParam('currency', '')}
            />
          )}
          {!!params.accountGroup && (
            <Chip
              label={t(`int.accountGroupSelect.items.${params.accountGroup}`)}
              onDelete={() => setParam('accountGroup', '')}
            />
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
        isNCOPage={isNCOPage}
        partners={visiblePartners}
      />
    </>
  );
};

export default Filters;
