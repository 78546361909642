import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { CardIcon } from 'domains/card/components';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import {
  Box,
  Grid,
  LabeledValue,
  Paper,
  Typography,
  UsersIcon,
  WalletIcon,
} from 'elements';
import {
  CardNewType,
  Money,
  SubscriptionPlanParam,
  subscriptionPlanParams,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { formatMoney } from 'services/utils';
import EditValueDialog from './EditValueDialog';

const VirtualMultiOrSingleUseCardIcon = ({
  maxUsage,
}: {
  maxUsage: 1 | null;
}) => {
  const {
    state: { cardConfigSettings },
  } = useGlobalState();
  const cardConfigSetting = useMemo(
    () =>
      cardConfigSettings.find(
        (item) =>
          item.canBeIssued &&
          item.cardType === CardNewType.virtual &&
          item.maxUsage === maxUsage
      ),
    [cardConfigSettings, maxUsage]
  );

  return <CardIcon cardDesignId={cardConfigSetting?.cardDesignIds[0] || ''} />;
};

export const getIconFromParamKey = (key: SubscriptionPlanParam) => {
  switch (key) {
    case SubscriptionPlanParam.BASE_FEE:
    case SubscriptionPlanParam.ADDITIONAL_USER_FEE:
      return <WalletIcon sx={{ color: 'text.primary' }} />;
    case SubscriptionPlanParam.FREE_MEMBERS_COUNT:
    case SubscriptionPlanParam.MAX_ACTIVE_MEMBERS_COUNT:
      return <UsersIcon sx={{ color: 'text.primary' }} />;
    case SubscriptionPlanParam.MAX_VIRTUAL_CARDS_COUNT:
      return <VirtualMultiOrSingleUseCardIcon maxUsage={null} />;
    case SubscriptionPlanParam.MAX_SINGLE_USE_CARDS_COUNT:
      return <VirtualMultiOrSingleUseCardIcon maxUsage={1} />;
  }
};

const SubscriptionPlanParamsSection = () => {
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const [
    paramsToBeChanged,
    setParamsToBeChanged,
  ] = useState<SubscriptionPlanParam | null>(null);
  const {
    state: { subscriptionPlan },
  } = useGlobalState();
  const currencyDisplay = useCurrencyDisplayMode();

  const getValue = (amount: Money | number | null) => {
    if (typeof amount === 'number') {
      return amount;
    }
    if (!amount) return '∞';
    return formatMoney(amount, i18n.language, {
      currencyDisplay,
      fractionalPart: amount.value % 100 !== 0,
    });
  };

  return (
    <>
      <Box p={3}>
        <Typography variant="h6" mb={3}>
          {t(
            'int.subscriptionSettingsPage.subscriptionPlanParamsSection.title'
          )}
        </Typography>
        <Paper variant="outlined" sx={{ p: 3, mb: 2 }}>
          <Grid container columnSpacing={4} rowSpacing={2}>
            {subscriptionPlanParams.map((paramKey: SubscriptionPlanParam) => (
              <Grid key={paramKey} item xs={6}>
                <LabeledValue
                  label={t(
                    `int.subscriptionSettingsPage.subscriptionPlanParamsSection.options.${paramKey}`
                  )}
                  value={getValue(subscriptionPlan[paramKey])}
                  icon={getIconFromParamKey(paramKey)}
                  onEdit={
                    canUser('subscription-plan:update')
                      ? () => setParamsToBeChanged(paramKey)
                      : undefined
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>

      <EditValueDialog
        open={!!paramsToBeChanged}
        onClose={() => setParamsToBeChanged(null)}
        paramKey={paramsToBeChanged}
      />
    </>
  );
};

export default SubscriptionPlanParamsSection;
