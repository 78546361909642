import { useTranslation } from 'react-i18next';
import { Avatar, AvatarProps, Badge, Typography } from 'elements';

const generateLogoByName = (firstName: string, lastName: string) =>
  firstName.slice(0, 1).toUpperCase() + lastName.slice(0, 1).toUpperCase();

interface Props extends AvatarProps {
  firstName: string;
  lastName: string;
  size?: 'medium' | 'large';
}

export const PrivateExpenseAvatar = ({
  firstName,
  lastName,
  size = 'medium',
  sx = [],
  ...props
}: Props) => {
  const { t } = useTranslation();

  const renderAvatar = () => {
    if (size === 'large')
      return (
        <Avatar
          variant="rounded"
          sx={[
            {
              background: (theme) =>
                theme.variables.states.primary.transparentBackground,
              color: 'primary.main',
              height: 80,
              width: 80,
              borderRadius: 2,
              fontSize: '40px',
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          {...props}
        >
          {generateLogoByName(firstName, lastName)}
        </Avatar>
      );

    return (
      <Avatar
        variant="rounded"
        sx={[
          {
            background: (theme) =>
              theme.variables.states.primary.transparentBackground,
            color: 'primary.main',
            height: 24,
            width: 24,
            fontSize: '16px',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      >
        <Typography variant="caption" lineHeight="1">
          {generateLogoByName(firstName, lastName)}
        </Typography>
      </Avatar>
    );
  };

  return size === 'medium' ? (
    <Badge
      badgeContent={t('common.badge.privateExpense')}
      color="primary"
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      sx={{
        '.MuiBadge-badge': {
          height: '14px',
          minWidth: '14px',
          padding: '0 3px',
          fontSize: '8px',
          lineHeight: '13.333px',
        },
      }}
    >
      {renderAvatar()}
    </Badge>
  ) : (
    renderAvatar()
  );
};
