import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { BillPaymentTypeBadge } from 'domains/billing/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Box,
  StatusBadge,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import {
  BillPaymentBillPaymentType,
  BillPaymentType,
  OrganizationAccountStatementPayment,
  PartialPaymentStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import BillPaymentItemMenu from './BillPaymentItemMenu';

interface Props {
  data: OrganizationAccountStatementPayment;
  onRefresh?: () => void;
  isWide?: boolean;
}

const BillPaymentItem = ({ data, onRefresh, isWide = false }: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const canUser = useCanUser();
  const getCardAccountName = useCardAccountNameGetter();
  const isCardAccountVisible = cardAccounts.length > 1 && isWide;

  const renderDate = () => {
    if (data.type === BillPaymentBillPaymentType.billPayment) {
      const primaryDate = data.paymentDate || data.collectionDate;
      const defermentDays = data.defermentDays;

      return (
        <>
          <Typography variant="body2" color="text.primary">
            {primaryDate ? (
              <Tooltip
                placement="right"
                title={
                  data.paymentDate
                    ? t('billPaymentItem.paymentDate')
                    : t('billPaymentItem.plannedBookingDate')
                }
              >
                <span>{moment(primaryDate).format('D MMM YYYY')}</span>
              </Tooltip>
            ) : (
              '-'
            )}
          </Typography>
          {data.cutOffDate && (
            <Typography variant="caption" color="text.secondary">
              <Tooltip
                placement="right"
                title={t('billPaymentItem.cutOffDate')}
              >
                <span>{moment(data.cutOffDate).format('D MMM YYYY')}</span>
              </Tooltip>
              {!!defermentDays && (
                <Tooltip
                  title={t('billPaymentItem.nDaysDeferredTitle', {
                    number: defermentDays,
                  })}
                >
                  <Box component="span" ml={1}>
                    {t('billPaymentItem.nDaysDeferred_v2', {
                      number: defermentDays,
                    })}
                  </Box>
                </Tooltip>
              )}
            </Typography>
          )}
        </>
      );
    }
    if (
      data.type === BillPaymentBillPaymentType.prefundPayment ||
      data.type === BillPaymentBillPaymentType.billPaymentReversal ||
      data.type === BillPaymentBillPaymentType.directDebitReversalFee
    ) {
      return (
        <Typography variant="body2" color="text.primary">
          <Tooltip placement="right" title={t('billPaymentItem.bookingDate')}>
            <span>{moment(data.bookingDate).format('D MMM YYYY')}</span>
          </Tooltip>
        </Typography>
      );
    }
    if (data.type === BillPaymentBillPaymentType.payout) {
      const primaryDate = data.bookingDate || data.collectionDate;

      return (
        <>
          <Typography variant="body2" color="text.primary">
            {primaryDate ? (
              <Tooltip
                placement="right"
                title={
                  data.bookingDate
                    ? t('billPaymentItem.bookingDate')
                    : t('billPaymentItem.plannedBookingDate')
                }
              >
                <span>{moment(primaryDate).format('D MMM YYYY')}</span>
              </Tooltip>
            ) : (
              '-'
            )}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            <Tooltip placement="right" title={t('billPaymentItem.requestDate')}>
              <span>{moment(data.requestDate).format('D MMM YYYY')}</span>
            </Tooltip>
          </Typography>
        </>
      );
    }

    return null;
  };

  const renderTransferType = (
    type: BillPaymentBillPaymentType,
    transferType: BillPaymentType
  ) => {
    if (
      type === BillPaymentBillPaymentType.billPaymentReversal ||
      type === BillPaymentBillPaymentType.directDebitReversalFee
    ) {
      return (
        <StatusBadge
          variant="filled"
          color="error"
          label={t('billPaymentItem.ddReturn')}
        />
      );
    }
    return <BillPaymentTypeBadge type={transferType} />;
  };

  const renderExtraInfo = () => {
    if (
      data.type === BillPaymentBillPaymentType.billPayment &&
      !!data.retryAttempt
    )
      return (
        <>
          <Typography variant="body2" color="text.primary" noWrap>
            <Trans
              i18nKey="billPaymentItem.retryForBillPayment"
              values={{
                count: data.retryAttempt,
                reference: data.initialPaymentPublicId,
              }}
            />
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {moment(data.initialPaymentPaymentDate).format('D MMM YYYY')}
          </Typography>
        </>
      );
    if (
      data.type === BillPaymentBillPaymentType.prefundPayment ||
      data.type === BillPaymentBillPaymentType.payout
    )
      return (
        <>
          <Tooltip title={data.counterPartyBankName}>
            <Typography variant="body2" color="text.primary" noWrap>
              {data.counterPartyBankName}
            </Typography>
          </Tooltip>
          <Typography
            component="div"
            variant="caption"
            color="text.secondary"
            noWrap
          >
            {data.counterPartyIban}
          </Typography>
        </>
      );
    if (
      data.type === BillPaymentBillPaymentType.billPaymentReversal ||
      data.type === BillPaymentBillPaymentType.directDebitReversalFee
    )
      return (
        <>
          <Typography variant="body2" color="text.primary" noWrap>
            {t('billPaymentItem.billPayment')} {data.originalPaymentReference}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {moment(data.originalPaymentPaymentDate).format('D MMM YYYY')}
          </Typography>
        </>
      );
    return null;
  };

  const renderCardAccountName = () => {
    const name = getCardAccountName(data.cardAccountId);

    return name ? (
      <Tooltip title={name}>
        <Typography variant="body2" color="text.primary" noWrap>
          {name}
        </Typography>
      </Tooltip>
    ) : null;
  };

  const renderAmount = () => {
    if (
      canUser('delayed-payment:retry') &&
      data.status === PartialPaymentStatus.delayed
    ) {
      return (
        <>
          <Typography
            variant="caption"
            color="error.main"
            textTransform="uppercase"
          >
            {t(`batchPaymentStatuses.${data.status}`)}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            <FormatMoney value={data.amount} fractionalPart positiveSign />
          </Typography>
        </>
      );
    }
    return (
      <Typography variant="body2" color="text.primary" noWrap>
        <FormatMoney value={data.amount} fractionalPart positiveSign />
      </Typography>
    );
  };

  const getAllCellsSizesInPercents = () => {
    if (isWide) {
      return isCardAccountVisible
        ? [15, 17, 17, 17, 17, 12.5, 4.5]
        : [15, 17, 27, 23, 0, 13, 5];
    }
    return [17, 18, 27, 24, 0, 14, 0];
  };
  const getCellWidth = (cellIndex: number) => {
    const allCellsSizesInPercent = getAllCellsSizesInPercents();
    return `${allCellsSizesInPercent[cellIndex]}%`;
  };

  return (
    <TableRow>
      <TableCell sx={{ border: 0, width: getCellWidth(0) }}>
        {renderDate()}
      </TableCell>
      <TableCell
        sx={{ border: 0, width: getCellWidth(1), textAlign: 'center' }}
        padding="none"
      >
        {renderTransferType(data.type, data.transferType)}
      </TableCell>
      <TableCell sx={{ border: 0, width: getCellWidth(2), maxWidth: 0 }}>
        <Tooltip
          title={
            t(`billPaymentBillPaymentType.${data.type}`) +
            (data.purpose ? `: ${data.purpose}` : '')
          }
        >
          <Typography
            component="div"
            variant="body2"
            color="text.primary"
            noWrap
          >
            <span>{t(`billPaymentBillPaymentType.${data.type}`)}</span>
            {data.purpose ? <span>{`: ${data.purpose}`}</span> : null}
          </Typography>
        </Tooltip>

        <Typography variant="caption" color="text.secondary">
          {data.reference}
        </Typography>
        {canUser('delayed-payment:retry') && data.returnReasonCode && (
          <Tooltip
            title={t('int.billPaymentItem.failReason', {
              message: t(`int.returnReasonCodes.${data.returnReasonCode}`),
              code: data.returnReasonCode,
            })}
          >
            <Typography
              component="div"
              variant="caption"
              color="error.main"
              noWrap
            >
              {t('int.billPaymentItem.failReason', {
                message: t(`int.returnReasonCodes.${data.returnReasonCode}`),
                code: data.returnReasonCode,
              })}
            </Typography>
          </Tooltip>
        )}
      </TableCell>
      <TableCell sx={{ border: 0, width: getCellWidth(3), maxWidth: 0 }}>
        {renderExtraInfo()}
      </TableCell>
      {isCardAccountVisible && (
        <TableCell sx={{ border: 0, width: getCellWidth(4), maxWidth: 0 }}>
          {renderCardAccountName()}
        </TableCell>
      )}
      <TableCell sx={{ border: 0, width: getCellWidth(5) }} align="right">
        {renderAmount()}
      </TableCell>
      {isWide && (
        <TableCell
          sx={{ border: 0, width: getCellWidth(6) }}
          align="right"
          padding="none"
        >
          <BillPaymentItemMenu payment={data} onUpdate={() => onRefresh?.()} />
        </TableCell>
      )}
    </TableRow>
  );
};

export default BillPaymentItem;
