import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ForwardUnmatchedReceiptsInterval } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, validateEmail } from 'services/utils';

type ChangeEmailDialogForm = {
  email: string;
};

export interface Props extends DialogProps {
  forwardingEmail: string | null;
  open: boolean;
  onClose: () => void;
}

const ChangeUnmatchedReceiptForwardingDialog = ({
  forwardingEmail,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { organization, receiptsSettings },
  } = useGlobalState();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<ChangeEmailDialogForm>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      email: forwardingEmail || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const email = values.email.trim();
        const {
          organizationId,
          ...updatedReceiptsSettingsPayload
        } = receiptsSettings!;

        const updatedReceiptsSettings = await api.updateReceiptsSettings(
          organization!.id,
          {
            ...updatedReceiptsSettingsPayload,
            forwardUnmatchedReceiptsToEmail: email,
            forwardUnmatchedReceipts: true,
            forwardUnmatchedReceiptsInterval:
              ForwardUnmatchedReceiptsInterval.immediately,
          }
        );

        dispatch({
          type: 'SET_USER_DATA',
          payload: {
            receiptsSettings: updatedReceiptsSettings,
          },
        });
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email || ''
  );

  const isSubmitDisabled =
    (receiptsSettings!.forwardUnmatchedReceipts &&
      formik.values.email === formik.initialValues.email) ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('receiptManagementSubPage.unmatchedReceiptForwardingDialog.title')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="change-email-unmatched-receipt-forwarding-form"
          noValidate
        >
          <TextField
            label={t(
              'receiptManagementSubPage.unmatchedReceiptForwardingDialog.emailLabel'
            )}
            {...formik.getFieldProps('email')}
            disabled={formik.isSubmitting}
            error={!!invalidEmailError || !!emailTooLongError}
            helperText={invalidEmailError || emailTooLongError}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="change-email-unmatched-receipt-forwarding-form"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ChangeUnmatchedReceiptForwardingDialog);
