import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Box } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useLocoiaMappedName from 'hooks/useLocoiaMappedName';
import { PageContent, PageHeader, PageTitle, Tab, Tabs } from 'layout';
import { FeatureModuleKey } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import BmdSubPage from './BmdSubPage';
import CardAccountsSubPage from './CardAccountsSubPage';
import CategoriesSubPage from './CategoriesSubPage';
import CodatSubPage from './CodatSubPage';
import DatevSubPage from './DatevSubPage';
import GeneralSubPage from './GeneralSubPage';
import LocoiaSubPage from './LocoiaSubPage';
import ProjectsSubPage from './ProjectsSubPage';
import SuppliersSubPage from './SuppliersSubPage';
import TeamsSubPage from './TeamsSubPage';
import VatRatesSubPage from './VatRatesSubPage';

const costUnitCentersTab = 'const-unit-centers';

const AccountingPage = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const canUser = useCanUser();
  const {
    state: { accountingSettings, featureModules },
  } = useGlobalState();
  const { datevExportEnabled, suppliersEnabled } = useFlags();
  const locoiaMappedName = useLocoiaMappedName();

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [pathname]);

  const isProjectsPageAllowed =
    accountingSettings!.projectEnabled && canUser('projects-sub-page:visit');

  const isTeamsPageAllowed =
    featureModules.TEAMS &&
    accountingSettings!.costCenterEnabled &&
    canUser('teams-page:visit');

  const getTabValue = useCallback(() => {
    const path = pathname.replace(/\/$/, '');
    if ([`${url}/projects`, `${url}/teams`].includes(path))
      return costUnitCentersTab;

    return path;
  }, [url, pathname]);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('settingsAccountingPage.title')}
          suptitle={t('navigation.settings.title')}
        />
      </PageHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={5}>
        <Tabs aria-label="Accounting page tabs" value={getTabValue()}>
          {canUser('accounting-sub-page:visit') && (
            <Tab
              component={Link}
              value={url}
              to={url}
              label={t('settingsAccountingPage.nav.general')}
            />
          )}

          {accountingSettings!.subcategoryEnabled &&
            canUser('categories-sub-page:visit') && (
              <Tab
                component={Link}
                value={`${url}/categories`}
                to={`${url}/categories`}
                label={t('settingsAccountingPage.nav.glAccounts')}
                data-intercom-target="gl-accounts-sub-tab"
              />
            )}

          {accountingSettings!.vatRateEnabled &&
            canUser('vat-rates-sub-page:visit') && (
              <Tab
                component={Link}
                value={`${url}/vat-rates`}
                to={`${url}/vat-rates`}
                label={t('settingsAccountingPage.nav.vatRates')}
                data-intercom-target="vat-rates-sub-tab"
              />
            )}

          {suppliersEnabled &&
            accountingSettings!.supplierEnabled &&
            canUser('suppliers-sub-page:visit') && (
              <Tab
                component={Link}
                value={`${url}/suppliers`}
                to={`${url}/suppliers`}
                label={t('settingsAccountingPage.nav.creditors')}
                data-intercom-target="suppliers-sub-tab"
              />
            )}

          {(isProjectsPageAllowed || isTeamsPageAllowed) && (
            <Tab
              component={Link}
              value={costUnitCentersTab}
              to={isTeamsPageAllowed ? `${url}/teams` : `${url}/projects`}
              label={t('settingsAccountingPage.nav.costCenterUnits')}
              data-intercom-target="cost-centers-units-sub-tab"
            />
          )}

          {canUser('card-accounts-sub-page:visit') &&
            (featureModules.MULTI_CARD_ACCOUNT ||
              featureModules.MULTI_CURRENCY_BILLING) && (
              <Tab
                component={Link}
                value={`${url}/accounts`}
                to={`${url}/accounts`}
                label={t('settingsAccountingPage.nav.accounts')}
                data-intercom-target="accounts-sub-tab"
              />
            )}

          {datevExportEnabled &&
            canUser('datev-sub-page:visit', accountingSettings) && (
              <Tab
                component={Link}
                value={`${url}/datev`}
                to={`${url}/datev`}
                label={t('settingsAccountingPage.nav.DATEV')}
                data-intercom-target="datev-sub-tab"
              />
            )}

          {canUser('bmd-sub-page:visit', accountingSettings) && (
            <Tab
              component={Link}
              value={`${url}/bmd`}
              to={`${url}/bmd`}
              label={accountingSettings!.accountingSystemName}
            />
          )}

          {canUser('locoia-sub-page:visit', accountingSettings) && (
            <Tab
              component={Link}
              value={`${url}/locoia`}
              to={`${url}/locoia`}
              label={locoiaMappedName}
              data-intercom-target="locoia-tab"
            />
          )}

          {canUser('accounting-system-subpage:visit', accountingSettings) && (
            <Tab
              component={Link}
              value={`${url}/accounting-system`}
              to={`${url}/accounting-system`}
              label={accountingSettings!.accountingSystemName}
            />
          )}
        </Tabs>
      </Box>

      <PageContent
        ref={contentRef}
        pt={5}
        display="flex"
        flexDirection="column"
      >
        <Switch>
          <Route exact path={path} component={GeneralSubPage} />
          <Route path={`${path}/categories`} component={CategoriesSubPage} />
          <Route path={`${path}/vat-rates`} component={VatRatesSubPage} />
          <Route path={`${path}/projects`} component={ProjectsSubPage} />
          <Route path={`${path}/teams`} component={TeamsSubPage} />
          <Route path={`${path}/suppliers`} component={SuppliersSubPage} />
          <Route path={`${path}/accounts`} component={CardAccountsSubPage} />
          <Route path={`${path}/datev`} component={DatevSubPage} />
          <Route path={`${path}/bmd`} component={BmdSubPage} />
          <Route path={`${path}/locoia`} component={LocoiaSubPage} />
          <Route path={`${path}/accounting-system`} component={CodatSubPage} />
        </Switch>
      </PageContent>
    </>
  );
};

export default withPageConfig(AccountingPage, {
  featureModule: FeatureModuleKey.accountingFeatures,
});
