import React, { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlLabelTooltipIcon,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import { FeatureModuleKey, SubscriptionPlanType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, mapFeatureModules } from 'services/utils';

const INTERNAL_MODULE_KEYS = [
  FeatureModuleKey.memberManagement,
  FeatureModuleKey.cardholderManagement,
  FeatureModuleKey.travelPurchasingCards,
  FeatureModuleKey.customCardholderName,
  FeatureModuleKey.logoCards,
  FeatureModuleKey.accountingFeatures,
  FeatureModuleKey.integrationsPage,
];

// Modules that are only shown for the partner organizations
const PARTNER_INTERNAL_MODULE_KEYS = [
  FeatureModuleKey.memberManagement,
  FeatureModuleKey.cardholderManagement,
  FeatureModuleKey.integrationsPage,
];

interface InternalModuleToggleProps
  extends Pick<
    FormControlLabelProps,
    'label' | 'checked' | 'onChange' | 'disabled'
  > {
  title?: React.ReactNode;
}

const InternalModuleToggle = ({
  title,
  ...props
}: InternalModuleToggleProps) => {
  return (
    <Tooltip title={title} placement="bottom-end">
      <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
        <FormControl fullWidth>
          <FormControlLabel
            {...props}
            control={<Switch size="small" />}
            labelPlacement="start"
            sx={{ py: '10px', mr: 0 }}
          />
        </FormControl>
      </Paper>
    </Tooltip>
  );
};

interface State {
  isOmrFeatureEnabled: boolean;
  isLoading: boolean;
  isConfirmationDialogOpen: boolean;
}

const InternalModulesSection = () => {
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    dispatch,
    state: {
      organization,
      featureModules,
      featureModulesWithData,
      subscriptionPlan,
    },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isOmrFeatureEnabled: organization!.omrFeatureEnabled,
    isLoading: false,
    isConfirmationDialogOpen: false,
  });
  const confirmDialogRef = useRef({
    title: '',
    description: '',
    onSuccess: () => {},
  });

  const internalModuleKeys = useMemo(
    () =>
      isPartnerBasedSource(organization!.partnerId)
        ? INTERNAL_MODULE_KEYS
        : INTERNAL_MODULE_KEYS.filter(
            (key) => !PARTNER_INTERNAL_MODULE_KEYS.includes(key)
          ),
    [featureModulesWithData]
  );

  const toggleInternalFeatureModule = async (
    feature: FeatureModuleKey,
    enabled: boolean
  ) => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.toggleInternalFeatureModule(organization!.id, {
        feature,
        enabled,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: mapFeatureModules(data),
      });
      const {
        cardConfigs: cardConfigSettings,
      } = await api.getCardConfigSettings(organization!.id);
      dispatch({
        type: 'SET_CARD_CONFIG_SETTINGS',
        payload: { cardConfigSettings },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConfirmationDialogOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const confirmInternalFeatureModuleToggle = (key: FeatureModuleKey) => {
    const action = featureModules[key] ? 'deactivate' : 'activate';
    const moduleName = t(
      `int.generalTermsPage.internalModulesSection.modules.${key}.name`
    );
    const customDescriptionKey = `int.generalTermsPage.internalModulesSection.modules.${key}.${action}ConfirmationDescription`;

    confirmDialogRef.current = {
      title: t(
        `int.generalTermsPage.internalModulesSection.${action}ConfirmationTitle`,
        { moduleName }
      ),
      description: t(
        i18n.exists(customDescriptionKey)
          ? customDescriptionKey
          : `int.generalTermsPage.internalModulesSection.${action}ConfirmationDescription`,
        { moduleName, orgName: organization!.name }
      ),
      onSuccess: () => toggleInternalFeatureModule(key, !featureModules[key]),
    };

    setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }));
  };

  const isModuleDisabled = (key: FeatureModuleKey) => {
    const isDisabled =
      !canUser('internal-feature-module:toggle') ||
      featureModulesWithData[key].disabledByPartner;

    if (key === FeatureModuleKey.customCardholderName) {
      return isDisabled || !featureModules.TRAVEL_PURCHASING_CARDS;
    }

    return isDisabled;
  };

  const toggleInsurance = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.updateInsuranceFlag(
        organization!.id,
        !subscriptionPlan.insuranceEnabled
      );
      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: { subscriptionPlan: data },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConfirmationDialogOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const confirmInsuranceToggle = () => {
    const action = subscriptionPlan.insuranceEnabled
      ? 'deactivate'
      : 'activate';

    confirmDialogRef.current = {
      title: t(
        `int.generalTermsPage.internalModulesSection.insuranceEnabled.${action}ConfirmationTitle`
      ),
      description: t(
        `int.generalTermsPage.internalModulesSection.insuranceEnabled.${action}ConfirmationDescription`
      ),
      onSuccess: toggleInsurance,
    };

    setState((prevState) => ({ ...prevState, isConfirmationDialogOpen: true }));
  };

  const toggleOmrFeature = async (e: SyntheticEvent, checked: boolean) => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isOmrFeatureEnabled: checked,
        isLoading: true,
      }));
      await api.toggleOmrFeature(organization!.id, checked);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isOmrFeatureEnabled: !checked,
        isLoading: false,
      }));
      logError(error);
    }
  };

  return (
    <>
      <ContentContainer pt={2} pb={3}>
        <Typography variant="h6" mb={3}>
          {t('int.generalTermsPage.internalModulesSection.title')}
        </Typography>

        <InternalModuleToggle
          label={t(
            'int.generalTermsPage.internalModulesSection.insuranceEnabled.label'
          )}
          checked={subscriptionPlan.insuranceEnabled}
          onChange={confirmInsuranceToggle}
          disabled={
            subscriptionPlan.type === SubscriptionPlanType.starter ||
            !canUser('subscription-plan:update')
          }
        />

        {internalModuleKeys.map((key) => (
          <InternalModuleToggle
            key={key}
            label={
              <Box display="inline-flex" alignItems="center" component="span">
                {t(
                  `int.generalTermsPage.internalModulesSection.modules.${key}.name`
                )}
                <FormControlLabelTooltipIcon
                  title={t(
                    `int.generalTermsPage.internalModulesSection.modules.${key}.tooltip`
                  )}
                />
              </Box>
            }
            title={
              featureModulesWithData[key].disabledByPartner &&
              t(
                'int.generalTermsPage.internalModulesSection.disabledOnPartnerLevelTooltip'
              )
            }
            checked={featureModules[key]}
            onChange={() => confirmInternalFeatureModuleToggle(key)}
            disabled={isModuleDisabled(key)}
          />
        ))}

        <InternalModuleToggle
          label={t('int.generalTermsPage.internalModulesSection.omrFeature')}
          checked={state.isOmrFeatureEnabled}
          onChange={toggleOmrFeature}
          disabled={!canUser('omr-feature:toggle')}
        />
      </ContentContainer>

      <Divider />

      <ConfirmDialog
        open={state.isConfirmationDialogOpen}
        loading={state.isLoading}
        title={confirmDialogRef.current.title}
        description={confirmDialogRef.current.description}
        onSuccess={confirmDialogRef.current.onSuccess}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmationDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default InternalModulesSection;
