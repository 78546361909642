import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import {
  Box,
  InfoIcon,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from 'elements';
import { Card, CardSimpleStatus } from 'services/constants';
import {
  formatMoney,
  getLimitPercentage,
  getMoneyWithOppositeSign,
  isCardVoid,
} from 'services/utils';

interface Props {
  card: Card;
}

const CardLimit = ({ card }: Props) => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();

  const { limitPercentage, isPercentageLow } = getLimitPercentage({
    availableLimit: card.availableLimit,
    limit: card.limit,
  });

  const isCardBalanceHidden =
    card.simpleStatus === CardSimpleStatus.requested ||
    card.simpleStatus === CardSimpleStatus.pending ||
    isCardVoid(card);

  return isCardBalanceHidden ? (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 1.5, 2, 2),
      })}
    >
      <Typography variant="caption" color="text.secondary" noWrap>
        {t('cardLimit.limit')}
      </Typography>
      <Tooltip
        title={t(`cardLimitFrequency.tooltips.${card.limitRenewFrequency}`)}
      >
        <InfoIcon
          sx={{ width: 16, height: 16, mx: 0.5, color: 'text.secondary' }}
        />
      </Tooltip>
      <Typography
        variant="body2"
        flexShrink={0}
        ml="auto"
        data-test-id="card-limit"
      >
        <Trans
          i18nKey={`cardLimitFrequency.limitWithFrequencies.${card.limitRenewFrequency}`}
          values={{
            limit: formatMoney(card.limit, i18n.language, {
              currencyDisplay,
              fractionalPart: true,
            }),
          }}
        />
      </Typography>
    </Paper>
  ) : (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography variant="caption" color="text.secondary">
            {t('cardLimit.available')}
          </Typography>
          <Typography
            variant="body2"
            color={isPercentageLow ? 'warning.main' : 'text.primary'}
          >
            <FormatMoney value={card.availableLimit} fractionalPart />
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box display="flex" alignItems="center">
            <Tooltip
              title={t(
                `cardLimitFrequency.tooltips.${card.limitRenewFrequency}`
              )}
            >
              <InfoIcon
                sx={{ width: 16, height: 16, mx: 0.5, color: 'text.secondary' }}
              />
            </Tooltip>
            <Typography variant="caption" color="text.secondary">
              {t('cardLimit.limit')}
            </Typography>
          </Box>
          {(card.requestedLimit || card.requestedLimitRenewFrequency) && (
            <Typography variant="caption2" component="div" color="info.main">
              <Trans
                i18nKey={`cardLimitFrequency.limitWithFrequencies.${
                  card.requestedLimitRenewFrequency || card.limitRenewFrequency
                }`}
                values={{
                  limit: formatMoney(
                    card.requestedLimit || card.limit,
                    i18n.language,
                    { currencyDisplay, fractionalPart: true }
                  ),
                }}
              />
            </Typography>
          )}
          <Typography variant="body2">
            <Trans
              i18nKey={`cardLimitFrequency.limitWithFrequencies.${card.limitRenewFrequency}`}
              values={{
                limit: formatMoney(card.limit, i18n.language, {
                  currencyDisplay,
                  fractionalPart: true,
                }),
              }}
            />
          </Typography>
        </Box>
      </Box>

      <LinearProgress
        value={limitPercentage}
        color={isPercentageLow ? 'warning' : 'primary'}
        size="small"
        sx={{ mt: 1, mb: 2 }}
      />

      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box>
          <Typography variant="caption" component="div" color="text.secondary">
            {t('cardLimit.spendingBalance')}
          </Typography>
          <Typography variant="body2">
            <FormatMoney
              value={getMoneyWithOppositeSign(card.balance)}
              fractionalPart
            />
          </Typography>
        </Box>
        <Box textAlign="right">
          <Typography variant="caption" component="div" color="text.secondary">
            {t('cardLimit.reset')}
          </Typography>
          <Typography variant="body2">
            {card.limitRenewDate
              ? moment.utc(card.limitRenewDate).format('DD.MM.YYYY')
              : '-'}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default CardLimit;
