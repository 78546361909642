import { useGlobalState } from 'context/GlobalState';
import { isTxCustomFieldVisible } from 'domains/transaction/utils';
import { Box } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  AccountingTransaction,
  CustomFieldStatus,
  CustomField as CustomFieldType,
  TxCustomField,
  UpdateAccTxnCustomFieldPayload,
} from 'services/constants';
import CustomField from './CustomField';

export type CustomFieldExtended = Omit<CustomFieldType, 'defaultValue'> & {
  defaultValue: string;
  createNewField: boolean;
  automatched: boolean;
};

interface TxCustomFieldObject {
  [id: string]: TxCustomField;
}

const getFieldsToShow = (
  transactionCustomFields: CustomFieldType[],
  fields: AccountingTransaction['txnCustomFieldIdToField'],
  disabled: boolean,
  isAdminApp: boolean
) => {
  if (!transactionCustomFields.length) return [];

  const transactionCustomFieldsByApp = transactionCustomFields.filter((item) =>
    isTxCustomFieldVisible(item, isAdminApp)
  );

  return transactionCustomFieldsByApp.reduce((result, field) => {
    const accTxCustomFieldObject = (fields as TxCustomFieldObject)?.[field.id];

    // Show only active fields or fields from acc tx response with values
    if (
      (field.status === CustomFieldStatus.active && !disabled) ||
      accTxCustomFieldObject?.value
    ) {
      return [
        ...result,
        {
          ...field,
          label: accTxCustomFieldObject?.label || field.label,
          defaultValue: accTxCustomFieldObject?.value || '',
          automatched: !!accTxCustomFieldObject?.automatched,
          // if field is not provided in acc tx, then we should use "create" endpoint
          createNewField: !accTxCustomFieldObject,
        },
      ];
    }

    return result;
  }, [] as CustomFieldExtended[]);
};

interface Props {
  fields: AccountingTransaction['txnCustomFieldIdToField'];
  disabled: boolean;
  organizationId: string;
  cardId: string;
  onUpdate: (
    data: UpdateAccTxnCustomFieldPayload,
    createNewField: boolean
  ) => void;
}

// no need to check permissions as we need the same check as for fetching "transactionCustomFields".
const CustomFields = ({
  fields,
  disabled,
  organizationId,
  cardId,
  onUpdate,
}: Props) => {
  const {
    state: { featureModules, transactionCustomFields },
  } = useGlobalState();
  const { isAdminApp } = useCurrentApp();

  const customFieldsArray = getFieldsToShow(
    transactionCustomFields,
    fields,
    disabled,
    isAdminApp
  );

  if (
    !featureModules.TRANSACTION_CUSTOM_FIELDS ||
    customFieldsArray.length === 0
  )
    return null;

  return (
    <>
      {customFieldsArray.map((field) => (
        <Box mb={1} key={field.id}>
          <CustomField
            id={field.id}
            field={field}
            disabled={disabled || field.status === CustomFieldStatus.inactive}
            organizationId={organizationId}
            cardId={cardId}
            onUpdate={onUpdate}
          />
        </Box>
      ))}
    </>
  );
};

export default CustomFields;
