import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, SettingsGroupBox } from 'layout';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  receiptNotNeededOrgLevel: boolean;
  isLoading: boolean;
}

const ReceiptNotNeededGroup = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { organization, receiptsSettings },
  } = useGlobalState();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    receiptNotNeededOrgLevel: receiptsSettings!.receiptNotNeeded,
    isLoading: false,
  });

  const onReceiptConfigChange = async (value: boolean) => {
    try {
      setState((prevState) => ({
        ...prevState,
        receiptNotNeededOrgLevel: value,
        isLoading: true,
      }));

      const {
        organizationId,
        ...updatedReceiptsSettingsPayload
      } = receiptsSettings!;

      const updatedReceiptsSettings = await api.updateReceiptsSettings(
        organization!.id,
        { ...updatedReceiptsSettingsPayload, receiptNotNeeded: value }
      );
      if (!mounted.current) return;

      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          receiptsSettings: updatedReceiptsSettings,
        },
      });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        receiptNotNeededOrgLevel: !value,
        isLoading: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <SettingsGroupBox>
      <ContentContainer>
        <Typography variant="h6" mb={1}>
          {t('receiptManagementSubPage.receiptNotNeeded.title')}
        </Typography>

        <Box mt={2}>
          <FormControl
            fullWidth
            disabled={!canUser('receipts-settings:change') || state.isLoading}
          >
            <FormControlLabel
              labelPlacement="start"
              label={t(
                'receiptManagementSubPage.receiptNotNeeded.switchReceiptNotNeeded'
              )}
              checked={state.receiptNotNeededOrgLevel}
              onChange={(_, checked: boolean) => onReceiptConfigChange(checked)}
              control={<Switch />}
            />
          </FormControl>
        </Box>
      </ContentContainer>
    </SettingsGroupBox>
  );
};

export default ReceiptNotNeededGroup;
