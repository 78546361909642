import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { getSubscriptionPlanName } from 'domains/organization/utils';
import { Alert, Box, Button, Grid, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { ContentContainer, SettingsGroupBox } from 'layout';
import {
  CardCount,
  CardSimpleStatus,
  CardType,
  SubscriptionPlanType,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getCardCount, getMoneyObject } from 'services/utils';
import UpgradePlanConfirmationDialog from './UpgradePlanConfirmationDialog';

interface Props {
  cardCount: CardCount;
}

const PlanSection = ({ cardCount }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { subscriptionPlan },
  } = useGlobalState();
  const [state, setState] = useState({
    isUpgradeDialogOpen: false,
  });

  const isInStarterPlan =
    subscriptionPlan.type === SubscriptionPlanType.starter;

  const singleUseCardCount = getCardCount(cardCount, CardType.singleUse);
  const activeSingleUseCardCount = getCardCount(cardCount, CardType.singleUse, [
    CardSimpleStatus.active,
  ]);
  const pendingOrRequestedSingleUseCardCount = getCardCount(
    cardCount,
    CardType.singleUse,
    [CardSimpleStatus.pending, CardSimpleStatus.requested]
  );

  const virtualCardCount = getCardCount(cardCount, CardType.virtual);
  const activeVirtualCardCount = getCardCount(cardCount, CardType.virtual, [
    CardSimpleStatus.active,
  ]);
  const pendingOrRequestedVirtualCardCount = getCardCount(
    cardCount,
    CardType.virtual,
    [CardSimpleStatus.pending, CardSimpleStatus.requested]
  );

  const membersLimitReached =
    subscriptionPlan.maxActiveMembersCount !== null &&
    subscriptionPlan.activeAndInvitedMembersCount >=
      subscriptionPlan.maxActiveMembersCount;
  const singleUseCardsLimitReached =
    singleUseCardCount === subscriptionPlan.maxSingleUseCardsCount;
  const virtualCardsLimitReached =
    virtualCardCount === subscriptionPlan.maxVirtualCardsCount;

  const renderKeyPlanTip = () => {
    if (
      membersLimitReached &&
      (singleUseCardsLimitReached || virtualCardsLimitReached)
    ) {
      return (
        <Box display="flex" mt={3}>
          <Alert color="warning">
            {t(
              'subscriptionPlanSettingsSubPage.planSection.membersAndCardsTip'
            )}
          </Alert>
        </Box>
      );
    } else if (membersLimitReached) {
      return (
        <Box display="flex" mt={3}>
          <Alert color="warning">
            {t('subscriptionPlanSettingsSubPage.planSection.membersTip')}
          </Alert>
        </Box>
      );
    } else if (singleUseCardsLimitReached || virtualCardsLimitReached) {
      return (
        <Box display="flex" mt={3}>
          <Alert color="warning">
            {t('subscriptionPlanSettingsSubPage.planSection.cardsTip')}
          </Alert>
        </Box>
      );
    }
    return null;
  };

  const getPlanDescriptionKey = () => {
    if (subscriptionPlan.type === SubscriptionPlanType.starter) {
      return 'starterDescription';
    }

    if (subscriptionPlan.type === SubscriptionPlanType.premium) {
      if (subscriptionPlan.freeMembersCount > 0) {
        return 'premiumDescriptionFull';
      }

      return 'premiumDescription';
    }

    if (
      typeof subscriptionPlan.baseFee?.value === 'number' &&
      typeof subscriptionPlan.additionalUserFee?.value === 'number'
    ) {
      if (
        subscriptionPlan.baseFee.value === 0 &&
        subscriptionPlan.additionalUserFee.value === 0
      ) {
        return 'noFees';
      }

      return 'baseFeeAndMemberFee';
    }

    if (typeof subscriptionPlan.baseFee?.value === 'number') {
      return 'baseFeeOnly';
    }

    if (typeof subscriptionPlan.additionalUserFee?.value === 'number') {
      return 'memberFeeOnly';
    }
  };

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6" mb={2}>
            {t('subscriptionPlanSettingsSubPage.planSection.title')}
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography variant="h4">
              {getSubscriptionPlanName(subscriptionPlan.type)}
            </Typography>
            {canUser('subscription-plan:upgrade') && (
              <Button
                sx={{ ml: 3 }}
                variant="outlined"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isUpgradeDialogOpen: true,
                  }))
                }
              >
                {t('subscriptionPlanSettingsSubPage.upgradePlan')}
              </Button>
            )}
          </Box>

          <Typography mt={1}>
            <Trans
              i18nKey={`subscriptionPlanSettingsSubPage.planSection.${getPlanDescriptionKey()}`}
              components={{
                ...(subscriptionPlan.additionalUserFee && {
                  additionalUserFee: (
                    <FormatMoney
                      value={subscriptionPlan.additionalUserFee}
                      fractionalPart={
                        subscriptionPlan.additionalUserFee.value % 100 !== 0
                      }
                    />
                  ),
                }),
                ...(subscriptionPlan.baseFee && {
                  baseFee: (
                    <FormatMoney
                      value={subscriptionPlan.baseFee}
                      fractionalPart={
                        subscriptionPlan.baseFee.value % 100 !== 0
                      }
                    />
                  ),
                }),
              }}
              values={{
                freeMembersCount: subscriptionPlan.freeMembersCount,
                partnerName,
              }}
            />
          </Typography>

          {[
            SubscriptionPlanType.light,
            SubscriptionPlanType.standard,
            SubscriptionPlanType.custom,
          ].includes(subscriptionPlan.type) ? (
            <Grid container mt={3}>
              <Grid item xs={4} pr={2}>
                <Typography variant="overline">
                  {t(
                    'subscriptionPlanSettingsSubPage.planSection.membersKeyPlan'
                  )}
                </Typography>
                <Box display="flex" alignItems="flex-end">
                  <Typography variant="h5">
                    {subscriptionPlan.activeMembersCount}
                  </Typography>
                  <Typography>
                    {subscriptionPlan.invitedMembersCount > 0 &&
                      ` (+${subscriptionPlan.invitedMembersCount})`}
                    &nbsp;/&nbsp;
                    {subscriptionPlan.maxActiveMembersCount
                      ? subscriptionPlan.maxActiveMembersCount
                      : t(
                          'subscriptionPlanSettingsSubPage.planSection.unlimited'
                        )}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4} pr={2}>
                <Typography variant="overline">
                  {t(
                    'subscriptionPlanSettingsSubPage.planSection.virtualCardsKeyPlan'
                  )}
                </Typography>
                <Box display="flex" alignItems="flex-end">
                  <Typography variant="h5">{activeVirtualCardCount}</Typography>
                  <Typography>
                    {pendingOrRequestedVirtualCardCount > 0 &&
                      ` (+${pendingOrRequestedVirtualCardCount})`}
                    &nbsp;/&nbsp;
                    {subscriptionPlan.maxVirtualCardsCount
                      ? subscriptionPlan.maxVirtualCardsCount
                      : t(
                          'subscriptionPlanSettingsSubPage.planSection.unlimited'
                        )}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4} pr={2}>
                <Typography variant="overline">
                  {t(
                    'subscriptionPlanSettingsSubPage.planSection.singleUseCardsKeyPlan'
                  )}
                </Typography>
                <Box display="flex" alignItems="flex-end">
                  <Typography variant="h5">
                    {activeSingleUseCardCount}
                  </Typography>
                  <Typography>
                    {pendingOrRequestedSingleUseCardCount > 0 &&
                      ` (+${pendingOrRequestedSingleUseCardCount})`}
                    &nbsp;/&nbsp;
                    {subscriptionPlan.maxSingleUseCardsCount
                      ? subscriptionPlan.maxSingleUseCardsCount
                      : t(
                          'subscriptionPlanSettingsSubPage.planSection.unlimited'
                        )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            // deprected plan types
            <Grid container mt={3}>
              {isInStarterPlan ? (
                <>
                  <Grid item xs={4} pr={2}>
                    <Typography variant="overline">
                      {t(
                        'subscriptionPlanSettingsSubPage.planSection.membersKeyPlan'
                      )}
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      {subscriptionPlan.maxActiveMembersCount ? (
                        <>
                          <Typography variant="h5">
                            {subscriptionPlan.activeMembersCount}
                          </Typography>
                          <Typography>
                            {subscriptionPlan.invitedMembersCount > 0 &&
                              ` (+${subscriptionPlan.invitedMembersCount})`}
                            &nbsp;/&nbsp;
                            {subscriptionPlan.maxActiveMembersCount}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="h5">
                          {t(
                            'subscriptionPlanSettingsSubPage.planSection.unlimited'
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={4} pr={2}>
                    <Typography variant="overline">
                      {t(
                        'subscriptionPlanSettingsSubPage.planSection.virtualCardsKeyPlan'
                      )}
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      {subscriptionPlan.maxVirtualCardsCount ? (
                        <>
                          <Typography variant="h5">
                            {activeVirtualCardCount}
                          </Typography>
                          <Typography>
                            {pendingOrRequestedVirtualCardCount > 0 &&
                              ` (+${pendingOrRequestedVirtualCardCount})`}
                            &nbsp;/&nbsp;{subscriptionPlan.maxVirtualCardsCount}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="h5">
                          {t(
                            'subscriptionPlanSettingsSubPage.planSection.unlimited'
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={4} pr={2}>
                    <Typography variant="overline">
                      {t(
                        'subscriptionPlanSettingsSubPage.planSection.singleUseCardsKeyPlan'
                      )}
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      {subscriptionPlan.maxSingleUseCardsCount ? (
                        <>
                          <Typography variant="h5">
                            {activeSingleUseCardCount}
                          </Typography>
                          <Typography>
                            {pendingOrRequestedSingleUseCardCount > 0 &&
                              ` (+${pendingOrRequestedSingleUseCardCount})`}
                            &nbsp;/&nbsp;
                            {subscriptionPlan.maxSingleUseCardsCount}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="h5">
                          {t(
                            'subscriptionPlanSettingsSubPage.planSection.unlimited'
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={4} pr={2}>
                    <Typography variant="overline">
                      {t(
                        'subscriptionPlanSettingsSubPage.planSection.membersKeyPlan'
                      )}
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      {subscriptionPlan.freeMembersCount ? (
                        <>
                          <Typography variant="h5">
                            {subscriptionPlan.activeMembersCount}
                          </Typography>
                          <Typography>
                            {subscriptionPlan.invitedMembersCount > 0 &&
                              ` (+${subscriptionPlan.invitedMembersCount})`}
                            &nbsp;/&nbsp;{subscriptionPlan.freeMembersCount}
                            &nbsp;
                            {t(
                              'subscriptionPlanSettingsSubPage.planSection.included'
                            )}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="h5">
                          {t(
                            'subscriptionPlanSettingsSubPage.planSection.unlimited'
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {!!subscriptionPlan.freeMembersCount &&
                    subscriptionPlan.activeMembersCount >
                      subscriptionPlan.freeMembersCount && (
                      <Grid item xs={4} pr={2}>
                        <Typography variant="overline">
                          {t(
                            'subscriptionPlanSettingsSubPage.planSection.memberFeesKeyPlan'
                          )}
                        </Typography>
                        <Box>
                          <Typography variant="h5">
                            <FormatMoney
                              value={getMoneyObject(
                                subscriptionPlan.baseFee.value +
                                  (subscriptionPlan.activeMembersCount -
                                    subscriptionPlan.freeMembersCount) *
                                    subscriptionPlan.additionalUserFee.value
                              )}
                              fractionalPart={
                                (subscriptionPlan.baseFee.value +
                                  (subscriptionPlan.activeMembersCount -
                                    subscriptionPlan.freeMembersCount) *
                                    subscriptionPlan.additionalUserFee.value) %
                                  100 !==
                                0
                              }
                            />
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                </>
              )}
            </Grid>
          )}

          {renderKeyPlanTip()}
        </ContentContainer>
      </SettingsGroupBox>

      <UpgradePlanConfirmationDialog
        open={state.isUpgradeDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUpgradeDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default PlanSection;
