import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import { ExpenseType, ProjectStatus } from 'services/constants';

interface Props {
  projectStatus: ProjectStatus;
  projectId: string | null;
  expenseType: ExpenseType;
}

const ProjectCell = ({ projectId, projectStatus, expenseType }: Props) => {
  const { t } = useTranslation();
  const {
    state: { projects, featureModules },
  } = useGlobalState();

  if (featureModules.PRIVATE_EXPENSE && expenseType === ExpenseType.private)
    return <DataGridCell>—</DataGridCell>;

  if (projectStatus === ProjectStatus.multiple)
    return (
      <DataGridCell sx={{ textTransform: 'uppercase' }}>
        {t('exportPage.multiple')}
      </DataGridCell>
    );
  if (projectStatus === ProjectStatus.missing)
    return <DataGridCell>—</DataGridCell>;

  const selectedProject = projects?.find((item) => item.id === projectId);
  if (!selectedProject) return null;

  return (
    <DataGridCell>
      {selectedProject.name}
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        noWrap
      >
        {selectedProject.costUnit}
      </Typography>
    </DataGridCell>
  );
};

export default ProjectCell;
