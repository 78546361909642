import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import {
  lazyLoadKlarnaLauncherScript,
  runKlarnaApiIntegration,
} from './helpers';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  sessionId?: string;
}

const ConnectBankAccountsThroughKlarnaDialog = ({
  onSuccess,
  onClose,
  sessionId,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [disableInteraction, setDisableInteraction] = useState(false);

  const handleClose = () => {
    if (disableInteraction) return;
    onClose();
  };

  const getKlarnaSession = () => {
    if (sessionId) return api.renewKlarnaConnection(sessionId);
    return api.connectBankAccountsThroughKlarna();
  };

  const connectBankAccounts = async () => {
    try {
      setIsLoading(true);
      if (!mounted.current) return;
      setDisableInteraction(true);
      const [session] = await Promise.all([
        getKlarnaSession(),
        lazyLoadKlarnaLauncherScript(),
      ]);
      if (!mounted.current) return;
      const isSuccessful = await runKlarnaApiIntegration(session.token);
      setDisableInteraction(false);

      if (isSuccessful) {
        await api.finishKlarnaSession(session.xs2aSessionId);
        if (!mounted.current) return;
        onSuccess();
        handleClose();
        return;
      }
      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setDisableInteraction(false);
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} onClose={handleClose}>
      <DialogTitle>
        {t('connectBankAccountsThroughKlarnaDialog.header')}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="flex-start" mb={3}>
          <Typography variant="h5" component="div" width={32} flexShrink={0}>
            1
          </Typography>
          <Typography variant="body1" ml={2}>
            {t('connectBankAccountsThroughKlarnaDialog.description.a')}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" mb={3}>
          <Typography variant="h5" component="div" width={32} flexShrink={0}>
            2
          </Typography>
          <Typography variant="body1" ml={2}>
            {t('connectBankAccountsThroughKlarnaDialog.description.b')}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" mb={3}>
          <Typography variant="h5" component="div" width={32} flexShrink={0}>
            3
          </Typography>
          <Typography variant="body1" ml={2}>
            {t('connectBankAccountsThroughKlarnaDialog.description.c')}
          </Typography>
        </Box>

        <Box display="flex" alignItems="flex-start" justifyContent="flex-end">
          <Typography variant="caption" mr={2}>
            {t('connectBankAccountsThroughKlarnaDialog.connectExplanation')}
          </Typography>
          <svg
            height="18px"
            viewBox="0 0 238.63 53.26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M42,0H30.25A30,30,0,0,1,18.11,24.23l-4.65,3.48,18,24.57H46.29L29.71,29.67A41.56,41.56,0,0,0,42,0Z" />
            <polygon points="0 52.28 12.03 52.28 12.03 0 0 0 0 52.28" />
            <polygon points="49.79 52.26 61.12 52.26 61.12 0.01 49.79 0.01 49.79 52.26" />
            <path d="m160.49 15.15c-4.32 0-8.41 1.34-11.14 5v-4.03h-10.78v36.14h10.91v-19c0-5.5 3.68-8.19 8.12-8.19 4.76 0 7.49 2.84 7.49 8.11v19.08h10.81v-23c0-8.41-6.69-14.13-15.41-14.13z" />
            <path d="M85.51,43.49a9.56,9.56,0,0,1-9.8-9.3,9.82,9.82,0,0,1,19.61,0,9.56,9.56,0,0,1-9.81,9.3Zm9.84-27.37v2.31A19.07,19.07,0,1,0,84.63,53.26,18.89,18.89,0,0,0,95.35,50v2.31h10.83V16.12Z" />
            <path d="M122.92,20.83V16.12H111.84V52.26h11.1V35.39c0-5.7,6.17-8.76,10.46-8.76h.12V16.12c-4.39,0-8.43,1.88-10.6,4.71Z" />
            <path d="M199.68,43.49a9.56,9.56,0,0,1-9.8-9.3,9.82,9.82,0,0,1,19.61,0,9.56,9.56,0,0,1-9.81,9.3Zm9.85-27.37v2.31a19.07,19.07,0,1,0,0,31.52v2.31h10.82V16.12Z" />
            <path d="m231.84 39.44a6.8 6.8 0 1 0 6.79 6.8 6.79 6.79 0 0 0-6.79-6.8z" />
          </svg>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={connectBankAccounts} disabled={isLoading}>
          {t('connectBankAccountsThroughKlarnaDialog.connect')}
        </Button>
      </DialogActions>
      {isLoading && <LoaderWithOverlay size={32} thickness={3} />}
    </Dialog>
  );
};

export default withDialogWrapper(ConnectBankAccountsThroughKlarnaDialog);
