import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { WarningContainer } from 'domains/transaction/components/TransactionDetails/style';
import { Alert, AlertTitle, Box, Tooltip, Typography } from 'elements';
import { TransactionReviewStatus } from 'services/constants';

interface Props {
  reviewStatus: TransactionReviewStatus | null;
  reviewComment: string | null;
  reviewedAt: string | null;
  reviewerFullName: string | null;
  reviewerEmail: string | null;
}

const TransactionReviewComment = ({
  reviewStatus,
  reviewComment,
  reviewedAt,
  reviewerFullName,
  reviewerEmail,
}: Props) => {
  const { t } = useTranslation();
  if (reviewStatus !== TransactionReviewStatus.flagged) return null;

  return (
    <WarningContainer>
      <Alert severity="error" sx={{ '.MuiAlert-message': { flex: 1 } }}>
        <AlertTitle>{t('transactionDetails.reviewComment.title')}</AlertTitle>
        {reviewComment}

        {reviewedAt && (
          <Box textAlign="right">
            <Tooltip title={reviewerEmail || ''}>
              <Typography variant="overline2" textAlign="right" mt={0.5}>
                {t('transactionDetails.reviewComment.info', {
                  name: reviewerFullName,
                  date: moment(reviewedAt).format('D MMM YYYY'),
                })}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </Alert>
    </WarningContainer>
  );
};

export default TransactionReviewComment;
