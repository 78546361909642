import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  Typography,
} from 'elements';
import { FilterDrawerClearButton } from 'layout';
import { ExpenseType, expenseTypes } from 'services/constants';

interface Props {
  value: ExpenseType | '';
  onChange: (type: ExpenseType | '') => void;
}

const ExpenseTypeFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();

  if (!featureModules.PRIVATE_EXPENSE) return null;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-expense-type"
        id="panel-expense-type-header"
      >
        <Typography>{t('filters.expenseType')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <FormGroup>
            {expenseTypes.map((type) => (
              <FormControlLabel
                key={type}
                checked={value === type}
                control={<Radio />}
                onChange={() => onChange(type)}
                label={t(`expenseTypes.${type}`)}
              />
            ))}
          </FormGroup>

          {!!value && <FilterDrawerClearButton onChange={() => onChange('')} />}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpenseTypeFilter;
