import { useTranslation } from 'react-i18next';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Button,
  Divider,
} from 'elements';
import { TransactionReviewStatus } from 'services/constants';

interface Props {
  onChange: (status: TransactionReviewStatus) => void;
}

const ReviewTransaction = ({ onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ActionBox>
        <ActionBoxTitle>
          {t('transactionDetails.reviewTransaction.title')}
        </ActionBoxTitle>

        {t('transactionDetails.reviewTransaction.description')}

        <ActionBoxActions>
          <Button
            color="error"
            onClick={() => onChange(TransactionReviewStatus.flagged)}
          >
            {t('transactionDetails.reviewTransaction.flagButton')}
          </Button>
          <Button
            color="success"
            onClick={() => onChange(TransactionReviewStatus.approved)}
          >
            {t('transactionDetails.reviewTransaction.approveButton')}
          </Button>
        </ActionBoxActions>
      </ActionBox>
      <Divider />
    </>
  );
};

export default ReviewTransaction;
