import React, { ReactNode } from 'react';
import { Paper, Typography } from '@mui/material';

const PaperLabeledValue = ({
  label,
  value,
}: {
  label: string;
  value: ReactNode;
}) => (
  <Paper
    variant="outlined"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      p: 2,
      mb: 2,
    }}
  >
    <Typography variant="caption" component="div" color="text.secondary" noWrap>
      {label}
    </Typography>
    <Typography variant="body2" component="div" flexShrink={0} ml="auto">
      {value}
    </Typography>
  </Paper>
);

export default PaperLabeledValue;
