import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { PaymentStatus } from 'services/constants';

const getColor = (status: PaymentStatus): StatusBadgeProps['color'] => {
  if (status === PaymentStatus.readyForPmt) return 'info';
  if (status === PaymentStatus.delayed) return 'warning';
  if (status === PaymentStatus.pending) return 'secondary';
  if (status === PaymentStatus.failed) return 'error';
  if (status === PaymentStatus.paid) return 'success';

  return 'default';
};

interface Props {
  status: PaymentStatus;
  count?: number;
}

const PaymentStatusBadge = ({ status, count }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      label={
        t(`paymentStatuses.${status}`).toUpperCase() +
        (isNumber(count) ? ` (${count})` : '')
      }
      variant="tinted"
      color={getColor(status)}
    />
  );
};

export default PaymentStatusBadge;
