import { Route, Switch } from 'react-router-dom';
import Auth0Callback from 'components/App/Auth0Callback';
import AppErrorOverlay from 'components/AppErrorOverlay';
import OnboardingPage from 'components/OnboardingPage';
import OnboardingRolePage from 'components/OnboardingRolePage';
import { useGlobalState } from 'context/GlobalState';
import {
  BillingPageRedirect,
  BillingPaymentsPage,
  BillingStatementsPage,
  StatementsPage,
} from 'domains/billing/pages';
import { CardAccountsPage, CardsPage, WalletPage } from 'domains/card/pages';
import {
  AdminProfilePage,
  CardholderProfilePage,
  ManagerTeamsPage,
  MembershipActivationPage,
  MembersPage,
  TeamsPage,
} from 'domains/member/pages';
import { OrganizationMerchantsPage } from 'domains/merchant';
import { DashboardPage, PliantEarthPage } from 'domains/organization/pages';
import {
  EmbeddedPartnerPage,
  PartnerAuthPage,
  PartnerAuthStatusPage,
} from 'domains/partner';
import { CardholderRewardsPage, RewardsPage } from 'domains/reward/pages';
import { Settings } from 'domains/settings';
import {
  AccountEntriesPage,
  AdminTransactionsPage,
  CardholderTeamTransactionsPage,
  CardholderTransactionsPage,
  ChTransactionsNeedsReviewPage,
  ExportPage,
  TransactionsNeedsReviewPage,
} from 'domains/transaction/pages';
import CardholderRedirect from './CardholderRedirect';
import OnboardingRedirect from './OnboardingRedirect';
import PartnerRedirect from './PartnerRedirect';
import RootRedirect from './RootRedirect';
import UserData from './UserData';
import AdminLayout from './layouts/AdminLayout';
import CardholderLayout from './layouts/CardholderLayout';
import PartnerLayout from './layouts/PartnerLayout';
import WhiteLabelLayout from './layouts/WhiteLabelLayout';
import {
  adminPaths,
  cardholderPaths,
  commonPaths,
  partnerPaths,
  whiteLabeledPaths,
} from './paths';

const commonPathsArray = Object.values(commonPaths);
const cardholderPathsArray = Object.values(cardholderPaths);

const adminPathsArray = Object.values(adminPaths);
const adminLayoutPathsArray = adminPathsArray.filter(
  (path) => path !== adminPaths.onboardingRole
);

const partnerPathsArray = Object.values(partnerPaths);
const partnerAuthPathsArray = [
  partnerPaths.partnerAuthPage,
  partnerPaths.partners,
];

const whiteLabeledPathsArray = Object.values(whiteLabeledPaths);
const whiteLabeledLayoutPathsArray = whiteLabeledPathsArray.filter(
  (path) => path !== whiteLabeledPaths.onboardingRole
);

const onboardedAdminPathsArray = adminLayoutPathsArray.filter(
  (path) =>
    ![
      adminPaths.onboarding,
      adminPaths.profile,
      adminPaths.members,
      adminPaths.memberDetails,
      adminPaths.partner,
      adminPaths.settings,
      adminPaths.settingsIntegrations,
    ].includes(path)
);

const onboardedWhiteLabeledPathsArray = whiteLabeledLayoutPathsArray.filter(
  (path) =>
    ![whiteLabeledPaths.onboarding, whiteLabeledPaths.profile].includes(path)
);

const allPathsArray = [
  '/',
  ...commonPathsArray,
  ...cardholderPathsArray,
  ...adminPathsArray,
  ...partnerPathsArray,
];

const CardholderRoutes = () => (
  <Route path={cardholderPathsArray}>
    <CardholderRedirect>
      <CardholderLayout>
        <Route
          path={cardholderPaths.profile}
          component={CardholderProfilePage}
        />
        <Route path={cardholderPaths.wallet} component={WalletPage} />
        <Route
          path={cardholderPaths.transactions}
          component={CardholderTransactionsPage}
        />
        <Route
          path={cardholderPaths.teamTransactions}
          component={CardholderTeamTransactionsPage}
        />
        <Route
          path={cardholderPaths.transactionsReview}
          component={ChTransactionsNeedsReviewPage}
        />
        <Route path={cardholderPaths.teams} component={ManagerTeamsPage} />
        <Route
          path={cardholderPaths.rewards}
          component={CardholderRewardsPage}
        />
      </CardholderLayout>
    </CardholderRedirect>
  </Route>
);

const AdminRoutes = () => (
  <Route path={adminPathsArray}>
    <Route path={adminPaths.onboardingRole} component={OnboardingRolePage} />
    <Route path={adminLayoutPathsArray}>
      <AdminLayout>
        <Route path={adminPaths.onboarding} component={OnboardingPage} />
        <Route path={adminPaths.profile} component={AdminProfilePage} />
        <Route path={adminPaths.members} component={MembersPage} />
        <Route path={adminPaths.teams} component={TeamsPage} />
        <Route path={adminPaths.partner} component={EmbeddedPartnerPage} />
        <Route path={adminPaths.settings} component={Settings} />
        <Route path={onboardedAdminPathsArray}>
          <OnboardingRedirect>
            <Route path={adminPaths.dashboard} component={DashboardPage} />
            <Route
              path={adminPaths.cardAccounts}
              component={CardAccountsPage}
            />
            <Route path={adminPaths.cards} component={CardsPage} />
            <Route
              path={adminPaths.transactions}
              component={AdminTransactionsPage}
            />
            <Route
              path={adminPaths.accounting}
              component={AccountEntriesPage}
            />
            <Route
              path={adminPaths.transactionsReview}
              component={TransactionsNeedsReviewPage}
            />
            <Route
              path={[
                adminPaths.export,
                adminPaths.exportQueue,
                adminPaths.exported,
              ]}
              component={ExportPage}
            />
            <Route path={adminPaths.statements} component={StatementsPage} />
            <Route path={adminPaths.billing} component={BillingPageRedirect} />
            <Route
              path={adminPaths.billingPayments}
              component={BillingPaymentsPage}
            />
            <Route
              path={adminPaths.billingStatements}
              component={BillingStatementsPage}
            />
            <Route path={adminPaths.rewards} component={RewardsPage} />
            <Route
              path={adminPaths.merchants}
              component={OrganizationMerchantsPage}
            />
            <Route path={adminPaths.pliantEarth} component={PliantEarthPage} />
          </OnboardingRedirect>
        </Route>
      </AdminLayout>
    </Route>
  </Route>
);

const PartnerRoutes = () => (
  <Route path={partnerPathsArray}>
    <PartnerLayout>
      <Switch>
        <Route
          path={partnerPaths.partnerIntegrationStatus}
          component={PartnerAuthStatusPage}
        />
        <Route path={partnerAuthPathsArray}>
          <PartnerRedirect>
            <Route
              path={partnerPaths.partnerAuthPage}
              component={PartnerAuthPage}
            />
          </PartnerRedirect>
        </Route>
      </Switch>
    </PartnerLayout>
  </Route>
);

const WhiteLabeledRoutes = () => (
  <Route path={whiteLabeledPathsArray}>
    <Switch>
      <Route
        path={whiteLabeledPaths.onboardingRole}
        component={OnboardingRolePage}
      />
      <Route path={whiteLabeledLayoutPathsArray}>
        <WhiteLabelLayout>
          <Route
            path={whiteLabeledPaths.onboarding}
            component={OnboardingPage}
          />
          <Route
            path={whiteLabeledPaths.partner}
            component={EmbeddedPartnerPage}
          />
          <Route
            path={whiteLabeledPaths.profile}
            component={AdminProfilePage}
          />
          <Route path={onboardedWhiteLabeledPathsArray}>
            <OnboardingRedirect>
              <Route path={whiteLabeledPaths.settings} component={Settings} />
              <Route path={whiteLabeledPaths.members} component={MembersPage} />
            </OnboardingRedirect>
          </Route>
        </WhiteLabelLayout>
      </Route>
    </Switch>
  </Route>
);

const MainRoutes = () => {
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();

  return isComplianceRiskWhiteLabelApp ? (
    <Switch>
      <Route path={[...whiteLabeledPathsArray, ...partnerPathsArray]}>
        <Route
          path={commonPaths.membershipActivation}
          component={MembershipActivationPage}
        />
        <PartnerRoutes />
        <WhiteLabeledRoutes />
      </Route>
      <Route path="*">
        <AppErrorOverlay errorCode="PAGE_NOT_FOUND" />
      </Route>
    </Switch>
  ) : (
    <>
      <Route
        path={commonPaths.membershipActivation}
        component={MembershipActivationPage}
      />
      <CardholderRoutes />
      <AdminRoutes />
      <PartnerRoutes />
    </>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path="/unauthorized">
        <AppErrorOverlay errorCode="UNAUTHORIZED" />
      </Route>
      <Route path="/callback">
        <Auth0Callback />
      </Route>
      <Route exact path={allPathsArray}>
        <UserData>
          <Route exact path="/" component={RootRedirect} />
          <MainRoutes />
        </UserData>
      </Route>
      <Route path="/email-not-verified">
        <AppErrorOverlay errorCode="EMAIL_NOT_VERIFIED" />
      </Route>
      <Route path="*">
        <AppErrorOverlay errorCode="PAGE_NOT_FOUND" />
      </Route>
    </Switch>
  );
};

export default Routes;
