import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as SecurityKeySvgIcon } from 'assets/icons/interface/securityKey.svg';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { PairMobileDeviceDialog } from 'domains/member/dialogs';
import {
  Box,
  Button,
  CircularProgress,
  DeviceMobileCameraIcon,
  ListItem,
  ListItemIcon,
  ListItemTextReversed,
  StatusBadge,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { Device, NetworkErrorCode } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface State {
  isLoading: boolean;
  pairedPhone: Device | null;
  hasError: boolean;
  isPairSecurityKeyDialogOpen: boolean;
  isPairMobileDeviceDialogOpen: boolean;
  isPairingSecurityKey: boolean;
  isUnpairDialogOpen: boolean;
  isUnpairing: boolean;
}

const CustomSecurityKeySvgIcon = styled(SecurityKeySvgIcon)`
  color: ${({ theme }) => theme.variables.success.main};
`;

const PairedDevice = () => {
  const {
    state: { featureModules, member },
  } = useGlobalState();
  const { securityKeyAuthenticationEnabled } = useFlags();
  const { t } = useTranslation();
  const {
    HELP_CENTER_PAIRING_MOBILE_DEVICE_URL,
    HELP_CENTER_PAIRING_SECURITY_KEY_URL,
  } = useUrls();
  const { logout } = useAuth0();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: false,
    pairedPhone: null,
    hasError: false,
    isPairSecurityKeyDialogOpen: false,
    isPairMobileDeviceDialogOpen: false,
    isPairingSecurityKey: false,
    isUnpairDialogOpen: false,
    isUnpairing: false,
  });
  const mounted = useMounted();
  const partnerName = usePartnerName();

  const toggleUnpairDialog = () =>
    setState((state) => ({
      ...state,
      isUnpairDialogOpen: !state.isUnpairDialogOpen,
    }));

  const togglePairSecurityKeyDialog = () =>
    setState((state) => ({
      ...state,
      isPairSecurityKeyDialogOpen: !state.isPairSecurityKeyDialogOpen,
    }));

  const togglePairMobileDeviceDialog = () =>
    setState((state) => ({
      ...state,
      isPairMobileDeviceDialogOpen: !state.isPairMobileDeviceDialogOpen,
    }));

  useEffect(() => {
    (async () => {
      try {
        const data = await api.getPairedDevice(member.id);
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          pairedPhone: data,
          isLoading: false,
        }));
      } catch (error) {
        if (!mounted.current) return;
        if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          setState((prevState) => ({
            ...prevState,
            hasError: true,
            isLoading: false,
          }));
          logError(error);
        }
      }
    })();
  }, []);

  const updateSecurityKey = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isUnpairing: true,
      }));
      await api.updateSecurityKey(member.organizationId, member.id);
      logout({
        returnTo: window.location.origin,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        pairedPhone: null,
        isPairSecurityKeyDialogOpen: false,
        isUnpairing: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        pairedPhone: null,
        isPairSecurityKeyDialogOpen: false,
        isUnpairing: false,
      }));
      logError(error);
    }
  };

  const unpairDevice = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isUnpairing: true,
      }));

      if (state.pairedPhone) {
        await api.unpairDevice(member.id);
      } else {
        await api.updateSecurityKey(member.organizationId, member.id);
        logout({
          returnTo: window.location.origin,
        });
      }

      if (!mounted.current) return;
      enqueueSnackbar(
        t('profilePage.devicePairing.successfulUnpairedToast', { partnerName })
      );
      setState((prevState) => ({
        ...prevState,
        pairedPhone: null,
        isUnpairDialogOpen: false,
        isUnpairing: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isUnpairDialogOpen: false,
        isUnpairing: false,
      }));
      logError(error);
    }
  };

  const renderContent = () => {
    if (state.isLoading) {
      return <CircularProgress sx={{ mt: 3 }} size="small" />;
    }

    if (state.hasError) {
      return <Typography color="error">{t('errors.loadData')}</Typography>;
    }

    if (state.pairedPhone || member.securityKeyPaired) {
      return (
        <>
          <Typography variant="body1" color="text.secondary" marginBottom={3}>
            <Trans
              i18nKey={`profilePage.devicePairing.${
                securityKeyAuthenticationEnabled &&
                featureModules.SECURITY_KEY_AUTHENTICATION &&
                member.securityKeyPaired
                  ? 'pairedSecurityKeyHint'
                  : 'pairedMobileDeviceHint'
              }`}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    style={{ color: 'inherit' }}
                    href={
                      securityKeyAuthenticationEnabled &&
                      featureModules.SECURITY_KEY_AUTHENTICATION &&
                      member.securityKeyPaired
                        ? HELP_CENTER_PAIRING_SECURITY_KEY_URL
                        : HELP_CENTER_PAIRING_MOBILE_DEVICE_URL
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ListItem component="div" disableGutters>
              <ListItemIcon>
                {state.pairedPhone ? (
                  <DeviceMobileCameraIcon color="success" />
                ) : (
                  <CustomSecurityKeySvgIcon color="success" />
                )}
              </ListItemIcon>
              <ListItemTextReversed
                primary={
                  state.pairedPhone?.deviceName || member.securityKeyName
                }
                secondary={t('profilePage.devicePairing.authenticator')}
              />
            </ListItem>
            <Button
              onClick={toggleUnpairDialog}
              size="large"
              variant="outlined"
              sx={{ flexShrink: 0 }}
            >
              {t(
                `profilePage.devicePairing.${
                  state.pairedPhone ? 'unpairMobileDevice' : 'unpairSecurityKey'
                }`
              )}
            </Button>
          </Box>
        </>
      );
    }

    return (
      <>
        <Typography variant="body1" mt={1}>
          {t('profilePage.devicePairing.noDevicePaired')}
        </Typography>
        <Typography variant="body2" color="text.secondary" marginBottom={3}>
          <Trans
            i18nKey={`profilePage.devicePairing.${
              securityKeyAuthenticationEnabled &&
              featureModules.SECURITY_KEY_AUTHENTICATION
                ? 'noDevicePairedHintWithSecurityKey'
                : 'noDevicePairedHint'
            }`}
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  style={{ color: 'inherit' }}
                  href={HELP_CENTER_PAIRING_SECURITY_KEY_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
        <Box display="flex" flexDirection="row">
          {securityKeyAuthenticationEnabled &&
            featureModules.SECURITY_KEY_AUTHENTICATION && (
              <Box mr={2}>
                <Button
                  onClick={togglePairSecurityKeyDialog}
                  size="large"
                  variant="outlined"
                >
                  {t('profilePage.devicePairing.pairSecurityKey')}
                </Button>
              </Box>
            )}
          <Button
            onClick={togglePairMobileDeviceDialog}
            size="large"
            variant="outlined"
          >
            {t('profilePage.devicePairing.pairMobileDevice')}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box mb={8}>
        <Box mb={1} display="flex" alignItems="center">
          <Typography variant="h6">
            {t('profilePage.devicePairing.title')}
          </Typography>
          {(state.pairedPhone || member.securityKeyPaired) && (
            <StatusBadge
              label={t('profilePage.devicePairing.active')}
              variant="filled"
              color="success"
              sx={{ ml: 1 }}
            />
          )}
        </Box>
        {renderContent()}
      </Box>

      <ConfirmDialog
        maxWidth="xs"
        title={t('profilePage.devicePairing.pairSecurityKeyDialog.title')}
        description={t(
          'profilePage.devicePairing.pairSecurityKeyDialog.description'
        )}
        confirmButtonProps={{
          children: t('common.button.confirm'),
        }}
        open={state.isPairSecurityKeyDialogOpen}
        loading={state.isPairingSecurityKey}
        onClose={togglePairSecurityKeyDialog}
        onSuccess={updateSecurityKey}
      />

      <PairMobileDeviceDialog
        open={state.isPairMobileDeviceDialogOpen}
        onClose={togglePairMobileDeviceDialog}
      />

      <ConfirmDialog
        title={
          state.pairedPhone
            ? t('profilePage.devicePairing.unpairDialog.mobileDeviceTitle')
            : t('profilePage.devicePairing.unpairDialog.securityKeyTitle')
        }
        description={
          state.pairedPhone
            ? t(
                'profilePage.devicePairing.unpairDialog.mobileDeviceDescription'
              )
            : t('profilePage.devicePairing.unpairDialog.securityKeyDescription')
        }
        confirmButtonProps={{
          children: t('common.button.confirm'),
        }}
        open={state.isUnpairDialogOpen}
        loading={state.isUnpairing}
        onClose={toggleUnpairDialog}
        onSuccess={unpairDevice}
      />
    </>
  );
};

export default PairedDevice;
