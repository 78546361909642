import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useFormik } from 'formik';
import { isEqual, isNil, omitBy, sortBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { OnboardingDocumentStatusBadge } from 'components/Badge';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import OnboardingDocumentDescription from 'domains/creditAndCompliance/components/OnboardingDocumentRow/OnboardingDocumentDescription';
import OnboardingDocumentTitle from 'domains/creditAndCompliance/components/OnboardingDocumentRow/OnboardingDocumentTitle';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  ArchiveIcon,
  Box,
  Button,
  ChatCircleTextIcon,
  DotsThreeOutlineVerticalIcon,
  FileTextIcon,
  FloppyDiskIcon,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  PencilSimpleIcon,
  QuestionIcon,
  Select,
  TextField,
  Tooltip,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OnboardingDocument,
  OnboardingDocumentAllowedAttribute,
  OnboardingDocumentName,
  OnboardingDocumentStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import AttributeField from './AttributeField';

const Bold = styled('b')`
  font-weight: 700;
`;

const sortAttributeFieldSlugs = (
  attributes: OnboardingDocumentAllowedAttribute[]
) =>
  sortBy(attributes, [
    (item) =>
      ({
        [OnboardingDocumentAllowedAttribute.orgName]: 1,
        [OnboardingDocumentAllowedAttribute.ibanRef]: 2,
        [OnboardingDocumentAllowedAttribute.fromMonthYear]: 3,
        [OnboardingDocumentAllowedAttribute.toMonthYear]: 4,
        [OnboardingDocumentAllowedAttribute.year]: 5,
        [OnboardingDocumentAllowedAttribute.date]: 6,
        [OnboardingDocumentAllowedAttribute.amount]: 7,
      }[item]),
  ]);

interface FormValues {
  attributes: { [key: string]: string } | null;
  customLabel: string;
  customLabelLocal: string;
  description: string;
  descriptionLocal: string;
  relatedEntityId: string;
  shouldAlsoRequest: boolean;
  type: OnboardingDocumentName;
}

interface CreateInformationRequestProps {
  category: string;
  onClear: () => void;
  subcategory: string;
  mode: 'create';
}

interface EditInformationRequestProps {
  doc: OnboardingDocument;
  mode: 'edit';
}

export function InformationRequestDraftCard(
  props: CreateInformationRequestProps
): React.ReactElement;
export function InformationRequestDraftCard(
  props: EditInformationRequestProps
): React.ReactElement;

export function InformationRequestDraftCard(
  props: CreateInformationRequestProps | EditInformationRequestProps
) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const api = useImperativeApi();

  const {
    state: { organization },
  } = useGlobalState();

  const {
    actions: {
      archiveDocument,
      setOnboardingDocuments,
      updateOnboardingDocument,
    },
    state: { canDocumentsSectionBeEdited, onboardingDocumentTypeGroups },
  } = useCreditAndComplianceContext();
  const onboardingDocumentTypeByName = onboardingDocumentTypeGroups!
    .onboardingDocumentTypeByName;
  const onboardingDocumentTypeNamesBySubcategory = onboardingDocumentTypeGroups!
    .onboardingDocumentTypeNamesBySubcategory;

  const [showDetails, setShowDetails] = useState(props.mode === 'create');
  const [isLoading, setIsLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      type:
        props.mode === 'create'
          ? onboardingDocumentTypeNamesBySubcategory![props.subcategory]
              .length === 1
            ? onboardingDocumentTypeNamesBySubcategory![props.subcategory][0]
            : ('' as OnboardingDocumentName)
          : props.doc.type.name,
      relatedEntityId: organization!.id,
      customLabel: props.mode === 'edit' ? props.doc.customLabel ?? '' : '',
      customLabelLocal:
        props.mode === 'edit' ? props.doc.customLabelLocal ?? '' : '',
      description: props.mode === 'edit' ? props.doc.description ?? '' : '',
      descriptionLocal:
        props.mode === 'edit' ? props.doc.descriptionLocal ?? '' : '',
      attributes: props.mode === 'edit' ? props.doc.attributes : null,
      shouldAlsoRequest: false,
    },
    onSubmit: async (values) => {
      try {
        if (props.mode === 'create') {
          const {
            onboardingDocuments,
          } = await api.createOnboardingDocuments(organization!.id, [values]);
          setOnboardingDocuments(onboardingDocuments);
          props.onClear();
        } else {
          const updatedDocument = await api.updateOnboardingDocument(
            organization!.id,
            props.doc.id,
            {
              attributes: values.attributes,
              customLabel: values.customLabel,
              customLabelLocal: values.customLabelLocal,
              description: values.description,
              descriptionLocal: values.descriptionLocal,
            }
          );
          updateOnboardingDocument(updatedDocument);
          setShowDetails(false);
        }
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  useEffect(() => {
    if (!formik.values.type || props.mode === 'edit') return;

    const defaultAttributes = sortAttributeFieldSlugs(
      onboardingDocumentTypeByName![formik.values.type].allowedAttributes
    ).reduce(
      (acc, curr) => ({
        ...acc,
        ...(curr === OnboardingDocumentAllowedAttribute.orgName
          ? {
              [curr]: organization!.name,
            }
          : { [curr]: '' }),
      }),
      {}
    );

    formik.setFieldValue('attributes', defaultAttributes);
  }, [formik.values.type, props.mode]);

  const isQuestion =
    formik.values.type &&
    onboardingDocumentTypeByName![formik.values.type].question;
  const docType =
    props.mode === 'edit' ? props.doc.type.name : formik.values.type;
  const hasPredefinedDescription = !!t(
    `onboardingDocumentType.description.${docType}`,
    {
      defaultValue: false,
    }
  );
  const showCustomTextFields = formik.values.type.endsWith('_OTHER');
  const hasEmptyFields =
    !formik.values.type ||
    !!(
      formik.values.attributes &&
      Object.values(formik.values.attributes).some((value) => !value)
    );
  const hasUpdatedFields =
    props.mode === 'create' ||
    !(
      isEqual(props.doc.attributes, formik.values.attributes) &&
      props.doc.customLabel === formik.values.customLabel &&
      props.doc.customLabelLocal === formik.values.customLabelLocal &&
      props.doc.description === formik.values.description &&
      props.doc.descriptionLocal === formik.values.descriptionLocal
    );
  const hasTooltip =
    props.mode === 'edit' &&
    !!t(`onboardingDocumentType.fileTypeTooltip.${docType}`, {
      defaultValue: false,
    });

  const getDocumentTypeName = (documentType: string) => {
    return (
      <Trans
        components={{ b: <b /> }}
        i18nKey={`int.onboardingDocumentType.${documentType}`}
        values={{
          [OnboardingDocumentAllowedAttribute.amount]: '[€€€€]',
          [OnboardingDocumentAllowedAttribute.date]: '[dd.mm.yyyy]',
          [OnboardingDocumentAllowedAttribute.fromMonthYear]: '[MMYY]',
          [OnboardingDocumentAllowedAttribute.ibanRef]: '[IIII]',
          [OnboardingDocumentAllowedAttribute.orgName]: '[XXXX]',
          [OnboardingDocumentAllowedAttribute.toMonthYear]: '[MMYY]',
          [OnboardingDocumentAllowedAttribute.year]: '[YY]',
        }}
      />
    );
  };

  return (
    <Box
      component="form"
      sx={{
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 1,
        padding: 2,
      }}
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" marginTop={0.5}>
          <Box component="span" marginRight={1}>
            {isQuestion ? <ChatCircleTextIcon /> : <FileTextIcon />}
          </Box>

          <Box>
            <OnboardingDocumentTitle
              {...(props.mode === 'create' && {
                altName: t(
                  `int.onboardingDocumentSubcategory.${props.subcategory}`
                ),
              })}
              attributes={formik.values.attributes ?? {}}
              customLabel={formik.values.customLabel}
              customLabelLocal={formik.values.customLabelLocal}
              name={formik.values.type}
            />
          </Box>

          {props.mode === 'edit' && hasTooltip && (
            <Tooltip
              title={
                <Trans
                  i18nKey={`onboardingDocumentType.fileTypeTooltip.${docType}`}
                  components={{ b: <Bold /> }}
                  values={{
                    orgName: formik.values.attributes?.orgName ?? '',
                  }}
                />
              }
            >
              <Box component="span" marginLeft={1}>
                <QuestionIcon />
              </Box>
            </Tooltip>
          )}
        </Box>

        <Grid
          container
          columnSpacing={2}
          width="fit-content"
          minWidth="fit-content"
          alignItems="center"
        >
          {props.mode === 'create' && (
            <>
              <Grid item>
                <Button
                  disabled={
                    hasEmptyFields || !hasUpdatedFields || formik.isSubmitting
                  }
                  size="medium"
                  type="submit"
                  variant="outlined"
                  startIcon={<FloppyDiskIcon />}
                >
                  {t('common.button.save')}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={{ minWidth: 'unset', padding: '7px' }}
                  disabled={formik.isSubmitting}
                  onClick={props.onClear}
                  variant="outlined"
                >
                  <XIcon fontSize="small" />
                </Button>
              </Grid>
            </>
          )}
          {props.mode === 'edit' && (
            <>
              <OnboardingDocumentStatusBadge status={props.doc.status} />

              {showDetails ? (
                <Grid container spacing={2} width="fit-content">
                  <Grid item>
                    <Button
                      disabled={
                        hasEmptyFields ||
                        !hasUpdatedFields ||
                        formik.isSubmitting
                      }
                      size="medium"
                      type="submit"
                      variant="outlined"
                      startIcon={<FloppyDiskIcon />}
                    >
                      {t('common.button.save')}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={() => {
                        formik.resetForm();
                        setShowDetails(false);
                      }}
                    >
                      {t('common.button.cancel')}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                canDocumentsSectionBeEdited && (
                  <>
                    {props.doc.status === OnboardingDocumentStatus.DRAFT && (
                      <Grid item>
                        <Button
                          size="medium"
                          type="submit"
                          variant="outlined"
                          startIcon={<PencilSimpleIcon />}
                          onClick={() => setShowDetails(true)}
                        >
                          {t('common.button.edit')}
                        </Button>
                      </Grid>
                    )}

                    {[
                      OnboardingDocumentStatus.DRAFT,
                      OnboardingDocumentStatus.REQUESTED,
                      OnboardingDocumentStatus.SUBMITTED,
                    ].includes(props.doc.status) && (
                      <>
                        <Grid item>
                          <Button
                            onClick={(event) =>
                              setMenuAnchorEl(event.currentTarget)
                            }
                            sx={{ minWidth: 'unset', padding: '7px' }}
                            variant="outlined"
                          >
                            <DotsThreeOutlineVerticalIcon fontSize="small" />
                          </Button>
                        </Grid>

                        <Menu
                          anchorEl={menuAnchorEl}
                          onClose={() => setMenuAnchorEl(null)}
                          open={!!menuAnchorEl}
                        >
                          <MenuItem
                            onClick={() =>
                              archiveDocument(
                                props.doc.id,
                                (isLoading: boolean) => setIsLoading(isLoading)
                              )
                            }
                          >
                            <ListItemIcon>
                              <ArchiveIcon />
                            </ListItemIcon>
                            Archive
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </>
                )
              )}
            </>
          )}
        </Grid>
      </Box>

      {hasPredefinedDescription ? (
        <OnboardingDocumentDescription
          description={
            <Trans
              i18nKey={`onboardingDocumentType.description.${docType}`}
              components={{ b: <Bold />, br: <br />, li: <li />, ul: <ul /> }}
              values={{
                ...omitBy(formik.values.attributes, isNil),
                ...(formik.values.attributes?.date && {
                  date: moment(
                    formik.values.attributes.date,
                    'YYYY-MM-DD'
                  ).format('DD.MM.YYYY'),
                }),
                orgName: organization!.name,
              }}
            />
          }
        />
      ) : (
        formik.values.description && (
          <OnboardingDocumentDescription
            description={formik.values.description}
            descriptionLocal={formik.values.descriptionLocal}
          />
        )
      )}

      {showDetails && (
        <Grid container spacing={3}>
          {props.mode === 'create' && (
            <Grid item width="293px">
              <FormControl fullWidth>
                <InputLabel shrink>
                  {t('int.onboardingDocumentCard.typeLabel')}
                </InputLabel>
                <Select
                  renderValue={(selected) =>
                    getDocumentTypeName(selected as string)
                  }
                  {...formik.getFieldProps('type')}
                  onChange={(e) => {
                    formik.setFieldValue('type', e.target.value);
                  }}
                >
                  {onboardingDocumentTypeNamesBySubcategory![
                    props.subcategory
                  ].map((slug) => (
                    <MenuItem
                      key={slug}
                      sx={{ whiteSpace: 'pre' }}
                      value={slug}
                    >
                      {getDocumentTypeName(slug)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {showCustomTextFields && (
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                marginTop={1}
                width="100%"
              >
                <Box mb="16px">
                  <TextField
                    label={t(
                      isQuestion
                        ? 'int.onboardingDocumentCard.customQuestion'
                        : 'int.onboardingDocumentCard.customLabel'
                    )}
                    {...formik.getFieldProps('customLabel')}
                  />
                </Box>

                <Box mb="16px">
                  <TextField
                    label={t(
                      isQuestion
                        ? 'int.onboardingDocumentCard.customQuestionLocal'
                        : 'int.onboardingDocumentCard.customLabelLocal',
                      { country: organization!.country }
                    )}
                    {...formik.getFieldProps('customLabelLocal')}
                  />
                </Box>

                <Box mb="16px">
                  <TextField
                    multiline
                    label={t('int.onboardingDocumentCard.description')}
                    {...formik.getFieldProps('description')}
                  />
                </Box>

                <TextField
                  multiline
                  label={t('int.onboardingDocumentCard.descriptionLocal', {
                    country: organization!.country,
                  })}
                  {...formik.getFieldProps('descriptionLocal')}
                />
              </Box>
            </Grid>
          )}

          {formik.values.attributes &&
            sortAttributeFieldSlugs(
              Object.keys(
                formik.values.attributes
              ) as OnboardingDocumentAllowedAttribute[]
            ).map((fieldName) => (
              <Grid item>
                <AttributeField
                  key={fieldName}
                  label={t(`int.onboardingDocumentCard.${fieldName}`)}
                  setValue={(value) =>
                    formik.setFieldValue(`attributes.${fieldName}`, value)
                  }
                  type={fieldName as OnboardingDocumentAllowedAttribute}
                  value={formik.values.attributes![fieldName]}
                />
              </Grid>
            ))}
        </Grid>
      )}

      {isLoading && <LoaderWithOverlay size={32} thickness={3} />}
    </Box>
  );
}
