import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { SupportedCountry, SupportedWebAppLanguage } from 'services/constants';
import { useFlags } from 'services/featureflags';

const ENGLISH = {
  code: SupportedWebAppLanguage.en,
  label: 'English',
};
const FINNISH = {
  code: SupportedWebAppLanguage.fi,
  label: 'Suomi',
};
const FRENCH = {
  code: SupportedWebAppLanguage.fr,
  label: 'Français',
};
const GERMAN = {
  code: SupportedWebAppLanguage.de,
  label: 'Deutsch',
};
const ITALIAN = {
  code: SupportedWebAppLanguage.it,
  label: 'Italiano',
};
const PORTUGUESE = {
  code: SupportedWebAppLanguage.pt,
  label: 'Português',
};
const SPANISH = {
  code: SupportedWebAppLanguage.es,
  label: 'Español',
};
const DUTCH = {
  code: SupportedWebAppLanguage.nl,
  label: 'Nederlands',
};

export const ALL_LANGUAGES = [
  GERMAN,
  ENGLISH,
  SPANISH,
  FRENCH,
  ITALIAN,
  DUTCH,
  PORTUGUESE,
  FINNISH,
];

/**
 * Returns an array of supported languages based on the organization billing country.
 * The first language in the array is the default language.
 * The "allLanguagesEnabled" feature flag is intended for testing purposes only.
 * It allows to easily access all languages and sets the default language to German.
 */
export const useSupportedLanguages = () => {
  const {
    state: { organization },
  } = useGlobalState();
  const { allLanguagesEnabled } = useFlags();

  return useMemo(() => {
    if (allLanguagesEnabled) return ALL_LANGUAGES;

    switch (organization?.billingAddress.country) {
      case SupportedCountry.at:
      case SupportedCountry.de:
        return [GERMAN, ENGLISH];
      case SupportedCountry.es:
        return [SPANISH, ENGLISH];
      case SupportedCountry.fi:
        return [FINNISH, ENGLISH];
      case SupportedCountry.fr:
        return [FRENCH, ENGLISH];
      case SupportedCountry.it:
        return [ITALIAN, ENGLISH, GERMAN];
      case SupportedCountry.lu:
        return [FRENCH, ENGLISH, GERMAN];
      case SupportedCountry.nl:
        return [DUTCH, ENGLISH];
      case SupportedCountry.pt:
        return [PORTUGUESE, ENGLISH];
      default:
        return [ENGLISH];
    }
  }, [organization?.billingAddress.country, allLanguagesEnabled]);
};
