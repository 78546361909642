import { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Organization, SupportedCountry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { validateVatIdByCountry } from 'services/utils/validators';

interface Props extends DialogProps {
  organizationId: string;
  organizationCountry: SupportedCountry;
  vatId: string | null;
  noPreselect?: boolean;
  onSuccess: (organization: Organization) => void;
  onClose?: () => void;
}

const YES = 'yes';
const NO = 'no';

const VatIdChangeDialog = ({
  organizationId,
  organizationCountry,
  vatId: initialVatId,
  noPreselect = false,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      isVatIdRequired:
        noPreselect && !initialVatId ? undefined : initialVatId ? YES : NO,
      vatId: initialVatId || '',
    },
    validate: ({ vatId }) => {
      const vatIdError = validateVatIdByCountry(organizationCountry, vatId, t);
      if (vatIdError) {
        return {
          vatId: vatIdError,
        };
      }
      return {};
    },
    onSubmit: () => {
      if (isLoading) return;
      changeVatId();
    },
  });

  const changeVatId = async () => {
    setIsLoading(true);

    try {
      const organizationData = await api.updateVatId(
        organizationId,
        formik.values.vatId.trim()
      );
      if (!mounted.current) return;
      onSuccess(organizationData);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (formik.values.isVatIdRequired === YES) {
      formik.setFieldValue('vatId', initialVatId || '', false);
    } else {
      formik.setFieldValue('vatId', '', false);
    }
  }, [formik.values.isVatIdRequired]);

  const onFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(e.target.name, e.target.value, false);
    formik.setFieldError(e.target.name, undefined);
  };

  const isVatIdRequired = formik.values.isVatIdRequired === YES;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('settingsOrganizationPage.vat')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="vat-id-change-dialog"
          noValidate
        >
          <Typography mb={1}>
            {t('settingsOrganizationPage.isVatIdRequired')}
          </Typography>

          <FormControl disabled={isLoading} sx={{ mb: 1 }}>
            <RadioGroup
              name="isVatIdRequired"
              value={formik.values.isVatIdRequired}
              onChange={onFieldChange}
              row
            >
              {[YES, NO].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={t(`common.${option}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {isVatIdRequired && (
            <TextField
              label={t('vatIdForm.vatIdField')}
              name="vatId"
              value={formik.values.vatId}
              onChange={onFieldChange}
              disabled={isLoading}
              autoFocus
              error={!!formik.errors.vatId}
              helperText={!!formik.errors.vatId && formik.errors.vatId}
            />
          )}
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" disabled={isLoading} onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>

        {isVatIdRequired ? (
          <Button
            form="vat-id-change-dialog"
            type="submit"
            disabled={!!formik.errors.vatId || isLoading}
            data-test-id="vat-id-submit"
          >
            {t('common.button.save')}
          </Button>
        ) : (
          <Button
            onClick={changeVatId}
            disabled={formik.values.isVatIdRequired === undefined || isLoading}
          >
            {t('common.button.save')}
          </Button>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(VatIdChangeDialog);
