import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddressDetails from 'components/AddressDetails';
import { Button } from 'components/Button';
import { LoaderWithOverlay } from 'components/Loader';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { MailDescription } from 'components/OnboardingPage/style';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import Tooltip from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationToHubspotLinkFormGroup } from 'domains/organization/components';
import { OrganizationStatus, SupportedCountry } from 'services/constants';
import { useCanUser } from 'services/rbac';
import DeliveryAddressFormGroup from './DeliveryAddressFormGroup';
import TradeNameFormGroup from './TradeNameFormGroup';
import VatIdFormGroup from './VatIdFormGroup';
import {
  Group,
  GroupDescription,
  GroupLabel,
  StyledCircleQuestionMarkIcon,
} from './style';

const OrganizationDetailsBlock = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { confirmOrgDetails },
    state: { isConfirmOrgDetailsLoading, onboarding },
  } = useOnboardingContext();
  const [isVatIdChosen, setIsVatIdChosen] = useState(
    [SupportedCountry.tr, SupportedCountry.us].includes(
      organization!.billingAddress.country
    )
      ? true
      : organization?.status === OrganizationStatus.onboardingDetails
      ? !!organization?.vatId
      : false
  );

  const getOrganizationDetailsState = () => {
    if (!onboarding) return 'disabled';

    if (onboarding.status === OrganizationStatus.onboardingDetails) {
      return 'active';
    }

    return 'completed';
  };

  return (
    <OnboardingBlockWrapper
      title={t('organizationDetailsBlock.title')}
      state={getOrganizationDetailsState()}
      testId="onboarding-details-block"
    >
      {!!onboarding && (
        <>
          <MailDescription $mb={53}>
            {t('organizationDetailsBlock.description')}
          </MailDescription>

          <Group>
            <GroupLabel>{t('organizationDetailsBlock.legalName')}</GroupLabel>
            <GroupDescription>{onboarding.legalName}</GroupDescription>
          </Group>
          <TradeNameFormGroup
            organization={organization!}
            label={
              <Box display="flex" alignItems="center">
                {t('common.tradeName')}
                <Tooltip title={t('tooltips.tradeName') as string}>
                  <StyledCircleQuestionMarkIcon />
                </Tooltip>
              </Box>
            }
          />
          {canUser('org-hubspot-id:view') && (
            <OrganizationToHubspotLinkFormGroup
              existingHubspotId={organization?.hubspotId || ''}
              label={t('organizationDetailsBlock.hubspotId')}
              width="280px"
            />
          )}
          <Group>
            <GroupLabel>
              {t('organizationDetailsBlock.billingAddress')}
            </GroupLabel>
            <GroupDescription>
              <AddressDetails address={onboarding.billingAddress} />
            </GroupDescription>
          </Group>
          <Group>
            <GroupLabel>
              {t('organizationDetailsBlock.registration')}
            </GroupLabel>
            <GroupDescription>
              {!!onboarding.nationalRegisterCourt && (
                <>
                  {onboarding.nationalRegisterCourt} <br />
                </>
              )}
              {onboarding.tradeRegisterNumber}
            </GroupDescription>
          </Group>
          <Group>
            <GroupLabel>
              {t('organizationDetailsBlock.foundationDate')}
            </GroupLabel>
            <GroupDescription>{onboarding.foundationDate}</GroupDescription>
          </Group>

          <VatIdFormGroup
            onboarding={onboarding}
            isChosen={isVatIdChosen}
            setIsVatIdChosen={() => setIsVatIdChosen(true)}
            label={t('organizationDetailsBlock.vatId')}
          />
          <DeliveryAddressFormGroup
            onboarding={onboarding}
            label={
              <Box display="flex" alignItems="center">
                {t('organizationDetailsBlock.deliveryAddress')}
                <Tooltip title={t('tooltips.deliveryAddress') as string}>
                  <StyledCircleQuestionMarkIcon />
                </Tooltip>
              </Box>
            }
          />

          {onboarding.status === OrganizationStatus.onboardingDetails && (
            <Box textAlign="right">
              <Button
                disabled={
                  !onboarding?.deliveryAddress ||
                  (organization!.tradeNameRequired &&
                    !organization!.tradeName) ||
                  !isVatIdChosen ||
                  isConfirmOrgDetailsLoading
                }
                onClick={confirmOrgDetails}
                data-test-id="confirm-onboarding-details-btn"
              >
                {t('organizationDetailsBlock.confirmButton')}
              </Button>
            </Box>
          )}
          {isConfirmOrgDetailsLoading && (
            <LoaderWithOverlay size={32} thickness={3} />
          )}
        </>
      )}
    </OnboardingBlockWrapper>
  );
};

export default OrganizationDetailsBlock;
