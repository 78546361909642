import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useGlobalState } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  CategoryFilter,
  ExpenseTypeFilter,
  ProjectFilter,
  ReceiptFilter,
  ReviewFilter,
  StatusFilter,
  TeamFilter,
  TransactionSimpleTypeFilter,
} from 'domains/transaction/components';
import { QueryParams as ChTeamsPageQueryParams } from 'domains/transaction/pages/CardholderTeamTransactionsPage';
import {
  NONE_VALUE,
  QueryParams as TeamsPageQueryParams,
  visibleTransactionTypes,
} from 'domains/transaction/pages/TransactionsPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  ExpenseType,
  MerchantCategory,
  Project,
  Team,
  TransactionReceiptStatus,
  TransactionReviewStatus,
  TransactionSimpleType,
  TransactionStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teams: Team[];
  projects: Project[];
  params: TeamsPageQueryParams | ChTeamsPageQueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

type KeyToValueType = {
  status: TransactionStatus;
  type: TransactionSimpleType;
};

type KeyValue<T extends keyof KeyToValueType> = {
  key: T;
  value: KeyToValueType[T];
};

type SetFilterArgs<T extends keyof KeyToValueType> = KeyValue<T> & {
  type: 'add' | 'remove';
};

interface State {
  status: TransactionStatus[];
  type: TransactionSimpleType[];
  category: MerchantCategory[];
  teamId: string[];
  projectIds: string[];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  cardAccountId: string;
  expenseType: ExpenseType | '';
}

const FiltersDrawer = ({
  teams,
  projects,
  params,
  setParam,
  ...props
}: Props) => {
  const { managerTransactionReviewEnabled } = useFlags();
  const {
    state: { cardAccounts, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    status: [],
    type: [],
    category: [],
    teamId: [],
    projectIds: [],
    receipt: '',
    fromDate: null,
    toDate: null,
    reviewStatus: '',
    cardAccountId: '',
    expenseType: '',
  });
  const isManagerReviewFilterVisible =
    managerTransactionReviewEnabled && featureModules.MANAGER_TX_REVIEWS;

  const areFiltersSelected = !!(
    state.status.length ||
    state.type.length ||
    state.category.length ||
    state.teamId.length ||
    state.receipt ||
    state.projectIds.length ||
    state.fromDate ||
    state.toDate ||
    state.reviewStatus ||
    state.cardAccountId ||
    state.expenseType
  );

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        type: params.type,
        category: params.category,
        teamId: params.teamId,
        projectIds: params.projectIds,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        cardAccountId: params.cardAccountId,
        expenseType: params.expenseType,
      });
  }, [params, props.open]);

  const setFilters = <T extends keyof KeyToValueType>({
    key,
    value,
    type,
  }: SetFilterArgs<T>) => {
    if (type === 'add')
      setState((prevState) => ({
        ...prevState,
        [key]: [...prevState[key], value],
      }));
    else
      setState((prevState) => ({
        ...prevState,
        [key]: (prevState[key] as KeyToValueType[T][]).filter(
          (item) => item !== value
        ),
      }));
  };

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.startOf('day')?.format(),
      toDate: state.toDate?.endOf('day')?.format(),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <StatusFilter
        value={state.status}
        onChange={(status, checked) =>
          setFilters({
            key: 'status',
            value: status,
            type: checked ? 'add' : 'remove',
          })
        }
      />

      <TransactionSimpleTypeFilter
        value={state.type}
        onChange={(value, checked) =>
          setFilters({
            key: 'type',
            value: value,
            type: checked ? 'add' : 'remove',
          })
        }
        options={visibleTransactionTypes}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      {cardAccounts.length > 1 && (
        <CardAccountFilter
          value={state.cardAccountId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, cardAccountId: value }))
          }
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={state.receipt}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, receipt: value }))
          }
        />
      )}

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {!!teams.length && (
        <TeamFilter
          teams={teams}
          value={state.teamId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, teamId: value }))
          }
        />
      )}

      {!!projects.length && (
        <ProjectFilter
          projects={projects}
          value={state.projectIds}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, projectIds: value }))
          }
        />
      )}

      <ExpenseTypeFilter
        value={state.expenseType}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, expenseType: value }))
        }
      />

      {isManagerReviewFilterVisible && (
        <ReviewFilter
          pageKey="transactionsPage"
          value={state.reviewStatus}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, reviewStatus: value }))
          }
        />
      )}
    </FilterDrawer>
  );
};

export default FiltersDrawer;
