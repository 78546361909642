import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Co2EmissionBadge } from 'components/Badge';
import { useGlobalState } from 'context/GlobalState';
import { CardIconWithDetails } from 'domains/card/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  IntegrationCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionDisputeIcon,
  TransactionMissingReceiptCell,
  TransactionReviewStatusIcon,
} from 'domains/transaction/components';
import {
  Box,
  drawerPlaceholderColumn,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
} from 'elements';
import { Transaction } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { exportFlowEnabled, managerTransactionReviewEnabled } = useFlags();
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { featureModules, organization, cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();

  const isManagerReviewVisible =
    managerTransactionReviewEnabled && featureModules.MANAGER_TX_REVIEWS;
  const isDisputedColumnVisible = canUser('transaction-dispute:view');

  return useMemo(() => {
    const columns: GridColDef<Transaction>[] = [
      {
        headerName: t('transactionsPage.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row, api }) => (
          <Box
            overflow="hidden"
            data-intercom-target={
              api.getRowIdFromRowIndex(0) === row.transactionId
                ? 'transactions-table-first-row'
                : undefined
            }
          >
            <MerchantCell transaction={row} showReceiptIcon showCommentIcon />
          </Box>
        ),
      },
      ...(featureModules.RECEIPT_MANAGEMENT &&
      organization?.missingReceiptNotificationEnabled
        ? [
            {
              headerName: '',
              field: 'receiptNeeded',
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              sortable: false,
              flex: 0.2,
              renderCell: ({ row }: GridRenderCellParams<Transaction>) => (
                <TransactionMissingReceiptCell transaction={row} />
              ),
            },
          ]
        : []),
      ...(isDisputedColumnVisible
        ? [
            {
              headerName: '',
              field: 'disputed',
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              sortable: false,
              flex: 0.2,
              renderCell: ({ row }: GridRenderCellParams<Transaction>) =>
                row.disputed ? <TransactionDisputeIcon /> : null,
            },
          ]
        : []),
      {
        headerName: t('transactionsPage.date'),
        field: 'createdAt',
        sortable: false,
        flex: 0.7,
        valueGetter: ({ row }) => moment(row.createdAt).format('D MMM YYYY'),
      },
      {
        headerName: t('transactionsPage.card'),
        field: 'cardType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardRefNum={row.cardRefNum}
            cardName={row.cardName}
            showCardNameTooltip
          />
        ),
      },
      {
        headerName: t('transactionsPage.member'),
        field: 'memberFirstName',
        flex: 0.8,
        sortable: false,
        valueGetter: ({ row }) =>
          getFullName(row.memberFirstName, row.memberLastName) || '-',
      },
      ...(cardAccounts.length > 1
        ? [
            {
              headerName: t('transactionsPage.account'),
              field: 'cardAccountName',
              flex: 0.8,
              sortable: false,
              valueGetter: ({ row }: GridRenderCellParams<Transaction>) =>
                getCardAccountName(row.cardAccountId),
            },
          ]
        : []),
      ...(featureModules.PLIANT_EARTH
        ? [
            {
              headerName: t('transactionsPage.emission'),
              field: 'emission',
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              sortable: false,
              flex: 0.6,
              renderCell: ({ row }: GridRenderCellParams<Transaction>) =>
                row.emission ? (
                  <Co2EmissionBadge unit="kg" value={row.emission} />
                ) : null,
            },
          ]
        : []),
      {
        headerName: t('transactionsPage.amount'),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 0.8,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      ...(isManagerReviewVisible
        ? [
            {
              headerName: t('transactionsPage.review'),
              field: 'review',
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              sortable: false,
              flex: 0.4,
              minWidth: 80,
              renderCell: ({ row }: GridRenderCellParams<Transaction>) => (
                <TransactionReviewStatusIcon
                  status={row.reviewStatus}
                  expenseType={row.expenseType}
                />
              ),
            },
          ]
        : []),
      ...(exportFlowEnabled
        ? [
            {
              headerName: t('transactionsPage.export'),
              field: '_integration_',
              sortable: false,
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              flex: 0.4,
              minWidth: 80,
              renderCell: ({ row }: GridRenderCellParams<Transaction>) => (
                <IntegrationCell
                  status={row.status}
                  exportStatus={row.exportStatus}
                  integrationType={row.integrationType}
                />
              ),
            },
          ]
        : []),
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [
    i18n.language,
    exportFlowEnabled,
    isManagerReviewVisible,
    isDisputedColumnVisible,
    getCardAccountName,
  ]);
};

export default useColumns;
