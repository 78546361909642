import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import upgradePlanDialogHero from 'assets/images/upgradePlanDialogHero.jpg';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { SubscriptionPlan, SubscriptionPlanType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

export const HeroImage = styled('div')<{ $image: string }>`
  height: 224px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${({ $image }) => $image}');
`;

interface Props extends DialogProps {
  onClose: () => void;
}

interface State {
  isUpgrading: boolean;
  standardPlanParams: SubscriptionPlan | null;
}

const UpgradePlanConfirmationDialog = ({ ...props }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isUpgrading: false,
    standardPlanParams: null,
  });

  const fetchStandardPlanParams = async () => {
    try {
      const { defaults } = await api.getAllPlans();
      const standardPlanParams = defaults.find(
        (plan: SubscriptionPlan) => plan.type === SubscriptionPlanType.standard
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        standardPlanParams,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (canUser('subscription-plan:upgrade')) {
      fetchStandardPlanParams();
    }
  }, []);

  const upgradePlan = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isUpgrading: true,
      }));
      const data = await api.updatePlan(organization!.id, {
        type: SubscriptionPlanType.standard,
      });
      if (!mounted.current) return;
      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: {
          subscriptionPlan: {
            ...data,
          },
        },
      });
      setState((prevState) => ({
        ...prevState,
        isUpgrading: false,
      }));
      enqueueSnackbar(
        t('subscriptionPlanSettingsSubPage.confirmationDialog.successToast'),
        {
          variant: 'success',
        }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUpgrading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      props.onClose();
    }
  };

  return (
    <Dialog {...props}>
      <HeroImage $image={upgradePlanDialogHero} />

      <DialogTitle>
        {t('subscriptionPlanSettingsSubPage.confirmationDialog.title')}
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" component="div">
          <Trans
            i18nKey="subscriptionPlanSettingsSubPage.confirmationDialog.description"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://www.getpliant.com/en/pricing/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              baseFee: state.standardPlanParams ? (
                <FormatMoney
                  value={state.standardPlanParams.baseFee}
                  fractionalPart={
                    state.standardPlanParams.baseFee.value % 100 !== 0
                  }
                />
              ) : (
                <div />
              ),
              additionalUserFee: state.standardPlanParams ? (
                <FormatMoney
                  value={state.standardPlanParams.additionalUserFee}
                  fractionalPart={
                    state.standardPlanParams.additionalUserFee.value % 100 !== 0
                  }
                />
              ) : (
                <div />
              ),
            }}
          />
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={upgradePlan} data-test-id="confirm-btn">
          {t(
            'subscriptionPlanSettingsSubPage.confirmationDialog.confirmUpgrade'
          )}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isUpgrading} />

      <LoaderWithOverlay
        loading={!state.standardPlanParams}
        BackdropProps={{
          appear: false,
          sx: (theme) => ({ background: theme.palette.common.white }),
        }}
      />
    </Dialog>
  );
};

export default withDialogWrapper(UpgradePlanConfirmationDialog);
