import React, { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useManagedTeams } from 'context/GlobalState';
import { TeamMemberAutocomplete } from 'domains/member/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withDialogWrapper,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PartialMember, TeamMember } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import InviteMemberStep from './InviteMemberStep';

interface State {
  teamId: string;
  autocompleteMembers: PartialMember[];
  membersToAdd: TeamMember[];
  isLoading: boolean;
  step: 'addExistingMember' | 'inviteNewMember';
  firstName: string;
  lastName: string;
}

interface Props extends DialogProps {
  teamId: string;
  onSuccess: () => void;
  onClose: () => void;
}

const AddTeamMembersDialog = ({ teamId, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const managedTeams = useManagedTeams();
  const [state, setState] = useState<State>({
    teamId,
    autocompleteMembers: [],
    membersToAdd: [],
    isLoading: false,
    step: 'addExistingMember',
    firstName: '',
    lastName: '',
  });
  const selectedTeam = useMemo(
    () => managedTeams.find((item) => item.id === state.teamId)!,
    [managedTeams, state.teamId]
  );

  const updateTeamMembers = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.updateTeamMembers(teamId, {
        membersToAdd: state.membersToAdd.map(({ memberId }) => ({
          memberId,
        })),
      });
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const addNewMembers = () => {
    setState((prevState) => {
      const addedMembers = prevState.autocompleteMembers.map(
        ({ id, firstName, lastName }) => ({
          memberId: id,
          firstName,
          lastName,
        })
      );
      return {
        ...prevState,
        autocompleteMembers: [],
        membersToAdd: sortBy<TeamMember>(
          [...prevState.membersToAdd, ...addedMembers],
          (member) => `${member.firstName} ${member.lastName}`.toLowerCase()
        ),
      };
    });
  };

  const removeMember = (member: TeamMember) => {
    setState((prevState) => ({
      ...prevState,
      membersToAdd: prevState.membersToAdd.filter(
        (item) => item.memberId !== member.memberId
      ),
    }));
  };

  return (
    <Dialog {...props}>
      {state.step === 'addExistingMember' ? (
        <>
          <DialogTitle>{t('addTeamMembersDialog.title')}</DialogTitle>
          <DialogContent>
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth disabled={state.isLoading}>
                  <InputLabel id="team-select-label">
                    {t('addTeamMembersDialog.team')}
                  </InputLabel>
                  <Select<string>
                    name="teamId"
                    labelId="team-select-label"
                    value={state.teamId}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        teamId: e.target.value,
                        autocompleteMembers: [],
                        membersToAdd: [],
                      }))
                    }
                    renderValue={() => selectedTeam.name}
                  >
                    {managedTeams.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TeamMemberAutocomplete
                  disabled={state.isLoading}
                  label={t('addTeamMembersDialog.members')}
                  teamId={state.teamId}
                  filteredOutMembers={state.membersToAdd}
                  value={state.autocompleteMembers}
                  onChange={(autocompleteMembers) =>
                    setState((prevState) => ({
                      ...prevState,
                      autocompleteMembers,
                    }))
                  }
                  onSave={addNewMembers}
                  onMemberInvite={(firstName, lastName) =>
                    setState((prevState) => ({
                      ...prevState,
                      firstName,
                      lastName,
                      step: 'inviteNewMember',
                    }))
                  }
                />
              </Grid>
            </Grid>
            <TableContainer sx={{ mt: 3 }}>
              {!!state.membersToAdd.length && (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('addTeamMembersDialog.name')}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.membersToAdd.map((member) => (
                      <TableRow
                        key={member.memberId}
                        sx={{ '.MuiTableCell-root': { border: 0 } }}
                      >
                        <TableCell>{`${member.firstName} ${member.lastName}`}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => removeMember(member)}
                            disabled={state.isLoading}
                            size="small"
                          >
                            <XIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button
              onClick={updateTeamMembers}
              disabled={state.isLoading || !state.membersToAdd.length}
            >
              {t('common.button.save')}
            </Button>
          </DialogActions>
          <LoaderWithOverlay loading={state.isLoading} />
        </>
      ) : (
        <InviteMemberStep
          teamId={state.teamId}
          firstName={state.firstName}
          lastName={state.lastName}
          onClose={props.onClose}
          onSuccess={onSuccess}
        />
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(AddTeamMembersDialog);
