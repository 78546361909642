import React, { useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import { omit } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import TaxIdNumberInputGroupNew from 'domains/organization/components/TaxIdNumberInputGroup/NewDesign';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  PercentField,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AmlRiskScoring,
  amlRiskScorings,
  countries,
  CreateNewRepresentative,
  LegalRepresentativeType,
  legalRepresentativeTypes,
  LegitimationDocument,
  LegitimationProvider,
  NetworkErrorCode,
  OrganizationRepresentative,
  OrganizationRepresentativeExternalStatus,
  OrganizationRepresentativePayload,
  RelatedPerson,
  SupportedCountry,
  TaxResidency,
  UboType,
  uboTypes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getBooleanFromYesNo,
  getGenericErrorMsg,
  getNetworkErrorCode,
  getYesNoFromBoolean,
  validateEmail,
  validateZipCodeByCountry,
} from 'services/utils';
import FileUploadButtons from './FileUploadButtons';

interface FormValues {
  citizenships: string[];
  city: string | null;
  companyShare: number | null;
  countryCode: string | null;
  countryOfBirth: string | null;
  dateOfBirth: string | null;
  email: string | null;
  givenName: string | null;
  isFatca: string;
  isPep: string;
  uboType: UboType | null;
  amlRiskScoringCheckDate: string;
  amlRiskScoringResult: AmlRiskScoring | null;
  sanctionListCheckCheckDate: string;
  sanctionListCheckResult: 'OK' | null;
  legalRepType: LegalRepresentativeType | null;
  legitimationDate: string;
  legitimationDocument: LegitimationDocument | null;
  legitimationProvider: LegitimationProvider | null;
  identificationDocumentDocumentId: string;
  identificationDocumentIssuanceDate: string;
  identificationDocumentIssuingOffice: string;
  identificationDocumentValidityDate: string;
  mobileNumber: string | null;
  placeOfBirth: string | null;
  postalCode: string | null;
  salutation: 1 | 2 | null;
  street: string | null;
  streetNumber: string | null;
  surName: string | null;
  taxResidences: TaxResidency[];
}

const validatePhoneNumberVarengold = (phoneNumber: string, t: TFunction) => {
  if (/^\+\d{1,3} \d{9,11}$/.test(phoneNumber)) return '';

  if (!phoneNumber.startsWith('+'))
    return t(
      'int.organizationRepresentativeSection.mobileErrors.startWithPlus'
    );

  if (!/^\+\d{1,3} /.test(phoneNumber)) {
    return t(
      'int.organizationRepresentativeSection.mobileErrors.spaceRequiredAfterCountryCode'
    );
  }

  if (/^\+\d{1,3} \d{0,8}$/.test(phoneNumber)) {
    return t('int.organizationRepresentativeSection.mobileErrors.minLength');
  }

  if (/^\+\d{1,3} \d{12,}$/.test(phoneNumber)) {
    return t('int.organizationRepresentativeSection.mobileErrors.maxLength');
  }

  return t('int.organizationRepresentativeSection.mobileErrors.invalidFormat');
};

const transformRelatedPersonToOrganisationRepresentative = (
  relatedPerson: RelatedPerson
  // @ts-expect-error - Object.entries returns [string, any][]
): OrganizationRepresentative => ({
  ...Object.entries(relatedPerson).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value.value,
  })),
  taxResidences: relatedPerson.taxResidences.map((item) => ({
    taxId: item.taxId.value,
    taxResidence: item.taxResidence.value,
    primary: item.primary.value,
  })),
});

const parseOrganisationRepresentative = (
  t: TFunction,
  orgRep?: OrganizationRepresentative
): FormValues => ({
  citizenships: orgRep?.citizenships ?? [],
  city: orgRep?.city ?? null,
  companyShare: orgRep?.companyShare ? +orgRep.companyShare * 100 : 0,
  countryCode: orgRep?.countryCode ?? '',
  countryOfBirth: orgRep?.countryOfBirth ?? null,
  dateOfBirth: orgRep?.dateOfBirth ?? null,
  email: orgRep?.email ?? null,
  givenName: orgRep?.givenName ?? '',
  isFatca: getYesNoFromBoolean(orgRep?.isFatca ?? null, t, {
    returnSlug: true,
  }),
  isPep: getYesNoFromBoolean(orgRep?.isPep ?? null, t, { returnSlug: true }),
  uboType: orgRep?.uboType ?? null,
  amlRiskScoringCheckDate: orgRep?.kyc?.amlRiskScoring?.checkDate ?? '',
  amlRiskScoringResult: orgRep?.kyc?.amlRiskScoring?.result ?? null,
  sanctionListCheckCheckDate: orgRep?.kyc?.sanctionListCheck?.checkDate ?? '',
  sanctionListCheckResult: orgRep?.kyc?.sanctionListCheck?.result ?? null,
  legalRepType: orgRep?.legalRepType ?? null,
  legitimationDate: orgRep?.legitimation?.date ?? '',
  legitimationDocument: orgRep?.legitimation?.document ?? null,
  legitimationProvider: orgRep?.legitimation?.provider ?? null,
  identificationDocumentDocumentId:
    orgRep?.legitimation?.identificationDocument?.documentId ?? '',
  identificationDocumentIssuanceDate:
    orgRep?.legitimation?.identificationDocument?.issuanceDate ?? '',
  identificationDocumentIssuingOffice:
    orgRep?.legitimation?.identificationDocument?.issuingOffice ?? '',
  identificationDocumentValidityDate:
    orgRep?.legitimation?.identificationDocument?.validityDate ?? '',
  mobileNumber: orgRep?.mobileNumber ?? null,
  placeOfBirth: orgRep?.placeOfBirth ?? null,
  postalCode: orgRep?.postalCode ?? null,
  salutation: orgRep?.salutation ?? null,
  street: orgRep?.street ?? null,
  streetNumber: orgRep?.streetNumber ?? null,
  surName: orgRep?.surName ?? null,
  taxResidences: orgRep?.taxResidences?.length
    ? orgRep?.taxResidences
    : [{ taxId: '', taxResidence: '', primary: true }],
});

interface CommonProps extends DialogProps {
  onClose: () => void;
}

type ConditionalProps =
  | {
      mode: 'create';
      relatedIndividual: CreateNewRepresentative;
      representative: undefined;
    }
  | {
      mode: 'edit';
      relatedIndividual: undefined;
      representative: RelatedPerson;
    };

type Props = CommonProps & ConditionalProps;

const EditRepresentativeDialog = ({
  mode,
  relatedIndividual,
  representative,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { fetchRepresentatives },
  } = useCreditAndComplianceContext();
  const [initialLoading, setInitialLoading] = useState(
    () => !!relatedIndividual && relatedIndividual.id !== 'manual'
  );

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: parseOrganisationRepresentative(
      t,
      representative
        ? transformRelatedPersonToOrganisationRepresentative(representative)
        : undefined
    ),
    validate: (values) => {
      const requiredFieldError = t(
        'int.organizationRepresentativeSection.requiredFieldError'
      );
      const errors: { [k in keyof FormValues]?: string } = {
        ...(!values.givenName && { givenName: requiredFieldError }),
        ...(!values.legalRepType && { legalRepType: requiredFieldError }),
        ...(!values.salutation && { salutation: requiredFieldError }),
        ...(!values.surName && { surName: requiredFieldError }),
        ...(!values.uboType && { uboType: requiredFieldError }),
      };

      if (values.mobileNumber) {
        const mobileNumberError = validatePhoneNumberVarengold(
          values.mobileNumber,
          t
        );
        if (mobileNumberError) errors.mobileNumber = mobileNumberError;
      }

      if (values.postalCode && values.countryCode) {
        const postalCodeError = validateZipCodeByCountry(
          values.countryCode as SupportedCountry,
          values.postalCode,
          ((key: string) => t(key, { ns: 'translation' })) as TFunction
        );

        if (postalCodeError) errors.postalCode = postalCodeError;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload: OrganizationRepresentativePayload = {
          ...omit(values, [
            'amlRiskScoringCheckDate',
            'amlRiskScoringResult',
            'sanctionListCheckCheckDate',
            'sanctionListCheckResult',
            'legitimationDate',
            'legitimationDocument',
            'legitimationProvider',
            'identificationDocumentDocumentId',
            'identificationDocumentIssuanceDate',
            'identificationDocumentIssuingOffice',
            'identificationDocumentValidityDate',
            'isFatca',
            'isPep',
            'companyShare',
            ...(allFieldsShown
              ? []
              : ['placeOfBirth', 'countryOfBirth', 'email', 'mobileNumber']),
          ]),
          companyShare:
            typeof values.companyShare === 'number'
              ? +(values.companyShare / 100).toFixed(4)
              : null,
          countryCode: values.countryCode || null,
          salutation: values.salutation!,
          surName: values.surName!,
          givenName: values.givenName!,
          legalRepType: values.legalRepType!,
          uboType: values.uboType!,
          isPep: getBooleanFromYesNo(values.isPep),
          isFatca: getBooleanFromYesNo(values.isFatca),
          taxResidences: values.taxResidences
            .filter((item) => item.taxId !== '' || item.taxResidence !== '')
            .map((item, idx) => ({ ...item, primary: idx === 0 })), // send first valid tax residence as primary if more than exists in formik
          kyc: {
            amlRiskScoring: {
              checkDate: values.amlRiskScoringCheckDate || null,
              result: values.amlRiskScoringResult || null,
            },
            checkResult: true,
            sanctionListCheck: {
              checkDate: values.sanctionListCheckCheckDate || null,
              result: values.sanctionListCheckResult || null,
            },
          },
          legitimation: {
            date: values.legitimationDate || null,
            document: values.legitimationDocument || null,
            provider: values.legitimationProvider || null,
            identificationDocument: {
              documentId: values.identificationDocumentDocumentId || null,
              issuanceDate: values.identificationDocumentIssuanceDate || null,
              issuingOffice: values.identificationDocumentIssuingOffice || null,
              validityDate: values.identificationDocumentValidityDate || null,
            },
          },
        };

        if (mode === 'edit') {
          await api.updateOrganizationRepresentative(
            organization!.id,
            representative!.id.value,
            payload as OrganizationRepresentative
          );
        } else {
          await api.createOrganizationRepresentative(
            organization!.id,
            payload as OrganizationRepresentative
          );
        }
        await fetchRepresentatives();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidTin) {
          const invalidTaxId: string =
            (isAxiosError(error) && error.response?.data?.message) || '';
          const invalidTaxIdIndex = values.taxResidences.findIndex(
            (item) => item.taxId === invalidTaxId
          );
          if (invalidTaxIdIndex > -1) {
            formik.setFieldError(
              `taxResidences.[${invalidTaxIdIndex}].taxId`,
              t('int.organizationRepresentativeSection.invalidTinError')
            );
            return;
          }
        }

        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email || ''
  );

  const isSubmitDisabled =
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting ||
    initialLoading;

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name] ?? '', // select requires empty string instead of null
      disabled: formik.isSubmitting || initialLoading,
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  const allFieldsShown =
    [LegalRepresentativeType.joint, LegalRepresentativeType.sole].includes(
      formik.values.legalRepType as LegalRepresentativeType
    ) ||
    ![UboType.fictive, UboType.other, UboType.shareholding].includes(
      formik.values.uboType as UboType
    );

  const yesNoOptions = [
    { name: 'yes', label: t('common.yes') },
    { name: 'no', label: t('common.no') },
  ];

  useEffect(() => {
    if (allFieldsShown) return;

    formik.setFieldValue(
      'placeOfBirth',
      representative?.placeOfBirth.value || ''
    );
    formik.setFieldError('placeOfBirth', undefined);
    formik.setFieldValue(
      'countryOfBirth',
      representative?.countryOfBirth.value || null
    );
    formik.setFieldError('countryOfBirth', undefined);
    formik.setFieldValue('email', representative?.email.value || '');
    formik.setFieldError('email', undefined);
    formik.setFieldValue(
      'mobileNumber',
      representative?.mobileNumber.value || ''
    );
    formik.setFieldError('mobileNumber', undefined);
  }, [allFieldsShown, representative]);

  useEffect(() => {
    if (relatedIndividual && relatedIndividual.id !== 'manual') {
      (async () => {
        try {
          const data = await api.getRelatedIndividual(
            relatedIndividual.id,
            relatedIndividual.idType!
          );

          if (!mounted.current) return;
          formik.setValues({
            ...formik.values,
            ...(data.shareholderAmountUnit && {
              companyShare: +data.shareholderAmountUnit,
            }),
            ...(data.country && { countryCode: data.country }),
            ...(data.city && { city: data.city }),
            ...(data.firstName && { givenName: data.firstName }),
            ...(data.lastName && { surName: data.lastName }),
            ...(data.postalCode && { postalCode: data.postalCode }),
            ...(data.street && { street: data.street }),
            ...(data.streetNumber && { streetNumber: data.streetNumber }),
          });

          if (!mounted.current) return;
          setInitialLoading(false);
        } catch (error) {
          if (!mounted.current) return;
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          setInitialLoading(false);
          logError(error);
        }
      })();
    }
  }, [relatedIndividual]);

  const disableAdditionalInfoFields =
    (!!representative &&
      [
        OrganizationRepresentativeExternalStatus.infoFilled,
        OrganizationRepresentativeExternalStatus.infoRequested,
      ].includes(representative.externalStatus.value)) ||
    formik.isSubmitting ||
    initialLoading;

  return (
    <Dialog {...props} maxWidth="md">
      <DialogTitle>{t('int.editRepresentativeDialog.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('int.organizationRepresentativeSection.businessBlockTitle')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.legalRepType}>
              <InputLabel>
                {t('int.organizationRepresentativeSection.legalRepLabel')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('legalRepType'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('legalRepType', value as LegalRepresentativeType)
                }
                renderValue={(value) =>
                  t(
                    `int.organizationRepresentativeSection.legalRepType.${value}`
                  )
                }
              >
                {legalRepresentativeTypes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(
                      `int.organizationRepresentativeSection.legalRepType.${item}`
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.legalRepType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.uboType}>
              <InputLabel>
                {t('int.organizationRepresentativeSection.uboLabel')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('uboType'), 'helperText')}
                onChange={({ target: { value } }) => {
                  onChange('uboType', value as UboType);
                  if (
                    value !== UboType.shareholding &&
                    !!formik.values.companyShare
                  ) {
                    formik.setFieldValue('companyShare', null);
                  }
                }}
                renderValue={(value) =>
                  t(
                    `int.organizationRepresentativeSection.uboTypeEdit.${value}`
                  )
                }
              >
                {uboTypes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(
                      `int.organizationRepresentativeSection.uboTypeEdit.${item}`
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.uboType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {UboType.shareholding === formik.values.uboType && (
              <PercentField
                {...getCustomFieldProps('companyShare')}
                onValueChange={({ floatValue = null }) =>
                  onChange('companyShare', floatValue)
                }
                label={t(
                  'int.organizationRepresentativeSection.companyShareLabel'
                )}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t('int.organizationRepresentativeSection.personalBlockTitle')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth error={!!formik.errors.salutation}>
              <InputLabel>
                {t('int.organizationRepresentativeSection.salutationLabel')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('salutation'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('salutation', value as 1 | 2)
                }
                renderValue={(value) => (value === 1 ? 'Mr' : 'Mrs')}
              >
                {[
                  { label: 'Mr', value: 1 },
                  { label: 'Mrs', value: 2 },
                ].map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.salutation}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              {...getCustomFieldProps('givenName')}
              onChange={(event) => onChange('givenName', event.target.value)}
              label={t('int.organizationRepresentativeSection.givenNameLabel')}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              {...getCustomFieldProps('surName')}
              onChange={(event) => onChange('surName', event.target.value)}
              label={t('int.organizationRepresentativeSection.surnameLabel')}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              {...getCustomFieldProps('street')}
              onChange={(event) => onChange('street', event.target.value)}
              label={t('int.organizationRepresentativeSection.streetLabel')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...getCustomFieldProps('streetNumber')}
              onChange={(event) => onChange('streetNumber', event.target.value)}
              label={t(
                'int.organizationRepresentativeSection.streetNumberLabel'
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('postalCode')}
              onChange={(event) => onChange('postalCode', event.target.value)}
              label={t('int.organizationRepresentativeSection.postalCodeLabel')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('city')}
              onChange={(event) => onChange('city', event.target.value)}
              label={t('int.organizationRepresentativeSection.cityLabel')}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete<
              { countryName: string; countryCode: string },
              false,
              true,
              false
            >
              {...omit(getCustomFieldProps('countryCode'), 'value')}
              onChange={(_, option) =>
                onChange('countryCode', option!.countryCode)
              }
              value={countries.find(
                (c) => c.countryCode === formik.values.countryCode
              )}
              options={countries}
              getOptionLabel={(option) => option.countryName}
              label={t(
                'int.organizationRepresentativeSection.countryCodeLabel'
              )}
              disableClearable
              placeholder={t(
                'int.organizationRepresentativeSection.countryCodePlaceholer'
              )}
              noOptionsText={t('common.nothingFound')}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete<
              { countryName: string; countryCode: string },
              false,
              true,
              false
            >
              {...omit(getCustomFieldProps('citizenships'), 'value')}
              onChange={(_, option) =>
                // BE expects string[]
                onChange('citizenships', option ? [option.countryCode] : [])
              }
              value={countries.find(
                (c) => c.countryCode === formik.values.citizenships?.[0]
              )}
              options={countries}
              getOptionLabel={(option) => option.countryName}
              label={t(
                'int.organizationRepresentativeSection.citizenshipLabel'
              )}
              disableClearable
              placeholder={t(
                'int.organizationRepresentativeSection.countryCodePlaceholer'
              )}
              noOptionsText={t('common.nothingFound')}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              {...getCustomFieldProps('dateOfBirth')}
              onChange={(event) => onChange('dateOfBirth', event.target.value)}
              label={t(
                'int.organizationRepresentativeSection.dateOfBirthLabel'
              )}
              type="date"
              inputProps={{
                max: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {allFieldsShown && (
              <TextField
                {...getCustomFieldProps('placeOfBirth')}
                onChange={(event) =>
                  onChange('placeOfBirth', event.target.value)
                }
                label={t(
                  'int.organizationRepresentativeSection.placeOfBirthLabel'
                )}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {allFieldsShown && (
              <Autocomplete<
                { countryName: string; countryCode: string },
                false,
                true,
                false
              >
                {...omit(getCustomFieldProps('countryOfBirth'), 'value')}
                onChange={(_, option) =>
                  onChange('countryOfBirth', option!.countryCode)
                }
                value={countries.find(
                  (c) => c.countryCode === formik.values.countryOfBirth
                )}
                options={countries}
                getOptionLabel={(option) => option.countryName}
                label={t(
                  'int.organizationRepresentativeSection.countryOfBirthLabel'
                )}
                disableClearable
                placeholder={t(
                  'int.organizationRepresentativeSection.countryCodePlaceholer'
                )}
                noOptionsText={t('common.nothingFound')}
              />
            )}
          </Grid>

          {allFieldsShown && (
            <>
              <Grid item xs={6}>
                <TextField
                  {...getCustomFieldProps('email')}
                  onChange={(event) => onChange('email', event.target.value)}
                  label={t('int.organizationRepresentativeSection.emailLabel')}
                  disabled={disableAdditionalInfoFields}
                  error={!!invalidEmailError || !!emailTooLongError}
                  helperText={invalidEmailError || emailTooLongError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...getCustomFieldProps('mobileNumber')}
                  onChange={(event) =>
                    onChange('mobileNumber', event.target.value)
                  }
                  label={t('int.organizationRepresentativeSection.mobileLabel')}
                />
              </Grid>
            </>
          )}

          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.isFatca}>
              <InputLabel>
                {t('int.organizationRepresentativeSection.fatcaLabel')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('isFatca'), 'helperText')}
                onChange={({ target: { value } }) => onChange('isFatca', value)}
                disabled={disableAdditionalInfoFields}
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.isFatca}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.isPep}>
              <InputLabel>
                {t('int.organizationRepresentativeSection.pepLabel')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('isPep'), 'helperText')}
                onChange={({ target: { value } }) => onChange('isPep', value)}
                disabled={disableAdditionalInfoFields}
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.isPep}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={12}>
            <TaxIdNumberInputGroupNew
              canBeEdited={!disableAdditionalInfoFields}
              isLoading={formik.isSubmitting || initialLoading}
              errors={formik.errors?.taxResidences ?? []}
              taxResidences={formik.values.taxResidences}
              onChange={(taxResidences) =>
                formik.setFieldValue('taxResidences', taxResidences)
              }
              onErrorChange={(errors) =>
                formik.setErrors({ taxResidences: errors })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t(
                'int.organizationRepresentativeSection.legitimationBlockTitle'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...getCustomFieldProps('legitimationDate')}
              onChange={(event) =>
                onChange('legitimationDate', event.target.value)
              }
              label={t(
                'int.organizationRepresentativeSection.legitimationDateLabel'
              )}
              type="date"
              inputProps={{
                max: moment().format('YYYY-MM-DD'),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.legitimationProvider}>
              <InputLabel>
                {t(
                  'int.organizationRepresentativeSection.legitimationProviderLabel'
                )}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('legitimationProvider'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange(
                    'legitimationProvider',
                    value as LegitimationProvider | null
                  )
                }
              >
                {Object.values(LegitimationProvider).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(
                      `int.organizationRepresentativeSection.legitimationProvider.${value}`
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.legitimationProvider}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.legitimationDocument}>
              <InputLabel>
                {t(
                  'int.organizationRepresentativeSection.legitimationDocumentLabel'
                )}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('legitimationDocument'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange(
                    'legitimationDocument',
                    value as LegitimationDocument | null
                  )
                }
              >
                {Object.values(LegitimationDocument).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(
                      `int.organizationRepresentativeSection.legitimationDocument.${value}`
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.legitimationDocument}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('identificationDocumentDocumentId')}
              onChange={(event) =>
                onChange('identificationDocumentDocumentId', event.target.value)
              }
              label={t('int.organizationRepresentativeSection.documentIdLabel')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('identificationDocumentIssuingOffice')}
              onChange={(event) =>
                onChange(
                  'identificationDocumentIssuingOffice',
                  event.target.value
                )
              }
              label={t(
                'int.organizationRepresentativeSection.issuingOfficeLabel'
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('identificationDocumentIssuanceDate')}
              onChange={(event) =>
                onChange(
                  'identificationDocumentIssuanceDate',
                  event.target.value
                )
              }
              label={t('int.organizationRepresentativeSection.issueDateLabel')}
              type="date"
              inputProps={{
                max: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('identificationDocumentValidityDate')}
              onChange={(event) =>
                onChange(
                  'identificationDocumentValidityDate',
                  event.target.value
                )
              }
              label={t(
                'int.organizationRepresentativeSection.validityDateLabel'
              )}
              type="date"
              inputProps={{
                min: moment().add(1, 'days').format('YYYY-MM-DD'),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {t(
                'int.organizationRepresentativeSection.complianceScreeningBlockTitle'
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('sanctionListCheckCheckDate')}
              onChange={(event) =>
                onChange('sanctionListCheckCheckDate', event.target.value)
              }
              label={t(
                'int.organizationRepresentativeSection.sanctionDateLabel'
              )}
              type="date"
              inputProps={{
                max: moment().format('YYYY-MM-DD'),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.sanctionListCheckResult}
            >
              <InputLabel>
                {t('int.organizationRepresentativeSection.sanctionResultLabel')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('sanctionListCheckResult'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange('sanctionListCheckResult', value as 'OK' | null)
                }
              >
                {[{ name: 'OK', label: 'OK' }].map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.sanctionListCheckResult}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('amlRiskScoringCheckDate')}
              onChange={(event) =>
                onChange('amlRiskScoringCheckDate', event.target.value)
              }
              label={t('int.organizationRepresentativeSection.amlDateLabel')}
              type="date"
              inputProps={{
                max: moment().format('YYYY-MM-DD'),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.amlRiskScoringResult}>
              <InputLabel>
                {t('int.organizationRepresentativeSection.amlResultLabel')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('amlRiskScoringResult'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange(
                    'amlRiskScoringResult',
                    value as AmlRiskScoring | null
                  )
                }
              >
                {amlRiskScorings.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.amlRiskScoringResult}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {representative && (
          <FileUploadButtons
            isInDialog
            representativeId={representative!.id.value}
            onError={() => formik.setSubmitting(false)}
            onFileSelectOpen={() => formik.setSubmitting(true)}
            onSuccess={(updatedRepresentative: OrganizationRepresentative) => {
              formik.setValues(
                parseOrganisationRepresentative(t, updatedRepresentative)
              );
              formik.setSubmitting(false);
            }}
          />
        )}

        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={isSubmitDisabled} onClick={formik.submitForm}>
          {t(mode === 'edit' ? 'common.button.save' : 'common.button.create')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting || initialLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditRepresentativeDialog);
