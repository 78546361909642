import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import { CompensationBatchStatusBadge } from 'domains/billing/components';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Box,
  Button,
  LoaderWithOverlay,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  WarningCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  CompensationBatchDetails,
  CompensationBatchStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import GenerateCompensationStatementsDialog from './GenerateCompensationStatementsDialog';

interface State {
  isLoading: boolean;
  compensationBatch: CompensationBatchDetails | null;
  error: unknown;
  isGenerateStatementsDialogOpen: boolean;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (batch: CompensationBatchDetails) => void;
}

const CompensationBatchDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { batchId } = useParams<{ batchId: string }>();
  const idRef = useRef(batchId);
  const [state, setState] = useState<State>({
    isLoading: true,
    compensationBatch: null,
    error: null,
    isGenerateStatementsDialogOpen: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const compensationBatch = await api.getCompensationBatchDetails(batchId);
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        compensationBatch,
        isLoading: false,
        error: null,
      }));
      onUpdate(compensationBatch);
    } catch (error) {
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
        compensationBatch: null,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!batchId) return;
    idRef.current = batchId;
    getData();
  }, [batchId]);

  return (
    <>
      <DetailsDrawer {...props}>
        {state.compensationBatch && (
          <>
            <DetailsDrawerHeader>
              <Typography variant="h4" mb={1}>
                {moment(state.compensationBatch.createdAt).format('YYYY-MM-DD')}
              </Typography>
              <CompensationBatchStatusBadge
                status={state.compensationBatch.state}
              />
              <Typography align="right" variant="h5">
                <FormatMoney
                  value={state.compensationBatch.amount}
                  fractionalPart
                />
              </Typography>
              <Typography align="right" variant="caption" component="div">
                {t('int.compensationBatchDetailsPage.batchAmount')}
              </Typography>
            </DetailsDrawerHeader>
            <DetailsDrawerContent>
              {state.compensationBatch.state ===
                CompensationBatchStatus.waiting && (
                <ActionBox
                  icon={<WarningCircleIcon fontSize="inherit" />}
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ActionBoxTitle>
                    {t('int.compensationBatchDetailsPage.submitDescription')}
                  </ActionBoxTitle>
                  <ActionBoxActions>
                    <Button
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isGenerateStatementsDialogOpen: true,
                        }))
                      }
                    >
                      {t('int.compensationBatchDetailsPage.generateStatements')}
                    </Button>
                  </ActionBoxActions>
                </ActionBox>
              )}

              <Box
                p={3}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Typography variant="overline" component="div">
                  {t('int.compensationBatchDetailsPage.info')}
                </Typography>
                <Paper variant="outlined" sx={{ py: 1.5, px: 2.5 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">
                      {t('int.compensationBatchDetailsPage.count')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {state.compensationBatch.count}
                    </Typography>
                  </Box>
                </Paper>
              </Box>

              <Box m={3}>
                <TableContainer>
                  <Table sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 2 / 3 }}>
                          {t('int.compensationBatchDetailsPage.organization')}
                        </TableCell>
                        <TableCell sx={{ width: 1 / 3 }} align="right">
                          {t('int.compensationBatchDetailsPage.amount')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.compensationBatch.redemptionBatches.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.organizationName}</TableCell>
                          <TableCell align="right">
                            <FormatMoney value={item.amount} fractionalPart />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </DetailsDrawerContent>
          </>
        )}
        {state.error && <WidgetError onReload={getData} />}
        <LoaderWithOverlay loading={state.isLoading} />
      </DetailsDrawer>
      <GenerateCompensationStatementsDialog
        open={state.isGenerateStatementsDialogOpen}
        compensationBatch={state.compensationBatch!}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isGenerateStatementsDialogOpen: false,
          }))
        }
        onSuccess={() => {
          setState((prevState) => ({
            ...prevState,
            isGenerateStatementsDialogOpen: false,
          }));
          getData();
        }}
      />
    </>
  );
};

export default withDetailsDrawerWrapper(CompensationBatchDetailsPage);
