import { useTranslation } from 'react-i18next';
import { TransactionAvatar } from 'components/Avatar';
import { Co2EmissionBadge, TransactionBadge } from 'components/Badge';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  TransactionAmount,
  TransactionReviewDropdown,
  VatRateAmount,
} from 'domains/transaction/components';
import { Box, Grid, StatusBadge, Typography } from 'elements';
import {
  AccountingTransaction,
  ExpenseType,
  Transaction,
  TransactionReviewStatus,
  TransactionStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { formatFXAmount } from 'services/utils';
import TransactionDatesInfo from './TransactionDatesInfo';
import TransactionIntegrationStatus from './TransactionIntegrationStatus';

interface Props {
  transaction: Transaction;
  isExportPage?: boolean;
  accountingTransactions?: AccountingTransaction[];
  isEmissionVisible?: boolean;
  isReceiptPreviewDialogOpen?: boolean;
  updateReview?: (status: TransactionReviewStatus) => Promise<void>;
}

const TransactionDetailsHeader = ({
  isExportPage,
  transaction,
  accountingTransactions,
  isEmissionVisible = false,
  isReceiptPreviewDialogOpen,
  updateReview,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { managerTransactionReviewEnabled } = useFlags();
  const {
    state: { featureModules },
  } = useGlobalState();

  return (
    <>
      <TransactionIntegrationStatus
        transaction={transaction}
        isReceiptPreviewDialogOpen={isReceiptPreviewDialogOpen}
      />

      <Box display="flex" justifyContent="space-between" mb={2}>
        <TransactionAvatar size="large" transaction={transaction} />

        <Box textAlign="right" minWidth={0} pl={2}>
          <TransactionBadge
            status={transaction.status}
            simpleType={transaction.simpleType}
            bookingDate={transaction.bookingDate}
          />

          <TransactionAmount
            variant="h4"
            status={transaction.status}
            simpleType={transaction.simpleType}
            data-test-id="tx-amount"
          >
            <FormatMoney
              value={transaction.transactionAmount}
              fractionalPart
              positiveSign
            />
          </TransactionAmount>

          <TransactionAmount
            variant="caption"
            color="textSecondary"
            status={transaction.status}
            simpleType={transaction.simpleType}
            data-test-id="tx-fx-amount"
          >
            {formatFXAmount(
              transaction.transactionAmount,
              transaction.fxTransactionAmount,
              i18n.language
            )}
          </TransactionAmount>

          {transaction.status !== TransactionStatus.declined &&
            accountingTransactions && (
              <VatRateAmount accountingTransactions={accountingTransactions} />
            )}

          {isEmissionVisible && !!transaction.emission && (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Co2EmissionBadge
                value={transaction.emission}
                unit="kg"
                IconProps={{ fontSize: 'small' }}
                TypographyProps={{
                  variant: 'caption',
                  color: 'textSecondary',
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" data-test-id="tx-merchant-name">
          {transaction.merchantName}
        </Typography>

        {featureModules.PRIVATE_EXPENSE &&
          transaction.expenseType === ExpenseType.private && (
            <StatusBadge
              color="primary"
              label={t('common.badge.privateExpenseStatus')}
              sx={{ ml: 1 }}
            />
          )}
      </Box>

      {transaction.merchantNameConfirmation &&
        transaction.merchantNameConfirmation !== transaction.merchantName && (
          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-test-id="tx-merchant-name-confirmation"
          >
            {transaction.merchantNameConfirmation}
          </Typography>
        )}

      <Grid container columnSpacing={3} rowSpacing={1} mt={1}>
        <TransactionDatesInfo transaction={transaction} />

        {updateReview &&
          !isExportPage &&
          managerTransactionReviewEnabled &&
          featureModules.MANAGER_TX_REVIEWS && (
            <Grid item display="flex" alignItems="center" ml="auto">
              <TransactionReviewDropdown
                reviewStatus={transaction.reviewStatus}
                reviewedAt={transaction.reviewedAt}
                reviewerFullName={transaction.reviewerFullName}
                updateReview={updateReview}
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default TransactionDetailsHeader;
