import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AppLogo from 'components/AppLogo';
import { useGlobalState } from 'context/GlobalState';
import { Box } from 'elements';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import AppSwitchButton from './AppSwitchButton';
import CardholderNavigation from './CardholderNavigation';
import NotificationsMenu from './NotificationsMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import ReceiptInboxMenu from './ReceiptInboxMenu';
import SupportMenu from './SupportMenu';
import UserMenu from './UserMenu';
import { MenuSection, StyledMainHeader } from './style';

const CardholderMainHeader = () => {
  const theme = useTheme();
  const { receiptInboxEnabled } = useFlags();
  const canUser = useCanUser();
  const {
    state: { featureModules },
  } = useGlobalState();

  return (
    <StyledMainHeader navigation>
      <Box
        component={Link}
        to="/"
        sx={{ mr: 5, lineHeight: 1, textDecoration: 'none' }}
      >
        <AppLogo mode={theme.navigationMode} />
      </Box>
      <CardholderNavigation />
      <MenuSection>
        {!receiptInboxEnabled && featureModules.RECEIPT_MANAGEMENT && (
          <ReceiptInboxMenu />
        )}
        {canUser('receipt-inbox:view') &&
          receiptInboxEnabled &&
          featureModules.RECEIPT_MANAGEMENT && <ReceiptInboxButton />}
        <SupportMenu />
        <NotificationsMenu />
        <UserMenu />
        <AppSwitchButton mode={theme.navigationMode} />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default CardholderMainHeader;
