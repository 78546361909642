import { useTranslation } from 'react-i18next';
import { Paper } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import FlagsSection from './FlagsSection';
import FxFeeSection from './FxFeeSection';
import PremiumCardFeeSection from './PremiumCardFeeSection';
import SubscriptionPlanParamsSection from './SubscriptionPlanParamsSection';
import SubscriptionPlanTypeSection from './SubscriptionPlanTypeSection';

const SubscriptionSettingsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('int.subscriptionSettingsPage.title')}
          suptitle={t('int.sidebar.terms.title')}
        />
      </PageHeader>
      <PageContent>
        <ContentContainer>
          <Paper variant="tinted" sx={{ mb: 3 }}>
            <SubscriptionPlanTypeSection />
            <SubscriptionPlanParamsSection />
            <PremiumCardFeeSection />
          </Paper>
          <FlagsSection />
          <FxFeeSection />
        </ContentContainer>
      </PageContent>
    </>
  );
};

export default withPageConfig(SubscriptionSettingsPage, {
  permission: 'subscription-settings-page:visit',
});
