import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import { PaymentStatusBadge } from 'domains/billing/components';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Box,
  Button,
  Chip,
  FileCsvIcon,
  IconButton,
  LoaderWithOverlay,
  Paper,
  Stack,
  Tooltip,
  Typography,
  WarningCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  PaymentStatus,
  ReceivableRepayment,
  ReceivableRepaymentAmount,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';
import RepaymentsList from './RepaymentsList';

interface State {
  isLoading: boolean;
  repayment: ReceivableRepayment | null;
  repaymentsPerOrg: ReceivableRepaymentAmount[];
  repaymentsLoadedPage: number;
  hasMoreRepayments: boolean;
  areRepaymentsLoading: boolean;
  repaymentsError: unknown;
  error: unknown;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (repayment: ReceivableRepayment) => void;
}

const ReceivableRepaymentDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const { repaymentId } = useParams<{ repaymentId: string }>();
  const mounted = useMounted();
  const idRef = useRef(repaymentId);
  const contentRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>({
    isLoading: true,
    repayment: null,
    repaymentsPerOrg: [],
    repaymentsLoadedPage: 0,
    hasMoreRepayments: false,
    areRepaymentsLoading: false,
    repaymentsError: null,
    error: null,
  });
  const canSubmit = state.repayment?.status === PaymentStatus.readyForPmt;

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        repaymentsLoadedPage: 0,
      }));
      const [
        repayment,
        { repayments: repaymentsPerOrg, hasNextPage: hasMoreRepayments },
      ] = await Promise.all([
        api.getReceivableRepayment(repaymentId),
        api.getRepaymentAmountsPerOrganisation(repaymentId, {
          page: state.repaymentsLoadedPage,
        }),
      ]);
      if (!mounted.current || repaymentId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        repayment,
        repaymentsPerOrg,
        hasMoreRepayments,
        error: null,
        repaymentsError: null,
        isLoading: false,
      }));
      contentRef.current?.scrollTo({ top: 0 });
    } catch (error) {
      if (!mounted.current || repaymentId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        repayment: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const getNextRepaymentsPage = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        areRepaymentsLoading: true,
        repaymentsError: null,
      }));
      const {
        repayments: repaymentsPerOrg,
        hasNextPage: hasMoreRepayments,
      } = await api.getRepaymentAmountsPerOrganisation(repaymentId, {
        page: state.repaymentsLoadedPage,
      });
      setState((prevState) => ({
        ...prevState,
        repaymentsPerOrg: [...prevState.repaymentsPerOrg, ...repaymentsPerOrg],
        hasMoreRepayments,
        areRepaymentsLoading: false,
        repaymentsLoadedPage: prevState.repaymentsLoadedPage + 1,
      }));
      if (!mounted.current || repaymentId !== idRef.current) return;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        areRepaymentsLoading: false,
        repaymentsError: error,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!repaymentId) return;
    idRef.current = repaymentId;
    getData();
  }, [repaymentId]);

  useEffect(() => {
    if (state.repayment) onUpdate(state.repayment);
  }, [state.repayment]);

  const onSubmitReceivableRepayment = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.submitReceivableRepayment(state.repayment!.paymentFileId);
      if (!mounted.current || repaymentId !== idRef.current) return;
      await getData();
    } catch (error) {
      if (!mounted.current || repaymentId !== idRef.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleDownloadFile = async () => {
    try {
      const response = await api.downloadPaymentFile(
        state.repayment!.paymentFileName
      );
      if (!mounted) return;
      downloadResponseAsFile(response);
    } catch (error) {
      if (!mounted) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DetailsDrawer {...props}>
        {state.repayment && (
          <>
            <DetailsDrawerHeader>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h4" component="span" mr={1}>
                  {moment(state.repayment.createdAt).format('YYYY-MM-DD')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mr="auto"
                >
                  <Chip
                    label={t(
                      `int.accountGroupSelect.items.${state.repayment.accountGroup}`
                    )}
                    size="small"
                  />
                  <Chip label={state.repayment.currency} size="small" />
                </Stack>
                <IconButton onClick={handleDownloadFile}>
                  <FileCsvIcon />
                </IconButton>
              </Box>
              <PaymentStatusBadge status={state.repayment.status} />
              <Typography align="right" variant="h5">
                <FormatMoney value={state.repayment.amount} fractionalPart />
              </Typography>
              <Typography align="right" variant="caption" component="div">
                {t('int.receivableRepaymentDetailsPage.amount')}
              </Typography>
            </DetailsDrawerHeader>
            <DetailsDrawerContent
              onScroll={(e) => {
                const target = e.currentTarget;
                if (
                  !state.repaymentsError &&
                  state.hasMoreRepayments &&
                  !state.areRepaymentsLoading &&
                  target.scrollTop + target.clientHeight >=
                    target.scrollHeight - 60
                ) {
                  getNextRepaymentsPage();
                }
              }}
            >
              {canSubmit && (
                <ActionBox
                  icon={<WarningCircleIcon fontSize="inherit" />}
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ActionBoxTitle>
                    {t('int.receivableRepaymentDetailsPage.submitButtonTitle')}
                  </ActionBoxTitle>
                  <ActionBoxActions>
                    <Button
                      disabled={state.isLoading}
                      onClick={onSubmitReceivableRepayment}
                    >
                      {t('int.receivableRepaymentDetailsPage.submitButton')}
                    </Button>
                  </ActionBoxActions>
                </ActionBox>
              )}

              <Box
                p={3}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Typography variant="overline" component="div">
                  {t('int.receivableRepaymentDetailsPage.info')}
                </Typography>
                <Paper variant="outlined" sx={{ py: 1.5, px: 2.5 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">
                      {t('int.receivableRepaymentDetailsPage.cardAccountCount')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {state.repayment.cardAccountCount}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={0.5}
                  >
                    <Typography variant="body1">
                      {t('int.receivableRepaymentDetailsPage.createdAt')}
                    </Typography>
                    <Tooltip
                      title={t('int.receivableRepaymentDetailsPage.berlinTime')}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {moment(state.repayment.createdAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={0.5}
                  >
                    <Typography variant="body1">
                      {t('int.receivableRepaymentDetailsPage.submittedAt')}
                    </Typography>
                    <Tooltip
                      title={t('int.receivableRepaymentDetailsPage.berlinTime')}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {moment(state.repayment.submittedAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>

                  {state.repayment.plannedPaymentDate && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={0.5}
                    >
                      <Typography variant="body1">
                        {t(
                          'int.receivableRepaymentDetailsPage.plannedPaymentDate'
                        )}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {state.repayment.plannedPaymentDate}
                      </Typography>
                    </Box>
                  )}
                  {state.repayment.actualPaymentDate && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={0.5}
                    >
                      <Typography variant="body1">
                        {t(
                          'int.receivableRepaymentDetailsPage.actualPaymentDate'
                        )}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {state.repayment.actualPaymentDate}
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Box>

              <RepaymentsList
                repayments={state.repaymentsPerOrg}
                hasNextPage={state.hasMoreRepayments}
                onLoadMore={getNextRepaymentsPage}
                error={state.repaymentsError}
              />
            </DetailsDrawerContent>
          </>
        )}
        {state.error && <WidgetError onReload={getData} />}
        <LoaderWithOverlay loading={state.isLoading} />
      </DetailsDrawer>
    </>
  );
};

export default withDetailsDrawerWrapper(ReceivableRepaymentDetailsPage);
