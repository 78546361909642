import { pick } from 'lodash';

export const commonPaths = {
  membershipActivation: '/membership-activation/:orgId',
};

export const cardholderPaths = {
  profile: '/profile',
  wallet: '/wallet',
  transactions: '/transactions',
  transactionDetails: '/transactions/:transactionId',
  teamTransactions: '/team-transactions',
  teamTransactionDetails: '/team-transactions/:transactionId',
  transactionsReview: '/transactions-review',
  transactionsReviewDetails: '/transactions-review/:transactionId',
  teams: '/teams',
  teamDetails: '/teams/:teamId',
  teamMemberDetailsPartial: '/teams/:teamId/members',
  teamMemberDetails: '/teams/:teamId/members/:memberId',
  teamCardDetailsPartial: '/teams/:teamId/cards',
  teamCardDetails: '/teams/:teamId/cards/:cardId',
  rewards: '/rewards',
  rewardsCardBenefits: '/rewards/card-benefits',
  rewardsPartnerDeals: '/rewards/partner-deals',
};

const adminPathPrefix = '/admin';

export const adminPaths = {
  onboardingRole: `${adminPathPrefix}/onboarding-role`,
  onboarding: `${adminPathPrefix}/onboarding`,
  profile: `${adminPathPrefix}/profile`,
  dashboard: `${adminPathPrefix}/dashboard`,
  cardAccounts: `${adminPathPrefix}/card-accounts`,
  cards: `${adminPathPrefix}/cards`,
  cardDetails: `${adminPathPrefix}/cards/:cardId`,
  transactions: `${adminPathPrefix}/transactions`,
  transactionDetails: `${adminPathPrefix}/transactions/:transactionId`,
  transactionsReview: `${adminPathPrefix}/transactions-review`,
  transactionsReviewDetails: `${adminPathPrefix}/transactions-review/:transactionId`,
  accounting: `${adminPathPrefix}/account`,
  accountingTransaction: `${adminPathPrefix}/account/transactions`,
  accountingTransactionDetails: `${adminPathPrefix}/account/transactions/:transactionId`,
  accountingEntry: `${adminPathPrefix}/account/entries`,
  accountingEntryDetails: `${adminPathPrefix}/account/entries/:entryId`,
  statements: `${adminPathPrefix}/statements`,
  billing: `${adminPathPrefix}/billing`,
  billingPayments: `${adminPathPrefix}/billing-payments`,
  billingStatements: `${adminPathPrefix}/billing-statements`,
  members: `${adminPathPrefix}/members`,
  memberDetails: `${adminPathPrefix}/members/:memberId`,
  teams: `${adminPathPrefix}/teams`,
  export: `${adminPathPrefix}/export`,
  exportQueue: `${adminPathPrefix}/export-queue`,
  exported: `${adminPathPrefix}/exported`,
  exportTransactionDetails: `${adminPathPrefix}/export/:transactionId`,
  exportQueueTransactionDetails: `${adminPathPrefix}/export-queue/:transactionId`,
  exportedTransactionDetails: `${adminPathPrefix}/exported/:transactionId`,
  rewards: `${adminPathPrefix}/rewards`,
  rewardsCashback: `${adminPathPrefix}/rewards/cashback`,
  rewardsPartnerDeals: `${adminPathPrefix}/rewards/partner-deals`,
  rewardsCardBenefits: `${adminPathPrefix}/rewards/card-benefits`,
  merchants: `${adminPathPrefix}/merchants`,
  merchantDetails: `${adminPathPrefix}/merchants/:merchantId`,
  settings: `${adminPathPrefix}/settings`,
  settingsIntegrations: `${adminPathPrefix}/settings/integrations`,
  settingsOrganization: `${adminPathPrefix}/settings/organization`,
  settingsBankAccounts: `${adminPathPrefix}/settings/organization/bank-accounts`,
  settingsReceiptManagement: `${adminPathPrefix}/settings/organization/receipt-management`,
  settingsSubscriptionPlan: `${adminPathPrefix}/settings/organization/subscription-plan`,
  settingsFeatureModules: `${adminPathPrefix}/settings/feature-modules`,
  settingsAccounting: `${adminPathPrefix}/settings/accounting`,
  settingsAccounts: `${adminPathPrefix}/settings/accounting/accounts`,
  settingsCategories: `${adminPathPrefix}/settings/accounting/categories`,
  settingsVatRates: `${adminPathPrefix}/settings/accounting/vat-rates`,
  settingsProjects: `${adminPathPrefix}/settings/accounting/projects`,
  settingsTeams: `${adminPathPrefix}/settings/accounting/teams`,
  settingsSuppliers: `${adminPathPrefix}/settings/accounting/suppliers`,
  settingsDatev: `${adminPathPrefix}/settings/accounting/datev`,
  settingsDatevOld: `${adminPathPrefix}/settings/datev`,
  settingsBmd: `${adminPathPrefix}/settings/accounting/bmd`,
  settingsLocoia: `${adminPathPrefix}/settings/accounting/locoia`,
  settingsAccountingSystem: `${adminPathPrefix}/settings/accounting/accounting-system`,
  pliantEarth: `${adminPathPrefix}/pliant-earth`,
  partner: `${adminPathPrefix}/partner`,
};

export const partnerPaths = {
  partners: '/partners',
  partnerIntegrationStatus: '/partners/integration', // for partner's status output on auth success/error
  partnerAuthPage: '/partners/:partnerId',
};

export const whiteLabeledPaths = {
  ...pick(adminPaths, [
    'onboardingRole',
    'onboarding',
    'profile',
    'settings',
    'settingsOrganization',
    'members',
    'memberDetails',
    'partner',
  ]),
  ...commonPaths,
};

export type AdminPathsKeys = keyof typeof adminPaths;
