import React, { useEffect, useRef, useState } from 'react';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import OrganizationMerchantDetailsPage from 'domains/merchant/pages/OrganizationMerchantDetailsPage';
import {
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  StorefrontIcon,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  countryCodesArray,
  DEFAULT_PAGE_LIMIT,
  GetOrganizationMerchantsParams,
  OrganizationMerchant,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { isSortValid } from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { sort, q, country } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+transactionsVolume',
    q: q ? q.trim() : '',
    country: country ? intersection(country.split(','), countryCodesArray) : [],
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ country }: QueryParams): number =>
  +!!country.length;

interface State {
  isLoading: boolean;
  merchants: OrganizationMerchant[];
  hasNextPage: boolean;
  totalCount: number;
  isSearchOpen: boolean;
}

const OrganizationMerchantsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const dataGridRef = useGridApiRef();
  const showPageError = useShowPageError();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:merchantId',
    true
  );
  const { columns, allowedSortKeys } = useColumns();
  const api = useImperativeApi();
  const mounted = useMounted();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const [state, setState] = useState<State>({
    isLoading: true,
    merchants: [],
    hasNextPage: false,
    totalCount: 0,
    isSearchOpen: false,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.merchants.length && !areFiltersApplied;

  const getRequestParams = (): GetOrganizationMerchantsParams => {
    const { sort, q, country } = paramsRef.current;

    return {
      sort,
      q: q.length ? q : undefined,
      countries: country.length ? country.join() : undefined,
    };
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      }
      const params = getRequestParams();
      const { merchants, hasNextPage, totalCount } = await api.getOrgMerchants(
        organization!.id,
        {
          ...params,
          page,
          limit,
        }
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        merchants: isLoadMore
          ? [...prevState.merchants, ...merchants]
          : merchants,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (state.isLoading || !state.merchants.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sortModel));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('organizationMerchantsPage.header')}></PageTitle>
        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          totalCount={state.totalCount}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<OrganizationMerchant>
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.merchantId ? [detailsParams.merchantId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.merchants}
          rowCount={state.totalCount}
          columns={columns}
          columnVisibilityModel={{
            displayName: true,
            country: true,
            teamResponses: !isDetailsPageOpen && featureModules.TEAMS,
            previousMonthTransactionsVolume: !isDetailsPageOpen,
            currentTransactionsVolume: !isDetailsPageOpen,
            transactionsCount: true,
            transactionsVolume: true,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          onSortModelChange={handleSortModelChange}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.merchants.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={StorefrontIcon}
                  label={t('organizationMerchantsPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:merchantId`}
          children={({ match }) => (
            <OrganizationMerchantDetailsPage
              open={!!match}
              onUpdate={(merchant) =>
                setState((prevState) => ({
                  ...prevState,
                  merchants: state.merchants.map((item) =>
                    item.id === merchant.id ? merchant : item
                  ),
                }))
              }
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(OrganizationMerchantsPage, {
  permission: 'organization-merchants-page:visit',
  featureFlag: 'organizationMerchantsPageEnabled',
});
