import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { UpdateFxFeePercentageDialog } from 'domains/terms/dialogs';
import { EditButton, Paper, Typography } from 'elements';
import { useCanUser } from 'services/rbac';

const FxFeeSection = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { subscriptionPlan },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ display: 'flex', alignItems: 'center', p: 2 }}
      >
        <Typography sx={{ width: '33%', py: '10px' }}>
          {t('int.subscriptionSettingsPage.fxFeeSection.title')}
        </Typography>
        <Typography sx={{ flexGrow: 1 }}>
          <FormatPercent value={subscriptionPlan.fxFeePercentage} />
        </Typography>
        {canUser('subscription-plan:update') && (
          <EditButton onClick={() => setIsDialogOpen(true)} />
        )}
      </Paper>

      <UpdateFxFeePercentageDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default FxFeeSection;
