import React, { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from 'components/MainHeader/style';
import { useGlobalState } from 'context/GlobalState';
import {
  BookOpenTextIcon,
  CaretRightIcon,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  PhoneIcon,
  Popover,
  Popper,
  ProjectorScreenIcon,
  QuestionIcon,
  Tooltip,
} from 'elements';
import useUrls from 'hooks/useUrls';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

const SupportMenu = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { bookDemoSupportMenuItemEnabled } = useFlags();
  const {
    HELP_CENTER_URL,
    BOOK_CONSULTING_SESSION,
    BOOK_GENERAL_ONBOARDING_WEBINAR,
    BOOK_ACCOUNTING_ONBOARDING_WEBINAR,
    FAQ_URL,
  } = useUrls();
  const {
    state: { generalInfo },
  } = useGlobalState();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const subMenuItemRef = useRef() as React.MutableRefObject<HTMLLIElement>;

  const openSubMenu = () => setSubMenuAnchorEl(subMenuItemRef.current);

  const closeSubMenu = () => setSubMenuAnchorEl(null);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const renderBookSessionsAndWebinarsMenuItem = () => {
    return (
      <div onMouseEnter={openSubMenu} onMouseLeave={closeSubMenu}>
        <MenuItem ref={subMenuItemRef} selected={!!subMenuAnchorEl}>
          <ListItemIcon>
            <ProjectorScreenIcon />
          </ListItemIcon>
          <ListItemText primary={t('header.supportMenu.bookSessions')} />
          <CaretRightIcon
            fontSize="small"
            sx={{ color: 'text.secondary', ml: 3 }}
          />
        </MenuItem>

        <Popper
          anchorEl={subMenuAnchorEl}
          open={!!subMenuAnchorEl}
          placement="right-start"
          disablePortal
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper elevation={8}>
                <MenuList dense>
                  {bookDemoSupportMenuItemEnabled &&
                    canUser('consulting-session:book') && (
                      <MenuItem
                        component="a"
                        href={BOOK_CONSULTING_SESSION}
                        target="_blank"
                        onClick={closeMenu}
                      >
                        {t('header.supportMenu.consultingSession')}
                      </MenuItem>
                    )}

                  <MenuItem
                    component="a"
                    href={BOOK_GENERAL_ONBOARDING_WEBINAR}
                    target="_blank"
                    onClick={closeMenu}
                  >
                    {t('header.supportMenu.webinarForGeneralOnboarding')}
                  </MenuItem>

                  {canUser('accounting-webinar:book') && (
                    <MenuItem
                      component="a"
                      href={BOOK_ACCOUNTING_ONBOARDING_WEBINAR}
                      target="_blank"
                      onClick={closeMenu}
                    >
                      {t('header.supportMenu.webinarForAccountingOnboarding')}
                    </MenuItem>
                  )}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  return (
    <>
      <Tooltip title={t('header.supportMenu.tooltip')}>
        <StyledIconButton
          onClick={openMenu}
          className={!!anchorEl ? 'selected' : ''}
        >
          <QuestionIcon />
        </StyledIconButton>
      </Tooltip>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        PaperProps={{ sx: { overflow: 'visible' } }}
      >
        <MenuList dense>
          <MenuItem
            component="a"
            href={`tel:${generalInfo.supportPhone}`}
            onClick={closeMenu}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            {generalInfo.supportPhoneFormatted}
          </MenuItem>

          <MenuItem
            component="a"
            href={HELP_CENTER_URL}
            target="_blank"
            onClick={closeMenu}
          >
            <ListItemIcon>
              <BookOpenTextIcon />
            </ListItemIcon>
            {t('header.supportMenu.helpCenter')}
          </MenuItem>

          {renderBookSessionsAndWebinarsMenuItem()}

          <MenuItem
            component="a"
            href={FAQ_URL}
            target="_blank"
            onClick={closeMenu}
          >
            <ListItemIcon>
              <QuestionIcon />
            </ListItemIcon>
            {t('header.supportMenu.faq')}
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

export default SupportMenu;
